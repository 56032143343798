import {useState, useEffect } from 'react';
import { PageVisitUtils, Pages } from '../utils/pageVisitUtils';

function useShowPageHelp(page?: Pages) {
    const [showHelp, setShowHelp] = useState(false);

    useEffect(() => {
        const pagesVisited = PageVisitUtils.getPagesVisited();
        // If we don't want a page, it's because we are initializing the PageVisitUtil.
        if (page) {
            if (pagesVisited) {
                const shouldShowHelp = pagesVisited[page].showTooltip
                setShowHelp(shouldShowHelp);
            }

            return PageVisitUtils.setPageInterestValue(page, 'showTooltip', false);
        }
    }, []);

    return showHelp;
}

export default useShowPageHelp;
