import * as React from 'react';
import styled from "styled-components";


const DividingLine = () => {
    return (
        <LineWrapper>
            <Line />
            <OR>OR</OR>
            <Line />
        </LineWrapper>
    );
};

const OR = styled.b`
    margin: 0 15px;
`;

const LineWrapper = styled.div`
    display: flex;
    width: 100%;
    max-width: 800px;
    align-items: center;
    margin: 15px 0;
`;

export const Line = styled.div`
    width: 100%;
    height: 1px;
    border-top: solid 1px white;
    margin: 10px 0;
`;

export default DividingLine;
