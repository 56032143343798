import querystring from 'query-string'

const refCodeKey = 'referralCode';
const refToLoc = 'referralToLocation';

export const getRefCodeAndSetIfPresent = () => {
    try {
        const referralToLocation = (new URL(document.location.href)).pathname;
        const query = querystring.parse(document.location.search);
        let refCode = query?.ad;
        if(query?.ad) {
            window.localStorage.setItem(refCodeKey, query.ad!.toString());
            window.localStorage.setItem(refToLoc, referralToLocation)

        } else if(window.localStorage[refCodeKey]) {
            refCode = window.localStorage[refCodeKey];
        }
        return refCode;
    } catch (e) {
        console.log("Could not get referralCode: ", e);
        return null;
    }
};

export const getRefToLocation = () => {
    try {
       return (window.localStorage[refToLoc])?? null;
    } catch (e) {
        console.log("Could not get referralToLocation: ", e);
        return null;
    }
};

export const clearRefCodeAndLoc = () => {
    try {
        window.localStorage.removeItem(refCodeKey);
        window.localStorage.removeItem(refToLoc);
    } catch (e) {
        console.log("Could not clear refCodeKey or refToLoc: ", e);
    }
};
