import * as React from 'react';
import styled from 'styled-components/macro';
import { LargeButton } from '../inputs/Button';

interface Props {
    image: string;
    link: string;
    text: string;
    buttonText: string;
    maxHeight?: number;
    onClick?: () => void;
}

const CallToActionFullWidth: React.FC<Props> = ({ image, link, text, buttonText, maxHeight, onClick }) => {
    return (
        <Container image={image} maxHeight={maxHeight}>
            <Image image={image} />
            <ContentWrapper>
                <Text>{text}</Text>
                {link && (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        <LargeButton >{buttonText}</LargeButton>
                    </a>
                )}
                {!link && (
                    <LargeButton onClick={onClick} >{buttonText}</LargeButton>
                )}
                
            </ContentWrapper>
        </Container>
    );
};

const Container = styled.article<{ image: string; maxHeight?: number }>`
    position: sticky;
    width: 100vw;
    height: 600px;
    max-height: 600px;
`;

const Image = styled.div<{ image: string }>`
    position: absolute;
    bottom: 0px;
    width: 100vw;
    height: 100%;

    opacity: 0.4;
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
`;

const ContentWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Text = styled.h2`
    text-shadow: 3px 3px 18px rgba(0, 0, 0, 0.9);
    font-family: all-round-gothic, sans-serif;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 0.5px;
    text-align: center;
    margin-bottom: 60px;
`;

export default CallToActionFullWidth;
