import * as React from 'react';
import { useEffect, useState } from 'react';
import { getApi, getApiBaseUrl, postApi, putApi } from '../api/api';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import IconCopy from '../images/atom-i-link.svg';
import IconCopyHover from '../images/atom-i-link-green.svg';
import {
    Engine,
    Scene,
    ThinEngine,
    EnvironmentTextureTools,
    EquiRectangularCubeTexture,
    HDRFiltering,
    Tools
} from '@babylonjs/core';
import styled from 'styled-components';
import { last } from 'lodash';

interface SceneComponentProps {
    antialias?: boolean;
    engineOptions?: any;
    adaptToDeviceRatio?: boolean;
    sceneOptions?: any;
    onRender?: (scene: Scene) => void;
    onSceneReady?: (scene: Scene) => void;
}

const InstantMeet: React.FunctionComponent<SceneComponentProps> = ({
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
    ...rest
}) => {
    const search = useLocation().search;
    const history = useHistory();

    const [instantMeetReady, setInstantMeetReady] = useState(false);
    const [meetInviteCode, setMeetInviteCode] = useState('');

    const [lastPresetSkyboxUrl, setLastPresetSkyboxUrl] = useState('');

    const [meetEnvIdName, setMeetEnvIdName] = useState('');
    const [spaceId, setSpaceId] = useState('');
    const [loadingString, setLoadingString] = useState('Loading Space...');
    const [skyboxUrl, setSkyboxUrl] = useState(() => {
        return String(new URLSearchParams(search).get('skyboxUrl') ?? '');
    });
    const [envId, setEnvId] = useState(() => {
        return String(new URLSearchParams(search).get('envId') ?? '');
    });

    const [scene, setScene] = useState({} as Scene);
    const [encodedImagetype, setEncodedImageType] = useState(1);
    const [encodedImageQuality, setEncodedImageQuality] = useState(8);

    const reactCanvas = React.useRef(null);
    const _envOptions = { imageTypeIndex: 1, imageQuality: 0.8 };

    const envExportImageTypes = [
        { label: 'PNG', value: 0, imageType: 'image/png' },
        { label: 'WebP', value: 1, imageType: 'image/webp' }
    ];

    const checkIsIOS = () => {
        return (
            /iPad|iPhone|iPod|iPhone Simulator|iPad Simulator|iPad Simulator/.test(navigator.platform) ||
            (navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2 &&
                /MacIntel|Macintosh/.test(navigator.platform)) ||
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        );
    };

    useEffect(() => {
        // Check if the canvas ref is defined
        console.log('SKYBOX:: useEffect: reactCanvas.current', reactCanvas.current);
        if (!reactCanvas.current || checkIsIOS()) return;
        // Create a new Babylon.js engine using the canvas element
        const engine = new Engine(
            reactCanvas.current,
            antialias,
            {
                autoEnableWebVR: false,
                powerPreference: 'high-performance'
            },
            adaptToDeviceRatio
        );
        console.log('NEW ENGINE CREATED');
        // Create a new Babylon.js scene using the engine
        const scene = new Scene(engine, sceneOptions);
        scene.skipFrustumClipping = true;
        scene.skipPointerMovePicking = true;

        // Check if the scene is ready
        if (scene.isReady()) {
            // If the scene is ready, call the onSceneReady function
            if (onSceneReady) onSceneReady(scene);
            setScene(scene);

            getMeet().then((meet) => {
                console.log('getMeet response: ', meet);
                if (meet.state.skyboxPreset && meet.state.skyboxPreset.value) {
                    setLastPresetSkyboxUrl(meet.state.skyboxPreset.value.sourceUrl);
                }
                setMeetInviteCode(meet.meetInviteCode);
                setMeetEnvIdName(meet.space.environment.idName);
                setSpaceId(meet.space.id);
            });
        } else {
            // If the scene is not ready yet, add an observer to call the onSceneReady function once the scene is ready
            if (onSceneReady) scene.onReadyObservable.addOnce((scene) => onSceneReady(scene));
            scene.onReadyObservable.addOnce((scene) => {
                getMeet().then((meet) => {
                    setScene(scene);
                    setMeetInviteCode(meet.meetInviteCode);
                    setMeetEnvIdName(meet.space.environment.idName);
                    setSpaceId(meet.space.id);
                });
            });
        }

        // Set up a resize function to resize the engine when the window is resized
        const resize = () => {
            scene.getEngine().resize();
        };
        if (window) {
            window.addEventListener('resize', resize);
        }
        // Return a cleanup function to dispose of the engine and remove the event listener when the component is unmounted
        return () => {
            scene.getEngine().dispose();
            if (window) {
                window.removeEventListener('resize', resize);
            }
        };
    }, []);

    const createEquiRectangularTexture = async (scene: Scene, filepath: string, resolution = 256, gamma: boolean) => {
        return await new Promise<EquiRectangularCubeTexture>((resolve, reject) => {
            const texture = new EquiRectangularCubeTexture(
                filepath,
                scene,
                resolution,
                false,
                gamma,
                () => {
                    resolve(texture);
                },
                () => {
                    reject();
                }
            );
        });
    };

    const prefilterEquirectangularCubeTextureAsync = async (
        texture: EquiRectangularCubeTexture,
        engine: ThinEngine
    ) => {
        try {
            return await new Promise<EquiRectangularCubeTexture>((resolve, reject) => {
                const filtering = new HDRFiltering(engine);
                filtering.prefilter(texture, () => {
                    texture.gammaSpace = false;
                    texture.lodGenerationScale = 0.8;
                    console.log('SKYBOX:: prefiltering done');
                    resolve(texture);
                });
            });
        } catch (error) {
            console.error('Error prefiltering cube texture:', error);
        }
    };
    const createEnvTextureFromJpg = async (filepath: string, scene: Scene, callback?: () => void) => {
        //OLD
        console.log('SKYBOX:: createEnvTextureFromJpg in instantMeet', filepath);
        const jpgCubeTexture = await createEquiRectangularTexture(
            scene,
            filepath,
            128,
            true //this is confusing -- needs to be set to true if you will be using it with PBR materials
        ).catch((e) => console.error(e));
        const envTextureArgs = {
            imageType: envExportImageTypes[encodedImagetype].imageType,
            imageQuality: encodedImageQuality / 10
        };
        if (!jpgCubeTexture) return;

        console.log('SKYBOX:: jpgCubeTexture created', filepath);
        const prefilteredJpgCubeTexture = await prefilterEquirectangularCubeTextureAsync(
            jpgCubeTexture,
            scene.getEngine()
        );

        if (!prefilteredJpgCubeTexture) {
            console.warn('SKYBOX:: cube texture failed in prefiltering process');
            return;
        }
        return EnvironmentTextureTools.CreateEnvTextureAsync(prefilteredJpgCubeTexture, envTextureArgs).then(
            async (buffer: ArrayBuffer) => {
                const blob = new Blob([buffer], { type: 'octet/stream' });
                console.log('SKYBOX:: CreateEnvTextureAsync: ', buffer, blob);
                // TODO: DEBUGGING REMOVE THIS
                return await uploadEnv(blob).then((url) => {
                    console.log('SKYBOX:: UploadEnv: ', url);
                    return url;
                });
            }
        );
    };

    const toBase64 = (file: Blob): Promise<any> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader?.result?.toString() ?? '');
            reader.onerror = (error) => reject(error);
        });

    const uploadJpg = async (file: Blob) => {
        try {
            const b64 = await toBase64(file);

            const date = new Date();
            const timestamp = date.getTime();
            const data = { path: 'spaceState', fileType: 'jpg', fileData: b64 };

            //const uploadResult = await eventBridge.uploadFile(prompt.replace(/[^a-zA-Z0-9]/g, '_') + timestamp, 'env', b64);
            const fileName = skyboxUrl.replace(/[^a-zA-Z0-9]/g, '_');

            const uniqueFileName = fileName + timestamp;
            console.log('InstantMeet:: Upload Jpg: uniqueFileName: ', uniqueFileName);
            const uploadResult: string = await putApi('/file/' + spaceId + '/' + uniqueFileName + '.jpg', data).then(
                async (r) => {
                    const json = await r.json();
                    if (!r.ok) {
                        console.log(json.message || 'Could not get space data');
                    }
                    console.log('Instant Meet uploadFile res: ', json);

                    const url = json.url ?? '';
                    if (url === '') {
                        console.error('uploadFile res.url is empty');
                        return;
                    }

                    return url;
                }
            );
            return uploadResult;
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const uploadEnv = async (file: Blob) => {
        try {
            const b64 = await toBase64(file);

            const date = new Date();
            const timestamp = date.getTime();
            const data = { path: 'spaceState', fileType: 'env', fileData: b64 };

            //const uploadResult = await eventBridge.uploadFile(prompt.replace(/[^a-zA-Z0-9]/g, '_') + timestamp, 'env', b64);
            const fileName = skyboxUrl.replace(/[^a-zA-Z0-9]/g, '_');

            const uniqueFileName = fileName + timestamp;
            console.log('InstantMeet:: Upload Env: uniqueFileName: ', uniqueFileName);
            const uploadResult: string = await putApi('/file/' + spaceId + '/' + uniqueFileName + '.env', data).then(
                async (r) => {
                    const json = await r.json();
                    if (!r.ok) {
                        console.log(json.message || 'Could not get space data');
                    }
                    console.log('Instant Meet uploadFile res: ', json);

                    const url = json.url ?? '';
                    if (url === '') {
                        console.error('uploadFile res.url is empty');
                        return;
                    }

                    return url;
                }
            );
            return uploadResult;
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const getMeet = async () => {
        return await getApi('/meet').then(async (r) => {
            const json = await r.json();
            if (r.ok) {
                return json;
            }
            return null;
        });
    };

    const updateSpace = async () => {
        if (spaceId) {
            if (lastPresetSkyboxUrl === skyboxUrl) {
                setInstantMeetReady(true);
                return;
            }
            setLoadingString('Uploading Jpg...');
            const assetArrayBuffer = await Tools.LoadFileAsync(skyboxUrl, true);
            console.log('SKYBOX:: assetArrayBuffer created for presetSkybox jpg: ', assetArrayBuffer);
            const assetBlob = new Blob([assetArrayBuffer]);
            const urlForJpg = await uploadJpg(assetBlob);
            const jpgUrl = urlForJpg || skyboxUrl;

            setLoadingString('Creating lighting and reflections...');

            let resizedJpgUrl = jpgUrl;

            if (checkIsIOS()) {
                resizedJpgUrl =
                    getApiBaseUrl() + '/api/image/resize/width/1000/height/500?imageUrl=' + encodeURIComponent(jpgUrl);
            }

            const envUrl = await createEnvTextureFromJpg(resizedJpgUrl, scene);
            const filename = skyboxUrl.replace(/^.*[\\\/]/, '').replace(new URL(skyboxUrl).search, '');
            const fileType = filename.split('.')[1].toLowerCase();
            console.log('SKYBOX:: updateSpace: envUrl: ', envUrl);

            await putApi(`/space/${spaceId}/state/skyboxPreset`, {
                envUrl,
                url: jpgUrl,
                fileType,
                filename,
                envIdName: meetEnvIdName,
                isActive: true,
                isDefault: false,
                isUpdated: false,
                sourceUrl: skyboxUrl
            });

           // alert('Pausing for logs');
            setInstantMeetReady(true);
        }
    };

    useEffect(() => {
        updateSpace();
    }, [spaceId]);

    useEffect(() => {
        if (instantMeetReady && meetInviteCode) {
            history.push(`/meetInvite/${meetInviteCode}`);
        }
    }, [instantMeetReady]);

    return (
        <>
            <Container>
                <FlexColumn>
                    {!checkIsIOS() && (
                        <>
                            <Header3>{loadingString}</Header3>
                        </>
                    )}
                    {checkIsIOS() && (
                        <>
                            <IOSText>iOS currently doesn’t support skybox conversion, copy link to another device to try again.</IOSText>
                            <CopiedButtonMobile
                                        type="button"
                                        onClick={() => {
                                            // Copy the text inside the text field
                                            navigator.clipboard.writeText(window.location.href);
                                        }}                            
                                    > Click Here To Copy</CopiedButtonMobile>
                        </>
                    )}
                    <canvas style={{ width: '100vw', height: '10vh' }} ref={reactCanvas} {...rest} />
                </FlexColumn>
            </Container>
        </>
    );
};

const CopiedButtonMobile = styled.button`
    min-width: 20px;
    padding-top: 50px;
    pointer-events: auto;
    background-color: transparent;
    background: url(${IconCopy}) center no-repeat transparent;

    &:hover {
        cursor: pointer;
        background: url(${IconCopyHover}) center no-repeat transparent;
    }
`;
const Container = styled.div`
    width: 100vw;
    height: 100vh;
    pointer-events: auto;
`;
const Header3 = styled.h3`
    font-size: 1.25rem;
`;
const IOSText = styled.p`
    font-size: 0.75rem;
    max-width: 90vw;
    text-align: center;
    margin-top: 20px;
    margin-right: 10px;
`;
const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20vh;
`;
export default InstantMeet;
