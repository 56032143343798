import * as React from 'react';
import { useEffect, useState } from 'react';
import { ApiStation, APIUserFollowing } from '../../api/ApiTypes';
import styled from 'styled-components/macro';
import { isStationOwner } from '../../permissions/stations';
import { getApi, putApi, deleteApi } from '../../api/api';
import { useModal } from '../../context/ModalContext';
import { FollowSettingsModal } from '../modals/FollowSettingsModal';
import { useSelector } from 'react-redux';
import { getCurrentUserType, getCurrentUser } from '../../store/selectors';
import { AccountFeatureModal } from '../modals/AccountFeatureModal';
import { StationRoleType } from '../../api/ApiTypes';
import { useAuthContext } from '../../context/AuthContext';
import { ReactComponent as FollowedIcon } from '../../images/i-check.svg';
import { isAnon } from '../../permissions/users';


const FollowTag = ({stationId, stationRole, isHovered}: {stationId: string, stationRole: StationRoleType, isHovered: boolean}) => {
    const [ userFollowingList, userFollowingListSetter ] = useState<APIUserFollowing>({stations: [], users: []});
    const [ count, countSetter ] = useState(0);
    const isOwner = isStationOwner(stationRole);
    const stationIdList = userFollowingList.stations.map((station) => station.id ?? '')
    const isFollowing = stationIdList.includes(stationId);
    const { openModal, closeModal} = useModal();
    const user = useSelector(getCurrentUser);
    const auth = useAuthContext();

    useEffect(() => {
        if (auth) {
            getFollowingList();
            getCount();
        }
    }, [auth]);

    useEffect(() => {
        getCount();
    }, [userFollowingList]);

    const getFollowingList = async () => {
        getApi('/user/me/following').then(async (response) => {
            const res = (await response.json() as APIUserFollowing);
            console.log('userFollowingList: ', res);
            userFollowingListSetter(res);
        });
    }
    const getCount = async () => {
        getApi(`/station/${stationId}/following/count`).then(async (response) => {
            const res = (await response.json());
            console.log('station follow count request res: ', res);
            countSetter(res.count);
        });
    }

    const handleFollow = async () => {
        if(isAnon(user)){
            openModal({content: <AccountFeatureModal /> });
        } else {
            if (!isFollowing) {

                putApi(`/station/${stationId}/follow`).then(async (response) => {
                    const res = (await response.json());
                    getFollowingList();
                    console.log('station follow request res: ', res);
                    const firstTimeFollowing = JSON.parse(localStorage.getItem('NWR_FTF_ModalData') ?? '{}' );


                    if (!firstTimeFollowing || !firstTimeFollowing.flag) {
                        openModal({content: <FollowSettingsModal />});
                        localStorage.setItem('NWR_FTF_ModalData', JSON.stringify({flag: true, dateSet: Date.now()}));
                    }
               });
            } else {
                deleteApi(`/station/${stationId}/follow`).then(async (response) => {
                    const res = (await response.json());
                    getFollowingList();
                    console.log('station unfollow request res: ', res);
                });
            }
        }
    }

    const actionButtonContent = () => {
        if (!isFollowing) {
            return <span>{'Follow'}</span>
        }

        return <span>{isHovered ? 'Unfollow' : <StyledFollowedIcon />}</span>
    }

    return (
        <Container>
            <Counter>{count}</Counter>
            {!isOwner && (
                <ActionButtonContainer>
                    <ActionButton isFollowing={isFollowing} isHovered={isHovered} onClick={() => handleFollow()}>
                        {actionButtonContent()}
                    </ActionButton>
                </ActionButtonContainer>
            )}
        </Container>

    );
};

const Container = styled.div`
    position: relative;
    left: -4px;
    display: flex;
    flex-direction: row-reverse;
    height: fit-content;
    width: fit-content;
    cursor: pointer;
    z-index: +1;

    &:hover {
    }
`;

const Counter = styled.div`
    height: 20px;
    width: fit-content;
    border-radius: 5px;
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--navy-blue);
    padding: 1px 5px 1px 5px;
    z-index: +1;
    font-weight: bold;
`;

const ActionButtonContainer = styled.div`
    position: relative;
    width: fit-content;
`;

const ActionButton = styled.div<{isFollowing: boolean, isHovered: boolean}>`
    position: relative;
    right: -5px;
    height: 20px;
    transition: var(--main-transition);
    padding: 1px 10px 1px 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--midnight);

    ${(props) => props.isFollowing ?
        `
            width: 30px;
            background-color: var(--off-white);
        `
        :
        `
            background-color: var(--tennis-green);
        `
    }

    ${(props) => {
        if (props.isHovered && props.isFollowing) return 'width: 100px;'
    }}
`;

const StyledFollowedIcon = styled(FollowedIcon)`
    position: absolute;
    top: -11px;
    left: -7px;
`;

export default FollowTag;
