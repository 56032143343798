import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import StreamHeader from './StreamHeader';
import StreamViewer from './StreamViewer';
import StreamIO from './StreamIO';
import { StreamContext } from './StreamContext';
import StreamUpsell from './StreamUpsell';

const StreamParent = () => {
    const { loadingStation, stationError, liveStreamPermission } = useContext(StreamContext);
    const permissionFailure = !liveStreamPermission || !liveStreamPermission.allowed

    if (loadingStation) return <Container><div style={{margin: '0 auto', fontSize: '30px', width: 'fit-content'}}>Loading...</div></Container>
    if (stationError) return <Container><div style={{margin: '0 auto', fontSize: '30px', width: 'fit-content'}}>{`Error: ${stationError}`}</div></Container>
    if (permissionFailure) return <Container><StreamUpsell></StreamUpsell></Container>

    return (
        <Container>
            <StreamHeader />
            <StreamViewer isHostView={true} />
            <StreamIO />
        </Container>
    );

};

const Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 700px;
    margin-top: 45px;
    margin-right: 30px;
}`;

export default StreamParent;
