import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import StreamKey from './streamSources/StreamKey';
import { ApiSpace } from '../../api/ApiTypes';
import ReactTooltip from 'react-tooltip';

const StreamSource = (
        // {space}: {space: ApiSpace}
    ) => {

    return (
        <Container>
            Stream Source
            <SourceBox>
                <StreamKey/>
                <DisabledSources>
                    <ReactTooltip effect="solid" />
                    <DisabledSource data-tip="Coming Soon">WebCam</DisabledSource>
                    <DisabledSource data-tip="Coming Soon">+ Screenshare</DisabledSource>
                    <DisabledSource data-tip="Coming Soon">Upload</DisabledSource>
                    <DisabledSource data-tip="Coming Soon">HostedURL</DisabledSource>
                </DisabledSources>
            </SourceBox>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    width: 50%;
    height: 100%;
    padding: 15px;
}`;

const SourceBox = styled.div`
    position: relative;
    width: 100%;
    height: 185px;
    margin-top: 15px;
    border-radius: 5px;
    font-weight: normal;
    font-size: 16px;
`;

const DisabledSources = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const DisabledSource = styled.div`
    height: 36px;
    width: 48%;
    padding-top: 8px;
    margin-top: 10px;
    text-align: center;
    text-transform: none;
    background-color: var(--charcoal-grey);
    color: var(--battleship-grey);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(28, 2, 33, 0.75);
    cursor: pointer;
`;

export default StreamSource;
