import * as React from 'react';
import { useEffect, useState } from 'react';
import { getApi } from '../api/api';
import styled from 'styled-components/macro';
//import LoadingComponent from '../components/loading/LoadingComponent';
import  LoadingComponent  from '../components/loading/LoadingComponent';
import { getCookie } from '../utils/CookieUtil';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearCurrentUser, setCurrentUser } from '../store/actions';
import { UserType } from '../api/ApiTypes';
import { isLoaded, useFirebase } from 'react-redux-firebase';
import { SubscriptionModal } from '../components/modals/PaymentModals';
import SpaceCatKeytar from '../images/SpaceCat-keytar.gif';
import SadCat from '../images/sad-cat.gif';

const PaymentConfirmation = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const sessionId = String(new URLSearchParams(window.location.search).get('sessionId') || '');
    const firebase = useFirebase();

    const [userData, setUserData] = useState({} as { [key: string]: any });
    const [loading, setLoading] = useState(true);
    const [shouldShowSecondaryButton, setShouldShowSecondaryButton] = useState(true);
    const [titleText, setTitleText] = useState('');
    const [subtitleText, setSubtitleText] = useState('');
    const [mainButtonText, setMainButtonText] = useState('');
    const [secondaryButtonText, setSecondaryButtonText] = useState('');
    const [redirectLink, setRedirectLink] = useState('');
    const [secondaryButtonClickAction, setSecondaryButtonClickAction] = useState('null');
    const [shouldMakeMainButtonWhite, setShouldMakeMainButtonWhite] = useState(false);
    const [shouldShowPricingModal, setShouldShowPricingModal] = useState(false);
    const [catGifSrc, setCatGifSrc] = useState(SpaceCatKeytar);

    /*     useEffect(() => {
        if (sessionId) {
            getApi(`/payment/session/${sessionId}`).then(async (res) => {
                const json = await res.json();
                console.log('`/payment/session/${sessionId}`', json);
                setSessionData(json);
                window.setTimeout(() => setLoading(false), 100);
            });
        }
    }, [sessionId]);
 */
    useEffect(() => {
        const redirectRoute = getCookie('paymentSuccessRoute');
        console.log('redirectRoute', redirectRoute);

        //parse redirect route for url params that come after ? and are then split by &
        const parsedRedirectRoute = redirectRoute?.split('?')[0];
        setRedirectLink(parsedRedirectRoute);

        const urlParams = redirectRoute?.split('?')[1]?.split('&');
        const fromPage = urlParams?.find((param) => param.includes('fromPage'))?.split('=')[1];
        const fromUserType = urlParams?.find((param) => param.includes('fromUserType'))?.split('=')[1] as UserType;
        const fromUserId = urlParams?.find((param) => param.includes('userId'))?.split('=')[1];

        getApi('/user/types')
            .then(async (userTypes) => {
                return await userTypes.json();
            })
            .then(async (userTypes) => {
                console.log('userTypes', userTypes);
                const fromUserTypeCast = userTypes[fromUserType] as UserType;
                console.log('fromUserTypeCast', fromUserTypeCast);
                //find key with the value of the fromUserType and return the string associated with it

                getApi(`/user/me`)
                    .then(async (r) => {
                        const response = await r.json();
                        console.log('`/user/me`', response);
                        if (r.ok) {
                            const userId = response.id;
                            dispatch(setCurrentUser(response));
                            setUserData(response);
                            setLoading(false);
                            console.log('userTypes[response?.userType]', userTypes[response?.userType]);
                            let newUserType = (userTypes[response?.userType] || response?.userType) as UserType;

                            console.log(
                                'PAYMENT_CONFIRMATION::: newUserType',
                                newUserType,
                                'fromUserType',
                                fromUserTypeCast
                            );

                            if (
                                newUserType === 'Guest' ||
                                newUserType === 'Anonymous' ||
                                !redirectRoute ||
                                userId != fromUserId
                            ) {
                                await firebase.logout().then(() => {
                                    dispatch(clearCurrentUser());
                                    history.push('/log-in?goToProfileEdit');
                                });
                            }

                            let newUserTypeCopy = null;
                            let nextPaymentDate = null;

                            console.log('newUserType', newUserType, 'fromUserType', fromUserType);

                            const res = await getApi('/payment/subscription/summery');
                            const json = await res.json();
                            console.log('`/payment/subscription/summery`', json.summery);
                            nextPaymentDate = json?.summery.nextPayment;
                            if (json?.summery.isCancelAtPeriodEnd) {
                                //change newUserType to Play
                                newUserTypeCopy = newUserType;
                                newUserType = userTypes['Play'] as UserType;
                            }

                            if (fromUserTypeCast === newUserType) {
                                history.push('/profile-edit');
                            }

                            let TitleText = "You've got a " + newUserType + ' account!';
                            let SubtitleText =
                                'You just unlocked a whole new NOWHERE. Continue to see what else you can do.';
                            let MainButtonText = 'Go to space';
                            let SecondaryButtonText = '';

                            const hasDowngradedFromPaidToFree =
                                (fromUserTypeCast === userTypes['Plus'] ||
                                    fromUserTypeCast === userTypes['Pro'] ||
                                    fromUserTypeCast === userTypes['Premiere']) &&
                                newUserType === userTypes['Play'];

                            const hasDowngraded =
                                (fromUserTypeCast === userTypes['Premiere'] && newUserType === userTypes['Pro']) ||
                                (fromUserTypeCast === userTypes['Pro'] && newUserType === userTypes['Plus']) ||
                                (fromUserTypeCast === userTypes['Premiere'] && newUserType === userTypes['Plus']);

                            const cameFromProfileEdit = fromPage === 'profileEdit';

                            const isFreeToPaid =
                                (fromUserTypeCast === userTypes['Play'] || fromUserTypeCast === userTypes['Guest']) &&
                                (newUserType === userTypes['Plus'] ||
                                    newUserType === userTypes['Pro'] ||
                                    newUserType === userTypes['Premiere']);

                            if (isFreeToPaid) {
                                TitleText = "You've got a new " + newUserType + ' account!';
                                MainButtonText = 'Go to new space';
                                SecondaryButtonText = 'Go to Meet';
                                setShouldShowSecondaryButton(true);
                                setSecondaryButtonClickAction('goToMeet');
                            } else if (hasDowngradedFromPaidToFree) {
                                TitleText = 'Sad to see you go.';
                                SubtitleText =
                                    'You can continue to use your ' +
                                    newUserTypeCopy +
                                    ' account until ' +
                                    nextPaymentDate +
                                    '. Reach out if we can help.';
                                MainButtonText = 'Okay';
                                SecondaryButtonText = 'Manage Plan';
                                setShouldShowSecondaryButton(true);
                                setSecondaryButtonClickAction('showPricingModal');
                                setCatGifSrc(SadCat);
                                setShouldMakeMainButtonWhite(true);
                            } else if (hasDowngraded) {
                                TitleText = "You've downgraded to a " + newUserType + ' account.';
                                SubtitleText =
                                    'You can still meet in your  ' +
                                    newUserType +
                                    ' account. Reach out if we can help.';
                                MainButtonText = 'Okay';
                            } else {
                                setShouldShowSecondaryButton(false);
                            }

                            if (cameFromProfileEdit && !isFreeToPaid && !hasDowngradedFromPaidToFree) {
                                setShouldShowSecondaryButton(true);
                                SecondaryButtonText = 'My Account';
                                // Correctly set the action function
                                setSecondaryButtonClickAction('goToProfileEdit');
                            }

                            setTitleText(TitleText);
                            setSubtitleText(SubtitleText);
                            setMainButtonText(MainButtonText);
                            setSecondaryButtonText(SecondaryButtonText);
                        }
                    })
                    .then(() => /* history.push(redirectRoute) */ null)
                    .catch((e: Error) => {
                        console.error('Could not get updated user with error: ', e);

                        firebase
                            .logout()
                            .then(() => {
                                dispatch(clearCurrentUser());
                                history.push('/log-in?goToProfileEdit');
                            })
                            .catch((e) => {
                                console.error('Could not logout', e);
                                history.push('/log-in?goToProfileEdit');
                            });
                        //  history.push(redirectRoute);
                    });
            })
            .catch((e) => {
                console.error('Could not get user types', e);

                firebase
                    .logout()
                    .then(() => {
                        dispatch(clearCurrentUser());
                        history.push('/log-in?goToProfileEdit');
                    })
                    .catch((e) => {
                        console.error('Could not logout', e);
                        history.push('/log-in?goToProfileEdit');
                    });
            });
    }, []);

    return (
        <>
            {shouldShowPricingModal && (
                <SubscriptionModal
                    handelClose={() => setShouldShowPricingModal(false)}
                    successRoute={'/profile-edit?fromPage=paymentConfirmation&fromUserType=' + userData?.userType}
                />
            )}
            {!loading && (
                <DaddyContatiner>
                    <Container>
                        <CatGif src={catGifSrc}></CatGif>
                        <Title>{titleText}</Title>
                        <Subtitle>{subtitleText}</Subtitle>
                        <FlexRow>
                            <MainButton
                                bgColor={shouldMakeMainButtonWhite ? 'white' : undefined}
                                onClick={() => history.push(redirectLink)}
                            >
                                <ButtonText>{mainButtonText}</ButtonText>
                            </MainButton>
                            {shouldShowSecondaryButton && (
                                <SecondaryButton
                                    onClick={() => {
                                        if (secondaryButtonClickAction === 'goToMeet') {
                                            history.push('/meet');
                                        } else if (secondaryButtonClickAction === 'showPricingModal') {
                                            const successRoute =
                                                '/profile-edit?fromPage=paymentConfirmation&fromUserType=' +
                                                userData?.userType +
                                                '&userId=' +
                                                userData.id;
                                            history.push('/pricing?' + successRoute);
                                            // setShouldShowPricingModal(true);
                                        } else if (secondaryButtonClickAction === 'goToProfileEdit') {
                                            history.push('/profile-edit');
                                        }
                                    }}
                                >
                                    <ButtonText>{secondaryButtonText}</ButtonText>
                                </SecondaryButton>
                            )}
                        </FlexRow>
                    </Container>
                </DaddyContatiner>
            )}
            {loading && (
                <>
                    <Loading></Loading>
                </>
            )}
        </>
    );
};

const DaddyContatiner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90vh;

    @media (max-width: 1000px) and (orientation: landscape) {
        height: 100%;
        margin-top: 20px;
        align-items: flex-start;
        margin-left: 20px;
    }

    @media (max-width: 500px) {
        height: 100%;
        margin-top: 20px;
    }
`;

const FlexRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 40px;
    padding-bottom: 60px;

    @media (max-width: 1000px) and (orientation: landscape) {
        padding-bottom: 0px;
        margin-top: 20px;
        width: 100%;
    }

    @media (max-width: 500px) {
        flex-direction: column;
        width: 100%;
        padding-right: 10px;
    }
`;
interface MainButtonProps {
    bgColor?: string;
}

const MainButton = styled.div<MainButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    background-color: ${(props) => props.bgColor || '#bee639'};
    color: #010027;
    border-radius: 20px;
    margin-right: 20px;

    @media (max-width: 1000px) and (orientation: landscape) {
        width: 50%;
    }

    @media (max-width: 500px) {
        margin-bottom: 20px;
        width: 100%;
    }
`;

const ButtonText = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
`;

const SecondaryButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid white;
    cursor: pointer;
    background-color: #010027;
    color: white;
    border-radius: 20px;
    margin-right: 20px;

    @media (max-width: 1000px) and (orientation: landscape) {
        width: 50%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    line-height: 65px;
    font-weight: bold;
    max-width: 700px;
    line-height: 60px;
    margin-bottom: 10px;
    margin-right: 30px;

    @media (max-width: 1000px) and (orientation: landscape) {
        font-size: 30px;
        line-height: 30px;
    }

    @media (max-width: 768px) {
        font-size: 50px;
    }

    @media (max-width: 500px) {
        font-size: 30px;
        line-height: 30px;
    }
`;

const Subtitle = styled.div`
    font-size: 16px;
    font-family: all-round-gothic;
    margin-right: 30px;
`;

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    max-width: 768px;
    @media (max-width: 768px) {
        margin-left: 30px;
    }
`;

const Loading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`;

const CatGif = styled.img`
    height: 220px;
    padding-bottom: 40px;
    padding-right: 30px;
    object-fit: cover;

    @media (max-width: 1000px) and (orientation: landscape) {
        height: 150px;
        padding-bottom: 20px;
    }
`;

export default PaymentConfirmation;
