import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import StationForm from '../components/stationAdmin/StationForm';
import CenteredSingleColumnLayout from '../components/layouts/CenteredSingleColumnLayout';
import { postApi } from '../api/api';
import { ApiStationEditableMetadata } from '../api/ApiTypes';
import { getCurrentUser } from '../store/selectors';
import { useSelector } from 'react-redux';
import Message from '../components/layouts/Message';
import { MediumButton } from '../components/inputs/Button';
import { canCreateStation } from '../permissions/users';

const CreateStation: React.FC = () => {
    const history = useHistory();
    const [error, setError] = React.useState('');

    const handleCreateStation = (stationData: ApiStationEditableMetadata) =>
        new Promise<void>((resolve) => {
            setError('');
            postApi('/station/create', stationData)
                .then(async (r) => {
                    const json = await r.json();
                    if (!r.ok) {
                        setError(json.message || 'Something went wrong');
                    }
                    if (json.station?.id) {
                        history.push(`/station/${json.station?.id}/admin`);
                    }
                    resolve();
                })
                .catch(() => {
                    setError('Something went very wrong');
                    resolve();
                });
        });

    const currentUser = useSelector(getCurrentUser);
    const currentUserCanCreate = canCreateStation(currentUser);

    if (!currentUserCanCreate)
        //TODO: Once we have anchoring on /spaces, we should point the link to the MyStations tab instead of /spaces
        return (
            <Message title="Hmm, doesn’t look like you have permission to create a station">
                <Link to="/spaces" style={{ marginRight: '30px' }}>
                    <MediumButton>Back to Stations</MediumButton>
                </Link>
                <a href="https://www.urnowhere.com/become-a-host" target="_blank" rel="noopener noreferrer">
                    <MediumButton>Request Access</MediumButton>
                </a>
            </Message>
        );

    return (
        <>
            <CenteredSingleColumnLayout
                title="Create Your Station"
                subtitle="The landing page for every Space you create. Let your participants know a little about you."
            >
                <StationForm onSubmit={handleCreateStation} />
                {error && <h2>{error}</h2>}
            </CenteredSingleColumnLayout>
        </>
    );
};

export default CreateStation;
