import * as React from 'react';
import { useEffect } from 'react';
import {useState} from 'react';
import styled from 'styled-components/macro';
import { onSpotifySuccess, onSpotifyFailure, setSpotifyScope, connectSpotify } from '../../routes/SpotifyEmbed';
import SpotifyLogo from '../../images/i-spotify.svg';
import { SmallButton } from './Button';

const SpotifyAuthButton = ({children, scope, onSuccess, onFailure, onSubmitting, disabled}: {children: string, scope: string, onSuccess: typeof onSpotifySuccess, onFailure: typeof onSpotifyFailure, onSubmitting: () => void, disabled: boolean}) => {
    onSpotifySuccess(onSuccess);
    onSpotifyFailure(onFailure);
    setSpotifyScope(scope);

    const [isFrameReady, setIsFrameReady] = useState(false);

    const iframeLoaded = () => {
        setIsFrameReady(true);
    }


    return (
        <SpotifyAuthButtonWrapper onClick={connectSpotify(onSubmitting, 'spotifyFrame')} disabled={disabled || !isFrameReady}>
            <Icon src={SpotifyLogo} />
            <Text>{children}</Text>
            <Frame name='spotifyFrame' id='spotifyFrame' src='/spotify'  onLoad={iframeLoaded}></Frame>
        </SpotifyAuthButtonWrapper>
    );
}



const SpotifyAuthButtonWrapper = styled(SmallButton)`
`;

const Icon = styled.img`
    margin-left: -0px;
    margin-right: 5px;
    padding-bottom: 2px;
`;

const Text = styled.span`
    padding: 5px 6px 5px 4px;
    margin: 0;
`;

const Frame = styled.iframe`
    border: 0px;
    width: 0px;
    height: 0px;
    opacity: 0;
`


export default SpotifyAuthButton;
