import * as firebase from 'firebase/app';
import { auth } from '../firebase/Connection';

export const doSendEmailVerificationToCurrentUser = () => {
    return doSendEmailVerification(auth.currentUser);
};

export const doSendEmailVerification = (user: firebase.User | null) => {
    const url = `${window.location.protocol}//${window.location.host}`;
    console.log('sending email to', url);
    console.log(auth.currentUser);
    return user?.sendEmailVerification({ url: url });
};
