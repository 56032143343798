import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import useInterval from '../../hooks/useInterval';
import { getApi } from '../../api/api';
import { ApiSpace, ApiStation } from '../../api/ApiTypes';
import SpaceCard from './SpaceCard';
import StationCard from './StationCard'
import HorizontalScrollBox from '../HorizontalScrollBox';

import { ReactComponent as FeaturedIcon } from '../../images/i-featured.svg';
import { ReactComponent as LiveNowIcon } from '../../images/i-livenow.svg';
import { ReactComponent as FollowingIcon } from '../../images/i-favorite.svg';
import { useFollowingContext } from '../following/FollowingContext';

const UPDATE_RATE = -1;
export const LiveSpaces = ({large, liveUpdateRate, openModal}: {large: boolean, liveUpdateRate?: number, openModal?: (link: string, text: string) => void}) => {
    const [spaces, spacesSetter] = useState<ApiSpace[] | null>(null);

    const isLiveUpdate = liveUpdateRate?? UPDATE_RATE > 0;

    useEffect(() => {
        getLiveSpaces();
    }, []);

    if (isLiveUpdate) useInterval(() => getLiveSpaces(), liveUpdateRate!);

    const getLiveSpaces = () => {
        getApi(`/spaces/public/active`).then(async (response) => {
            const res = (await response.json()) as ApiSpace[];
            console.log('get live spaces', res);

            spacesSetter(res);
        });
    }

    const getNextSpaces = () => {
        console.log('get next spaces');
    };

    const getPreviousSpaces = () => {
        console.log('get previous spaces');
    };

    if (!spaces || spaces.length === 0) return null;

    return (
        <LiveSpaceContainer>
            <LiveSpaceTitle>
                <StyledLiveNowIcon/>
                <div>Live Now</div>
            </LiveSpaceTitle>
            <HorizontalScrollBox items={spaces.map((space, i) => <SpaceCard large={large} key={i} space={space} openModal={openModal}/>)} scrollAmount={244}/>
        </LiveSpaceContainer>

    );
};

const LiveSpaceContainer = styled.div`
    margin-bottom: 50px;
`;

const LiveSpaceTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 30px;
    margin-bottom: 24px;
    font-weight: bold;
`;

const StyledLiveNowIcon = styled(LiveNowIcon)`
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
    & path {
        fill: var(--tennis-green);
    }
`;


export const FeaturedSpaces = ({ liveUpdateRate, openModal}: {liveUpdateRate?: number, openModal?: (link: string, text: string) => void}) => {
    const [spaces, spacesSetter] = useState<ApiSpace[] | null>(null);
    const [displayedSpaces, displayedSpacesSetter] = useState<ApiSpace[] | null>(null);

    const isLiveUpdate = liveUpdateRate?? UPDATE_RATE > 0;

    useEffect(() => {
        getFeaturedSpaces();
    }, []);

    // const Grid = () => {
    //     if (!spaces) return null;

    //     return (
    //         <FeaturedSpaceRow>
    //             {spaces.map((space, i) => {
    //                 return <SpaceCard key={i} space={space} />
    //             })}
    //         </FeaturedSpaceRow>
    //     );
    // };

    //if (liveUpdate) useInterval(() => getFeaturedSpaces(), UPDATE_RATE);
    if (isLiveUpdate) useInterval(() => getFeaturedSpaces(), liveUpdateRate!);

    const getFeaturedSpaces = () => {
        getApi(`/spaces/public/featured`).then(async (response) => {
            const res = (await response.json()) as ApiSpace[];
            console.log('get featured spaces', res);

            spacesSetter(res);
        });
    };

    const getDisplayedSpaces = () => {
        console.log('getDisplayedSpaces');
    }

    const getNextSpaces = () => {
        console.log('get next spaces');
    };

    const getPreviousSpaces = () => {
        console.log('get previous spaces');
    };

    if (!spaces || spaces.length === 0) return null;

    return (
        <FeaturedSpaceContainer>
            <FeaturedSpaceTitle>
                <StyledFeaturedIcon/>
                <div>Featured</div>
            </FeaturedSpaceTitle>
            {/* <Grid /> */}
            <HorizontalScrollBox items={spaces.map((space, i) => <SpaceCard key={i} space={space} large={false} openModal={openModal}/>)} scrollAmount={244}/>
        </FeaturedSpaceContainer>
    );
};

const FeaturedSpaceContainer = styled.div`
    margin-bottom: 50px;
`;

const FeaturedSpaceTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 30px;
    margin-bottom: 24px;
    font-weight: bold;
`;

const StyledFeaturedIcon = styled(FeaturedIcon)`
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
    & path {
        fill: var(--tennis-green);
    }
`;

const ScrollerWrapper = styled.div`
    width: 100%;
    overflow: hidden;
`;

export const FollowedStations = () => {
    const { userFollowingList } = useFollowingContext();
    const mapStations = () => {
        return userFollowingList.stations.map((station, i) => <StationCard key={i} station={station} large={false}/>)
    };
    // While on this page we want to continue rendering followed StationCards even if the user has unfollowed the station.
    // const stationCardsArr = useMemo(() => {
    //     return mapStations();
    // },[]);
    const stationCardsArr = mapStations();

    if (stationCardsArr.length === 0) return null;
    return (
        <FollowedStationsContainer>
            <FollowedStationsTitle>
                <StyledFollowingIcon/>
                <div>Following</div>
            </FollowedStationsTitle>
            <HorizontalScrollBox items={stationCardsArr} scrollAmount={244}/>
        </FollowedStationsContainer>
    );
};

const FollowedStationsContainer = styled.div`
    margin-bottom: 50px;
`;

const FollowedStationsTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 30px;
    margin-bottom: 24px;
    font-weight: bold;
`;

const StyledFollowingIcon = styled(FollowingIcon)`
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
    & path {
        fill: var(--tennis-green);
    }
`;
