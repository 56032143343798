export default class TimeUtils {
    public static formatDuration(durationSecs: number): string {
        if (durationSecs < 60) {
            return '1min';
        }

        const totalMinutes = Math.floor(durationSecs / 60);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.floor(totalMinutes % 60);

        return hours > 0 ? `${hours}hr ${minutes}min` : `${minutes}min`;
    }
}
