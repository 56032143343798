import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import useInterval from '../../hooks/useInterval';
import { getApi, putApi } from '../../api/api';
import { ApiSpace, ApiStation } from '../../api/ApiTypes';
import StationCard from './StationCard';
import { isStationManager, isSpaceHostInStation } from '../../permissions/stations';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCurrentUser } from '../../store/selectors';
import { setCurrentUser } from '../../store/actions';
import { isAnon, canHaveStations } from '../../permissions/users';
import { ReactComponent as HostIcon } from '../../images/i-host.svg';

const liveUpdate = false;
const UPDATE_RATE = 15000;

const MyStations = () => {
    const [stations, stationsSetter] = useState<ApiStation[] | null>(null);
    const [isLoaded, isLoadedSetter] = useState(false);
    const [isGettingFreeSpace, setIsGettingFreeSpace] = useState(false);
    const curUser = useSelector(getCurrentUser);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        getMyStations().then(() => isLoadedSetter(true));
    }, []);

    if (liveUpdate) useInterval(() => getMyStations(), UPDATE_RATE);

    const getMyStations = async () => {
        getApi(`/user/me/stations`).then(async (response) => {
            const res = (await response.json()) as ApiStation[];

            // Only show stations that a user is the manager of or that contains a space they are a manager of.
            const filteredStations = res.filter(
                (station) => isStationManager(station) || isSpaceHostInStation(station)
            );

            stationsSetter(filteredStations);
        });
    };

    const getMyFreeMeet = () => {
        if (isAnon(curUser)) {
            history.push('/meet');
            return;
        }

        putApi(`/user/${curUser.id}/upgrade/free`)
            .then(async (resp) => {
                const response = await resp.json();
                if (resp.ok) {
                    dispatch(setCurrentUser(response));
                    history.push('/meet');
                    //  history.push('/station/latest')

                    //setShowFreeSpace(false)
                }
            })
            .catch((e) => {
                console.warn('Error getting free meet', e);
            });
    };

    const Grid = () => {
        if (!isLoaded) return <>Loading...</>;
        if (isLoaded && (!stations || stations.length === 0)) {
            if (isAnon(curUser)) {
                return (
                    <div>
                        Nothing to see here yet.&nbsp;
                        <a href={'/meet'} style={{ textDecoration: 'underline' }}>
                            Get your free space
                        </a>{' '}
                        to start hosting.{' '}
                    </div>
                );
            } else if (!canHaveStations(curUser)) {
                return (
                    <div>
                        Nothing to see here yet.&nbsp;
                        <a href="#getFreeSpace" onClick={getMyFreeMeet} style={{ textDecoration: 'underline' }}>
                            Get your free space
                        </a>{' '}
                        to start hosting.{' '}
                    </div>
                );
            } else {
                return (
                    <div>
                        Nothing to see here yet.&nbsp;
                        <a href={'/station/create'} style={{ textDecoration: 'underline' }}>
                            Create your free station
                        </a>{' '}
                        to start hosting.{' '}
                    </div>
                );
            }
        }

        return (
            <MyStationsGrid>
                {stations!.map((station, i) => {
                    return <StationCard key={i} station={station} dynamic={true} />;
                })}
            </MyStationsGrid>
        );
    };

    return (
        <Container>
            <MyStationsTitle>
                <StyledHostIcon />
                <div>Stations I Host</div>
            </MyStationsTitle>
            <Grid />
        </Container>
    );
};

const Container = styled.div``;

const MyStationsTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 30px;
    margin-bottom: 24px;
    font-weight: bold;
`;

const StyledHostIcon = styled(HostIcon)`
    width: 24px;
    height: 24px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
    & path {
        fill: var(--tennis-green);
    }
`;

const MyStationsGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(244px, 1fr));
    justify-content: space-between;
    row-gap: 10px;
`;

export default MyStations;
