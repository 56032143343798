import * as React from 'react';
import MediaQuery from 'react-responsive';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
// import nowhereLogo from '../../images/nwr-logo-rev02-KO_webVector.svg';
import nowhereBetaLogo from '../../images/i-nwr-logo-beta.svg';
import { isEmpty, useFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/reducers';
import NavDropdown from './NavDropdown';
import { getCurrentUser } from '../../store/selectors';
import { useEffect, useState } from 'react';
import { getApi } from '../../api/api';
import { ApiStation } from '../../api/ApiTypes';
import NavDropdownMobile from './NavDropdownMobile';
import { isStationManager as checkIsStationManager } from '../../permissions/stations';
import { clearCurrentUser } from '../../store/actions';
import { env } from '../../utils/envLoader';
import { canManageEnvironments, canManageUsers, canCreateStation } from '../../permissions/users';

const Header: React.FC = () => {
    const auth = useSelector(({ firebase: { auth } }: RootState) => auth);
    const currentUser = useSelector(getCurrentUser);
    const [isStationManager, setIsStationManager] = useState(false);
    const { pathname } = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [hasHostedSpaces, setHasHostedSpaces] = useState(false);
    const dispatch = useDispatch();
    // Render empty Header when in 3D
    const isLobbyRoute = useRouteMatch('/space/:spaceId/lobby');
    const isLobbySoupRoute = useRouteMatch('/space/:spaceId/lobbySoup');
    const landingPage = ((lp: string) => (lp ? lp : 'Explorer'))(env('LANDING_PAGE'));

    const history = useHistory();
    const firebase = useFirebase();
    const handleSignOut = async () => {
        await firebase.logout().then(() => {
            dispatch(clearCurrentUser());
            history.push('/log-in');
        });
    };

    useEffect(() => {
        if (isEmpty(auth)) return;
        getApi(`/user/me/stations`).then(async (response) => {
            const res = (await response.json()) as ApiStation[];
            const stationManager = res.filter((station) => checkIsStationManager(station)).length > 0;
            setIsStationManager(stationManager);

            const spaceHost =
                res.filter((station) => station.hostedSpaceIds && station.hostedSpaceIds.length > 0).length > 0;
            setHasHostedSpaces(spaceHost);
        });
    }, [pathname, auth]);

    const canBeManager = canCreateStation(currentUser) || isStationManager || hasHostedSpaces;

    const isEventsPage = pathname.startsWith('/events') || pathname.startsWith('/create-event');

    // StationPage is deprecated
    // const isManagerPage =
    //     pathname.startsWith('/managed') || (pathname.startsWith('/space') && !pathname.startsWith('/spaces')) || pathname.endsWith('admin');
    // const isStationPage = !isManagerPage && (pathname.startsWith('/stations') || pathname.startsWith('/station'));
    const isOrbitPage = pathname.startsWith('/orbit');
    const isEnvironmentPage = pathname.startsWith('/environment');
		const isPlayersPage = pathname.startsWith('/users')
    const isSpacesPage = pathname.startsWith('/spaces');
    const isMeetPage = pathname.startsWith('/meet');

    if (
        pathname.startsWith('/log-in') ||
        pathname.startsWith('/invite') ||
        pathname.startsWith('/managerInvite') ||
        pathname.startsWith('/sign-up')
    )
        return (
            <a href="https://www.urnowhere.com/">
                <img
                    src={nowhereBetaLogo}
                    alt="Nowhere Logo"
                    style={{ height: '25px', top: '30px', left: '0px', position: 'absolute', zIndex: 99 }}
                />
            </a>
        );
    return (
        <>
            {!isLobbyRoute && !isLobbySoupRoute && (
                <Container style={{ display: isLobbyRoute || isLobbySoupRoute ? 'none' : 'flex' }}>
                    <NavLinkContainer>
                        <Link to={`/${landingPage}`}>
                            <img
                                src={nowhereBetaLogo}
                                className="nav-logo-link"
                                alt="Nowhere Logo"
                                style={{ height: '30px' }}
                            />
                        </Link>
                        <MediaQuery query="(min-width: 768px)">
                            {!isEmpty(auth) && (
                                <>
                                    <HeaderLink to="/meet" $highlighted={isMeetPage}>
                                        Meet
                                    </HeaderLink>
                                    <HeaderLink to="/spaces" $highlighted={isSpacesPage}>
                                        Spaces
                                    </HeaderLink>
                                    <HeaderLink to="/events" $highlighted={isEventsPage}>
                                        Events
                                    </HeaderLink>
                                    {/* {canBeManager && (
                                        <HeaderLink to={'/managed'} $highlighted={isManagerPage}>
                                            Host
                                        </HeaderLink>
                                    )} */}
                                    <HeaderLink to="/orbit" $highlighted={isOrbitPage}>
                                        Orbit
                                    </HeaderLink>
                                    { canManageUsers(currentUser) && (
                                        <HeaderLink to={'/users'} $highlighted={isPlayersPage}>
                                            Players
                                        </HeaderLink>
                                    )}
                                    { canManageEnvironments(currentUser) && (
                                        <HeaderLink to={'/environments'} $highlighted={isEnvironmentPage}>
                                            Environments
                                        </HeaderLink>
                                    )}
                                </>
                            )}
                        </MediaQuery>
                    </NavLinkContainer>
                    <NavLinkContainer>
                        {isEmpty(auth) ? (
                            <>
                                <HeaderLink style={{ marginRight: '40px' }} to="/log-in">
                                    Log in
                                </HeaderLink>
                            </>
                        ) : (
                            <>
                                <MediaQuery query="(min-width: 768px)">
                                    <NavDropdown isOpen={isOpen} setIsOpen={setIsOpen} handleSignOut={handleSignOut} />
                                </MediaQuery>
                                <MediaQuery query="(max-width: 768px)">
                                    <NavDropdownMobile
                                        canBeManager={canBeManager}
                                        isOpen={isOpen}
                                        setIsOpen={setIsOpen}
                                        handleSignOut={handleSignOut}
                                    />
                                </MediaQuery>
                            </>
                        )}
                    </NavLinkContainer>
                </Container>
            )}
            {!isLobbyRoute && !isLobbySoupRoute && <SpaceKeeper />}
        </>
    );
};

const Container = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 60;
    width: 100vw;
    margin-bottom: -60px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    background-color: var(--midnight);
    height: 60px;
`;

const SpaceKeeper = styled.div`
    height: 58px;
`;

export const HeaderLink = styled(Link)<{ $highlighted?: boolean }>`
    // note that prefixing a styled component attribute with a $ dollar sign
    // prevents that prop from being passed to untyped children
    // and avoids weird errors; for instance, here we are keeping the
    // anchor tag child of the Link component from getting a boolean attribute

    // see https://styled-components.com/docs/api#transient-props
    height: 58px;
    margin: 0 0 0 60px;
    font-family: all-round-gothic, sans-serif;
    font-size: 14px;
    font-weight: bold;
    padding: 0;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    display: flex;
    align-items: center;
    background-color: var(--midnight);
    border-top: 3px solid var(--midnight);
    color: ${({ $highlighted }) => ($highlighted ? 'var(--tennis-green) !important' : 'var(--white)')};
    border-bottom: 3px solid ${({ $highlighted }) => ($highlighted ? 'var(--tennis-green)' : 'var(--midnight)')};
    &:hover {
        text-decoration: none;
        border-bottom: 3px solid var(--tennis-green);
    }
`;

const NavLinkContainer = styled.div`
    display: flex;
    align-items: center;
`;

export default Header;
