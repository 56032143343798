/** @jsxRuntime classic */
// /** @jsx jsx */
import styled from 'styled-components/macro';
import { jsx } from '@emotion/core';
import ReactSelect, { OptionProps, ValueType, StylesConfig, components } from 'react-select';
import { SelectIcon, CheckNoCircleIcon } from '../../images/icons';
import React from 'react';

export const SelectStyle = `font-size: 12px;
padding: 2px 10px;
border-radius: 15px;
width: 300px;
height: 30px;
color: black;
appearance: menulist;
margin-bottom: 30px;`;

export type OptionType = {
    value: string;
    label: string;
};

type SelectProps = {
    options: OptionType[];
    onChange: (value: string) => void;
    value: string;
    width?: string;
    id?: string;
    spClassName?: string;
};

const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
        <SelectIcon />
    </components.DropdownIndicator>
);

const OptionWrapper = styled.div`
    position: relative;

    .svgIcon {
        position: absolute;
        top: 10px;
        left: 0px;
        stroke: var(--tennis-green);
        z-index: 100;
    }
`;

const Option = (props: OptionProps<OptionType, false>) => {
    const { children, className, cx, getStyles, isDisabled, isFocused, isSelected, innerRef, innerProps } = props;
    return (
        <OptionWrapper>
            <div style={{ paddingLeft: '20px' }}>
                <div
                    ref={innerRef}
                    css={getStyles('option', props)}
                    className={cx(
                        {
                            option: true,
                            'option--is-disabled': isDisabled,
                            'option--is-focused': isFocused,
                            'option--is-selected': isSelected
                        },
                        className
                    )}
                    {...innerProps}
                >
                    {children}
                </div>
                {isSelected && <CheckNoCircleIcon />}
            </div>
        </OptionWrapper>
    );
};

export const Select: React.FC<SelectProps> = ({ options, onChange, value, width, id, spClassName }) => {
    const handleChange = (option: ValueType<OptionType, false>) => {
        const value = (option as OptionType).value;
        onChange(value);
    };

    const selected = options.filter((o) => o.value === value)[0];

    const selectWidth = width || '100%';

    const selectStyles: StylesConfig<OptionType, false> = {
        container: (provided) => ({
            ...provided,
            width: selectWidth,
            marginTop: '10px',
            marginBottom: '20px'
        }),
        control: (provided) => ({
            ...provided,
            background: 'var(--steel-blue)',
            border: 'none',
            borderRadius: '5px',
            boxShadow: '0 0 6px 0 rgba(28, 2, 33, 0.75)',
            cursor: 'pointer',
            color: 'var(--light-pink)',
            stroke: 'var(--light-pink)',
            ':hover': {
                background: 'var(--purple-blue)',
                color: 'var(--tennis-green)',
                stroke: 'var(--tennis-green)'
            }
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'inherit'
        }),
        indicatorSeparator: () => ({
            display: 'none'
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            stroke: 'inherit',
            paddingTop: '12px',
            marginRight: '-10px'
        }),
        menuList: (provided) => ({
            ...provided,
            overflowX: 'visible'
        }),
        menu: (provided) => ({
            ...provided,
            padding: '4px 30px 4px 20px',
            background: 'var(--steel-blue)',
            borderRadius: '10px'
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            color: 'var(--light-pink)',
            background: state.isFocused ? 'var(--purple-blue)' : 'none',
            borderRadius: '5px',
            padding: '8px 12px 8px 8px'
        })
    };

    return (
        <ReactSelect
            id={id}
            options={options}
            onChange={handleChange}
            value={selected}
            styles={selectStyles}
            components={{ DropdownIndicator, Option }}
            className={spClassName?? ''}
        />
    );
};
