import { ApiUser } from '../api/ApiTypes';

export interface SystemState {
    agreedToTerms: boolean;
    profileLoaded: boolean;
}

export const AGREE_TO_TERMS = 'AGREE_TO_TERMS';

export interface AgreeToTermsAction {
    type: typeof AGREE_TO_TERMS;
}

export const SET_PROFILE_LOADED = 'SET_PROFILE_LOADED';

export interface SetProfileLoadedAction {
    type: typeof SET_PROFILE_LOADED;
}

export type SystemActionTypes = AgreeToTermsAction | SetProfileLoadedAction;

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export interface SetCurrentUserAction {
    type: typeof SET_CURRENT_USER;
    currentUser: ApiUser;
}

export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';

export interface ClearCurrentUserAction {
    type: typeof CLEAR_CURRENT_USER;
}

export type CurrentUserActionTypes = SetCurrentUserAction | ClearCurrentUserAction;
