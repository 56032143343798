import { ApiUser, UserPermission, ApiSpace } from '../api/ApiTypes';


export const userHas = (user: ApiUser, perm: string) => {
    return user?.permissions?.find((p: UserPermission) => {
        return p.name === perm && p.allowed === true;
    })? true: false;
};

export const userHasPermission = (user: ApiUser, perm: string) => {
    return user?.permissions?.find((p: UserPermission) => {
        return p.name === perm;
    });
}

export const canAdmin = (user: ApiUser) => {
  return userHas(user, 'canAdmin');
}

export const canAccessAdvancedSettings = (user: ApiUser) => {
  return userHas(user, 'canAccessAdvancedSettings');
}

export const canManageEnvironments = (user: ApiUser) => {
  return userHas(user, 'canManageEnvironments');
}

export const canManageUsers = (user: ApiUser) => {
  return userHas(user, 'canManageUsers');
}

export const canManageDiscover = (user: ApiUser) => {
  return userHas(user, 'canManageUsers');
}

export const canCreateStation = (user: ApiUser) => {
  return userHas(user, 'canCreateStation');
}

export const canGetFreeUpgrade = (user: ApiUser) => {
  return userHas(user, 'canGetFreeUpgrade');
}

export const canViewCTA = (user: ApiUser) => {
  return userHas(user, 'canViewCTA');
}
export const canViewAccountUpsell = (user: ApiUser) => {
  return userHas(user, 'canViewAccountUpsell');
}
export const canUseUnlimitedTime = (user: ApiUser) => {
  return userHas(user, 'canUseUnlimitedTime');
}
export const isGuest = (user: ApiUser) => {
  return userHas(user, 'guestAuthority');
}

export const canHaveStations = (user: ApiUser) => {
  const perm = userHasPermission(user, 'canCreateStation');
  if(!perm) return false;
  return ( (perm?.data?.maxAllowed?? 0) > 0 );
}

export const isAnon = (user: ApiUser) => {
  return userHas(user, 'anonAuthority');
}

export const hasStationOwnerAuthority = ( user: ApiUser, stationId: string ) => {
  const isAdmin = userHas(user, 'canAdmin');
  if(isAdmin) return true;
  const perm = userHasPermission(user, 'stationOwnerAuthorityOf');
  if(!perm) return false;
  return (perm?.data?.stations?? []).includes(stationId);
}

export const canManageRacing = ( user: ApiUser, space: ApiSpace ) => {
  if(canAdmin(user)) return true;
  if(!hasStationOwnerAuthority(user, space.stationId)) return false;
  return space.isRaceable;
}

export const canManageRacingSubsection = ( user: ApiUser, space?: ApiSpace, subsection?: string ) => {
  if(canAdmin(user)) return true;
  if(!hasStationOwnerAuthority(user, space?.stationId?? 'UNKOWN')) return false;

  const subsectionAccess: { [key: string]: boolean } = {
    prizePayouts:           false,
    racing:                 false,
    metadata:               false,
    autoCalibration:        false,
    automaticRaceConfig:    true,
    racerRestrictions:      true,
    raceDynamics:           true,
    raceModes:              true,
    tournaments:            true,
    tournamentsEntryCircle: false,
    entryCircle:            false,
    laneAssignment:         false,
    startingGun:            true,
    startingLine:           false,
    finishLine:             false,
    scorersTableLocation:   false,
    podium:                 false,
    scoreboard:             true,
    raceComplete:           false,
  };

  return !!subsectionAccess[subsection?? 'UNKOWN'];
}
