import * as React from 'react';
import styled from 'styled-components/macro';
import SpaceBox from '../station/SpaceBox';
import { Link } from 'react-router-dom';
import { ApiSpace } from '../../api/ApiTypes';

interface SpaceListProps {
    stationTitle?: string;
    spaces: ApiSpace[];
}

// Sort spaces into active/inactive first, and then alphabetically
const sortSpaces = (a: ApiSpace, b: ApiSpace) => {
    if (a.isActive !== b.isActive) {
        return a.isActive ? -1 : 1;
    }

    // TODO: kill once all records have a title
    if (!a || !b) return 0;

    if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
    }
    if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
    }

    return 0;
};

const AdminSpaceList: React.FunctionComponent<SpaceListProps> = ({ stationTitle, spaces }) => {
    const spacesSorted = React.useMemo(() => [...spaces].sort(sortSpaces), [spaces]);

    return (
        <Container>
            {spacesSorted &&
                spacesSorted.map((space: ApiSpace) => (
                    <NoUnderlineLink key={space.id} to={`/space/${space.id}`}>
                        <SpaceBox stationTitle={stationTitle} space={space} key={space.id} />
                    </NoUnderlineLink>
                ))}
        </Container>
    );
};

const Container = styled.div`
    width: 300px;
    border-radius: 20px;
    box-shadow: inset 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--midnight);
`;

const NoUnderlineLink = styled(Link)`
    display: block;
    margin: 0 0 15px 0;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
`;

export default AdminSpaceList;
