import * as React from 'react';
import styled from 'styled-components/macro';
import { useState } from 'react';

const VimeoBackground = ({ vimeoId }: { vimeoId: string }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <VimeoWrapper loaded={loaded}>
            <iframe
                title="This is a background video"
                src={`https://player.vimeo.com/video/${vimeoId}?background=1&autoplay=1&loop=1&byline=0&title=0`}
                frameBorder="0"
                allowFullScreen
                onLoad={() => {
                    console.log('loaded');
                    setLoaded(true);
                }}
            />
        </VimeoWrapper>
    );
};

const VimeoWrapper = styled.div<{ loaded: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
    transition: all 0.7s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    opacity: ${({ loaded }) => (loaded ? '1' : '0')};

    iframe {
        width: 100vw;
        height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export default VimeoBackground;
