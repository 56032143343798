import * as React from 'react';
import styled from 'styled-components/macro';
import { ApiSpace } from '../../api/ApiTypes';
import LinkIcon from '../../images/space-link-icon.svg';
import EmailIcon from '../../images/circle-avatar-info.svg';
import HostLinkIcon from '../../images/host-link-icon.svg';
import InviteByLinkForm from './InviteByLinkForm';
import InviteByEmailForm from './InviteByEmailForm';
import InviteHostByLinkForm from './InviteHostByLinkForm';
import InviteByLinkButtonOnly from './InviteByLinkButtonOnly';
import PauseNewUserAccessForm from './PauseNewUserAccessForm';
import useShowPageHelp from '../../hooks/useShowPageHelp';
import HelpText from '../HelpText'
import { envAsBool } from '../../utils/envLoader';

interface Props {
    inviteCode: string;
    inviteHostCode: string;
    inviteSpectateCode: string;
    space: ApiSpace;
}

type Invites = {
    inviteUserLink: string;
    inviteHostLink: string;
    userLinkTooltip: string,
    hostLinkTooltip: string;
    emailTooltip: string;
    inviteUserLinkLite: string;
    inviteUserToSpectateLink: string;
    inviteUserToSpectateLinkToolTip: string;
    userLinkLiteTooltip: string;
}

const getInviteLinks = (inviteCode: string, inviteHostCode: string, inviteSpectateCode: string, spaceId: string): Invites => {
    return {
        inviteUserLink: `${window.location.protocol}//${window.location.host}/invite/${inviteCode}?spaceId=${spaceId}`,
        inviteHostLink: `${window.location.protocol}//${window.location.host}/hostInvite/${inviteHostCode}`,
        inviteUserLinkLite: `${window.location.protocol}//${window.location.host}/invite/${inviteCode}/lite?spaceId=${spaceId}`,
        inviteUserToSpectateLink: `${window.location.protocol}//${window.location.host}/spectateInvite/${inviteSpectateCode}`,
        inviteUserToSpectateLinkToolTip: "Link lets guests enter as a sepctator.",
        userLinkTooltip: "Link lets guests skip the Station Overview, and go directly to this Space. Also gives access to the whole Station.",
        userLinkLiteTooltip: "A stand alone page to watch streaming content without loading into the 3D world. Use for low-end devices or a passive overflow page.",
        hostLinkTooltip: "Only share this link with people you want to Host in this Space. \
            Anyone who clicks this link will gain access to this Space's admin page and \
            in-world Host controls. They will NOT have access to the Station admin page nor access to Host controls in other Spaces.",
        emailTooltip: "Sends a unique Direct Invite Link via email that can ONLY be used by this email."

    };
};

export const InviteToSpaceButton: React.FC<Props> = ({ inviteCode, inviteHostCode, inviteSpectateCode, space }) => {
    const invLinks = getInviteLinks(inviteCode, inviteHostCode, inviteSpectateCode, space.id);

    return (
        <InviteByLinkButtonOnly
            link={invLinks.inviteUserLink}
            tooltip={invLinks.userLinkTooltip}
        />
    )
};

const InviteToSpace: React.FC<Props> = ({ inviteCode, inviteHostCode, inviteSpectateCode, space}) => {
    const invLinks = getInviteLinks(inviteCode, inviteHostCode, inviteSpectateCode, space.id);
    const showHelp = useShowPageHelp('spaceAdmin');
    // const showHelp = true;
    const helpText = `Send guests directly to this 3D space.`;
    //const helpTextLite = `Send guests directly to the lite version of this space`;
    const helpTextInnerStyle = {
        top: '114px',
        right: '-30px'
    };
    const hostHelpText = 'Invite co-hosts with this link so they can screenshare and amplify to present or perform.';
    const hostHelpTextInnerStyle = {
        width: '300px',
        top: '-120px',
        right: '-30px'
    };


    return (
        <Container>
            {showHelp && (<HelpText label={helpText} noWrap={true} innerStyle={helpTextInnerStyle} />)}
            <InviteByLinkForm
                icon={LinkIcon}
                link={invLinks.inviteUserLink}
                header="Invite Guest By Link"
                tooltip={invLinks.userLinkTooltip}
            />
            { envAsBool('ENABLE_NEW_USER_ACCESS_TOGGLE') && (
                <PauseNewUserAccessForm
                    spaceId={space.id}
                />
            )}
            <InviteByLinkForm
                icon={LinkIcon}
                link={invLinks.inviteUserLinkLite}
                header="Stream Viewer Invite"
                tooltip={invLinks.userLinkLiteTooltip}
            />
            { space?.modeSettings?.spectate?.isEnabled  && (
               <InviteByLinkForm
                  icon={LinkIcon}
                  link={invLinks.inviteUserToSpectateLink}
                  header="Spectator Invite"
                  tooltip={invLinks.inviteUserToSpectateLinkToolTip}
              />
            )}
            <InviteByEmailForm
                header="Invite Guest By Email"
                icon={EmailIcon}
                placeholder="Enter email address to invite"
                tooltip={invLinks.emailTooltip}
                space={space}
            />
            <InviteHostByLinkForm
                link={invLinks.inviteHostLink}
                icon={HostLinkIcon}
                header="Invite Host"
                tooltip={invLinks.hostLinkTooltip}
            />
            {showHelp && (<HelpText label={hostHelpText} noWrap={false} innerStyle={hostHelpTextInnerStyle} />)}
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    margin-right: 30px;
    max-width: 700px;
`;

export default InviteToSpace;
