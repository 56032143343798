import * as React from 'react';
import useCopyClipboard from '../../hooks/useCopyClipboard';
import { TinyButton } from '../inputs/Button';
import Divider from '../Divider';
import styled from 'styled-components/macro';
import { GreyishBodyText } from '../Typography';
import LinkIcon from '../../images/i-invite-link.svg';
import { LinkButton } from '../inputs/Button';

interface Props {
    link: string;
    tooltip: string;
}
const InviteByLinkButtonOnly: React.FC<Props> = ({ link, tooltip }) => {
    const [LinkCopied, setLinkCopied] = useCopyClipboard(link, { successDuration: 2000 });

    return (
        <LinkButton onClick={setLinkCopied} disabled={false} >
            <img src={LinkIcon} style={{ margin: '1px 8px 3px -12px', width: '20px'}} alt="link" />
            {LinkCopied ? 'Copied!' : 'Invite'}
        </LinkButton>
    )
};


export default InviteByLinkButtonOnly;
