import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { getAuthError } from '../store/selectors';
import UploadProfilePic, { doImageUpload } from './orbit/UploadProfilePic';
import ValidatedFormInput from './inputs/ValidatedFormInput';
import { StickyButton } from './inputs/Button';
import InputLabel from './inputs/InputLabel';
import { OutlinedInput } from './inputs/OutlinedInput';
import FormErrorMessage from './inputs/FormErrorMessage';
import Checkbox, { OpenCheckbox } from './inputs/Checkbox';
import SliderCaptcha from 'slider-captcha/react';
import { v4 as uuidv4 } from 'uuid';
import { getApiBaseUrl } from '../api/api';
import { envAsBool } from '../utils/envLoader';

type SignUpProps = {
    onSubmit: (
        userProfile: Record<string, any>,
        password: string,
        captchaToken: string,
        setSubmitError: (error: string) => void
    ) => void;
};

const SignUpByEmailForm = ({ onSubmit }: SignUpProps) => {
    const authError = useSelector(getAuthError);
    const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit', shouldFocusError: true });

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [croppedAvatar, setCroppedAvatar] = useState(null as any);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [privacyPolicy, setPrivacyPolicy] = useState(false as any);
    const [optInMarketing, setOptInMarketing] = useState(false as any);
    const [submitError, setSubmitError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [captchaSession, setCaptchaSession] = useState(uuidv4());
    const [captchaToken, setCaptchaToken] = useState('');

    const allowPlusEmails = envAsBool('ALLOW_PLUS_SYMBOL_EMAIL_SIGNUP');

    React.useEffect(() => {
        // I'm so sorry...
        // There should only be one of these per page...
        const container = window.document.getElementsByClassName('scaptcha-container')[0];
        // So dumb
        const styleTag = container.childNodes[0];
        if (styleTag) {
            styleTag.textContent = captchaStyles;
        }
    }, []);

    const togglePrivacyPolicyAcceptance = () => setPrivacyPolicy(!privacyPolicy);

    const toggleOptInMarketing = () => setOptInMarketing(!optInMarketing);

    const handleError = (error: string) => {
        setSubmitting(false);
        setSubmitError(error);
    };

    const submitEmailPassword = async () => {
        setSubmitting(true);
        setSubmitError('');
        const avatarUrl = await doImageUpload(croppedAvatar);

        if (captchaToken === '') {
            handleError('Please confirm you are human');
            return;
        }

        const userProfile: any = { email, firstName, lastName, optInMarketing };
        if (avatarUrl) {
            userProfile.avatarUrl = avatarUrl;
        }

        onSubmit(userProfile, password, captchaToken, handleError);
    };

    const verifiedCallback = (token: string) => {
        setCaptchaToken(token);
    };

    const getCaptchaUrl = (type: string) => {
        if (type === 'create') {
            return getApiBaseUrl() + '/captcha/' + captchaSession + '/create';
        }
        return getApiBaseUrl() + '/captcha/' + captchaSession + '/verify';
    };

    return (
        <Container>
            <UploadProfilePic onImageChanged={setCroppedAvatar} />
            <div className="d--f fd--c ai--c">
                <form
                    id="signup-Form"
                    name="wf-form-signup-Form"
                    data-name="signup Form"
                    className="form-2"
                    onSubmit={handleSubmit(submitEmailPassword)}
                    style={{ width: '100%', maxWidth: '310px' }}
                >
                    <NameContainer className="d--f">
                        <div>
                            <InputLabel htmlFor="firstName">First Name *</InputLabel>
                            <ValidatedFormInput
                                type="text"
                                maxLength={256}
                                name="firstName"
                                errors={errors}
                                validation={register({ required: 'First name is required' })}
                                value={firstName}
                                onChange={(e: any) => setFirstName(e.target.value)}
                                style={{ width: '200px', marginRight: '15px' }}
                            />
                        </div>
                        <div>
                            <InputLabel htmlFor="lastName">Last Name *</InputLabel>
                            <ValidatedFormInput
                                type="text"
                                maxLength={256}
                                name="lastName"
                                errors={errors}
                                validation={register({ required: 'Last name is required' })}
                                value={lastName}
                                onChange={(e: any) => setLastName(e.target.value)}
                                style={{ width: '295px' }}
                            />
                        </div>
                    </NameContainer>
                    <InputLabel htmlFor="signupEmail-2">Email *</InputLabel>
                    <ValidatedFormInput
                        type="email"
                        maxLength={256}
                        name="email"
                        errors={errors}
                        validation={register({
                            required: 'Email is required',
                            pattern: {
                                value: allowPlusEmails
                                    ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                    : /^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address'
                            }
                        })}
                        value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                        style={{ width: '310px' }}
                    />
                    <InputLabel htmlFor="loginPassword-2">Password *</InputLabel>
                    <OutlinedInput
                        type="password"
                        maxLength={256}
                        name="password"
                        data-name="loginPassword"
                        id="loginPassword"
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        style={{ width: '310px', marginBottom: '10px' }}
                    />
                    <ValidatedFormInput
                        type="password"
                        maxLength={256}
                        name="password"
                        validation={register({
                            required: 'Please confirm your password',
                            minLength: {
                                value: 8,
                                message: 'Password must be at least 8 characters'
                            },
                            validate: () => confirmPassword === password || 'Passwords must match'
                        })}
                        data-name="confirmLoginPassword"
                        id="confirmLoginPassword"
                        errors={errors}
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e: any) => setConfirmPassword(e.target.value)}
                        style={{ width: '310px' }}
                    />

                    <div style={{ display: 'flex' }}>
                        <StyledCaptcha
                            create={getCaptchaUrl('create')}
                            verify={getCaptchaUrl('verify')}
                            callback={verifiedCallback}
                            variant="dark"
                        />
                    </div>

                    <br />

                    <div style={{ display: 'flex' }}>
                        <OpenCheckbox
                            validation={register({
                                validate: () => {
                                    console.log('privacyPolicy', privacyPolicy);
                                    return privacyPolicy || 'You must agree to the privacy policy';
                                }
                            })}
                            errors={errors}
                            name="privacy"
                            checked={privacyPolicy}
                            onChange={togglePrivacyPolicyAcceptance}
                        >
                            I accept NWR&apos;s{' '}
                            <a
                                href="https://urnowhere.com/privacy-policy"
                                style={{ textDecoration: 'underline' }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms and Privacy Policy
                            </a>{' '}
                            which treats everyone like guests in our home.
                        </OpenCheckbox>
                    </div>

                    <div style={{ display: 'flex' }}>
                        <OpenCheckbox name="optInMarketing" checked={optInMarketing} onChange={toggleOptInMarketing}>
                            Send me email updates
                            <br />
                            about Nowhere
                        </OpenCheckbox>
                    </div>

                    {errors?.['privacy']?.message && <FormErrorMessage error={errors?.['privacy']?.message} />}

                    <div className="d--f jc--fe" onClick={handleSubmit(submitEmailPassword)}>
                        {<FormErrorMessage>{authError?.message || submitError}</FormErrorMessage>}
                        <StickyButton disabled={submitting}>Submit</StickyButton>
                    </div>
                </form>
            </div>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: 768px) {
        flex-direction: row;
        margin: 0 auto;
    }
`;
const NameContainer = styled.div`
    max-width: 100px;
    display: flex;
    flex-direction: column;
    @media (min-width: 1000px) {
        display: flex;
        flex-direction: row;
    }
`;

const captchaStyles = `
.scaptcha-icon-light {
    display: none;
}

.scaptcha-icon-dark {
    display: block;
}

.scaptcha-anchor-container {
    display: flex;
    align-items: center;
    justify-content: left;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--off-white);
    border-radius: 10px;
    background-color: rgba(0,0,0,0);
    width: 100%;
    height: 50px;
    padding: 13px;
    max-width: 400px;
}

.scaptcha-anchor-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    display: flex;
    border: 1px solid #f6f6f9;
    border-radius: 3px;
    height: 24px;
    width: 24px;
    background-color: rgba(0,0,0,0);
}

.scaptcha-anchor-checkbox-default:hover {
    cursor: pointer;
    border: 2px solid #bee639;
}

.scaptcha-anchor-label {
    font-size: 13px;
    font-family: all-round-gothic, sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: left;
    color: #c6c6c6;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-left: 13px;
    cursor: default;
}

.scaptcha-card-background {
    width: 250px;
    height: 150px;
}

.scaptcha-card-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 250px;
    min-height: 150px;
    z-index: 1;
}

.scaptcha-card-container {
    position: absolute;
    padding: 15px 15px 0px 15px;
    min-width: 280px;
    min-height: 216px;
    background-color: #1a1a1a;
    box-shadow: 0px -1px 0px -2px rgba(0, 0, 0, 0.2), 0px 2px 9px 0px rgba(0, 0, 0, 0.14), 0px 5px 9px 0px rgba(0, 0, 0, 0.15);
    margin-top: -260px;
    margin-left: -7px;
}

@media only screen and (max-width: 639px) {
    .scaptcha-card-container {
    left: 50%;
    margin-left: -140px;
    }
}
.scaptcha-card-slider-puzzle {
    margin-left: 15px;
    margin-top: 15px;
    position: absolute;
    left: 5px;
    top: 0;
    height: 150px;
    width: 60px;
    cursor: pointer;
}

.scaptcha-card-slider-control {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}

.scaptcha-card-slider-control-default {
    background-color: #242222;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.scaptcha-card-slider-control-active,
.scaptcha-card-slider-control-success,
.scaptcha-card-slider-control-failure {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.scaptcha-card-slider-control-active {
    background-color: #0889e4;
}

.scaptcha-card-slider-control-success {
    background-color: #35dd74;
}

.scaptcha-card-slider-control-failure {
    background-color: #e40808;
}

.scaptcha-card-slider-container {
    margin-top: 7px;
    position: relative;
    height: 44px;
    width: 250px;
}

.scaptcha-card-slider-track {
    box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 3px;
    left: 0;
    width: 250px;
    height: 24px;
    background-color: #353535;
    border-radius: 12px;
}

.scaptcha-card-slider-mask {
    box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 3px;
    left: 0;
    width: 0;
    height: 24px;
    border-radius: 12px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.scaptcha-card-slider-track-default,
.scaptcha-card-slider-track-active {
    background-color: #3caeff;
}

.scaptcha-card-slider-track-success {
    background-color: #83f788;
}

.scaptcha-card-slider-track-failure {
    background-color: #ff3c3c;
}

.scaptcha-card-slider-label {
    font-size: 13px;
    font-family: all-round-gothic, sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #716e6e;
    padding-left: 20px;
    position: absolute;
    top: 3px;
    left: 0;
    width: 250px;
    height: 24px;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: default;
}

.scaptcha-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1px;
    margin-top: 1px;
}

.scaptcha-hidden {
    background: none;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    position: fixed;
}

.scaptcha-container * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

`;

const StyledCaptcha = styled(SliderCaptcha)`
    ${captchaStyles}
`;
export default SignUpByEmailForm;
