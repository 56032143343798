import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { putApi, getApi } from '../api/api';
import { StationDistributionInviteRules, ApiSpace } from '../api/ApiTypes';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { AtCapacityModal } from '../components/modals/LobbyModals'

const AcceptMeetInvite: React.FC = () => {
  const { meetInviteCode } = useParams() as any;
  const history = useHistory();
  const [error, setError] = useState(false);
  const [isAllFull, setIsAllFull] = useState(false);
  const [space, setSpace] = useState<{ [key: string]: any }>({})

  const ALL_FULL_PREFIX = '__ALL_FULL__::';

  const getStationDistribution = async (stationId: string) => {
    const station = await getApi(`/station/${stationId}`).then(async (res) => {
      return res.json();
    });

    return station!.station.distributionInviteRules! as StationDistributionInviteRules;
  };

  const getSpace = async (spaceId: string) => {
    return await getApi(`/space/${spaceId}`).then(async (res) => {
      const s = await res.json();
      return s.space;
    });
  };


  useEffect(() => {
    (async () => {
      const response = await putApi(`/meet/invite/${meetInviteCode}`).catch(() => setError(true));
        if (!response || !response.ok) {
          setError(true);
          return;
        }
        const { success, meetInvite } = await response.json();

        console.log({ success, meetInvite });

        if(meetInvite.spaceId === '__DISABLED__') {
          console.log('Invite has been disabled');
          setError(true);
          return;
        }

        //Check if all spaces are full -
        //Will only happen if this is a station distribution invite
        const isAllFull = (meetInvite.spaceId.indexOf(ALL_FULL_PREFIX) === 0);
        if(isAllFull) {
          const spaceId = meetInvite.spaceId.split(ALL_FULL_PREFIX)[1];
          const stationId = meetInvite.stationId;
          //We need to get the station distribution rules
          getStationDistribution(stationId).then(async (sdr: StationDistributionInviteRules) => {
            //Lets capture the station distribution overflow
            const overflowSpaceId = sdr.allSpacesFullOverflow;
            //We need to get the space so we can modify it before sending it to the overflow modal
            const _space = await getSpace(spaceId);
            //Lets override the spaces overflow setting to use the station allSpacesFullOverflow
            const space = { ..._space, overflowSpaceId };
            setSpace(space);
            setIsAllFull(true);
          }).catch((e: Error) => {
            console.log('Failed to handle isAllFull with error: ', e);
            setError(true);
          });
        }

        if(!isAllFull) {
          const isSpectateInvite = ((meetInvite?.roleGrant?? 'MEMBER') === 'SPECTATOR');

          if(!success) {
            setError(true);
            return;
          }
          history.push(`/space/${meetInvite.spaceId}/lobby${isSpectateInvite? '?isForcedSpectate=true': ''}`);
        }
    })();
  }, [meetInviteCode]);

  return <MeetInviteDiv>
    {isAllFull && <AtCapacityModal space={space as ApiSpace} />}
    <h3>{error? 'Meet invite code invalid': ''}</h3>
  </MeetInviteDiv>;
};

const MeetInviteDiv = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: red;
`;

export default AcceptMeetInvite;
