import {
    AGREE_TO_TERMS,
    SET_CURRENT_USER,
    SetCurrentUserAction,
    SET_PROFILE_LOADED,
    SetProfileLoadedAction,
    CLEAR_CURRENT_USER,
    ClearCurrentUserAction,
    AgreeToTermsAction
} from './types';
import { ApiUser } from '../api/ApiTypes';

export function agreeToTerms(): AgreeToTermsAction {
    return {
        type: AGREE_TO_TERMS
    };
}

export function setCurrentUser(currentUser: ApiUser): SetCurrentUserAction {
    return {
        type: SET_CURRENT_USER,
        currentUser
    };
}

export function clearCurrentUser(): ClearCurrentUserAction {
    return {
        type: CLEAR_CURRENT_USER
    };
}

export function setProfileLoaded(): SetProfileLoadedAction {
    return {
        type: SET_PROFILE_LOADED
    };
}
