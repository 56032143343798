import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import useCopyClipboard from '../../hooks/useCopyClipboard';
import { APIDiscover } from '../../api/ApiTypes';
import { getApiBaseUrl } from '../../api/api';
import { TransparentModal } from '../layouts/Modal';
import { SmallCloseButtonStyles } from '../layouts/Modal';

type EventCardModalProps = {
    event: APIDiscover,
    isOpen: boolean,
    handleClose: () => void,
    ctaButton: any
}

const EventCardModal = ({event, isOpen, handleClose, ctaButton}: EventCardModalProps) => {
    const link = `${location.origin}/events/${event.id}`;
    const [linkCopied, linkCopiedSetter] = useCopyClipboard(link, { successDuration: 2000 });
    const timeInMS = event.startDateTime._seconds * 1000;
    const endDtmMS = event.endDateTime._seconds * 1000;
    const baseUrl = document.location.origin;

    /* you can use the 'webcal:// protocal for streaming multi events as a calendar
     * 'const calUrl = () => getApiBaseUrl().replace('http://', 'webcal://').replace('https://', 'webcal://') + '/discover/calendar'
     */
    const calUrl = () => getApiBaseUrl() + '/discover/calendar/' + event.id;

    const googleCalUrl = () => {
      const title = encodeURI(event.title);
      const description = encodeURI(event.description);
      const purchaseLink = event.isPaid? encodeURI(event.purchaseLink): '';
      const destinationLink = `${baseUrl}/` + (event.inviteTo?.spaceId? `/space/${event.inviteTo!.spaceId!}/loby`: `/station/${event.inviteTo.stationId}`);
      const inviteLink = event.isPaid? encodeURI(`${destinationLink}`): encodeURI(event!.inviteLink!);
      const details = description + (event.isPaid? `%0ABuy tickets here: ${purchaseLink}`: `%0AEvent Link: ${inviteLink}`);
      const startDate = moment(timeInMS).toISOString().split('.').slice(0, -1).join('').replace( /\W/g , '') + 'Z';
      const endDate = moment(endDtmMS).toISOString().split('.').slice(0, -1).join('').replace( /\W/g , '') + 'Z';
      return `https://calendar.google.com/calendar/u/0/r/eventedit?text=${title}&dates=${startDate}/${endDate}&location=${inviteLink}&details=${details}&sf=true&output=xml`
    }

    const onCalSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const select = event.currentTarget;
      const calValue = select.value;
      select.value = '';

      if(calValue === 'ICS') {
        document.location.href = calUrl();
      } else {
        window.open(googleCalUrl(), '_blank');
      }
    }

    return (
        <TransparentModal
            isOpen={isOpen}
            handleClose={handleClose}
            injected={true}
        >
            <Container>
                <LeftSide>
                    <ImageContainer>
                        <Image image={event.thumbnail}/>
                        {ctaButton(event, BottomButtonOverride)}
                    </ImageContainer>
                    <DateContainer>
                        <Tab />
                        <DateRightContainer>
                            <Date className='noTranslate'>{moment(timeInMS).format('dddd, MMMM Do')}</Date>
                            <Time className='noTranslate'>{moment(timeInMS).format('h:mm a')}</Time>
                        </DateRightContainer>
                    </DateContainer>
                    <Category>{event.category}</Category>
                </LeftSide>
                <RightSide>
                    <Title className='noTranslate'>{event.title}</Title>
                    <Host className='noTranslate'>{event.hostName}</Host>
                    <CopyLinkButton
                        linkCopied={linkCopied}
                        onClick={linkCopiedSetter}
                    >
                        {linkCopied ? 'Link Copied!' : 'Copy Link'}
                    </CopyLinkButton>
                    <StyledCalContainer>
                        <StyledSelect
                            className="stream-cal-select"
                            defaultValue={''}
                            onChange={onCalSelect}
                        >
                            <StyledOption value={''}>Add To Calendar</StyledOption>
                            <StyledOption value={'ICS'}>ICS</StyledOption>
                            <StyledOption value={'GCAL'}>Google Calendar</StyledOption>
                        </StyledSelect>
                    </StyledCalContainer>

                    <Description className="noTranslate">{event.description}</Description>
                </RightSide>
                <CloseButton onClick={handleClose}/>
            </Container>
        </TransparentModal>
    );
};

const Container = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    height: fit-content;
    max-height: 568px;
    width: 100%;
    max-width: 768px;
    padding-bottom: 60px;
    background-color: rgba(1,0,39,0.95);
    border-radius: 20px;
    box-shadow: 1px 2px 30px 0 rgba(0, 0, 0, 0.5);
`;

const RightSide = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 30px;
`;

const CloseButton = styled.button`
    ${SmallCloseButtonStyles}
    position: absolute;
    top: 15px;
    right: 15px;
`;

const Title = styled.div`
    font-size: 60px;
    line-height: 60px;
    font-weight: bold;
`;
const Host = styled.div`
    font-size: 16px;
    letter-spacing: 1px;
`;
const Description = styled.div`
    overflow-y: auto;
`;

const LeftSide = styled.div`
    width: 40%;
`;

const ImageContainer = styled.div`
    border-radius: 20px;
    margin-bottom: 30px;
`;

const Image = styled.div<{image: string}>`
    width: 100%;
    height: 360px;
    background-image: url(${(props) => props.image});
    background-color: var(--battleship-grey);
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 20px;
`;

const BottomButtonOverride = `
    border-bottom-left-radius: 0px;
`;

const DateContainer = styled.div`
    display: flex;
    margin-bottom: 18px;
    font-weight: bold;
`;

const Tab = styled.div`
    width: 10px;
    height: 40px;
    background-color: var(--purple-blue);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right: 10px;
`;

const DateRightContainer = styled.div`
    letter-spacing: 1.33px;
`;

const Date = styled.div`
    text-transform: uppercase;
`;

const Time = styled.div``;

const Category = styled.div`
    width: fit-content;
    height: 30px;
    margin-left: 20px;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--off-white);
    color: var(--midnight);
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: bold;
`;

const CopyLinkButton = styled.div<{linkCopied: boolean}>`
    width: fit-content;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 0px;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${(props) => props.linkCopied ? 'var(--tennis-green)' : 'var(--off-white)'};
    color: ${(props) => props.linkCopied ? 'var(--midnight)' : 'var(--midnight)'};
    border-radius: 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: var(--main-transition);

    &:hover {
        background-color: ${(props) => props.linkCopied ? 'var(--tennis-green)' : 'var(--tennis-green)'};
        color: ${(props) => props.linkCopied ? 'var(--midnight)' : 'var(--midnight)'};
    }
`;

const StyledCalContainer = styled.div`
    position: relative;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0 15px 15px 15px;
    margin-top: -45px;
    margin-left: 90px;
    margin-bottom: 20px;
`;

const StyledSelect = styled.select`
    width: fit-content;
    height: 30px;
    padding-left: 10px;
    margin-top: 15px;
    ext-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: var(--midnight);
    border-radius: 20px;
`;

const StyledOption = styled.option`
`;

export default EventCardModal;
