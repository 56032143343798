import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { getAuthState } from '../store/selectors';

interface Props {
    children: React.ComponentElement<any, any>;
    path: string;
    exact?: boolean;
}

// This is a normal route, except it bounces you to the home path if you're already logged in and try to visit it
// Mostly here for log-in and sign-up routes

const NonAuthorizedRoute: React.FC<Props> = ({ children, ...rest }) => {
    const auth = useSelector(getAuthState);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isEmpty(auth) || !auth.emailVerified ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
};

export default NonAuthorizedRoute;
