import { ApiUser } from '../api/ApiTypes';
import { get } from 'lodash';

export const getNameFromUser = (user: ApiUser) => {
    return [user.firstName, user.lastName].join(' ').trim() || 'Anonymous';
};

// Function for dynamically calculating width of react-table rows, taken from:
//   https://github.com/tannerlinsley/react-table/issues/94
const MAGIC_SPACING = 9;  // estimate based on font size and width

export type accessorFunction = (val: Record<string, any>) => string | number;
export type accessorType = string | accessorFunction;

export const getColumnWidth = (
  data: Record<string, any>[], accessor: accessorType, headerText: string
) => {
  const cellLength = Math.max(
    ...data.map(row => {
      let value: string | number = '';

      if (typeof accessor === 'string') {
        value = get(row, accessor);
      } else {
        value = accessor(row);
      }

      if (typeof value === 'number') return value.toString().length;
      return (value || '').length;
    }),
    headerText.length
  );

  return cellLength * MAGIC_SPACING;
};
