import styled from 'styled-components/macro';

// Todo: Kill this, is only used when should use text label on validated form input
const InputLabel = styled.label`
    font-family: all-round-gothic, sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.17px;
    margin-bottom: 11px;
    text-transform: uppercase;
    white-space: nowrap;
`;

export default InputLabel;
