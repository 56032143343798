import * as React from 'react';
import { useSelector } from 'react-redux';
import LoadingComponent from '../components/loading/LoadingComponent';
import { getAuthState, getCurrentUserLoaded, getCurrentUser } from '../store/selectors';
import LoggedInAcceptInvite from './LoggedInAcceptinvite';
import LoggedOutAcceptInvite from './LoggedOutAcceptInvite';
import { isEmpty } from 'react-redux-firebase';

const AcceptInvite: React.FC = () => {
    const auth = useSelector(getAuthState);
    const profileLoaded = useSelector(getCurrentUserLoaded);

    if (!profileLoaded) {
        return <LoadingComponent />;
    }

    if (!isEmpty(auth)) {
        return <LoggedInAcceptInvite />;
    } else {
        return <LoggedOutAcceptInvite />;
    }
};

export default AcceptInvite;
