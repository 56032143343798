// Lifted from
// https://gist.github.com/kottenator/9d936eb3e4e3c3e02598#gistcomment-2664601
export type PageIndicator = number | '...';

export const getRange = (start: number, end: number): Array<PageIndicator> =>
    [...Array(end - start + 1).keys()].map((_, i) => i + start);

export const getPageIndicators = (current: number, length: number, delta = 4) => {
    const range = {
        start: Math.round(current - delta / 2),
        end: Math.round(current + delta / 2)
    };

    if (range.start - 1 === 1 || range.end + 1 === length) {
        range.start += 1;
        range.end += 1;
    }

    let pages =
        current > delta
            ? getRange(Math.min(range.start, length - delta), Math.min(range.end, length))
            : getRange(1, Math.min(length, delta + 1));

    const withDots = (value: number, pair: Array<PageIndicator>) => (pages.length + 1 !== length ? pair : [value]);

    if (pages[0] !== 1) {
        pages = withDots(1, [1, '...']).concat(pages);
    }

    if (pages[pages.length - 1] < length) {
        pages = pages.concat(withDots(length, ['...', length]));
    }

    return pages;
};
