// Paginated Horizontal Scroller

import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components/macro';

type Props = {
    items: any[];
    scrollAmount: number;
    // Requires valid CSS string
    customStyles?: string;
};

const HorizontalScrollBox = ({ items, scrollAmount, customStyles}: Props) => {
    const ItemsRef = useRef<HTMLDivElement | null>(null);
    const [atStart, atStartSetter] = useState(false);
    const [atEnd, atEndSetter] = useState(false);

    const scrollRight = () => {
        console.log('scroll right')
        ItemsRef.current?.scrollBy({left: scrollAmount, behavior: 'smooth'});
    };

    const scrollLeft = () => {
        ItemsRef.current?.scrollBy({left: -scrollAmount, behavior: 'smooth'});
    };

    return (
        <Container>
            <ScrollIconContainer>
                <ScrollIcon direction='left' onClick={scrollLeft} atStart={atStart} atEnd={atEnd}>{'<'}</ScrollIcon>
                <ScrollIcon direction='right' onClick={scrollRight} atStart={atStart} atEnd={atEnd}>{'>'}</ScrollIcon>
            </ScrollIconContainer>
            <Wrapper ref={ItemsRef}>
                <Items styles={customStyles ?? ''}>
                    {items.map((item) => item)}
                </Items>
            </Wrapper>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    width: 100%;
`;

const Wrapper = styled.div`
    width: 100%;
    overflow: hidden;
`;

const Items = styled.div<{styles: string}>`
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${(props) => props.styles}
`;

const ScrollIconContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 50px;
    right: 0;
    top: -45px;

    @media (min-width: 768px) {
        width: 30px;
    }
`;

const ScrollIcon = styled.div<{direction: string, atStart: boolean, atEnd: boolean}>`
    height: fit-content;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 1px 1px 15px 0 var(--shadow-black);
    user-select: none;

    ${(props) => {
        if ((props.atStart && props.direction === 'left') || (props.atEnd && props.direction === 'right')) {
            return `color: grey;`
        } else {
            return `color: var(--off-white);`
        }
    }}

    &:hover {
        color: var(--tennis-green);
    };
`;

export default HorizontalScrollBox;
