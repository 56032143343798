import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Engine,
    Scene,
    ThinEngine,
    EnvironmentTextureTools,
    EquiRectangularCubeTexture,
    HDRFiltering
} from '@babylonjs/core';
import {} from '@babylonjs/loaders';
import styled from 'styled-components';
import { Tools } from 'babylonjs';
import { encode } from 'querystring';
import { start } from 'repl';

const envExportImageTypes = [
    { label: 'PNG', value: 0, imageType: 'image/png' },
    { label: 'WebP', value: 1, imageType: 'image/webp' }
];

interface SceneComponentProps {
    antialias?: boolean;
    engineOptions?: any;
    adaptToDeviceRatio?: boolean;
    sceneOptions?: any;
    onRender?: (scene: Scene) => void;
    onSceneReady?: (scene: Scene) => void;
}

const Jpg2Env: React.FC<SceneComponentProps> = ({
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
    ...rest
}) => {
    const [scene, setScene] = useState({} as Scene);
    const [envResolution, setEnvResolution] = useState(1024);
    const [encodedImagetype, setEncodedImageType] = useState(1);
    const [encodedImageQuality, setEncodedImageQuality] = useState(8);
    const [startedConversion, setStartedConversion] = useState(false);

    const handleEnvResolutionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedResolution = event.target.value; // Retrieve the selected value from the event
        // Update envResolution based on the selected image type
        if (selectedResolution === '512') {
            setEnvResolution(512);
        } else if (selectedResolution === '1024') {
            setEnvResolution(1024);
        } else if (selectedResolution === '2048') {
            setEnvResolution(2048);
        }
    };

    const handleEncodedImageTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedType = event.target.value; // Retrieve the selected value from the event
        // Update envResolution based on the selected image type
        if (selectedType === '0') {
            setEncodedImageType(0);
        } else if (selectedType === '1') {
            setEncodedImageType(1);
        }
    };
    const handleEncodedImageQualityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedType = event.target.value; // Retrieve the selected value from the event

        console.log('selectedType', selectedType);

        if (selectedType === '1') {
            setEncodedImageQuality(1);
        } else if (selectedType === '2') {
            setEncodedImageQuality(2);
        } else if (selectedType === '3') {
            setEncodedImageQuality(3);
        } else if (selectedType === '4') {
            console.log('encoded image quality set to 4')
            setEncodedImageQuality(4);
        } else if (selectedType === '5') {
            setEncodedImageQuality(5);
        } else if (selectedType === '6') {
            setEncodedImageQuality(6);
        } else if (selectedType === '7') {
            setEncodedImageQuality(7);
        } else if (selectedType === '8') {
            setEncodedImageQuality(8);
        } else if (selectedType === '9') {
            setEncodedImageQuality(9);
        } else if (selectedType === '10') {
            setEncodedImageQuality(10);
        }
    };
    // Create a ref to the canvas element
    const reactCanvas = React.useRef(null);

    const _envOptions = { imageTypeIndex: 1, imageQuality: 0.8 };

    // Handle drop event
    const handleFileChange = (fileList: FileList | null) => {
        if (!fileList) return console.error('FileList empty');

        const file = fileList[0];

        const name = file.name;
        const filetype = name.split('.').pop();

        if (filetype === 'jpg' || filetype === 'jpeg') {
            const jpgUrl = URL.createObjectURL(file);
            console.log('handleJpg URL: ', jpgUrl);
            // This URL is an intermediate, cleaning up onLoad callback
            const onLoad = () => URL.revokeObjectURL(jpgUrl);
            createEnvTextureFromJpg(jpgUrl, scene, name, onLoad);
        }
        // Create the environment texture from the dropped image
    };

    // on load, hit the meet api and begin the loading sequence
    useEffect(() => {
        // Check if the canvas ref is defined
        if (!reactCanvas.current) return;
        // Create a new Babylon.js engine using the canvas element
        const engine = new Engine(
            reactCanvas.current,
            antialias,
            {
                autoEnableWebVR: false,
                powerPreference: 'high-performance'
            },
            adaptToDeviceRatio
        );
        console.log('NEW ENGINE CREATED');
        // Create a new Babylon.js scene using the engine
        const scene = new Scene(engine, sceneOptions);
        scene.skipFrustumClipping = true;
        scene.skipPointerMovePicking = true;

        // Check if the scene is ready
        if (scene.isReady()) {
            // If the scene is ready, call the onSceneReady function
            if (onSceneReady) onSceneReady(scene);
            setScene(scene);
        } else {
            // If the scene is not ready yet, add an observer to call the onSceneReady function once the scene is ready
            if (onSceneReady) scene.onReadyObservable.addOnce((scene) => onSceneReady(scene));
        }

        // Set up a resize function to resize the engine when the window is resized
        const resize = () => {
            scene.getEngine().resize();
        };
        if (window) {
            window.addEventListener('resize', resize);
        }
        // Return a cleanup function to dispose of the engine and remove the event listener when the component is unmounted
        return () => {
            scene.getEngine().dispose();
            if (window) {
                window.removeEventListener('resize', resize);
            }
        };
    }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady]);

    const createEquiRectangularTexture = async (scene: Scene, filepath: string, resolution = 512, gamma: boolean) => {
        return await new Promise<EquiRectangularCubeTexture>((resolve, reject) => {
            const texture = new EquiRectangularCubeTexture(
                filepath,
                scene,
                resolution,
                false,
                gamma,
                () => {
                    resolve(texture);
                },
                () => {
                    reject();
                }
            );
        });
    };

    const prefilterEquirectangularCubeTextureAsync = async (
        texture: EquiRectangularCubeTexture,
        engine: ThinEngine
    ) => {
        return await new Promise<EquiRectangularCubeTexture>((resolve, reject) => {
            const filtering = new HDRFiltering(engine);
            filtering.prefilter(texture, () => {
                texture.gammaSpace = false;
                texture.lodGenerationScale = 0.8;
                console.log('prefiltering done');
                resolve(texture);
            });
        });
    };

    const createEnvTextureFromJpg = async (filepath: string, scene: Scene, name: string, callback?: () => void) => {
        //OLD
        console.log('888 createEnvTextureFromJpg', filepath);
        setStartedConversion(true);
        const jpgCubeTexture = await createEquiRectangularTexture(
            scene,
            filepath,
            envResolution,
            true //this is confusing -- needs to be set to true if you will be using it with PBR materials
        ).catch((e) => console.error(e));
        const envTextureArgs = {
            imageType: envExportImageTypes[encodedImagetype].imageType,
            imageQuality: encodedImageQuality/10
        };
        if (!jpgCubeTexture) return;

        const prefilteredJpgCubeTexture = prefilterEquirectangularCubeTextureAsync(jpgCubeTexture, scene.getEngine());

        return EnvironmentTextureTools.CreateEnvTextureAsync(await prefilteredJpgCubeTexture, envTextureArgs).then(
            (buffer: ArrayBuffer) => {
                const blob = new Blob([buffer], { type: 'octet/stream' });
                console.log('CreateEnvTextureAsync: ', buffer, blob);
                // TODO: DEBUGGING REMOVE THIS
                Tools.Download(blob, `${name}+${Date.now()}.env`);
                window.location.reload();
            }
        );
    };

    // Render a canvas element and pass any additional props to it
    if (!startedConversion) {
        return (
            <Container>
                <Header>Jpg to Env converter</Header>
                <SubHead>
                    Drag and drop a jpg or choose a jpg to convert to an env. Once uploaded, the download will
                    automatically start after the conversion process.{' '}
                </SubHead>
                <Canvas>
                    <canvas style={{ width: '100vw', height: '100vh' }} ref={reactCanvas} {...rest} />
                </Canvas>
                <Input type="file" onChange={(e) => handleFileChange(e.target.files)} accept=".jpg, .jpeg" />
                <Header2>Advanced Settings</Header2>
                <FlexRow>
                    <FlexColumn>
                        <Paragraph>Choose Env Cubemap Resoultion</Paragraph>
                        <Select value={envResolution} onChange={handleEnvResolutionChange}>
                            <option value="512">{'512'}</option>
                            <option value="1024">{'1024'}</option>
                            <option value="2048">{'2048'}</option>
                        </Select>
                    </FlexColumn>
                    <FlexColumn>
                        <Paragraph>Choose Image Encode Type</Paragraph>
                        <Select value={encodedImagetype} onChange={handleEncodedImageTypeChange}>
                            <option value={1}>{'webP'}</option>
                            <option value={0}>{'png'}</option>
                        </Select>
                    </FlexColumn>
                    <FlexColumn>
                        <Paragraph>Choose Image Encode Type</Paragraph>
                        <Select value={encodedImageQuality} onChange={handleEncodedImageQualityChange}>
                            <option value={1}>{'.1'}</option>
                            <option value={2}>{'.2'}</option>
                            <option value={3}>{'.3'}</option>
                            <option value={4}>{'.4'}</option>
                            <option value={5}>{'.5'}</option>
                            <option value={6}>{'.6'}</option>
                            <option value={7}>{'.7'}</option>
                            <option value={8}>{'.8'}</option>
                            <option value={9}>{'.9'}</option>
                            <option value={10}>{'1.0'}</option>
                        </Select>
                    </FlexColumn>
                </FlexRow>
                <Paragraph>
                    Coming soon: Hit the url with file:file:://filepath to automaticlly convert and download the file...
                </Paragraph>
            </Container>
        );
    } else {
        return (
            <Container>
                <Header>Generating</Header>
            </Container>
        );
    }
};

const Select = styled.select`
    margin-left: 10vw;
    margin-top: 5px;
    background-color: grey;
`;
const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;
const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 50px;
`;
const Paragraph = styled.p`
    margin-left: 10vw;
    margin-top: 20px;
`;

const SubHead = styled.h5`
    margin-left: 10vw;
    margin-right: 50px;
    margin-bottom: 20px;
`;
const Header2 = styled.h2`
    margin-left: 10vw;
    margin-top: 20px;
`;
const Header = styled.h1`
    margin-left: 10vw;
    margin-bottom: 20px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    overflow: hidden;
    width: 100vw;
    height: 100vh;

    background-color: #010027;
`;

const Canvas = styled.div`
    display: none;
    width: 100vw;
    height: 100vh;
    pointer-events: auto;
`;

const Input = styled.input`
    display: flex;
    margin-left: 10vw;
    justify-content: center;
    align-items: center;
`;
export default Jpg2Env;
