import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import MooseBackground from '../../images/nwr_Moose.png';
import { StreamContext } from './StreamContext';

interface Props {
    showIdleMessage: boolean
}

const StreamViewerIdle: React.FC<Props> = ({showIdleMessage}) =>{

    const streamContext = useContext(StreamContext);

    return (
        <IdleMessage onClick={streamContext.refreshStream} className={showIdleMessage ? 'visible' : 'invisible'}>
            <Almost>Almost ready!</Almost>
            <div style={{marginTop: '10px'}}>
                Copy the url and key into your livestream<br />
                software and begin the broadcast.<br />
                <br />
                Preview can take up to 30 seconds.<br />
                <br />
                If preview doesn{`'`}t start automtically,<br />
                click here to refresh.<br />
            </div>
        </IdleMessage>
    );

}

const IdleMessage = styled.div`
    position: absolute;
    padding-top: 110px;
    text-align: center;
    z-index: 10;
    width: 100%;
    height: 394px;
    background-color: var(--battleship-grey);
    background-image: url(${MooseBackground});
    background-position: -38px 30px;
    cursor: pointer;
    &.visible{
        visibility: visible;
    }
    &.invisible{
        visibility: hidden;
    }
`;

const Almost = styled.div`
font-size: 25px;
font-weight: bold;
line-height: 30px;
`;

export default StreamViewerIdle;
