import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { deleteApi } from '../../api/api';
import { Modal } from '../layouts/Modal';
import Message from '../layouts/Message';

const RemoveSelfFromStationModal = (
  { hideModal, isOpen, stationId }: {
    isOpen: boolean, hideModal: () => void, stationId: string
  }
) => {
  const history = useHistory();

  const remove = () => {
    deleteApi(`/station/${stationId}/users/me`)
        .then(async (r) => {
            const json = await r.json();
            if (!r.ok) {
                console.log(json.message || 'Error: remove failed');
            } else {
                history.push('/')
            }
        })
        .catch((e) => console.log(e));
  }

  return (
    <Modal handleClose={hideModal} isOpen={isOpen}>
      <Message
        title="Are you sure you want to fully remove yourself from this station?"
        body="You will have to be invited again to rejoin it."
        onCancel={hideModal}
        onConfirm={remove}
        confirmButtonText="Yes, Leave Station"
      />
    </Modal>
  );
};

export default RemoveSelfFromStationModal;
