import * as React from 'react';
import styled from 'styled-components/macro';
import InviteByEmailForm from './InviteByEmailForm';
import EmailIcon from '../../images/circle-avatar-info.svg';
import LinkIcon from '../../images/space-link-icon.svg';
import HostLinkIcon from '../../images/host-link-icon.svg';
import InviteByLinkForm from './InviteByLinkForm';
import StationDistributionInviteForm from './StationDistributionInviteForm';
import { ApiStationDetails } from '../../api/ApiTypes';
import { canUseDistributedInvite } from '../../permissions/stations';
import { envAsBool } from '../../utils/envLoader';

interface Props {
    inviteCode: string;
    inviteManagerCode: string;
    station: ApiStationDetails;
}

const InviteToStation: React.FC<Props> = ({ inviteCode, inviteManagerCode, station }) => {
    const inviteUserLink = `${window.location.protocol}//${window.location.host}/invite/${inviteCode}`;
    const inviteManagerLink = `${window.location.protocol}//${window.location.host}/managerInvite/${inviteManagerCode}`;

    return (
        <Container>
            <InviteByLinkForm
                icon={LinkIcon}
                link={inviteUserLink}
                header="Invite Link"
                tooltip="Anyone with link can view station page and join any space"
            />
            <InviteByLinkForm
                link={inviteManagerLink}
                icon={HostLinkIcon}
                header="Invite Manager"
                tooltip="Managers can control any space in your station. Only share this link with people you want to control this station."
            />
            {envAsBool('ENABLE_DISTRIBUTED_INVITE') && canUseDistributedInvite(station.station) && <StationDistributionInviteForm
                station={station}
                header="Distributed Invite"
                icon={LinkIcon}
                tooltip="Distribute players across selected spaces"
            />}
            <InviteByEmailForm
                header="Invite guests by email"
                icon={EmailIcon}
                placeholder="Enter email address to invite"
                tooltip="Clicking add will send and invitation to access this space"
            />
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    margin-right: 30px;
    max-width: 700px;
`;

export default InviteToStation;
