import * as React from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { envAsBool } from '../utils/envLoader';
import { writeSpaceControlState } from '../utils/CookieUtil';
import { auth } from '../firebase/Connection';
import { getApi, postApi } from '../api/api';
import { ApiSpace } from '../api/ApiTypes';
import { Helmet } from 'react-helmet';
import VimeoBackground from '../components/layouts/VimeoBackground';
import {useLocation} from "react-router-dom";
import UserAgentHelper from "../utils/UserAgentHelper";

import {
    ErrorModal,
    NotAuthorizedModal,
    SpaceClosedModal,
    AtCapacityModal,
    ActiveConfirmModal,
    SpaceGateAuthModal,
    NoAnonAllowedModal,
    NoUnverifiedEmailModal,
    NewUserAccessDeniedModal,
    WelecomeWorldModal,
} from '../components/modals/LobbyModals';
import { verticalCentering } from '../css/positioning';
import { getCurrentUserId } from '../store/selectors';
import { getCurrentUser } from '../store/selectors';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import useShowPageHelp from '../hooks/useShowPageHelp';

const TakeOver = styled.iframe<{ isLoaded: boolean }>`
    height: ${(props) => (props.isLoaded ? '100vh' : '100vh')};
    width: ${(props) => (props.isLoaded ? '100vw' : '100vw')};
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${(props) => (props.isLoaded ? 1 : 0)};
    z-index: ${(props) => (props.isLoaded ? 1000 : 1)};
    border: unset;
`;

const LiteLobby = () => {
    console.log('litelobby');
    const { spaceId, subSpace } = useParams() as any;

    const [needsActiveConfirm, setNeedsActiveConfirm] = useState(false);
    const [hostUrl, setHostUrl] = useState('http://localhost:8082/');
    const [environmentName, setEnvironmentName] = useState('');
    const [gaveUp, setGaveUp] = useState(false);
    const [error, setError] = useState(false);
    const [healthy, setHealthy] = useState(false);
    const [idToken, setIdToken] = useState('');
    const [showVimeoCommponent, setShowVimeoCommponent] = useState(envAsBool('ENABLE_SPACE_LOADING_SCREEN'));
    const [notAuthorizedReason, setNotAuthorizedReason] = useState('' as string);
    const [notAuthorizedMessage, setNotAuthorizedMessage] = useState('' as string);
    const [notAuthorizedAddtionalParams, setNotAuthorizedAddtionalParams] = useState({} as  { [key: string]: any });
    const [space, setSpace] = useState<ApiSpace | undefined>(undefined);
    const userId = useSelector(getCurrentUserId);
    const user = useSelector(getCurrentUser);
    const urlParamIsFirstTimeUser = (user.isFirstTimeUser === undefined || user.isFirstTimeUser)? "&isFirstTimeUser=true": "";
    const search = useLocation().search;

    const loadingScreenText = {
        head: 'Headphones On. Spatial Audio Ahead.',
        body: 'Close all other tabs, applications and go full screen for the ultimate NOWHERE experience.'
    };
    // Initialize our pageVisit cookie for firsttime users
    const showHelp = useShowPageHelp();
    // This is false is we don't pass a page to the above hook
    if (showHelp) console.log('show help');

    // true if the backend needs to be spun up and we know it'll take ~1 min
    const [created, setCreated] = useState(false);
    const [recentlyCreated] = useState(false);


    // const shouldIFrameSpace = (host: string) => {
    //     if(!envAsBool('REMOVE_IFRAME_WHEN_POSSIBLE')) return true;
    //     return (new URL(host).host.split(':')[0].endsWith(window.location.host.split(':')[0]))? false: true;
    // };


    useEffect(() => {
        console.log('spaceId: ', spaceId);
        const loadPage = async () => {

            if(!spaceId) return;

            console.log('loading ....');

            const idToken = await auth.currentUser?.getIdToken(/* forceRefresh */ true);
            if (!idToken) {
                setGaveUp(true);
                return;
            }
            setIdToken(idToken);

            console.log('idToken: ', idToken);

            const getDefaultsForErrorState = () => {
                return {
                    allowed: false,
                    message: "Could not determine acccess permissions",
                    reason: 'ERROR_RETRIEVING_PERMISSIONS',
                    space: {id: spaceId} as ApiSpace,
                    addtionalParams: {},
                    isAdmin: false,
                    station: {},
                    user: {},
                }
            }

            const getSpaceAccessInfo = async () => {
                let d = getDefaultsForErrorState();
                let retry = 5;
                while(d?.reason === 'ERROR_RETRIEVING_PERMISSIONS') {
                  try {
                    d =  await (async () => {
                        return getApi(`/space/${spaceId}/allowed/lite`).then((res) => res.json()).catch(() => getDefaultsForErrorState());
                    })();
                    if(d?.allowed === true) return d;
                  } catch (e) {
                    console.log("Error getting access info...", e);
                  }
                  retry -= 1; if(retry < 1) break;
                }
                return d;
            }

            const { allowed, message, reason, space, addtionalParams, isAdmin, station, user } =  await getSpaceAccessInfo();

            setSpace(space);

            if (!allowed) {
                // If the space is full but the person has access privileges, we still
                // want to show the space name as part of the error modal, so we need to
                // fetch the basic space data
                console.log(reason);
                if (['AT_CAPACITY', 'CRYPTO_ASSET_REQUIRED', 'NOT_ACTIVE', 'ERROR_RETRIEVING_PERMISSIONS', 'WELCOME_WORLD_REQUIRED'].indexOf(reason) > -1) {
                    try {
                        const spaceData = await (await getApi(`/space/${spaceId}`)).json();
                        setSpace(spaceData.space);
                    } catch (e) {
                        console.log("Could not get space data with error: ", e);
                    }
                }
                setNotAuthorizedReason(reason!);
                setNotAuthorizedMessage(message!);
                setNotAuthorizedAddtionalParams(addtionalParams?? {});
                return;
            }

            // we know that this person is an admin of the space b/c otherwise
            // they wouldn't be allowed when it's closed
            if (!space?.isActive) {
                setNeedsActiveConfirm(true);
            }

            // first call the space info api
            const response = await getApi(`/space/${spaceId}/getOrCreateServe/lite`).catch(() => setError(true));
            if (!response || !response.ok) {
                setError(true);
                return;
            }

            const { hostUrl, environmentName, mobileEnvironmentName } = await response.json();

            setError(false);
            setHostUrl(hostUrl);
            setCreated(created);
            setEnvironmentName(UserAgentHelper.isMobile() ? mobileEnvironmentName: environmentName);

            setHealthy(true);
        };
        loadPage();
    }, [spaceId]);

    useEffect(() => {
        console.log('999 hostUrl: ', hostUrl);
    }, [hostUrl]);

    if (error) return <ErrorModal />;
    if (notAuthorizedReason || notAuthorizedMessage) {
        if (notAuthorizedReason === 'ERROR_RETRIEVING_PERMISSIONS') {
            const attempt = parseInt(new URLSearchParams(search).get('attempt')?? '0');
            if(attempt < 2) {
                document.location.href = document.location.href.replace('attempt=', 'permerr=') + ((document.location.search)? '&': '?') + 'attempt=' + (attempt + 1);
                return <h3>Getting permissions...</h3>
            } else {
                return <NotAuthorizedModal space={space} notAuthorizedMessage={notAuthorizedMessage} />
            }
        } else if (notAuthorizedReason === 'AT_CAPACITY') {
            return <AtCapacityModal space={space} />;
        } else if (notAuthorizedReason === 'NOT_ACTIVE') {
            return <SpaceClosedModal space={space} />;
        } else if (notAuthorizedReason === 'CRYPTO_ASSET_REQUIRED') {
            return <SpaceGateAuthModal space={space} notAuthorizedMessage={notAuthorizedMessage} />;
        } else if (notAuthorizedReason === 'NO_ANON_ALLOWED') {
            return <NoAnonAllowedModal space={space} notAuthorizedMessage={notAuthorizedMessage} />;
        } else if (notAuthorizedReason === 'NO_UNVERIFIED_EMAIL_ALLOWED') {
            return <NoUnverifiedEmailModal space={space} notAuthorizedMessage={notAuthorizedMessage} />;
        } else if (notAuthorizedReason === 'NEW_USER_ACCESS_IS_PAUSED') {
            return <NewUserAccessDeniedModal space={space} notAuthorizedMessage={notAuthorizedMessage} />;
        } else if (notAuthorizedReason === 'WELCOME_WORLD_REQUIRED') {
            return <WelecomeWorldModal space={space} notAuthorizedMessage={notAuthorizedMessage} addtionalParams={notAuthorizedAddtionalParams} />;
        } else {
            return <NotAuthorizedModal space={space} notAuthorizedMessage={notAuthorizedMessage} />;
        }
    }
    if (needsActiveConfirm)
        return <ActiveConfirmModal spaceId={spaceId} enterSpace={() => setNeedsActiveConfirm(false)} />;

    if (healthy && idToken && !needsActiveConfirm) {
        const meta3DUrl = `${hostUrl}?space=${spaceId}`;

        let spaceParams = '';

        if (subSpace) {
            spaceParams += subSpace;
        }

        spaceParams += `scene=${environmentName}&userId=${userId}&space=${spaceId}&idToken=${idToken}&referrer=${encodeURIComponent(document.location.origin)}${urlParamIsFirstTimeUser}`;

        // if(space?.isWelcomeWorld) {
        //     spaceParams += '&isWelcomeWorld=true';
        //     const targetSpaceId = new URLSearchParams(search).get('targetSpaceId');
        //     if(targetSpaceId) spaceParams += `&targetSpaceId=${targetSpaceId}`;
        // }

        writeSpaceControlState(spaceId, spaceParams);

        //
        // WHERE THE RUBBER MEETS THE ROAD
        //
        console.log('going to liteURL: ', meta3DUrl);
        (window.location.href as any) = meta3DUrl;

        return (
            <>
                <Helmet>
                    <title>{`NOWHERE | ${space?.title || ''} | Lobby`}</title>
                </Helmet>

                {!userId && <Redirect to={{ pathname: '/log-in', search: '?hasUserId=false' }} />}

                {showVimeoCommponent && (
                    <div id="VimeoVideoDiv">
                        <VimeoBackground vimeoId="438728376" />
                        <VideoOverlay>
                            <MessageDiv>
                                <h3>{gaveUp ? 'Space Failed to Load. Try again later.' : loadingScreenText.head}</h3>

                                {created && <h3>{loadingScreenText.body}</h3>}
                                {recentlyCreated && <h3>{loadingScreenText.body}</h3>}
                            </MessageDiv>
                        </VideoOverlay>
                    </div>
                )}
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>{`NOWHERE | ${space?.title || ''} | Lobby`}</title>
            </Helmet>
            {showVimeoCommponent && (
                <VideoOverlay>
                    <MessageDiv>
                        <h3>{gaveUp ? 'Space Failed to Load. Try again later.' : loadingScreenText.head}</h3>

                        {created && <h3>{loadingScreenText.body}</h3>}
                        {recentlyCreated && <h3>{loadingScreenText.body}</h3>}
                    </MessageDiv>
                </VideoOverlay>
            )}
        </>
    );
};

// const LiteLobby = () => {
//     useEffect(() => {
//         const loadPage = async () => {

//             if(!spaceId) return;

//             console.log('loading ....');

//             const idToken = await auth.currentUser?.getIdToken(/* forceRefresh */ true);
//             if (!idToken) {
//                 setGaveUp(true);
//                 return;
//             }
//             setIdToken(idToken);

//             console.log('idToken: ', idToken);

//             const getDefaultsForErrorState = () => {
//                 return {
//                     allowed: false,
//                     message: "Could not determine acccess permissions",
//                     reason: 'ERROR_RETRIEVING_PERMISSIONS',
//                     space: {id: spaceId} as ApiSpace,
//                     addtionalParams: {},
//                     isAdmin: false,
//                     station: {},
//                     user: {},
//                 }
//             }

//             const getSpaceAccessInfo = async () => {
//                 let d = getDefaultsForErrorState();
//                 let retry = 5;
//                 while(d?.reason === 'ERROR_RETRIEVING_PERMISSIONS') {
//                   try {
//                     d =  await (async () => {
//                         return getApi(`/space/${spaceId}/allowed`).then((res) => res.json()).catch(() => getDefaultsForErrorState());
//                     })();
//                     if(d?.allowed === true) return d;
//                   } catch (e) {
//                     console.log("Error getting access info...", e);
//                   }
//                   retry -= 1; if(retry < 1) break;
//                 }
//                 return d;
//             }

//             const { allowed, message, reason, space, addtionalParams, isAdmin, station, user } =  await getSpaceAccessInfo();

//             setSpace(space);

//             if (!allowed) {
//                 // If the space is full but the person has access privileges, we still
//                 // want to show the space name as part of the error modal, so we need to
//                 // fetch the basic space data
//                 console.log(reason);
//                 if (['AT_CAPACITY', 'CRYPTO_ASSET_REQUIRED', 'NOT_ACTIVE', 'ERROR_RETRIEVING_PERMISSIONS', 'WELCOME_WORLD_REQUIRED'].indexOf(reason) > -1) {
//                     try {
//                         const spaceData = await (await getApi(`/space/${spaceId}`)).json();
//                         setSpace(spaceData.space);
//                     } catch (e) {
//                         console.log("Could not get space data with error: ", e);
//                     }
//                 }
//                 setNotAuthorizedReason(reason!);
//                 setNotAuthorizedMessage(message!);
//                 setNotAuthorizedAddtionalParams(addtionalParams?? {});
//                 return;
//             }


//             // first call the space info api
//             const response = await getApi(`/space/${spaceId}/lite`).catch(() => setError(true));
//             if (!response || !response.ok) {
//                 setError(true);
//                 return;
//             }

//             const { hostUrl } = await response.json();

//             setHostUrl(hostUrl);
//         };
//         loadPage();
//     }, [spaceId]);

//     return (
//         <div>Loading...</div>
//     )
// }

const MessageDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const VideoOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    ${verticalCentering},

    h3 {
        text-shadow: 3px 3px 18px rgba(0, 0, 0, 0.9);
    }
`;

export default LiteLobby;
