import * as React from 'react';
import { Select } from './Select';
import { getCookie, write3DAccessableCookie, languagePrefrenceCookieName } from '../../utils/CookieUtil';
import { useState, useEffect} from 'react';
import { translationHelper } from '../../utils/TanslationHelper';
import { envAsBool } from '../../utils/envLoader';


interface LanguageSelectFormProps {
    isInstantApply: boolean;
};

export const LanguageSelect: React.FC<LanguageSelectFormProps> = ({ isInstantApply }) => {

  const defaultLanguage = (() => {
    const langPref = getCookie(languagePrefrenceCookieName);

    if(langPref) return langPref;

    if(envAsBool('ENABLE_AUTO_TRANSLATE')) {
       return translationHelper.localLang;
    }

    return translationHelper.defaultLang;
  })();


  const [langPref, setLangPref] = useState(defaultLanguage);

  const LanguageSelectValues: { label: string; value: string;  }[] = [
    { label: 'English', value: "en-US" },
    { label: 'Português', value: 'pt-BR' },
    { label: 'Français', value: 'fr' },
  ].filter((lsv) => {
    if(envAsBool('ENABLE_FRENCH_TRANSLATE')) return true;
    if(lsv.value === 'fr') return false;
    return true;
  });

  useEffect(() => {
    //Write cookie
    const expireAfter = new Date(); expireAfter.setDate(expireAfter.getDate() + (360 * 5));
    write3DAccessableCookie(languagePrefrenceCookieName, langPref, expireAfter);
    //if isInstantApply then translate now
    if(isInstantApply) {
      translationHelper.translate(langPref);
    }
  }, [langPref]);

  const onLanguageChange = (value: string) => {
    setLangPref(value);
  }

  return (
    <span className="noTranslate"><Select
      onChange={onLanguageChange}
      value={langPref}
      options={LanguageSelectValues}
    /></span>
  );
}
