import { RootState } from './reducers';
import { UserType } from '../api/ApiTypes';
import { canAdmin } from '../permissions/users';
export const getAuthState = (state: RootState) => state.firebase.auth;

export const getAuthError = (state: RootState) => state.firebase.authError;

export const getCurrentUserLoaded = (state: RootState) => state.system.profileLoaded;

export const getCurrentUserName = (state: RootState) =>
    [state.currentUser?.firstName, state.currentUser?.lastName].filter((s) => Boolean(s)).join(' ');

export const getCurrentUserType = (state: RootState): UserType => state.currentUser?.userType as UserType;

export const getCurrentUserEmail = (state: RootState): string => state.currentUser?.email;

export const getCurrentUserId = (state: RootState): string => state.firebase.auth.uid;

export const getCurrentUser = (state: RootState) => state.currentUser;

export const getCurrentUserIsAdmin = (state: RootState): boolean => {
  return canAdmin(state.currentUser);
};

export const getCurrentUserIsHost = (state: RootState): boolean => ['Host'].includes(getCurrentUserType(state));

export const getCurrentUserHasModifiedProfile = (state: RootState): boolean => {
    const profile = getCurrentUser(state) as any;
    const { badgeSubtitle, company, displayEmail, social, title, url } = profile;

    return !!(badgeSubtitle || company || displayEmail || social || title || url);
};

export const getAgreedToTerms = (state: RootState) => state.system.agreedToTerms;
