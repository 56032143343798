import * as React from 'react';
import styled from 'styled-components/macro';
import { useState, useEffect } from 'react';
import { ApiStation, ApiUser } from '../../api/ApiTypes';
import { StationUtil } from '../../utils/StationUtil';
import useCopyClipboard from '../../hooks/useCopyClipboard';
import FollowTag from '../following/FollowTag';

import hostIcon from '../../images/i-host@3x.webp';
import settingsIcon from '../../images/i-settings@3x.webp';
import inviteIcon from '../../images/i-link@3x.webp';
import previewIcon from '../../images/i-preview.webp';

const InviteByLinkButton = ({ link, large, hovered }: { link: string; large: boolean; hovered: boolean }) => {
    const [linkCopied, linkCopiedSetter] = useCopyClipboard(link, { successDuration: 2000 });

    return (
        <InviteLinkContainer hovered={hovered}>
            <HiddenIcon large={large ?? false} hovered={hovered} src={inviteIcon} onClick={linkCopiedSetter} />
            {linkCopied && <LinkCopiedTooltip>Copied!</LinkCopiedTooltip>}
        </InviteLinkContainer>
    );
};

const StationCard = ({ station, large, dynamic }: { station: ApiStation; large?: boolean; dynamic?: boolean }) => {
    const [hovered, setHovered] = useState(false);
    const [followTagHovered, setFollowTagHovered] = useState(false);
    const isAdmin = station.role ? ['OWNER', 'ADMIN', 'MANAGER'].includes(station.role) : false;
    console.log('stationCard-role: ', station.role);
    const isMeet = station.isMeet || false;

    const isLive = station.stationAttendance > 0;
    const goToStationLink = `/station/${station.id}`;
    const goToMeetStationLink = `/meet/station/${station.id}`;
    const settingsButtonTarget = '_self';

    const goToStation = () => {
        if (isAdmin && isMeet) {
            open(goToMeetStationLink, '_top');
        } else {
            open(goToStationLink, '_top');
        }
    };

    return (
        <Container
            large={large ?? false}
            dynamic={dynamic ?? false}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Card url={StationUtil.getImage(station)} large={large ?? false} hovered={hovered} isMeet={isMeet}>
                <ClickBox onClick={goToStation} large={large ?? false} />

                <TagContainer>
                    <LeftTags>
                        {isLive ? (
                            <LiveTag>Live</LiveTag>
                        ) : (
                            // <LiveTag>Live</LiveTag>
                            <div></div>
                        )}
                        <PlayerCountTag isMeet={isMeet}>Spaces: <span className="noTranslate">{`${station.numSpaces}`}</span></PlayerCountTag>
                    </LeftTags>
                    {!isMeet && (
                        <FollowTagContainer
                            onMouseEnter={() => setFollowTagHovered(true)}
                            onMouseLeave={() => setFollowTagHovered(false)}
                            hovered={hovered}
                        >
                            <FollowTag
                                stationId={station.id!}
                                stationRole={station.role ?? 'MEMBER'}
                                isHovered={hovered && followTagHovered}
                            />
                        </FollowTagContainer>
                    )}
                    {isMeet && (
                                 <FollowTagContainer
                                 onMouseEnter={() => setFollowTagHovered(true)}
                                 onMouseLeave={() => setFollowTagHovered(false)}
                                 hovered={hovered}
                             >
                             <PlayerCountTag isMeet={isMeet}>{`Meet`}</PlayerCountTag>
                             </FollowTagContainer>
                    )}
                </TagContainer>

                <AdminContainer large={large ?? false} hovered={hovered} isMeet={isMeet}>
                    {!isMeet && (
                        <InviteByLinkButton
                            link={`${window.location.protocol}//${window.location.host}/${
                                station?.inviteCode ? 'invite/' + station.inviteCode : 'station/' + station.id
                            }`}
                            large={large ?? false}
                            hovered={hovered}
                        />
                    )}
                    <StyledSettingsAnchor
                        href={
                            isAdmin && isMeet
                                ? `/meet/station/${station.id}`
                                : `/station/${station.id}${isAdmin ? '/admin' : ''}`
                        }
                        target={settingsButtonTarget}
                        rel="noreferrer"
                        hovered={hovered}
                    >
                        <HiddenIcon
                            large={large ?? false}
                            hovered={hovered}
                            src={isAdmin ? settingsIcon : previewIcon}
                        />
                    </StyledSettingsAnchor>
                    <HostIcon large={large ?? false} src={hostIcon} />
                </AdminContainer>
            </Card>
            <Title large={large ?? false} hovered={hovered} className="noTranslate">
                {station.title}
            </Title>
        </Container>
    );
};

export default StationCard;

const Container = styled.div<{ onClick?: () => void; large: boolean; dynamic: boolean }>`
    position: relative;
    width: ${(props) => (props.large ? '410px' : props.dynamic ? '100%' : '244px')};
    height: fit-content;
    cursor: pointer;
`;

const Card = styled.div<{ url: string; large: boolean; hovered: boolean; isMeet: boolean }>`
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    height: 135px;
    border-radius: 10px;
    box-shadow: 1px 1px 30px 0 rgba(0, 0, 0, 0.35);
    background-size: cover;
    transition: var(--main-transition);

    ${(props) => `background-image: url(${props.url});`}

    ${(props) =>
        props.large
            ? `height: 227px;
         padding: 15px;
        `
            : ``}

    ${(props) =>
        !props.isMeet
            ? !props.hovered
                ? `border: solid 5px var(--midnight);`
                : `border: solid 5px var(--purple-blue);`
            : !props.hovered
            ? `border: solid 5px var(--midnight);`
            : `border: solid 5px #810A9A;`}
`;

const ClickBox = styled.div<{ large: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: +1;
    ${(props) => (props.large ? `width: 342px;` : `width: 204px;`)}
`;

const TagContainer = styled.div`
    position: relative;
    height: 100%;
`;

const LeftTags = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: fit-content;
`;

const tagStyles = `
    width: fit-content;
    height: fit-content;
    padding: 1px 6px 1px 6px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: var(--burnt-red);
    box-shadow: 1px 1px 6px 0 #010027;
    border-radius: 5px;
`;

const LiveTag = styled.div`
    ${tagStyles}
    background-color: var(--burnt-red);
`;

const PlayerCountTag = styled.div<{ isMeet: boolean }>`
    ${tagStyles}
    background-color: var(--purple-blue);
    ${(props) =>
        props.isMeet
            ? `
            background-color: #810A9A;
        `
            : `
       
        `}
`;

const FollowTagContainer = styled.div<{ hovered: boolean }>`
    position: absolute;
    top: 0;
    transition: var(--main-transition);
    z-index: +2;
    right: ${(props) => (props.hovered ? '35px' : '0px')};
}`;

const Title = styled.h2<{ large: boolean; hovered: boolean }>`
    margin-top: 10px;
    margin-left: 5px;
    transition: var(--main-transition);

    ${(props) => (props.large ? `font-size: 30px;` : `font-size: 20px;`)}

    ${(props) => (props.hovered ? `color: var(--tennis-green);` : ``)}
`;

const HostName = styled.h3`
    margin-left: 5px;
    font-size: 14px;
    font-weight: normal;
`;

const AdminContainer = styled.div<{ large: boolean; hovered: boolean; isMeet: boolean }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    bottom: 0;
    right: 0;
    padding-left: 0px;
    padding-bottom: 3px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--purple-blue);
    transition: var(--main-transition);
    cursor: pointer;

    ${(props) =>
        props.isMeet
            ? `
            background-color: #810A9A;
        `
            : `
       
        `}

    ${(props) =>
        props.large
            ? `
            width: 68px;
            height: 51px;
        `
            : `
            width: 40px;
            height: 30px;
        `}
    ${(props) =>
        props.hovered
            ? `
            height: 100%;
        `
            : `
            border-top-left-radius: 10px;
            border-top-right-radius: 0px;
        `}
`;

const HostIcon = styled.img<{ large: boolean }>`
    ${(props) =>
        props.large
            ? `
            width: 24px;
            height: 24px;
        `
            : `
            width: 14px;
            height: 14px;
        `}
`;

const HiddenIcon = styled.img<{ large: boolean; hovered: boolean }>`
    opacity: 0;
    ${(props) =>
        props.large
            ? `
            width: 44px;
            height: 44px;
        `
            : `
            width: 26px;
            height: 26px;
        `}
    ${(props) =>
        props.hovered
            ? `
            opacity: 1;
        `
            : `
            height: 0px;
        `}

    border-radius: 5px;
    &:hover {
        border: 2px solid var(--tennis-green);
    }
    transition: var(--main-transition);
`;

const InviteLinkContainer = styled.div<{ hovered: boolean }>`
    ${(props) => (props.hovered ? `` : `height: 0px;`)}
    position: relative;
`;

const StyledSettingsAnchor = styled.a<{ hovered: boolean }>`
    ${(props) => (props.hovered ? `` : `height: 0px;`)}
`;

const LinkCopiedTooltip = styled.div`
    position: absolute;
    top: 20%;
    right: 100%;
    width: fit-content;
    height: 20px;
    background-color: var(--tennis-green);
    border-radius: 5px;
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.35);
    padding: 0 4px 2px 4px;
    color: var(--midnight);
`;

const Hashtags = styled.h4``;
