import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { remoteLogger } from './services/logging/remoteLogger';
import { env } from './utils/envLoader';

const gtmLoaderScript = document.createElement('script');
gtmLoaderScript.src = `https://www.googletagmanager.com/gtag/js?id=${env('ANALYTICS_ID')}`;
gtmLoaderScript.async = true;
document.head.append(gtmLoaderScript);

const gtmMainScript = document.createElement('script');
gtmMainScript.text = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${env('ANALYTICS_ID')}');
`;
document.head.append(gtmMainScript);

const stripeScript = document.createElement('script');
stripeScript.async = true;
stripeScript.src = 'https://js.stripe.com/v3/pricing-table.js';
document.head.append(stripeScript);

remoteLogger.onReady(() => {
    ReactDOM.render(<App />, document.getElementById('root'));
});
