import * as React from 'react';
import styled from 'styled-components/macro';;
import ReactDOM from 'react-dom';
import SpotifyLogin from 'react-spotify-login';
import EmbedTitle from '../components/embeds/EmbedTitle'
import { env } from "../utils/envLoader";

export const onSpotifySuccess = (cb: (response: any) => any) => {
    // @ts-ignore
    window.parent.onSpotifySuccess = cb;
};

export const onSpotifyFailure = (cb: (response: any) => any) => {
    // @ts-ignore
    window.parent.onSpotifyFailure = cb;
};

export const setSpotifyScope = (scope: string) => {
    // @ts-ignore
    window.parent.spotifyScope = scope;
};

export const connectSpotify = (onSubmitting: () => void, iFrameName = 'spotifyFrame') => {
    return () => {
        onSubmitting();
        // @ts-ignore
        window.frames[iFrameName].document.querySelectorAll('#spotifyWrapper button')[0].click();
    };
};


const SpotifyEmbed = () => {
    const onSuccess = (response: any) => {
        console.log(response);
        // @ts-ignore
        window.parent.onSpotifySuccess(response);
    };
    const onFailure = (response: any) => {
        console.error(response);
        // @ts-ignore
        window.parent.onSpotifyFailure(response);
    };

    return (
        <SpotifyEmbedWrapper id="spotifyWrapper">
            <EmbedTitle title="Spotify Connect"></EmbedTitle>
            <SpotifyLogin clientId={env('SPOTIFY_CLIENT_ID')}
                redirectUri={env('SPOTIFY_REDIRECT_URI')}
                // @ts-ignore
                scope={window.parent.spotifyScope}
                onSuccess={onSuccess}
                onFailure={onFailure}/>
        </SpotifyEmbedWrapper>
    );
};

const SpotifyEmbedWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direct: column;
    padding-top: 10px;
    padding-left: 150px;
    padding-right: 150px;
`;

export default SpotifyEmbed;
