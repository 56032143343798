import * as React from 'react';
import GoogleAuthButton from '../components/inputs/GoogleAuthButton';
import styled from 'styled-components/macro';
import SignUpByEmailForm from '../components/SignUpByEmailForm';
import CenteredSingleColumnLayout from '../components/layouts/CenteredSingleColumnLayout';
import { Link, Redirect, useLocation, useParams } from 'react-router-dom';
import { isEmpty } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { getAuthState, getCurrentUser, getCurrentUserType } from '../store/selectors';
import { useHistory } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';
import { doSendEmailVerification } from '../utils/sendEmailVerification';
import { postApi } from '../api/api';
import { clearCurrentUser, setCurrentUser } from '../store/actions';
import { SpaceUtil } from '../utils/SpaceUtil';
import { useState, useEffect } from 'react';
import DividingLine from '../components/DividingLine';
import { getRefCodeAndSetIfPresent, getRefToLocation, clearRefCodeAndLoc } from '../utils/ReferralCodeHelper';
import { isAnon } from '../permissions/users';
import * as fb from 'firebase/app';
import 'firebase/auth';

const SignUp = () => {
    const location = useLocation() as any;

    const auth = useSelector(getAuthState);

    const user = useSelector(getCurrentUser);

    const currentUserType = useSelector(getCurrentUserType);

    const urlParams = new URLSearchParams(window.location.search);
    const returnToGame = urlParams.get('returnToGame');
    const successRoute = urlParams.get('successRoute');
    const returnToPricing = urlParams.get('returnToPricing');
    const googlePostAuthDestination = returnToGame ? '/return-to-game' : document.location.href;

    const referrer = location?.state?.from?.pathname;
    const redirectLink = referrer || '/';
    const history = useHistory();
    const firebase = useFirebase();
    const dispatch = useDispatch();

    const referralCode = getRefCodeAndSetIfPresent();
    const referralToLoc = getRefToLocation();

    const [isEmailPassSignUp, setIsEmailPassSignUp] = useState<boolean>(false);

    const queryString = window.location.search;
    //console.log('!!! qs', queryString);
    //console.log('!!! location', location);

    const handleSignOut = async () => {
        await firebase.logout().then(() => {
            dispatch(clearCurrentUser());
            history.push('/sign-up' + queryString);
        });
    };

    let hubSpotScript = document.getElementById('hs-script-loader') as HTMLScriptElement | null;
    if (!hubSpotScript) {
        hubSpotScript = document.createElement('script');
        hubSpotScript.type = 'text/javascript';
        hubSpotScript.id = 'hs-script-loader';
        hubSpotScript.async = true;
        hubSpotScript.defer = true;
        hubSpotScript.src = '//js.hs-scripts.com/8540380.js';
        document.body.append(hubSpotScript);
    }

    const onSubmit = async (
        userProfile: Record<string, any>,
        password: string,
        captchaToken: string,
        handleError?: (msg: string) => void
    ) => {
        setIsEmailPassSignUp(true);

        //This should not happen but just to be sure...
        if (password && !captchaToken) {
            history.push('/sign-up');
        }

        //console.log('userProfile.avatarUrl', userProfile.avatarUrl);

        const profileData = {
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            userType: userProfile.userType,
            avatarUrl: userProfile.avatarUrl,
            isInvited: isInvited(),
            captchaToken: captchaToken,
            badgeSubtitle: '',
            referralCode: referralCode,
            optInMarketing: userProfile.optInMarketing
        };

        //console.log('email sign-up workflow');

        let currentProfile = null;

        if (auth?.isAnonymous) {
            try {
                console.log('linking anonymous user with new credentials');
                const credential = fb.auth.EmailAuthProvider.credential(userProfile.email, password);
                await firebase.auth().currentUser?.linkWithCredential(credential);
            } catch (err) {
                console.error('failed to link anonymous user with new credentials', err);
                alert('Account upgrade failed. Try again with a different email or try logging instead.');
                //refresh page 
                window.location.reload();
                //handleSignOut();
                return;
            }

            try {
                const updateResponse = await postApi('/user/me/update', profileData);
                if (updateResponse.ok) {
                    currentProfile = await updateResponse.json();
                } else {
                    console.error('failed to update anonymous user with signup profile data', updateResponse);
                    return;
                }
            } catch (err) {
                console.error('failed to update anonymous user with signup profile data', err);
                return;
            }
        } else {
            try {
                console.log('Creating new user user credentials');
                await firebase.createUser({ email: userProfile.email, password });
            } catch (err) {
                console.error('failed to create new user credentials', err);
                if (handleError) handleError('Email address is already in use');
                handleSignOut();
                return;
            }
            try {
                const createResponse = await postApi('/user/create', profileData);
                if (createResponse.ok) {
                    currentProfile = await createResponse.json();
                } else {
                    //If we could not create the user in the DB -
                    //We have no choice but to sign out of firebase auth -
                    //Not doing so puts user is a strange state
                    console.error('failed to create new user', createResponse);
                    if (handleError) handleError('Sign-up failed due to server error...');
                    handleSignOut();
                    return;
                }
            } catch (err) {
                console.error('failed to create new user', err);
                handleSignOut();
                return;
            }
        }

        const currentUser = await firebase.auth().currentUser;
        const userId = currentUser?.uid ?? '';

        if (!userId) {
            //Something has gone wrong as we have no userId
            console.error('failed to succcesfully sign up');
            handleSignOut();
            return;
        }

        await acceptInvitation('/station/acceptInvite', 'inviteCode');
        await acceptInvitation('/station/acceptManagerInvite', 'inviteManagerCode');
        await acceptInvitation('/space/acceptHostCode', 'acceptHostCode');

        doSendEmailVerification(currentUser);

        dispatch(setCurrentUser(currentProfile));

        const queryString = window.location.search;

        if (returnToGame && auth.isAnonymous && !isAnon(currentProfile)) {
            history.push('/returnToGame');
            return;
        }

        if (referralToLoc) {
            clearRefCodeAndLoc();
            history.push(referralToLoc);
            return;
        }

        if (queryString && urlParams.get('spaceId')) {
            const spaceId = urlParams.get('spaceId');
            history.push(`/space/${spaceId}/lobby`);
        } else if(!returnToGame && !returnToPricing){
            const lastOwnedSpaceId = await SpaceUtil.getLatestOwnedSpaceId(userId);
            lastOwnedSpaceId
                .map((id: string) => {
                    history.push(`/meet/space/${id}`);
                    //history.push(`/space/${id}/lobby`);
                })
                .getOrElse(() => {
                    //This state should not happen -
                    //but we will to be safe we will -
                    //direct to landing page
                    history.push(`/meet`);
                });
        }
    };

    if (!isEmpty(auth) && !auth.isAnonymous && !isEmailPassSignUp) {
        history.push(redirectLink);
    }

    const isInvited = () => {
        const params = new URLSearchParams(location.search);
        return ['inviteCode', 'inviteManagerCode', 'acceptHostCode', 'returnToGame', 'spaceId'].reduce(
            (acc: boolean, key: string) => {
                if (acc) return true;
                return params.get(key) ? true : false;
            },
            false
        );
    };

    const acceptInvitation = async (endpoint: string, key: string) => {
        const params = new URLSearchParams(location.search);
        const code = params.get(key);

        if (code) {
            return postApi(endpoint, { [key]: code }).catch((e) => {
                console.error('failed to accept invite with error: ', e);
            });
        }
    };

    useEffect(() => {
        console.log('currentUserType change: ', currentUserType, 'returnToPricing: ', returnToPricing, 'returnToGame: ', returnToGame);
        if (currentUserType && currentUserType !== 'Anonymous') {
            if (returnToGame) {
                document.location.href = '/return-to-game';
            } else if (returnToPricing) {
                const successRouteNew =
                    successRoute + '?fromPage=signUp&fromUserType=' + user?.userType + '&userId=' + user.id;
                document.location.href = '/pricing?' + successRouteNew;
            }
        }
    }, [currentUserType]);

    const logInLink = auth?.isAnonymous
        ? returnToGame
            ? `/log-in?returnToGame=${returnToGame}`
            : '/log-in'
        : redirectLink;

    return (
        <>
            <CenteredSingleColumnLayout title="Welcome to NOWHERE!" subtitle="Sign-up to grab your free space.">
                <GoogleAuthButton type="signup" destination={googlePostAuthDestination}>
                    Sign up with Google
                </GoogleAuthButton>
                <DividingLine />
                <SignUpByEmailForm onSubmit={onSubmit} />
                <Link to={logInLink} style={{ display: 'inline-block', paddingBottom:'20px', margin: '10px 10px 10px 0' }}>
                    <h3>
                        Already have an account? <u>Log-in</u>
                    </h3>
                </Link>
            </CenteredSingleColumnLayout>
        </>
    );
};

export default SignUp;
