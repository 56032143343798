import * as React from 'react';
import Toggle from '../inputs/Toggle';
import Message from '../layouts/Message';
import { Modal } from '../layouts/Modal';
import { ApiSpace } from '../../api/ApiTypes';
import { postApi } from '../../api/api';

type AllSpaceToggleProps = {
    spaces: ApiSpace[];
    stationId?: string;
};

const AllSpaceToggle: React.FC<AllSpaceToggleProps> = ({ spaces, stationId }) => {
    const openSpaceCount = spaces.filter(({ isActive }) => isActive).length;
    const [openAllWarningModal, setOpenAllWarningModal] = React.useState(false);
    const [closeAllWarningModal, setCloseAllWarningModal] = React.useState(false);
    const handleClick = (value: boolean) => {
        value ? setOpenAllWarningModal(true) : setCloseAllWarningModal(true);
    };

    return (
        <>
            <Toggle value={openSpaceCount > 0} onChange={handleClick} />
            <OpenAllWarningModal
                isOpen={openAllWarningModal}
                handleClose={() => setOpenAllWarningModal(false)}
                stationId={stationId}
            />
            <CloseAllWarningModal
                isOpen={closeAllWarningModal}
                handleClose={() => setCloseAllWarningModal(false)}
                stationId={stationId}
            />
        </>
    );
};

type ModalProps = {
    isOpen: boolean;
    handleClose: () => void;
    stationId?: string;
};

const OpenAllWarningModal: React.FC<ModalProps> = ({ isOpen, handleClose, stationId }) => {
    const [updating, setUpdating] = React.useState(false);

    const closeAllSpaces = () => {
        setUpdating(true);
        postApi(`/station/${stationId}/openAllSpaces`)
            .then(async (r) => {
                const json = await r.json();

                if (!r.ok) {
                    setUpdating(false);
                    console.log(json.error || 'could not open spaces');
                    handleClose();
                } else {
                    window.location.reload();
                }
            })
            .catch((err) => console.log('error: ', err));
    };

    return (
        <Modal isOpen={isOpen} handleClose={handleClose}>
            <Message
                title="Are you sure you want to open all spaces in this station?"
                onCancel={handleClose}
                onConfirm={closeAllSpaces}
                confirmButtonText={updating ? 'Opening spaces...' : 'Open All Spaces'}
                confirmButtonDisabled={updating}
            />
        </Modal>
    );
};

const CloseAllWarningModal: React.FC<ModalProps> = ({ isOpen, handleClose, stationId }) => {
    const [updating, setUpdating] = React.useState(false);

    const closeAllSpaces = (kickUsers: boolean) => {
        setUpdating(true);
        postApi(`/station/${stationId}/closeAllSpaces`, { kickUsers })
            .then(async (r) => {
                const json = await r.json();

                if (!r.ok) {
                    setUpdating(false);
                    console.log(json.error || 'could not close spaces');
                    handleClose();
                } else {
                    window.location.reload();
                }
            })
            .catch((err) => console.log('error: ', err));
    };

    return (
        <Modal isOpen={isOpen} handleClose={handleClose}>
            <Message
                title="Do you want to close all spaces in this station?"
                body="Any attendees currently in one of the spaces will be booted."
                onCancel={handleClose}
                onConfirm={() => closeAllSpaces(true)}
                onSecondConfirm={() => closeAllSpaces(false)}
                confirmButtonText="Close Spaces and Kick Guests Out"
                confirmButtonDisabled={updating}
                secondConfirmButtonText="Close Spaces but Guests Can Stay"
                secondConfirmButtonDisabled={updating}
            />
        </Modal>
    );
};
export default AllSpaceToggle;
