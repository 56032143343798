import { ApiUser } from '../api/ApiTypes';
import { getApi, postApi } from '../api/api';

export const logInAnonymously = async (firebase: any): Promise<{ success: boolean; user?: Partial<ApiUser> }> => {
    console.log('log in anonymously...');

    const anonAuth = await firebase.auth().signInAnonymously();
    const userResponse = await getApi('/user/me');
    const failResponse = { success: false };

    try {
        if (!userResponse.ok) {
            console.log('failed to create anonymous user profile', userResponse);
            return failResponse;
        }
        const user = <Partial<ApiUser>>await userResponse.json();
        user.userType = 'Anonymous';
        user.avatarUrl = 'https://storage.googleapis.com/nwr-prod-env.appspot.com/images/moose%20in%20the%20mist.jpg';
        user.firstName = 'Mystery';
        user.lastName = 'Human';
        user.badgeSubtitle = 'Ask me to sign-up to connect';
        delete user.id;

        const updateResponse = await postApi('/user/me/update', user);
        if (!updateResponse.ok) {
            console.log('failed to update anonymous user profile', updateResponse);
            return failResponse;
        }

        const updatedUser = await updateResponse.json();
        if (!updatedUser.permissions) {
            await getApi('/user/me').then(async (response: any) => {
                const user = await response.json();
                console.log('updating user permissions: anon: response: ', user);
                updatedUser.permissions = user.permissions;
            });
        }

        return { success: true, user: updatedUser };
    } catch (err) {
        console.error('failed to authorize and update user profile', err);
        return failResponse;
    }
};
