import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { postApi } from '../api/api';
import SpaceForm from '../components/stationAdmin/SpaceForm';
import CenteredSingleColumnLayout from '../components/layouts/CenteredSingleColumnLayout';
import { ApiSpaceEditableMetadata } from '../api/ApiTypes';

const CreateSpace: React.FC = () => {
    const history = useHistory();
    const { stationId } = useParams() as any;
    const [error, setError] = React.useState('');

    const handleCreateSpace = (spaceData: ApiSpaceEditableMetadata) =>
        new Promise<void>((resolve) => {
            postApi(`/station/${stationId}/createSpace`, spaceData)
                .then(async (r) => {
                    const json = await r.json();
                    if (!r.ok) {
                        setError(json.message || 'Something went wrong');
                    }

                    if (r.ok && json.id) {
                        history.push(`/space/${json.id}`);
                    }
                    resolve();
                })
                .catch((err) => {
                    resolve();
                    console.log('error: ', err);
                });
        });

    return (
        <>
            <CenteredSingleColumnLayout title="Create New Space" subtitle="">
                <SpaceForm
                    title="Create a Space"
                    onSubmit={(spaceData: ApiSpaceEditableMetadata) => handleCreateSpace(spaceData)}
                    stationId={stationId}
                />
                {error && <h2>{error}</h2>}
            </CenteredSingleColumnLayout>
        </>
    );
};

export default CreateSpace;
