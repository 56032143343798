import * as React from 'react';
import styled from 'styled-components/macro';
import { isEmpty, useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { postApi } from '../../api/api';
import { getAuthState } from '../../store/selectors';
import Message from '../layouts/Message';
import LoadingScreen from '../loading/LoadingScreen';
import FullScreenContainer from '../layouts/FullScreenContainer';
import { GreyishBodyText } from '../Typography';
import SignUpByEmailForm from '../SignUpByEmailForm';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../../store/actions';

const SignInFromEmail = ({ continueUrl }: { continueUrl: string }) => {
    const firebase = useFirebase();
    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState('');

    const auth = useSelector(getAuthState);

    const history = useHistory();

    React.useEffect(() => {
        if (!isEmpty(auth)) {
            history.push(continueUrl);
        }

        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            setLoading(false);
        } else {
            setError('This does not appear to be a valid sign in link');
        }
        // eslint-disable-next-line
    }, [firebase, continueUrl]);

    const loginUser = async (
        userProfile: Record<string, any>,
        password: string,
        captchaToken: string,
        setSubmitError: (error: string) => void
    ) => {
        firebase
            .auth()
            .signInWithEmailLink(userProfile.email, window.location.href)
            .then(function () {
                firebase
                    .auth()
                    .currentUser!.updatePassword(password)
                    .then(() => {
                        // Now that the user exists in Firebase Auth, we also need to create the
                        // fireStore version
                        postApi('/user/create', {
                            firstName: userProfile.firstName,
                            lastName: userProfile.lastName,
                            avatarUrl: userProfile.avatarUrl,
                            userType: 'Guest'
                        })
                            .then(async (response) => {
                                const json = await response.json();
                                dispatch(setCurrentUser(json));
                                history.push(continueUrl);
                            })
                            .catch((e) => {
                                setSubmitError(e.message);
                                console.log(e);
                            });
                    })
                    .catch((e) => {
                        setSubmitError(e.message);
                        console.log(e);
                    });
            })
            .catch((e) => {
                setSubmitError(e.message);
                console.log(e);
            });
    };

    if (error) {
        return (
            <FullScreenContainer>
                <Message title="Cannot Sign In" body={error} />
            </FullScreenContainer>
        );
    }

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <FullScreenContainer>
            <Title>Welcome to Nowhere!</Title>
            <Text>Please confirm your email and create your profile to continue.</Text>
            <SignUpByEmailForm onSubmit={loginUser} />
        </FullScreenContainer>
    );
};

const Title = styled.h3`
    margin-bottom: 5px;
`;

const Text = styled(GreyishBodyText)`
    margin-bottom: 30px;
`;

export default SignInFromEmail;
