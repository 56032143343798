import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import CycleIcon from '../../images/i-refresh.svg';
import { StreamContext } from './StreamContext';
import StreamSession from './StreamSession';
import useInterval from '../../hooks/useInterval';

const StreamStatus: React.FC = () => {

    const streamContext = useContext(StreamContext);

    // for debugging
    const logDate = (timestamp: number) => {
        const t = new Date(timestamp);
        console.log(t.getUTCFullYear() +"/"+ (t.getUTCMonth()+1) +"/"+ t.getUTCDate() + " " + t.getUTCHours() + ":" + t.getUTCMinutes() + ":" + t.getUTCSeconds())
    };

    const pad = (timepart: number) => {
        let padded = timepart.toString();
        if(padded.length == 1){
            padded = '0'+padded;
        }
        return padded;
    };

    const timerValue = (startTimestamp: number) => {
        const currentTimestamp = Date.now();
        const diff = currentTimestamp - startTimestamp;
        const diffDate = new Date(diff)
        const output = `${pad(diffDate.getUTCHours())}:${pad(diffDate.getUTCMinutes())}:${pad(diffDate.getUTCSeconds())}`;
        return output;
    };

    let streamTimer = "00:00:00";
    let streamSecondsDefault = 0;
    if(streamContext.stream.details.createdAt.seconds != null){
        const sessionStartInMs = streamContext.stream.details.createdAt.seconds*1000;
        const currentTimestampInMs = Date.now();
        streamSecondsDefault = currentTimestampInMs - sessionStartInMs;
        streamTimer = timerValue(sessionStartInMs!);
    };

    const [streamSeconds, setStreamSeconds] = useState(streamSecondsDefault);

    const updateStreamSeconds = () => {
        setStreamSeconds(streamSeconds + 1);
    };

    useInterval(() => {
        updateStreamSeconds();
    }, 1000);

    const status = streamContext.stream.details.status.toUpperCase()
    return (
        <StreamStatusContainer>
            <RefreshButton onClick={streamContext.forceRefreshStream}>
                <img src={CycleIcon} /> &nbsp; REFRESH
            </RefreshButton>
            <StatusContainer>
                <LiveStatus>
                    <LiveStatusContainer>
                        <LiveStatusLabel className={status == 'ACTIVE' ? 'active' : (status == 'DISABLED' ? 'disabled' : '')}>{status}</LiveStatusLabel>
                    </LiveStatusContainer>
                    <Label>SOURCE</Label>
                </LiveStatus>
                <StreamSession timer={streamTimer} />
            </StatusContainer>
        </StreamStatusContainer>
    );
};

const StreamStatusContainer = styled.div`
    position: relative;
    height: 38px;
`;

const RefreshButton = styled.div`
    position: absolute;
    z-index: 1;
    top: -23px;
    font-size: 9px;
    font-weight: bold;
    cursor: pointer;
`;

const RefreshIcon = styled.img`
    height: 10px;
    margin: 0px 0;
`;

const StatusContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 34px;
`;

const LiveStatus = styled.div`
    height: 38px;
    margin-right:20px;
`;

const LiveStatusContainer = styled.div`
    height: 18px;
    width: 74px;
    margin: 3px 10px 9px 0;
`;

const Label = styled.div`
    height: 14px;
    margin: 6px 0 0;
    font-family: all-round-gothic;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #ffffff;
`;

const LiveStatusLabel = styled.p`
    padding: 2px 4px 2px 5px;
    border-radius: 5px;
    border: solid 1px var(--off-white);
    background-color: var(--battleship-grey);
    font-family: all-round-gothic, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: var(--off-white);
    &.active {
        background-color: var(--tennis-green);
        color: var(--midnight);
    }
    &.disabled {
        background-color: var(--burnt-red);
    }
`;


export default StreamStatus;
