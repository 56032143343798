export class Download {
    public filename = "";
    public constructor(public content:string) {
        //Nothing to do
    }

    public static body(textBody: string) {
        return new Download(textBody);
    }

    public as(fname: string) {
        this.filename = fname;
        this._download();
    }

    private _download() {
        //  Create hidden downloadable links
        const eleLink = document.createElement('a');
        eleLink.download = this.filename;
        eleLink.style.display = 'none';
        //  Character content is converted into blob address
        const blob = new Blob([this.content]);
        eleLink.href = URL.createObjectURL(blob);
        //  Trigger click
        document.body.appendChild(eleLink);
        eleLink.click();
        //  Then remove
        document.body.removeChild(eleLink);
    }
}
