import * as React from 'react';
import { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { StyledLargeButton as Button } from '../inputs/Button';
import IconClose from '../../images/i-close.svg';

const TRANSITION_MS = 300;

const Shadow = styled.div<{ open: boolean }>`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--midnight-translucent);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transition: opacity ease ${TRANSITION_MS}ms;
    opacity: ${({ open }) => (open ? 1 : 0)};
`;

const Wrapper = styled.div<{ open: boolean }>`
    max-width: 100%;
    max-height: 100%;
    border-radius: 1.5rem;
    background: var(--navy-blue);

    transition: opacity ease ${TRANSITION_MS}ms, transform ease ${TRANSITION_MS}ms;
    transform: translateY(${({ open }) => (open ? 0 : 100)}px);

    color: var(--off-white);
    position: relative;
    pointer-events: all;
`;

const Close = styled.button`
    border: none;
    background: url(${IconClose}) center no-repeat transparent;
    cursor: pointer;
    position: fixed;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    z-index: 11;
`;

export const ModalWrapper = styled.div`
    padding: 1.5rem;
    margin: 20px 40px 20px 40px;
    @media (max-width: 950px) {
        margin: 0px;
    }
`;

export const ModalTitle = styled.div`
    font-size: 60px;
    line-height: 80px;
    font-weight: bold;
    @media (max-width: 950px) {
        font-size: 40px;
    }
`;

export const ModalDescription = styled.div`
    margin-top: 15px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    @media (max-width: 950px) {
        font-size: 14px;
    }
`;
export const ModalWrapperYoutube = styled.div`
    padding: 1.5rem;
    margin: 20px 40px 20px 40px;
    max-width: 53vw;
    @media (max-width: 950px) {
        margin: 0px;
        max-width: 100vw;
    }
`;

export const ModalTitleYoutube = styled.div`
    font-size: 60px;
    font-weight: bold;
    max-width: 80vw;
    @media (max-width: 950px) {
        font-size: 40px;
        max-width: 100vw;
    }
`;

export const ModalDescriptionYoutube = styled.div`
    margin-top: 15px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    max-height: 10vh;
    overflow: auto;

    @media (max-width: 950px) {
        font-size: 14px;
        max-width: 100vw;
    }
`;

export const ModalInput = styled.div`
    margin-top: 15px;
`;

export const ModalTextArea = styled.textarea`
    color: var(--off-white);
    border: 1px solid var(--off-white);
    background-color: var(--midnight);
    border-radius: 0px 10px 10px 10px;
    font-size: 14px;
    padding: 7px 14px 3px 10px;
    margin-top: 10px;
    width: 100%;
    height: 80px;
`;

export const ModalAction = styled.div`
    margin-top: 40px;
`;

export const ActionButton = styled(Button)`
    border: none;
    font-size: 20px;
    letter-spacing: 1.14px;
    margin-right: 15px;
 
    &:hover {
        border: none;
    }
`;

export const ActionButtonPrimary = styled(ActionButton)`
    background-color: var(--tennis-green);
    color: var(--navy-blue);
`;

export const ActionButtonSevere = styled(ActionButton)`
    background-color: var(--burnt-red);
    color: var(--off-white);
`;

export const ActionButtonSecondary = styled(ActionButton)`
    background-color: var(--navy-blue);
    border: 1px solid var(--off-white);
`;

export const Cancel = styled.a`
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
`;

export const Modal = (props: PropsWithChildren<{ visible: boolean; onHidden: () => void }>) => {
    const [open, setOpen] = useState(false);

    const render = props.visible || open;

    useEffect(() => {
        if (props.visible) {
            setOpen(true);
        }

        // Handle escape key event by closing the dialog
        const escapeKeyHandler = (event: any) => {
            if (props.visible) {
                if (event.key === 'Escape') {
                    onClose();
                }
            }
        };

        document.addEventListener('keydown', escapeKeyHandler);

        return () => {
            document.removeEventListener('keydown', escapeKeyHandler);
        };
    }, [props.visible]);

    const onClose = async () => {
        setOpen(false);

        // await Tools.DelayAsync(1000);

        props.onHidden();
    };

    if (render) {
        return (
            <Shadow open={open} className="shadow">
                <Wrapper open={open}>
                    <Close onClick={onClose} />
                    {props.children}
                </Wrapper>
            </Shadow>
        );
    } else {
        return null;
    }
};
