export default class FrameUtil {
  public static isFramed():boolean {
    try {
      const tl = window.top.location.href; //this error if we are in a frame
      return window.top.location.href !== document.location.href;
    } catch(e) {
      return true;
    }
  }

  public static parentHost(): string {
    try {
      return new URL(document.referrer).host;
    } catch(e) {
      return document.referrer? document.referrer: 'localhost';
    }
  }

  public static allowPermissions(exclude: string[]): string[] {
    return [
      'camera',
      'microphone',
      'geolocation',
      'autoplay',
      'encrypted-media',
      'fullscreen',
      'payment',
      'usb',
      //'speaker-selection',
      'gyroscope',
      'picture-in-picture',
      //'vr',
      'display-capture',
      'clipboard-write',
      'web-share',
    ].filter((ap) => exclude.indexOf(ap) < 0)
  }
}



