import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components/macro';
import { SmallGreenButton } from '../inputs/Button';
import StreamStatus from './StreamStatus';
import { ApiStationDetails } from '../../api/ApiTypes';
import { StreamContext } from './StreamContext';

const StreamHeader = () => {

    const streamContext = useContext(StreamContext);

    return (
        <Container>
            <StreamStatus></StreamStatus>
            { !streamContext.stream.isActive ?
                <StartButton
                    disabled={!streamContext.stream.id || ['offline','idle'].includes(streamContext.stream.details.status)}
                    onClick={streamContext.updateActive}
                >
                    Start Stream
                </StartButton>
            :
                <StopButton
                    onClick={streamContext.updateActive}
                >
                    Stop Stream
                </StopButton>
            }
        </Container>
    );
};

const startStopButtonStyles = `
    width: 151px;
    height: 36px;
    padding-top: 7px;
    margin-top: 5px;
    font-size: 16px;
    letter-spacing: 0.8px;
`

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 106px;
    padding-left: 35px;
    padding-top: 30px;
    padding-right: 30px;
    background-color: #151347;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
`;

const StartButton = styled(SmallGreenButton)`
    ${startStopButtonStyles}
    background-color: var(--burnt-red);
    color: var(--off-white);
    &:disabled{
        background-color: var(--battleship-grey);
        color: var(--dark);
    }
`;

const StopButton = styled(SmallGreenButton)`
    ${startStopButtonStyles}
    background-color: var(--tennis-green);
    color: var(--dark-purple);
    &:hover {
        background-color: var(--burnt-red);
    }
`;

export default StreamHeader;
