import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { GreenDivider } from '../Divider';
import { ThinTitle } from '../Typography';
import { Helmet } from 'react-helmet';
import { ApiStation } from '../../api/ApiTypes';
import { StationUtil } from '../../utils/StationUtil';
import useShowPageHelp from '../../hooks/useShowPageHelp';
import HelpText from '../HelpText';
import FollowTag from '../following/FollowTagNoContext';

type StationPageHeaderProps = { station: ApiStation, isStationAdmin?: boolean };

const StationPageHeader: React.FC<StationPageHeaderProps> = ({ station, isStationAdmin }) => {
    const [isHovered, isHoveredSetter] = useState(false);
    const showHelp = useShowPageHelp('stationPage');
    const helpText = 'This is your home venue, called a Station.';

    return (
        <Container
            onMouseEnter={() => isHoveredSetter(true)}
            onMouseLeave={() => isHoveredSetter(false)}
        >
            <Helmet>
                <title>{`NOWHERE | ${station.title}`}</title>
            </Helmet>
            <TopBlock>
                <Hero src={StationUtil.getImage(station)} alt="" />
                <TitleBlock>
                    <FollowTag stationId={station.id!} stationRole={station.role ?? 'MEMBER'} isHovered={isHovered} />
                    <StationTitle>
                        {showHelp && isStationAdmin && (
                            <HelpText label={helpText} noWrap={true}/>
                        )}
                        {station.title}
                    </StationTitle>
                    <InfiniteDivider />
                    <SubtitleContainer>
                        <span style={{}}>Host:</span> <span style={{}} className="noTranslate">{station.subtitle}</span>
                    </SubtitleContainer>
                    <DescriptionText className="noTranslate">
                        {station.description}
                    </DescriptionText>
                    <DescriptionGradientOverlay />
                </TitleBlock>
            </TopBlock>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    height: fit-content;
    max-width: 1400px;
    margin: 0 auto;
    margin-bottom: 60px;
    overflow-x: hidden;

    @media (min-width: 980px) {
        height: 70vh;
        max-height: 370px;
        padding-left: 50px;
        padding-right: 50px;
    }
`;

const TopBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: 1fr 1fr;
    max-width: 1400px;
    margin: 0 auto;
    height: 100%;

    @media (min-width: 980px) {
        flex-direction: row;
    }
`;

const Hero = styled.img`
    display: block;
    position: relative;
    width: 100%;
    max-width: 706px;
    max-height: 320px;
    object-fit: cover;
    border-radius: 0 0 30px 0;

    @media (min-width: 980px) {
        width: calc(50% + 25px);
        max-width: none;
        max-height: none;
        left: -20px;
        mask-image: linear-gradient(to right, rgba(1, 0, 39, 0) 0%, rgba(1,0,39,1) 20%);
    }
`;

const TitleBlock = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;

    @media (min-width: 980px) {
        width: calc(50% - 15px);
        padding-left: 10px;
        padding-top: 40px;
        padding-right: none;
    }
`;

const StationTitle = styled(ThinTitle)`
    min-width: 400px;
    margin-top: 5px;
    font-size: clamp(25px, 45px, 65px);
    overflow-wrap: break-word;
`;

const SubtitleContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: all-round-gothic, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.17px;
`;

const InfiniteDivider = styled(GreenDivider)`
    width: 300%;
`;

const DescriptionText = styled.div`
    padding-bottom: 0px;
    font-family: all-round-gothic, sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.17px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        color: white;
    }

    @media (min-width: 980px) {
        padding-bottom: 100px;
    }
`;

const DescriptionGradientOverlay = styled.div`
    display: none;
    position: absolute;
    top: -40px;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 70%, #010027);
    z-index: +1;
    pointer-events: none;

    @media (min-width: 980px) {
        display: block;
    }
`;

export default StationPageHeader;
