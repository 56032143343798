import * as React from 'react';
import { getApi, putApi } from '../api/api';
import { ApiKey } from '../api/ApiTypes';
import { useEffect, useState } from 'react';
import LoadingScreen from '../components/loading/LoadingScreen';
import CenteredSingleColumnLayout from '../components/layouts/CenteredSingleColumnLayout';
import styled from 'styled-components/macro';
import { TinyButton } from '../components/inputs/Button';


const DeveloperApiKeyManager: React.FC = () => {
  const [apiKey, setApiKey] = useState({} as ApiKey);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getApi('/developer/key').then(async (r) => {
      const json = await r.json();
      if (!r.ok) {
          console.log(json.message || 'Could not get api key');
      }

      if (json) {
          setApiKey(json);
          setLoading(false);
      } else {
          console.log('Api Key data missting');
      }
    });
  }, []);

  const regenKey = () => {
    putApi(`/developer/key/${apiKey.id}`).then(async (r) => {
      const json = await r.json();
      if (!r.ok) {
          console.log(json.message || 'Could not regen api key');
      }

      if (json) {
          setApiKey(json);
          setLoading(false);
      } else {
          console.log('Api Key data missing');
      }
    });
  };

  if (loading) return <LoadingScreen />;
  
  return (
    <Container>
        <h3>Developer</h3>
        <h4>Api Key Management</h4>
        <div>ApiKey: {apiKey.key}</div>
        <ButtonContainer>
          <TinyButton onClick={regenKey} type="button">
              Regenerate API Key
          </TinyButton>
        </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  padding: 60px;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin-top: 30px;
    > * {
        margin-right: 60px;
    }
`;

export default DeveloperApiKeyManager;
