import * as React from 'react';
import GoogleAuthButton from '../../components/inputs/GoogleAuthButton';
import styled from 'styled-components/macro';
import SignUpByEmailFormDevBackdoor from './SignUpByEmailFormDevBackdoor';
import CenteredSingleColumnLayout from '../../components/layouts/CenteredSingleColumnLayout';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { isEmpty } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { getAuthState, getCurrentUserType, getCurrentUser } from '../../store/selectors';
import { useHistory } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';
import { doSendEmailVerification } from '../../utils/sendEmailVerification';
import { postApi } from '../../api/api';
import { clearCurrentUser, setCurrentUser } from '../../store/actions';
import { SpaceUtil } from '../../utils/SpaceUtil';
import { useEffect } from 'react';
import { isAnon } from '../../permissions/users';
import DividingLine from '../../components/DividingLine';
import * as fb from 'firebase/app';
import 'firebase/auth';

const SignUpDevBackdoor = () => {
    const location = useLocation() as any;

    const auth = useSelector(getAuthState);

    const currentUserType = useSelector(getCurrentUserType);
    const user = useSelector(getCurrentUser);

    const urlParams = new URLSearchParams(window.location.search);
    const returnToGame = urlParams.get('returnToGame');

    const referrer = location?.state?.from?.pathname;
    const redirectLink = referrer || '/';
    const history = useHistory();
    const firebase = useFirebase();
    const dispatch = useDispatch();


    const handleSignOut = async () => {
        await firebase.logout().then(() => {
            dispatch(clearCurrentUser());
            history.push('/sign-up');
        });
    };

    let hubSpotScript = document.getElementById('hs-script-loader') as HTMLScriptElement | null;
    if (!hubSpotScript) {
        hubSpotScript = document.createElement('script');
        hubSpotScript.type = 'text/javascript';
        hubSpotScript.id = 'hs-script-loader';
        hubSpotScript.async = true;
        hubSpotScript.defer = true;
        hubSpotScript.src = '//js.hs-scripts.com/8540380.js';
        document.body.append(hubSpotScript);
    }

    const onSubmit = async (userProfile: Record<string, any>, password: string) => {

        const profileData = {
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            userType: userProfile.userType,
            avatarUrl: userProfile.avatarUrl,
            isInvited: isInvited(),
            badgeSubtitle: ""
        };

        console.log('email sign-up workflow');

        let currentProfile = null;

        if(auth?.isAnonymous){

            try {
                console.log('linking anonymous user with new credentials');
                const credential = fb.auth.EmailAuthProvider.credential(userProfile.email, password);
                await firebase.auth().currentUser?.linkWithCredential(credential);
            } catch (err){
                console.error('failed to link anonymous user with new credentials', err);
                handleSignOut();
                return;
            }

            try {
                const updateResponse = await postApi('/user/me/update', profileData);
                if (updateResponse.ok) {
                    currentProfile = await updateResponse.json();
                } else {
                    console.error('failed to update anonymous user with signup profile data', updateResponse);
                    return;
                }
            } catch (err){
                console.error('failed to update anonymous user with signup profile data', err);
                return;
            }

        } else {

            try {
                console.log('Creating new user user credentials');
                await firebase.createUser({ email: userProfile.email, password });
            } catch (err){
                console.error('failed to create new user credentials', err);
                handleSignOut();
                return;
            }
            try {
                const createResponse = await postApi('/user/create', profileData);
                if(createResponse.ok) {
                    currentProfile = await createResponse.json();
                } else {
                    //If we could not create the user in the DB -
                    //We have no choice but to sign out of firebase auth -
                    //Not doing so puts user is a strange state
                    console.error('failed to create new user', createResponse);
                    handleSignOut();
                    return;
                }

            } catch (err){
                console.error('failed to create new user', err);
                handleSignOut();
                return;
            }
        }

        const currentUser = await firebase.auth().currentUser;
        const userId = currentUser?.uid ?? "";

        if(!userId) {
            //Something has gone wrong as we have no userId
            console.error('failed to succcesfully sign up');
            handleSignOut();
            return;
        }

        await acceptInvitation('/station/acceptInvite', 'inviteCode');
        await acceptInvitation('/station/acceptManagerInvite', 'inviteManagerCode');
        await acceptInvitation('/space/acceptHostCode', 'acceptHostCode');

        doSendEmailVerification(currentUser);

        dispatch(setCurrentUser(currentProfile));

        const queryString = window.location.search;

        if(returnToGame && auth.isAnonymous && !isAnon(currentProfile)){
            history.push('/returnToGame');
            return;
        }

        if(queryString){
            const lastOwnedSpaceId = await SpaceUtil.getLatestOwnedSpaceId(userId);
            lastOwnedSpaceId.map((id: string) => {
                history.push(`/space/${id}/lobby`);
            }).getOrElse(() => {
                // let the normal flow apply
            });
        } else {
            history.push(redirectLink);
        }

    };

    if (!isEmpty(auth) && !auth.isAnonymous) {
        history.push(redirectLink);
    }

    const isInvited = () => {
        const params = new URLSearchParams(location.search);
        return ['inviteCode', 'inviteManagerCode', 'acceptHostCode', 'returnToGame']
          .reduce((acc: boolean, key:string) => {
            if(acc) return true;
            return (params.get(key))? true: false;
          }, false);
    }

    const acceptInvitation = async (endpoint: string, key: string) => {
        const params = new URLSearchParams(location.search);
        const code = params.get(key);

        if (code) {
            return postApi(endpoint, { [key]: code }).catch((e) => {
                console.error('failed to accept invite with error: ', e);
            });
        }
    };

    useEffect(() => {
        if(currentUserType && !isAnon(user) && returnToGame){
            history.push('/return-to-game');
        }
    }, [currentUserType]);


    const logInLink = auth?.isAnonymous ? ( returnToGame ? `/log-in?returnToGame=${returnToGame}` : '/log-in' ) : redirectLink;

    return (
        <>
            <CenteredSingleColumnLayout
                title="Welcome to NOWHERE!"
                subtitle="Sign-up to grab your free space."
            >
                <GoogleAuthButton>Sign up with Google</GoogleAuthButton>
                <DividingLine />
                <SignUpByEmailFormDevBackdoor onSubmit={onSubmit} />
                <Link to={logInLink} style={{ display: 'inline-block', width: '85vw', margin: '10px 10px 10px 0' }}>
                    <h3>
                        Already have an account? <u>Log-in</u>
                    </h3>
                </Link>
            </CenteredSingleColumnLayout>
        </>
    );
};

export default SignUpDevBackdoor;
