import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { StreamContext } from './StreamContext';
import { ApiStationDetails } from '../../api/ApiTypes';
import videojs from '@mux/videojs-kit';
import '@mux/videojs-kit/dist/index.css';
import StreamViewerIntro from './StreamViewerIntro';
import StreamViewerIdle from './StreamViewerIdle';

// A Note On LOCAL:
// 1. a manual Firebase update is needed to emulate the web hook in higher envs
// Change Stream -> Details -> Status to 'Active' once stream is broadcasting to see preview
// 2.To trigger a status change in 3d a curl PUT is required that sets the status manually
// via the backend API

const StreamViewer = ({isHostView}: {isHostView?: boolean}) => {

    const streamContext = useContext(StreamContext);
    console.log('999 streamContext: ', streamContext);

    useEffect(() => {

        const feedStatusActive = streamContext.stream.details.status == 'active';

        if(feedStatusActive){

            const player = videojs('mux-nowhere-stream');

            player.src({ type: 'video/mux', src: streamContext.stream.details.playback.id });

            player.ready(function() {
                const playResult = player.play()
                    .then(function() {
                        console.log('Feed play successfull.')
                    }).catch(function(error: any) {
                        console.log('Feed play failed.')
                    });
            });

        } else {
            console.log('Stream status not active. Aborting player setup.')
        }

    }, [streamContext.stream, streamContext.stream.details.status]);

    // ** SEE NOTE AT TOP OF PAGE **
    // The idea here is to layer the preview panels over the video until all requirements are met
    return (
        <Container>
            <StreamViewerIntro isHostView={isHostView ?? false}/>
            {isHostView && (<StreamViewerIdle showIdleMessage={streamContext.stream.details.status !== 'active'} />)}
            {/* The wrapping div is needed due to https://github.com/videojs/video.js/issues/4935#issuecomment-363941121 */}
            <div>
                <video
                    id="mux-nowhere-stream"
                    className="video-js vjs-16-9"
                    controls
                    preload="auto"
                    width="100%"
                    autoPlay={true}
                    muted={true}
                    data-setup='{"timelineHoverPreviews": false}'
                />
            </div>
        </Container>
    );


};
const Container = styled.div`
    position: relative;
    width: 100%;
    height: 394px;
    background-color: var(--battleship-grey);
    color: var(--off-white);
`;

export default StreamViewer;
