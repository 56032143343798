import React, { useContext, useState } from 'react';
import { BaseButton, GreenHoverButtonStyles } from '../../inputs/Button';
import styled from 'styled-components/macro';
import useCopyClipboard from '../../../hooks/useCopyClipboard';
import Divider from '../../Divider';
import InfoIcon from '../../../images/atom-i-info.svg';
import ReactTooltip from 'react-tooltip';
import { useParams } from 'react-router-dom';
import { StreamContext, StreamSourceType } from '../StreamContext';
import CloseIcon from '../../../images/atom-i-close-dark.svg';


const StreamKey: React.FC = () => {

    const streamContext = useContext(StreamContext);

    const active = streamContext.source === "STREAM_KEY";
    const streamKey = streamContext.stream.details.streamKey;
    const streamURL = streamContext.stream.details.streamingEndPoints.http;

    const [KeyCopied, setKeyCopied] = useCopyClipboard(streamKey, { successDuration: 2000 });
    const [URLCopied, setURLCopied] = useCopyClipboard(streamURL, { successDuration: 2000 });

    const { stationId } = useParams() as any;

    const handleCreateClick = () => {
        streamContext.createStream("STREAM_KEY");
    };

    const handleDeleteClick = () => {
        streamContext.deleteStream(streamContext.stream.id);
    }

    const handleInfoClick = () => {
      window.open('https://urnowhere.notion.site/Broadcast-Screen-Share-Live-Stream-d531361e28b44459bde2af89f769560e?pvs=4#30daa8b7c0e64cf3b76a233e0891ec5','_blank');
    }

    if(!active) {
        return(
            <Container>
                <InactiveSource onClick={handleCreateClick}>
                    Stream Key
                    <ReactTooltip effect="solid" />
                    <InactiveInfo onClick={handleInfoClick} data-tip="Click to learn how to use a stream key in our help center" src={InfoIcon} />
                </InactiveSource>
            </Container>
        )
    } else {
        return (
            <Container>
                <Title>
                    Stream Key
                    <ReactTooltip effect="solid" />
                    <Info onClick={handleInfoClick} data-tip="Click to learn how to use a stream key in our help center" src={InfoIcon} />
                    <DeleteStream src={CloseIcon} onClick={handleDeleteClick} />
                </Title>
                <KeyForm>
                    <KeyRow>
                        <Field>{streamURL}</Field>
                        <CopyButton onClick={setURLCopied}>
                            {URLCopied ? 'Copied!' : 'Copy URL'}
                        </CopyButton>
                    </KeyRow>
                    <Divider style={{ margin: '5px 0' }} />
                    <KeyRow>
                        <Field>{streamKey}</Field>
                        <CopyButton onClick={setKeyCopied}>
                            {KeyCopied ? 'Copied!' : 'Copy Key'}
                        </CopyButton>
                    </KeyRow>
                    <Divider style={{ margin: '5px 0' }} />
                </KeyForm>
            </Container>
        );
    }
};

const Container = styled.div`
    width: 320px;
`;

const InactiveSource = styled.div`
    height: 36px;
    width: 100%;
    padding-top: 8px;
    margin-top: 10px;
    text-align: center;
    text-transform: none;
    background-color: var(--light-navy);
    color: var(--off-white);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(28, 2, 33, 0.75);
    cursor: pointer;
`;

const Title = styled.div`
    height: 36px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    text-transform: none;
    background-color: var(--tennis-green);
    color: var(--midnight);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(28, 2, 33, 0.75);
    cursor: pointer;
    z-index: +1;
`;

const InactiveInfo = styled.img`
    margin-left: 10px;
    margin-top: -4px;
`;

const Info = styled.img`
    margin-left: 10px;
`;

const DeleteStream = styled.img`
    position: absolute;
    right: 10px;
    top: 10px;
`;

const KeyForm = styled.div`
    background-color: var(--purple-blue);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 5px 15px;
`;

const KeyRow = styled.div`
    width: 100%;
    position: relative;
`;

const Field = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 70%;
    margin-top:5px;
    font-size: 14px;
`;

const CopyButton = styled(BaseButton)`
    position: absolute;
    right: 0px;
    top: 5px;
    padding: 5px 10px 4px 10px;
    height: 24px;
    font-size: 12px;
    padding-top: 5px;
    text-align: center;
    font-weight: bold;
    line-height: 10px;
    background-color: var(--off-white);
    color: var(--midnight);
    border-radius: 13px;
    box-shadow: 0 0 6px 0 rgba(28, 2, 33, 0.75);
    cursor: pointer;
    z-index: +1;
    ${GreenHoverButtonStyles}
`;


export default StreamKey;
