import { ApiSpace, ApiUser, ApiStation } from '../api/ApiTypes';
import { getApi } from '../api/api';
import { none, Option, some, tri } from 'm.m';

export class SpaceUtil {
    static getImage(space: ApiSpace) {
        return space?.imageUrl || space?.environment?.defaultImageUrl || '';
    }

    static async getLatestOwnedSpaceId(userId: string): Promise<Option<string>> {
        const lStationId: Option<string> = await getApi(`/user/me/stations`).then(async (response) => {
            const res = (await response.json()) as ApiStation[];
            const myLastestStation = res.filter((s) => s.creatorId === userId).pop();
            const latestStation = myLastestStation? myLastestStation!: res.slice(-1).pop();
            const latestStationId = latestStation!.id!;
            return some(latestStationId);
        }).catch(() => none());

        const lSpaceId: Option<string> = await lStationId.map(async (sId: string) => {
            return getApi(`/station/${sId}`).then(async (details) => {
                const rDetails = await details.json();
                const lSpace = rDetails!.spaces!.slice(-1).pop();
                return some(lSpace!.id!)
            }).catch(() => none());
        }).getOrElse(() => none());


        return lSpaceId;
    }

}
