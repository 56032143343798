import * as React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { getAuthState } from '../store/selectors';
import { ApiUser } from '../api/ApiTypes';
import LoadingScreen from '../components/loading/LoadingScreen';
import { useDispatch } from 'react-redux';
import { clearCurrentUser, setCurrentUser, setProfileLoaded } from '../store/actions';
import { getApi } from '../api/api';

interface Props {
    children: React.ComponentElement<any, any>;
}

const AuthIsLoaded: React.FC<Props> = ({ children }) => {
    const firebase = useFirebase();
    const auth = useSelector(getAuthState);
    const loaded = isLoaded(auth);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setProfileLoaded());

        if (loaded) {
            getApi('/user/me').then(async (response) => {
                if (response.ok) {
                    const json = (await response.json()) as ApiUser;
                    if(Object.keys(json).length <= 1){
                        await firebase.logout();
                        dispatch(clearCurrentUser());
                    } else {
                        dispatch(setCurrentUser(json));
                    }
                }
            });
        } else {
            dispatch(clearCurrentUser());
        }
    }, [loaded, dispatch]);

    return !loaded ? <LoadingScreen /> : children;
};

export default AuthIsLoaded;
