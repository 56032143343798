import * as React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import FormErrorMessage from '../components/inputs/FormErrorMessage';
import { getAuthError } from '../store/selectors';
import ValidatedFormInput from '../components/inputs/ValidatedFormInput';
import Message from '../components/layouts/Message';

const ResetPassword = () => {
    const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit', shouldFocusError: true });
    const [email, setEmail] = React.useState('');
    const [resetError, setResetError] = React.useState('');
    const [resetDone, setResetDone] = React.useState(false);

    const firebase = useFirebase();
    const authError = useSelector(getAuthError);

    const resetPassword = () => {
        console.log('resetting', email);
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                setResetDone(true);
            })
            .catch(function (error) {
                if (error.code === 'auth/user-not-found') {
                    setResetError('This email address is not currently registered. Perhaps you need to sign up?');
                } else {
                    setResetError('Oops! Something went wrong while resetting your password. Please try again later.');
                }
            });
    };

    if (resetDone) {
        return <Message title="Thank You">You should receive an email with a reset link in a few minutes</Message>;
    }

    return (
        <div className="body-2">
            <div>
                <div className="section-main">
                    <div className="main-content-block">
                        <div className="title-div">
                            <h1 className="h1">Reset Password</h1>
                        </div>
                        <div className="form-block-2 w-form">
                            <form
                                id="signup-Form"
                                name="wf-form-signup-Form"
                                data-name="signup Form"
                                onSubmit={handleSubmit(resetPassword)}
                                className="form-2"
                            >
                                <label htmlFor="loginEmail-2">Email</label>
                                <ValidatedFormInput
                                    type="email"
                                    errors={errors}
                                    validation={register({
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'Invalid email address'
                                        }
                                    })}
                                    className="w-input"
                                    maxLength={256}
                                    name="email"
                                    value={email}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                                />
                                <div className="div-block">
                                    <div id="loginAnimation" className="signup-animation" />
                                    <button id="loginButton" className="submit-button round w-button">
                                        Submit
                                    </button>
                                </div>
                                {authError ? <FormErrorMessage>{authError.message}</FormErrorMessage> : <></>}
                            </form>
                            <br />
                            <br />
                            {resetError && (
                                <div className="w-form-fail">
                                    <div>{resetError}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
