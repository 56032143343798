import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AllSpaces from '../components/spaces/AllSpaces';
import { LiveSpaces, FeaturedSpaces, FollowedStations } from '../components/spaces/LiveFeaturedSpaces';
import MyStations from '../components/spaces/MyStations';
import History from '../components/spaces/History';
import { EventsCTA } from '../components/layouts/CTAFooter';
import { canCreateStation as checkCanCreateStations } from '../permissions/users';
import { getCurrentUser, getCurrentUserType } from '../store/selectors';
import { LargeButton, StickyButton } from '../components/inputs/Button';
import { AddSpaces } from '../components/spaces/SpacesDebug';
import { FollowingDebug } from '../components/following/FollowingDebug';
import { useHash } from '../hooks/useHash';

import { ReactComponent as GlobalIcon } from '../images/i-global.svg';
import { ReactComponent as HistoryIcon } from '../images/i-history.svg';
import { ReactComponent as HostIcon } from '../images/i-host.svg';
import { envAsBool, envAsNumber } from '../utils/envLoader';

const defaultTab = '#discover';
const getTab = (hash: string) => {
    if (hash === '#discover') return '#discover';
    if (hash === '#my-stations') return '#my-stations';
    if (hash === '#history') return '#history';
    return defaultTab;
}

const Spaces = () => {
    const {hash, updateHash} = useHash();
    const tab = getTab(hash);
    const history = useHistory();
    const currentUser = useSelector(getCurrentUser);
    const canCreateStations = checkCanCreateStations(currentUser);
    const SHOW_DEBUG = envAsBool('SPACES_DEBUG');
    const ENABLE_LIVE_NOW = envAsBool('ENABLE_LIVE_NOW');

    return (
        <SpacesContainer>
            <Header>
                <Title>Spaces</Title>
                {/* <SearchContainer>Search</SearchContainer> */}
                {SHOW_DEBUG && (<FollowingDebug />)}
                {SHOW_DEBUG && (<AddSpaces />)}
            </Header>
            <Body>
                <TabSelectorContainer>
                    <TabSelector>
                        <Tab
                            selected={tab === '#discover'}
                            onClick={() => updateHash('#discover')}
                        >
                            <StyledGlobalIcon selected={tab === '#discover'}/>
                            <GlobalTabText>Discover</GlobalTabText>
                        </Tab>
                        <Tab
                            selected={tab === '#my-stations'}
                            onClick={() => updateHash('#my-stations')}
                        >
                            <StyledHostIcon selected={tab === '#my-stations'}/>
                            <HostTabText>My Stations</HostTabText>
                        </Tab>
                        <Tab
                            selected={tab === '#history'}
                            onClick={() => updateHash('#history')}
                        >
                            <StyledHistoryIcon selected={tab === '#history'}/>
                            <HistoryTabText>History</HistoryTabText>
                        </Tab>
                    </TabSelector>
                </TabSelectorContainer>

                {tab === '#discover' && (
                    <DiscoverContainer>
                        {ENABLE_LIVE_NOW && ( <LiveSpaces large={true} liveUpdateRate={envAsNumber('LIVE_NOW_UPDATE_INTERVAL')}/> )}
                        <FeaturedSpaces />
                        <FollowedStations />
                        <AllSpaces />
                    </DiscoverContainer>
                )}
                {tab === '#my-stations' && (
                    <MyStationsContainer>
                        <MyStations />
                        {canCreateStations && (
                            <StickyButton onClick={() => history.push('/station/create')} disabled={false}>
                                Create a new station
                            </StickyButton>
                        )}
                    </MyStationsContainer>
                )}
                {tab === '#history' && (
                    <HistoryContainer>
                        <History />
                    </HistoryContainer>
                )}
            </Body>
            <EventsCTA />
        </SpacesContainer>
    );
};

const SpacesContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    padding: 0 10px 0 30px;

    @media (min-width: 375px) {
        padding: 0 30px 0 30px;
    }

    @media (min-width: 768px) {
        padding: 0 90px 0 90px;
    }
`;

const Title = styled.h1`
    font-size: 60px;
`;

const SearchContainer = styled.div`
    width: 240px;
    height: 40px;
    padding: 10px 16px 0 20px;
    border-radius: 23px;
    background-color: var(--off-white);
    color: grey;
`;

const Body = styled.div`
    width: 100%;
    height: 100%;
    flex: 1 0 auto;
    margin-bottom: 140px;
`;

const TabSelectorContainer = styled.div`
    width: 100%;
    height: 40px;
    border-bottom: 2px solid var(--tennis-green);
`;

const TabSelector = styled.div`
    display: flex;
    height: 100%;
    padding-left: 10px;

    @media (min-width: 375px) {
        padding-left: 30px;
    }

    @media (min-width: 768px) {
        padding-left: 90px;
    }
`;

const Tab = styled.div<{selected: boolean}>`
    display: flex;
    justify-content: space-between;
    width: fit-content;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: var(--navy-blue);
    letter-spacing: 1.17px;
    font-weight: bold;
    text-transform: uppercase;

    ${(props) => props.selected ?
        `
            border: 2px solid var(--tennis-green);
            border-bottom: 2px solid var(--midnight);
            color: var(--tennis-green);
        `
        :
        `
            cursor: pointer;
            border-bottom: 2px solid var(--tennis-green);
        `
    }

    &:hover div {
        color: var(--tennis-green);
    }

    &:hover svg path {
        fill: var(--tennis-green);
    }
`;

const TabTextStyles = `margin-top: 9px;`;

const GlobalTabText = styled.div`
    ${TabTextStyles}
`;
const HostTabText = styled.div`
    ${TabTextStyles}
`;
const HistoryTabText = styled.div`
    ${TabTextStyles}
`;

const TabIconStyles = `
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    transition: var(--main-transition);
`;

const StyledGlobalIcon = styled(GlobalIcon)<{selected: boolean}>`
    ${TabIconStyles}
    & path {
        fill: var(${(props) => props.selected ? '--tennis-green' : '--off-white'});
    }
`;

const StyledHostIcon = styled(HostIcon)<{selected: boolean}>`
    ${TabIconStyles}
    & path {
        fill: var(${(props) => props.selected ? '--tennis-green' : '--off-white'});
    }
`;

const StyledHistoryIcon = styled(HistoryIcon)<{selected: boolean}>`
    ${TabIconStyles}
    & path {
        fill: var(${(props) => props.selected ? '--tennis-green' : '--off-white'});
    }
`;

const DiscoverContainer = styled.div`
    padding 24px 10px 0 10px;

    @media (min-width: 375px) {
        padding: 24px 30px 0 30px;
    }

    @media (min-width: 768px) {
        padding: 24px 90px 0 90px;
    }
`;

const MyStationsContainer = styled.div`
    padding 24px 10px 0 10px;

    @media (min-width: 375px) {
        padding: 24px 30px 0 30px;
    }

    @media (min-width: 768px) {
        padding: 24px 90px 0 90px;
    }
`;

const HistoryContainer = styled.div`
    padding 24px 10px 0 10px;

    @media (min-width: 375px) {
        padding: 24px 30px 0 30px;
    }

    @media (min-width: 768px) {
        padding: 24px 90px 0 90px;
    }
`;

export default Spaces;
