import * as React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AcceptHostInvite from './AcceptHostInvite';
import AcceptCookiesModal from '../components/modals/AcceptCookiesModal';
import AcceptInvite from './AcceptInvite';
import AcceptManagerInvite from './AcceptManagerInvite';
import AdminAuthRoute from './AdminAuthRoute';
import AdminInstances from './AdminInstances';
import AuthRoute from './AuthRoute';
import ChromeOnlyModal from '../components/modals/ChromeOnlyModal';
import Orbit from './Orbit';
import CreateEnvironment from './CreateEnvironment';
import CreateSpace from './CreateSpace';
import CreateStation from './CreateStation';
import CredentialsModal from '../components/modals/CredentialsModal';
import EditSpace from './EditSpace';
import EditStation from './EditStation';
import Environments from './Environments';
import FirebaseEmailHandler from './FirebaseEmailHandler';
import FourOhFour from './404';
import Header from '../components/header/Header';
import Landing from './Landing';
import LogIn from './LogIn';
import NonAuthorizedRoute from './NonAuthorizedRoute';
import Managed from './Managed';
import ProfileEdit from './ProfileEdit';
import ResetPassword from './ResetPassword';
import ReturnToGame from './ReturnToGame';
import SignUp from './SignUp';
import SignUpBackDoor from './dev-only-backdoor/SignUpDevBackdoor';
import SpaceAdmin from './SpaceAdmin';
import SpaceKicked from './SpaceKicked';
import SpaceLobby from './SpaceLobby';
import LiteLobby from './LiteLobby';
import StationAdmin from './StationAdmin';
import StationPage from './StationPage';
import Stations from './Stations';
import Spaces from './Spaces';
import NwrEvents from './NwrEvents';
import TokenDebug from './TokenDebug';
import VerifyEmail from './VerifyEmail';
import UpdateEnvironment from './UpdateEnvironment';
import PortalEmbed from './PortalEmbed';
import SpacesEmbed from './SpacesEmbed';
import OrbitEmbed from './OrbitEmbed';
import SpotifyEmbed from './SpotifyEmbed';
import GlobalStyles from '../css/GlobalStyles';
import UserAdmin from './UserAdmin';
import { env } from '../utils/envLoader';
import EventsEmbed from './EventsEmbed';
import { FollowingContextProvider } from '../components/following/FollowingContext';
import { ModalsManager } from '../components/modals/ModalsManager';
import Meet from './Meet';
import Jpg2Env from './Jpg2Env';
import AcceptMeetInvite from './AcceptMeetInvite';
import DeveloperApiKeyManager from './DeveloperApiKeyManager';
import EmbedNowhere from './EmbedNowhere';
import StreamAdminEmbed from './StreamAdminEmbed';
import InstantMeet from './InstantMeet';
import SpaceEntrySplash from './SpaceSplash';
import SpaceStreamViewer from './SpaceStreamViewer';
import PaymentConfirmation from './PaymentConfirmation';
import { PricingPage } from './PricingPage';
import { SpaceRaceConfig } from './SpaceRaceConfig';

const Routes = () => {
    return (
        <Router>
            <Switch>
                {/*
                    Embedded views for 3D UI. Moved to a separate switch to avoid
                    cookie-popups (e.g. agreeing to terms) and the navbar
                */}
                <Route path="/embeds">
                    <GlobalStyles transparent />
                    <Switch>
                        <Route path="/embeds/portals/:spaceId">
                            <PortalEmbed />
                        </Route>
                        <Route path="/embeds/spaces/:spaceId">
                            <SpacesEmbed />
                        </Route>
                        <Route path="/embeds/orbit">
                            <OrbitEmbed />
                        </Route>
                        <Route path="/embeds/spotify">
                            <SpotifyEmbed />
                        </Route>
                        <Route path="/embeds/events">
                            <EventsEmbed />
                        </Route>
                        <Route path="/embeds/streamAdmin/:spaceId">
                            <StreamAdminEmbed />
                        </Route>
                    </Switch>
                </Route>

                <Route path="/meet">
                    <GlobalStyles transparent />
                    <Switch>
                        <AuthRoute path="/meet/space/:spaceId" disableCredsModal={true}>
                            <Meet />
                        </AuthRoute>
                        <AuthRoute path="/meet/station/:stationId" disableCredsModal={true}>
                            <Meet />
                        </AuthRoute>
                        <AuthRoute path="/meet" disableCredsModal={true}>
                            <Meet />
                        </AuthRoute>
                    </Switch>
                </Route>

                <Route path="/jpg2Env">
                    <GlobalStyles transparent />
                    <Jpg2Env/>
                </Route>

                <Route path="/spotify">
                    <AuthRoute exact path="/spotify">
                            <SpotifyEmbed />
                    </AuthRoute>
                </Route>

                <Route path="/spaceEntry/:meetInviteCode">
                    <SpaceEntrySplash />
                </Route>

                <Route path="/embedNowhere/3rdPartyAuth">
                  <Route path="/embedNowhere/3rdPartyAuth/:provider/:meetInviteCode">
                    <EmbedNowhere />
                  </Route>
                </Route>

                {/* non-embed, main site views */}
                <Route path="/">
                    <Header />
                    <AcceptCookiesModal />
                    <ChromeOnlyModal />
                    <Switch>
                        <AuthRoute exact path="/">
                            <Landing />
                        </AuthRoute>
                        <AuthRoute path="/developer/api">
                            <DeveloperApiKeyManager />
                        </AuthRoute>
                        <AuthRoute path="/payment/success">
                          <PaymentConfirmation />
                        </AuthRoute>
                        <Route path="/log-in">
                            <LogIn />
                        </Route>
                        <Route path="/invite/:inviteCode">
                            <AcceptInvite />
                        </Route>
                        <AuthRoute path="/instantMeet">
                          <InstantMeet />
                        </AuthRoute>
                        <AuthRoute path="/spectateInvite/:meetInviteCode">
                          <AcceptMeetInvite />
                        </AuthRoute>
                        <AuthRoute path="/meetInvite/:meetInviteCode">
                          <AcceptMeetInvite />
                        </AuthRoute>
                        <AuthRoute path="/embedNowhere/:meetInviteCode">
                          <EmbedNowhere />
                        </AuthRoute>
                        <Route path="/invite/:inviteCode/:spaceId/lite">
                            <AcceptInvite />
                        </Route>
                        <Route path="/managerInvite/:inviteManagerCode">
                            <AcceptManagerInvite />
                        </Route>
                        <Route path="/hostInvite/:inviteHostCode">
                            <AcceptHostInvite />
                        </Route>
                        <AuthRoute path="/managed">
                            <Redirect to="/spaces#my-stations"/>
                        </AuthRoute>
                        <AuthRoute path="/creds-temp">
                            <CredentialsModal />
                        </AuthRoute>
                        <NonAuthorizedRoute path="/verify-email">
                            <VerifyEmail />
                        </NonAuthorizedRoute>
                        <Route path="/sign-up">
                            <SignUp />
                        </Route>
                        { /* allows autmoated testing to bypass CAPTCHA in non-prodcution ENV's */ }
                        { (env('ENVIRONMENT').toLocaleLowerCase().indexOf('prod') < 0) &&
                        <Route path="/sign-up-backdoor">
                            <SignUpBackDoor />
                        </Route>
                        }
                        <Route path="/firebase-email-handler">
                            <FirebaseEmailHandler />
                        </Route>
                        <NonAuthorizedRoute path="/reset-password">
                            <ResetPassword />
                        </NonAuthorizedRoute>
                        <AuthRoute path="/profile-edit">
                            <ProfileEdit />
                        </AuthRoute>
                        <AuthRoute path="/return-to-game">
                            <ReturnToGame />
                        </AuthRoute>
                        <AuthRoute path="/events/:eventId">
                            <NwrEvents />
                        </AuthRoute>
                        <AuthRoute path="/events">
                            <NwrEvents />
                        </AuthRoute>
                        <AuthRoute path="/create-event">
                            <NwrEvents create={true}/>
                        </AuthRoute>
                        <AuthRoute path="/update-event/:eventId">
                            <NwrEvents update={true}/>
                        </AuthRoute>
                        <AuthRoute path="/stations">
                            <Redirect to="/spaces#my-stations"/>
                        </AuthRoute>
                        <AuthRoute path="/spaces">
                            <FollowingContextProvider>
                                <Spaces />
                            </FollowingContextProvider>
                        </AuthRoute>
                        
                        <AuthRoute path="/station/create">
                            <CreateStation />
                        </AuthRoute>
                        <AuthRoute path="/station/:stationId/:spaceId/landing">
                            <StationPage />
                        </AuthRoute>
                        <AuthRoute path="/station/:stationId/admin">
                            <StationAdmin />
                        </AuthRoute>
                        <AuthRoute path="/station/:stationId/createSpace">
                            <CreateSpace />
                        </AuthRoute>
                        <AuthRoute path="/station/:stationId/edit">
                            <EditStation />
                        </AuthRoute>
                        <AuthRoute path="/station/:stationId">
                            <StationPage />
                        </AuthRoute>
                        <AuthRoute path="/pricing">
                            <PricingPage />
                        </AuthRoute>
                        <AuthRoute path="/space/:spaceId/race">
                            <SpaceRaceConfig />
                        </AuthRoute>
                        <AuthRoute path="/space/:spaceId/edit">
                            <EditSpace />
                        </AuthRoute>
                        <AuthRoute path="/space/:spaceId/stream">
                            <SpaceStreamViewer />
                        </AuthRoute>
                        <AuthRoute path="/space/:spaceId/lobby/:subSpace">
                            <SpaceLobby />
                        </AuthRoute>
                        <AuthRoute path="/space/:spaceId/lobby">
                            <SpaceLobby />
                        </AuthRoute>
                        <AuthRoute path="/space/:spaceId/lite">
                            <LiteLobby />
                        </AuthRoute>
                        <AuthRoute path="/space/:spaceId/kicked">
                            <SpaceKicked />
                        </AuthRoute>
                        <AuthRoute path="/space/:spaceId/lobbySoup">
                            <SpaceLobby />
                        </AuthRoute>
                        <AuthRoute path="/space/:spaceId">
                            <SpaceAdmin />
                        </AuthRoute>

                        <AuthRoute path="/orbit">
                            <Orbit />
                        </AuthRoute>

                        <AdminAuthRoute path="/admin/instances">
                            <AdminInstances />
                        </AdminAuthRoute>
                        <AdminAuthRoute path="/environments">
                            <Environments />
                        </AdminAuthRoute>
                        <AdminAuthRoute path="/environment/create">
                            <CreateEnvironment />
                        </AdminAuthRoute>
                        <AdminAuthRoute path="/environment/:environmentId">
                            <UpdateEnvironment />
                        </AdminAuthRoute>

                        <AdminAuthRoute path="/users">
                            <UserAdmin />
                        </AdminAuthRoute>

                        {/* NOTE(blackmad): these are for dev use */}
                        <AuthRoute path="/tokenDebug">
                            <TokenDebug />
                        </AuthRoute>
                        <AuthRoute path="/debugToken">
                            <TokenDebug />
                        </AuthRoute>

                        {/* Fallback to 404 */}
                        <Route>
                            <FourOhFour />
                        </Route>
                    </Switch>
                    <ModalsManager />
                </Route>
            </Switch>
        </Router>
    );
};

export default Routes;
