import * as React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { ApiEnvironment, ApiEnvironmentEditableMetadata } from '../../api/ApiTypes';
import UploadImage from '../inputs/UploadImage';
import ValidatedFormInput from '../inputs/ValidatedFormInput';
import { AuthButton } from '../inputs/Button';
import { OpenCheckbox } from '../inputs/Checkbox';
import { Select } from '../inputs/Select';

type EnvironmentFormProps = {
    onSubmit: (spaceData: ApiEnvironmentEditableMetadata) => void;
    environment?: ApiEnvironment;
};

const EnvironmentForm = ({ onSubmit, environment }: EnvironmentFormProps) => {
    const [displayName, setDisplayName] = React.useState('');
    const [idName, setIdName] = React.useState('');
    const [mobileIdName, setMobileIdName] = React.useState('');
    const [defaultImageUrl, setDefaultImageUrl] = React.useState('');
    const [dropdownImageUrl, setDropdownImageUrl] = React.useState('');

    const [uploadingDefault, setUploadingDefault] = React.useState(false);
    const [uploadingDropdown, setUploadingDropdown] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [isGlobal, setIsGlobal] = React.useState(false);
    const [isDefaultMeet, setIsDefaultMeet] = React.useState(false);
    const [defaultMeetOrder, setDefaultMeetOrder] = React.useState(-1);
    const [type, setType] = React.useState('CUSTOM');

    const { register, handleSubmit, errors } = useForm({
        reValidateMode: 'onSubmit',
        shouldFocusError: true
    });

    const processSubmit = () => {
        setSubmitting(true);
        const environmentData: ApiEnvironmentEditableMetadata = {
            displayName,
            idName,
            mobileIdName,
            defaultImageUrl,
            dropdownImageUrl,
            global: isGlobal,
            isDefaultMeet,
            defaultMeetOrder,
            type,
        };

        if(defaultMeetOrder < 0) environmentData.defaultMeetOrder = undefined;
        onSubmit(environmentData);
    };

    React.useEffect(() => {
        if (environment?.displayName) setDisplayName(environment.displayName);
        if (environment?.idName) setIdName(environment.idName);
        if (environment?.mobileIdName) setMobileIdName(environment.mobileIdName);
        if (environment?.defaultImageUrl) setDefaultImageUrl(environment.defaultImageUrl);
        if (environment?.dropdownImageUrl) setDropdownImageUrl(environment.dropdownImageUrl);
        if (environment?.global) setIsGlobal(environment.global);
        if (environment?.isDefaultMeet) setIsDefaultMeet(environment.isDefaultMeet!);
        if (environment?.defaultMeetOrder?? -1 > -1) setDefaultMeetOrder(environment?.defaultMeetOrder?? -1)
        if (environment?.type) setType(environment!.type!);
    }, [environment]);

    const typeSelectValues = [
        { label: 'Custom', value: 'CUSTOM' },
        { label: 'Core', value: 'CORE' },
        { label: 'Premium', value: 'PREMIUM' },
    ];

    return (
        <Form onSubmit={handleSubmit(processSubmit)}>
            <Select
                onChange={(value) => setType(value)}
                value={type}
                options={typeSelectValues}
                spClassName="noTranslate"
            />
            <ValidatedFormInput
                labelText="Display Name"
                errors={errors}
                name="displayName"
                type="text"
                validation={register({ required: 'Environment Display name is required' })}
                value={displayName}
                onChange={(e: any) => setDisplayName(e.target.value)}
            />
            <ValidatedFormInput
                labelText="Id Name"
                errors={errors}
                name="idName"
                type="text"
                validation={register({ required: 'Environment Id name is required' })}
                value={idName}
                onChange={(e: any) => setIdName(e.target.value)}
            />
            <ValidatedFormInput
                labelText="Mobile Id Name"
                errors={errors}
                name="mobileIdName"
                type="text"
                validation={register({ required: 'Mobile Environment Id name is required' })}
                value={mobileIdName}
                onChange={(e: any) => setMobileIdName(e.target.value)}
            />
            <UploadImage
                onUpload={(u: string) => setDefaultImageUrl(u)}
                existingImage={environment?.defaultImageUrl}
                uploading={uploadingDefault}
                setUploading={setUploadingDefault}
                imageType="Default"
            />
            <UploadImage
                onUpload={(u: string) => setDropdownImageUrl(u)}
                existingImage={environment?.dropdownImageUrl}
                uploading={uploadingDropdown}
                setUploading={setUploadingDropdown}
                imageType="Dropdown"
            />
            <CheckboxWrapper>
                <OpenCheckbox
                    errors={errors}
                    name="global"
                    checked={isGlobal}
                    onChange={() => setIsGlobal(!isGlobal)}
                />
                Global Environment?
            </CheckboxWrapper>
            <CheckboxWrapper>
                <OpenCheckbox
                    errors={errors}
                    name="isDefaultMeet"
                    checked={isDefaultMeet}
                    onChange={() => setIsDefaultMeet(!isDefaultMeet)}
                />
                Default Meet Environment?
            </CheckboxWrapper>
            {isDefaultMeet && (<ValidatedFormInput
                labelText="Default Meet Order"
                errors={errors}
                name="defaultMeetOrder"
                type="text"
                validation={register({})}
                value={(defaultMeetOrder > -1)? defaultMeetOrder: '' }
                onChange={(e: any) => setDefaultMeetOrder(parseInt(e.target.value))}
            />)}
            <AuthButton disabled={uploadingDefault || uploadingDropdown || submitting}>
                {uploadingDefault || uploadingDropdown
                    ? 'Uploading...'
                    : environment
                    ? 'Update Environment'
                    : 'Create Environment'}
            </AuthButton>
        </Form>
    );
};

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;

    label {
        margin-bottom: 0;
    }
`;

const Form = styled.form`
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export default EnvironmentForm;
