import * as React from 'react';
import { useState } from 'react';
import ValidatedFormInput, { TwoInputsOneLine } from '../components/inputs/ValidatedFormInput';
import { useForm } from 'react-hook-form';
import { StickyButton, MediumButton, StickyFormStatusContainer, RedButton } from '../components/inputs/Button';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../store/selectors';
import UploadProfilePic, { doImageUpload } from '../components/orbit/UploadProfilePic';
import { postApi, deleteApi, getApi } from '../api/api';
import { ApiUser, APITipHistory, APITip, ThirdPartyAvatar, UserType, ApiSubscriptionSummery } from '../api/ApiTypes';
import { GreyishBodyText, Title, WhiteLinkText } from '../components/Typography';
import styled from 'styled-components/macro';
import { useFirebase } from 'react-redux-firebase';
import FormErrorMessage from '../components/inputs/FormErrorMessage';
import SocialCardFormContents from '../components/orbit/SocialCardFormContents';
import Cookies from 'js-cookie';
import { setCurrentUser } from '../store/actions';
import { useDispatch } from 'react-redux';
import Divider from '../components/Divider';
import { Modal } from '../components/layouts/Modal';
import Message from '../components/layouts/Message';
import { clearCurrentUser } from '../store/actions';
import Checkbox, { OpenCheckbox } from '../components/inputs/Checkbox';
import { USDDollarFormat } from '../utils/MoneyUtils';
import InfoIcon from '../images/atom-i-info-alt.svg';
import ReactTooltip from 'react-tooltip';
import { profile } from 'console';
import { trimEnd } from 'lodash';
import { Select } from '../components/inputs/Select';
import { isAnon } from '../permissions/users';
import { SubscriptionModal } from '../components/modals/PaymentModals';
import _ from 'lodash';
import { env } from '../utils/envLoader';
import { write3DAccessableCookie } from '../utils/CookieUtil';
import { setUserState } from '../utils/UserStateHelper';

const ProfileEdit = () => {
    const history = useHistory();

    const urlParams = new URLSearchParams(window.location.search);
    const returnToGame = urlParams.get('returnToGame');
    const openPricingPage = urlParams.get('openPricingPage');

    const useFormObject: any = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: true
    });
    const { register, handleSubmit, errors } = useFormObject;
    const dispatch = useDispatch();

    const currentUser = useSelector(getCurrentUser);
    const [userProfile, setUserProfile] = useState<ApiUser>(({} as unknown) as ApiUser);
    const [userProfileChanged, setUserProfileChanged] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [apiError, setApiError] = useState('');
    const [notificationErrors, setNotificationErrors] = useState<string[]>([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isTipOptIn, setIsTipOptIn] = useState(false);
    const [tipHistory, setTipHistory] = useState<APITipHistory>(({} as unknown) as APITipHistory);
    const [smsNotify, setSmsNotify] = useState<boolean>(false);
    const [smsNumber, setSmsNumber] = useState<string>('');
    const [thirdPartyAvatar, setThirdPartyAvatar] = useState<ThirdPartyAvatar>(({} as unknown) as ThirdPartyAvatar);
    const [thirdPartyAvatarProvider, setThirdPartyAvatarProvider] = useState<string>('');
    const [thirdPartyAvatarFormat, setThirdPartyAvatarFormat] = useState<string>('');
    const [thirdPartyAvatarLocation, setThirdPartyAvatarLocation] = useState<string>('');
    const [emailNotify, setEmailNotify] = useState<boolean>(true);
    const [showSubscriptionsModal, setShowSubscriptionsModel] = useState<boolean>(openPricingPage ? true : false);
    const [subscriptionSummery, setSubscriptionSummery] = useState<ApiSubscriptionSummery>({ type: 'NO_ACTIVE_SUB' });
    const [subscriptionManageLink, setSubscriptionManageLink] = useState(env('STRIPE_MANAGE_ACCOUNT_LINK'));

    //if url params

    const avatarProviderList = [
        { label: 'Ready Player Me', value: 'RPM' },
        { label: 'Upland', value: 'upland' }
    ];

    const avatarFormatList = [{ label: 'GLB (RPM)', value: 'RPM' }];

    const hardPrefs = {};

    const defaultPrefs = {
        notifications: {
            email: {
                isAllowed: true,
                notifyOn: [
                    {
                        eventType: 'EVENT_REMINDERS',
                        isAllowed: true
                    }
                ]
            },
            sms: {
                isAllowed: false,
                number: '',
                notifyOn: [
                    {
                        eventType: 'EVENT_REMINDERS',
                        isAllowed: false
                    }
                ]
            }
        }
    };

    const userPrefs = (smsNotify: boolean, smsNumber: string, emailNotify: boolean) => {
        const newPrefs = defaultPrefs;
        newPrefs.notifications.sms.isAllowed = smsNotify;
        newPrefs.notifications.sms.notifyOn[0].isAllowed = smsNotify;
        newPrefs.notifications.sms.number = smsNumber;

        newPrefs.notifications.email.isAllowed = emailNotify;
        newPrefs.notifications.email.notifyOn[0].isAllowed = emailNotify;
        return newPrefs;
    };

    React.useEffect(() => {
        setThirdPartyAvatar({
            provider: thirdPartyAvatarProvider,
            format: thirdPartyAvatarFormat,
            location: thirdPartyAvatarLocation
        });
    }, [thirdPartyAvatarProvider, thirdPartyAvatarFormat, thirdPartyAvatarLocation]);

    /**
     * User is loaded via redux selector 'currentUser'
     */
    React.useEffect(() => {
        getApi(`/user/me?isSyncSub=true`).then(async (r) => {
            const response = await r.json();
            if(r.ok) {
              dispatch(setCurrentUser(response));
              setUserProfile({ ...userProfile, userType: response.userType });
            }
          });
    }, []);

    React.useEffect(() => {
        console.log("user: ", currentUser)
        setSubscriptionManageLink(`${env('STRIPE_MANAGE_ACCOUNT_LINK')}?prefilled_email=${encodeURIComponent(currentUser.email)}`);
        //Casts the user type to the new type
        getApi('/user/types')
            .then(async (res) => {
                return await res.json();
            })
            .then(async (aut) => {
                if (!currentUser?.userType) return Object.keys(aut)[0] as UserType;
                return aut[currentUser!.userType!] as UserType;
            })
            .then((ut) => {
                setUserProfile({ ...currentUser, userType: ut });
            });

        setIsTipOptIn(currentUser.tippingEmail ? true : false);
        setSmsNotify(currentUser.preferences?.notifications?.sms?.isAllowed ? true : false);
        setSmsNumber(currentUser.preferences?.notifications?.sms?.number ?? '');
        const en = () => {
          if(currentUser.preferences?.notifications?.email?.isAllowed === undefined) return true;
          return currentUser.preferences?.notifications?.email?.isAllowed? true: false;
        };
        setEmailNotify(en());
        setIsTipOptIn(currentUser.tippingEmail? true: false);
        if(currentUser.thirdPartyAvatar) {
          setThirdPartyAvatarProvider(currentUser!.thirdPartyAvatar.provider);
          setThirdPartyAvatarFormat(currentUser!.thirdPartyAvatar.format);
          setThirdPartyAvatarLocation(currentUser!.thirdPartyAvatar.location);
        }


        retreiveTipHistory();
        retreiveSubscriptionSummery();
    }, [currentUser]);

    const updateUserProfile = (value: any) => {
        setUserProfile(value);
        setUserProfileChanged(true);
    };

    const retreiveTipHistory = () => {
        getApi(`/tip/history/P2P/${currentUser.id}`)
            .then(async (atp) => {
                setTipHistory((await atp.json()) as APITipHistory);
            })
            .catch((e: Error) => {
                console.warn('Could not get tip history with error: ', e);
            });
    };

    const retreiveSubscriptionSummery = () => {
        getApi(`/payment/subscription/summery`)
            .then(async (res) => {
                const ss = (await res.json()) as ApiSubscriptionSummery;
                console.log({ subscriptionSummery: ss });
                setSubscriptionSummery({ ...subscriptionSummery, ...ss });
            })
            .catch((e: Error) => {
                console.warn('Could not get subscription history with error: ', e);
            });
    };

    const onImageChanged = async (url: string) => {
        await doImageUpload(url)
            .then((newAvatarUrl) => {
                return postApi('/user/me/update', {
                    avatarUrl: newAvatarUrl || userProfile.avatarUrl,
                    userType: userProfile.userType,
                    badgeSubtitle: userProfile.badgeSubtitle,
                    company: userProfile.company,
                    displayName: userProfile.displayName,
                    social: userProfile.social,
                    title: userProfile.title,
                    firstName: userProfile.firstName,
                    lastName: userProfile.lastName,
                    url: userProfile.url,
                    phone: userProfile.phone,
                    displayEmail: userProfile.displayEmail,
                    tippingEmail: userProfile.tippingEmail,
                    optInMarketing: userProfile.optInMarketing,
                    preferences: userPrefs(smsNotify, smsNumber, emailNotify),
                    thirdPartyAvatar,
                });
            })
            .then(async (r) => {
                const response = await r.json();
                if (!r.ok) {
                    if (response.apiError?.isJoi) {
                        console.log(response.apiError.details.map((d: any) => d.message).join(', '));
                        setApiError(response.apiError.details.map((d: any) => d.message).join(', '));
                    } else {
                        setApiError('💣 Something went wrong with uploading your image 🙁');
                    }
                } else {
                    setUpdated(true);
                    dispatch(setCurrentUser(response));
                    setTimeout(() => {
                        setUpdated(false);
                    }, 30000);
                }
            });
    };

    const updateProfile = async () => {
        setUpdating(true);
        setUpdated(false);
        setApiError('');
        const updatedUser = {
            userType: userProfile.userType,
            avatarUrl: userProfile.avatarUrl,
            badgeSubtitle: userProfile.badgeSubtitle,
            company: userProfile.company,
            displayName: userProfile.displayName,
            social: userProfile.social,
            title: userProfile.title,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            url: userProfile.url,
            phone: userProfile.phone,
            displayEmail: userProfile.displayEmail,
            tippingEmail: userProfile.tippingEmail,
            optInMarketing: userProfile.optInMarketing,
            preferences: userPrefs(smsNotify, smsNumber, emailNotify),
            thirdPartyAvatar,
        };
        console.log('updatedUser', updatedUser);

        postApi('/user/me/update', updatedUser)
            .then(async (r) => {
                let response = null;
                try {
                    response = await r.json();
                } catch(e) {
                    console.error('updateProfile failed to parse response (not json)', e);
                    setApiError('Something went wrong.');
                    setUpdating(false);
                    return;
                }
                if (response && !r.ok) {
                    if (response.error?.isJoi) {
                        console.log(response.error.details.map((d: any) => d.message).join(', '));
                        setApiError(response.error.details.map((d: any) => d.message).join(', '));
                    } else {
                        setApiError('Something went wrong');
                    }
                } else {
                    setUpdated(true);
                    const now = new Date();
                    setUserState('CredentialModalOptOut', { optOutTime: now.getTime() });
                  //  Cookies.set('CredentialModalOptOut', 'perm');
                    dispatch(setCurrentUser(response));
                    setTimeout(() => {
                        setUpdated(false);
                    }, 10000);
                }
                setUpdating(false);
            })
            .catch((err) => {
                console.error('updateProfile failed', err);
                setApiError(err.message);
                setUpdating(false);
            });
    };

    type comMethodType = 'email' | 'sms';
    type eventType = 'EVENT_REMINDERS'; // add new notififaction

    const toggleSmsNotifyOn = () => {
        if (smsNumber.trim() === '') {
            setNotificationErrors(['Phone number required for SMS alerts.']);
            return;
        }
        setNotificationErrors([]);
        setSmsNotify(!smsNotify);
        setUserProfileChanged(true);
    };

    const toggleEmailNotifyOn = () => {
        setNotificationErrors([]);
        setEmailNotify(!emailNotify);
        setUserProfileChanged(true);
    }

    const handleSmsNumberChange = (newValue:string) => {
        if(newValue.trim() === ''){
            setSmsNotify(false);
            setNotificationErrors(['Phone number required for SMS alerts.']);
        } else {
            setNotificationErrors([]);
        }
        setSmsNumber(newValue);
        setUserProfileChanged(true);
    };

    const toggleTipping = (isAllowed: boolean) => {
        if (!isAllowed) {
            updateUserProfile({
                ...userProfile,
                tippingEmail: ''
            });
        } else {
            updateUserProfile({
                ...userProfile,
                tippingEmail: userProfile.tippingEmail? userProfile.tippingEmail: userProfile.email
            });
        }
        setIsTipOptIn(isAllowed);
    };

    const firebase = useFirebase();
    const [resetDone, setResetDone] = useState(false);
    const handleResetPassword = () => {
        const { email } = userProfile;
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                setResetDone(true);
            })
            .catch(function (apiError) {
                if (apiError.code === 'auth/user-not-found') {
                    setApiError('This email address is not currently registered. Perhaps you need to sign up?');
                } else {
                    setApiError('Oops! Something went wrong while resetting your password. Please try again later.');
                }
            });
    };

    const handleSignUp = () => {
        const signInLink = returnToGame ? '/sign-up?returnToGame=true' : '/sign-up';
        history.push(signInLink);
    };

    if (isAnon(currentUser)) {
        return (
            <AnonContainer>
                <h1>Get set up in seconds!</h1>
                <AnonSummary>Quickly create your personal account to experience all NOWHERE has to offer.</AnonSummary>
                <Button onClick={handleSignUp}>Create your account</Button>
            </AnonContainer>
        );
    }

    const showSubscriptionModal = () => {
        // if the user already has a subscription, then we should show the manage subscription modal
        if (subscriptionSummery.type === 'ACTIVE') {
            const successRoute = '/station/latest?fromPage=profileEdit&fromUserType=' + userProfile.userType  + '&userId=' + userProfile.id;
            if (successRoute) {
                write3DAccessableCookie(
                    'paymentSuccessRoute',
                    successRoute,
                    new Date(new Date().setDate(new Date().getDate() + 1))
                );
            }

          window.open(subscriptionManageLink, '_blank');  
        } else {
            const successRoute = '/station/latest?fromPage=profileEdit&fromUserType=' + userProfile.userType + '&userId=' + userProfile.id;
            history.push('/pricing?' + successRoute);
        }
        //setShowSubscriptionsModel(true);
    };

    const hideSubscriptionModal = () => {
        setShowSubscriptionsModel(false);
    };

    return (
        <Container>
            <BodyContainer>
                <Title>My Account</Title>
                <UploadProfilePic horizontal currentImage={userProfile.avatarUrl} onImageChanged={onImageChanged} />
                {updated && <h3>Update Successful!</h3>}
                {showSubscriptionsModal && (
                    <SubscriptionModal
                        handelClose={hideSubscriptionModal}
                        successRoute={'/profile-edit?fromPage=profileEdit&fromUserType=' + userProfile.userType}
                    />
                )}
                <form onSubmit={handleSubmit(updateProfile)}>
                    <div style={{ marginBottom: '10px', width: '100%' }}>
                        <h3>Account Info</h3>
                        <GreyishBodyText>Private, for internal use only.</GreyishBodyText>
                        <br />
                        <TwoInputsOneLine>
                            <ValidatedFormInput
                                labelText="First Name"
                                type="text"
                                errors={errors}
                                validation={register({
                                    required: 'First name is required'
                                })}
                                maxLength={50}
                                name="firstName"
                                value={userProfile.firstName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        firstName: e.target.value
                                    })
                                }
                            />
                            <ValidatedFormInput
                                labelText="Last Name"
                                type="text"
                                errors={errors}
                                validation={register({
                                    required: 'Last name is required'
                                })}
                                maxLength={50}
                                name="lastName"
                                value={userProfile.lastName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        lastName: e.target.value
                                    })
                                }
                            />
                        </TwoInputsOneLine>
                        <label htmlFor="email">Email</label>
                        <h5 style={{ marginBottom: '30px' }}>
                            <b className="noTranslate">{userProfile.email}</b>
                        </h5>

                        <OpenCheckbox
                            name="optInMarketing"
                            checked={userProfile.optInMarketing ?? false}
                            onChange={() =>
                                updateUserProfile({
                                    ...userProfile,
                                    optInMarketing: !(userProfile.optInMarketing ?? false)
                                })
                            }
                        >
                            Send me email updates
                            <br />
                            about Nowhere
                        </OpenCheckbox>
                        <br />
                        {resetDone ? (
                            <h4>Check your email for password reset instructions</h4>
                        ) : (
                            <MediumButton onClick={handleResetPassword}>Change Password</MediumButton>
                        )}
                    </div>

                    {/* <div style={{ marginBottom: '30px', width: '100%' }}>
                        <h3>Tip Settings</h3>
                        <GreyishBodyText>If and where to receive tips</GreyishBodyText>
                        <br />
                        <CheckboxWrapper>
                            <OpenCheckbox
                                errors={errors}
                                name="tippingOptIn"
                                checked={isTipOptIn? true: false}
                                onChange={() => toggleTipping(!isTipOptIn)}
                            />
                            I want to receive tips
                        </CheckboxWrapper>

                        {isTipOptIn ? (
                            <>
                            <ValidatedFormInput
                                labelText="Tip Email Address (PayPal)"
                                type="text"
                                errors={errors}
                                validation={register({
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address'
                                    }
                                })}
                                maxLength={256}
                                name="tippingEmail"
                                value={userProfile.tippingEmail?? ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        tippingEmail: e.target.value
                                    })
                                }
                            />
                            <GreyishBodyText>Tip email address will be shown to Nowhere users.</GreyishBodyText>
                            </>
                        ): null}
                        <br />
                        <label htmlFor="tipsReceived">
                            Total Tips Received
                            <ReactTooltip effect="solid" />
                            <InactiveInfo data-tip="This is meant to be an estimate, and may not be an accurate reflection of total funds received." src={InfoIcon} />

                        </label>
                        <h5 style={{ marginBottom: '30px' }}>
                            <b>{USDDollarFormat(tipHistory?.summery?.totalAmount?? 0)}</b>
                        </h5>
                    </div> */}

                    <SocialCardFormContents
                        useForm={useFormObject}
                        userProfile={userProfile}
                        updateUserProfile={updateUserProfile}
                    />
                </form>
                <StickyButton onClick={handleSubmit(updateProfile)} disabled={updating || !userProfileChanged}>
                    {updating ? 'Updating...' : updated ? 'Updated!' : 'Submit updates'}
                </StickyButton>
                <StickyFormStatusContainer>
                    {apiError && <FormErrorMessage>Error: {apiError}</FormErrorMessage>}
                    {updated && <h3>Update Successful!</h3>}
                </StickyFormStatusContainer>
                <Divider />
                <h3>Advanced Avatar Settings</h3>
                <label htmlFor="AvatarProvider">
                    Avatar Provider
                    <ReactTooltip effect="solid" />
                    <InactiveInfo data-tip="The provider of the avatar" src={InfoIcon} />
                </label>
                <Select
                    onChange={(value) => {
                        setThirdPartyAvatarProvider(value);
                        setUserProfileChanged(true);
                    }}
                    value={thirdPartyAvatarProvider}
                    options={avatarProviderList}
                />
                <label htmlFor="AvatarFormat">
                    Avatar Format
                    <ReactTooltip effect="solid" />
                    <InactiveInfo data-tip="The format of the avatar" src={InfoIcon} />
                </label>
                <Select
                    onChange={(value) => {
                        setThirdPartyAvatarFormat(value);
                        setUserProfileChanged(true);
                    }}
                    value={thirdPartyAvatarFormat}
                    options={avatarFormatList}
                />
                <ValidatedFormInput
                    labelText="Avatar Location URL"
                    type="text"
                    errors={errors}
                    validation={register({
                        //required: 'First name is required'
                    })}
                    maxLength={256}
                    name="thirdPartyAvatarLocation"
                    value={thirdPartyAvatarLocation}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setThirdPartyAvatarLocation(e.target.value);
                        setUserProfileChanged(true);
                    }}
                />

                <Divider />
                <DangerHeader>Danger Zone</DangerHeader>
                <RedButton onClick={() => setDeleteModalOpen(true)}>Delete Account</RedButton>
                <DeleteUserModal isOpen={deleteModalOpen} hideModal={() => setDeleteModalOpen(false)} />

                <a
                    href="https://urnowhere.com/privacy-policy"
                    style={{ textDecoration: 'underline' }}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <PrivacyLink>Privacy Policy</PrivacyLink>
                </a>
            </BodyContainer>
            <RightContainer>
                <h3 style={{ marginBottom: '30px' }}>Membership</h3>
                <h4 style={{ textTransform: 'uppercase' }}>Type</h4>
                <SubscriptionSummeryInfo>
                    <h3>{userProfile.userType}</h3>
                    {subscriptionSummery.type === 'ACTIVE' && (
                        <h5>{subscriptionSummery?.summery?.description ?? ''}</h5>
                    )}
                </SubscriptionSummeryInfo>
                {subscriptionSummery.type === 'ACTIVE' && (
                    <h5 style={{ textTransform: 'capitalize' }}>
                        { subscriptionSummery?.summery?.message?? '' }
                    </h5>
                )}
                <MediumButton onClick={showSubscriptionModal}>Manage Plan & Billing</MediumButton>

                <NotificationOptions>
                    <Header1>Notifications</Header1>
                    <RowName>
                        Event reminders
                        <br />
                        <FinePrint>Receive 2 email updates per event, SMS only sends 1 at start time.</FinePrint>
                    </RowName>

                    <Header2>Email</Header2>
                    <Checkbox2>
                        {/* Always true and uneditable as per NWR- requirements */}
                        <Checkbox
                            checked={emailNotify}
                            labelText=""
                            disabled={false}
                            onChange={() => {
                                toggleEmailNotifyOn();
                            }}
                        />
                    </Checkbox2>

                    <Header3>
                        SMS
                        <br />
                        <FinePrint>Recommended</FinePrint>
                    </Header3>
                    <Checkbox3>
                        <Checkbox
                            checked={smsNotify}
                            labelText=""
                            onChange={() => {
                                toggleSmsNotifyOn();
                            }}
                        />
                    </Checkbox3>
                </NotificationOptions>
                <PhoneOptions>
                    <Label>Phone number for SMS alerts *</Label>
                    <PhoneInput
                        placeholder="123-456-7890"
                        value={smsNumber}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleSmsNumberChange(e.target.value);
                        }}
                    />
                    <FinePrint>
                        * SMS available for U.S. numbers only. More to come.
                        <br />
                        Standard messaging rates apply.
                    </FinePrint>
                </PhoneOptions>
                {notificationErrors && <NotificationsErrors>{notificationErrors}</NotificationsErrors>}
            </RightContainer>
        </Container>
    );
};

const SubscriptionSummeryInfo = styled.div`
    display: flex;
    justify-content: space-between;
`;

const InactiveInfo = styled.img`
    margin-left: 10px;
    margin-top: -4px;
    fill: #ffffff;
`;

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;

    label {
        margin-bottom: 0;
    }
`;

const AnonContainer = styled.div`
    margin-top: 25vh;
    margin-left: auto;
    margin-right: auto;
    width: 50vh;
`;

const AnonSummary = styled.div`
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 20px;
    line-height: 30px;
    & a {
        font-weight: bold;
        text-decoration: underline;
        &:hover {
            color: var(--tennis-green);
        }
    }
`;

const Button = styled.button`
    color: var(--midnight);
    border-radius: 15px;
    font-weight: bold;
    padding: 5px 15px;
    font-size: 17px;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
        background-color: var(--tennis-green);
    }
`;

const BodyContainer = styled.section`
    margin: 30px 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    @media (min-width: 768px) {
        width: 100%;
    }
    max-width: 600px;
    > * > div {
        margin: 20px 0;
        > h3 {
            margin: 5px 0;
        }
    }
    @media (min-width: 768px) {
        margin: 40px 120px 100px 10vw;
        align-items: flex-start;
    }
`;

const RightContainer = styled.div`
    width: 549px;
    padding: 30px 60px;
    display: inline-block;
    border-radius: 0 0 0 15px;
    box-shadow: inset 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--midnight);
    > * {
        margin-bottom: 15px;
    }
    margin-bottom: 150px;
    @media (min-width: 768px) {
        position: absolute;
        top: 0;
        right: 0;
    }
`;

const Container = styled.div`
    display: flex;
    position: relative;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const PrivacyLink = styled(WhiteLinkText)`
    bottom: 15px;
    left: 120px;
    position: absolute;
    &:hover {
        color: white;
        text-decoration: none;
    }
`;

const DangerHeader = styled.h3`
    margin-bottom: 15px;
`;

const NotificationOptions = styled.div`
    margin-top: 54px;
    display: grid;
    grid-template-columns: 230px 80px 80px;
    grid-template-rows: 40px 60px;
    gap: 10px 10px;
    justify-items: stretch;
    align-items: stretch;
`;

const Header1 = styled.div`
    grid-area: 1 / 1 / 1 / 1;
    font-weight: bold;
    font-size: 24px;
`;
const Header2 = styled.div`
    grid-area: 1 / 2 / 1 / 2;
    justify-self: center;
    font-weight: bold;
`;
const Header3 = styled.div`
    grid-area: 1 / 3 / 1 / 3;
    justify-self: center;
    font-weight: bold;
    text-align: center;
`;
const RowName = styled.div`
    grid-area: 2 / 1 / 2 / 1;
    font-weight: bold;
`;
const Checkbox2 = styled.div`
    grid-area: 2 / 2 / 2 / 2;
    justify-self: center;
`;
const Checkbox3 = styled.div`
    grid-area: 2 / 3 / 2 / 3;
    justify-self: center;
`;
const FinePrint = styled.div`
    font-size: 12px;
    color: var(--battleship-grey);
    font-weight: normal;
    white-space: nowrap;
`;
const PhoneOptions = styled.div``;
const Label = styled.div`
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
`;
const PhoneInput = styled.input`
    border: 1px solid var(--off-white);
    color: var(--off-white);
    background-color: var(--midnight);
    width: 439px;
    height: 40px;
    margin: 6px 0 10px 0;
    padding: 10px 15px;
    border-radius: 10px;
    letter-spacing: 1px;
`;
const NotificationsErrors = styled.div`
    color: var(--burnt-red);
    font-weight: bold;
    margin-top: 20px;
`;
export const ActionButton = styled(Button)`
    border: none;
    font-size: 20px;
    letter-spacing: 1.14px;
    margin-right: 15px;
`;

export const ActionButtonPrimary = styled(ActionButton)`
    background-color: var(--tennis-green);
    color: var(--navy-blue);
`;

const DeleteUserModal = ({ isOpen, hideModal }: { isOpen: boolean; hideModal: () => void }) => {
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSignOut = async () => {
        await firebase.logout().then(() => {
            dispatch(clearCurrentUser());
            history.push({
                pathname: '/log-in',
                state: { showDeleteConfirm: true }
            });
        });
    };

    const onDelete = () => {
        deleteApi(`/user/me`).then(async (r) => {
            if (!r.ok) {
                console.log('could not delete user');
            } else {
                handleSignOut();
            }
        });
    };

    return (
        <Modal handleClose={hideModal} isOpen={isOpen}>
            <Message
                title="Are you sure you want to delete your account?"
                body="This will remove all data associated with your account. This cannot be undone."
            >
                <ModalButtons>
                    <CancelButton onClick={hideModal}>Cancel</CancelButton>
                    <RedButton onClick={onDelete}>Yes, Delete My Account</RedButton>
                </ModalButtons>
            </Message>
        </Modal>
    );
};

const ModalButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-align: center;
    width: 100%;
`;

const CancelButton = styled.button`
    appearance: none;
    font-size: 16px;
    font-weight: bold;
    background: none;
    border: none;
    margin-right: 20px;
`;

const CallToAction = styled.div`
    margin-top: 50px;
    font-size: 20px;
    & a {
        font-weight: bold;
        text-decoration: underline;
        &:hover {
            color: var(--tennis-green);
        }
    }
`;

export default ProfileEdit;
