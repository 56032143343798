export interface Winner {
  userId: string;
  time: number;
}

export interface PrizeDistribution {
  userId: string;
  amount: number;
  place: number;
  time: number;
}

const roundToPrecision = (value: number, precision: number): number => {
    const multiplier = 10 ** precision;
    return Math.round(value * multiplier) / multiplier;
}

export const distributePrizes = (winners: Winner[], totalPrize: number, distributionRules: number[]): PrizeDistribution[] => {
  winners.sort((a, b) => a.time - b.time);

  const prizeDistribution: PrizeDistribution[] = [];
  let i = 0;

  while (i < winners.length) {
    const tieWinners: Winner[] = [winners[i]];
    let j = i + 1;

    while (j < winners.length && winners[j].time === winners[i].time) {
      tieWinners.push(winners[j]);
      j++;
    }

    const tieCount = tieWinners.length;
    let totalPercentage = 0;

    for (let k = 0; k < tieCount && (i + k) < distributionRules.length; k++) {
      totalPercentage += distributionRules[i + k];
    }

    const sharedAmount = (totalPrize * totalPercentage) / 100 / tieCount;

    for (const winner of tieWinners) {
      prizeDistribution.push({
        userId: winner.userId,
        amount: roundToPrecision(sharedAmount, 1),
        place: i + 1,
        time: winner.time,
      });
    }

    i += tieCount;
  }

  return prizeDistribution;
}
