import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import StreamHeader from '../components/streaming/StreamHeader'
import StreamViewer from '../components/streaming/StreamViewer';
import StreamIO from '../components/streaming/StreamIO';
import { StreamContext } from '../components/streaming/StreamContext';
import StreamProvider from '../components/streaming/StreamContext';
import { ApiSpace, ApiStationDetails, ApiStation } from '../api/ApiTypes';
import  LoadingComponent  from '../components/loading/LoadingComponent';
import { useParams, useHistory } from 'react-router-dom';
import { getApi } from '../api/api';
import StreamUpsell from '../components/streaming/StreamUpsell';

interface StationPermissions {
    allowed: boolean,
    name: string,
    reason: string
}

const StreamAdminEmbed = () => {
    return (
        <StreamProvider>
            <ContextualContainer />
        </StreamProvider>
    )
};

const ContextualContainer = () => {
    const { stationDetails, loadingStation, stationError, liveStreamPermission } = useContext(StreamContext);
    const permissionFailure = !liveStreamPermission || !liveStreamPermission.allowed

    if (loadingStation) return <ErrorContainer><div style={{margin: '0 auto', fontSize: '30px'}}>Loading...</div></ErrorContainer>
    if (stationError) return <ErrorContainer><div style={{margin: '0 auto', fontSize: '30px'}}>{`Error: ${stationError}`}</div></ErrorContainer>
    if (!stationDetails) return <ErrorContainer><div style={{margin: '0 auto', fontSize: '30px'}}>{`You don't have access to this station`}</div></ErrorContainer>
    if (permissionFailure) return <ErrorContainer><StreamUpsell></StreamUpsell></ErrorContainer>

    return (
        <Container>
            <StreamParent />
        </Container>
    )
}

const StreamParent = () => {
    return (
        <StreamContainer className='noTranslate'>
            <StreamHeader />
            <StreamViewer isHostView={true} />
            <StreamIO />
        </StreamContainer>
    );
};

const ErrorContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #010027;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #010027;
`;

const StreamContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding-bottom: 60px;
}`;

export default StreamAdminEmbed;
