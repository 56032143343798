import * as React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUserIsAdmin } from '../store/selectors';
import { Route } from 'react-router-dom';
import FourOhFour from './404';

interface Props {
    children: React.ComponentElement<any, any>;
    path: string;
    exact?: boolean;
}

// This is a normal route that is a 404 for any users who are not admins

const AdminAuthRoute: React.FC<Props> = ({ children, ...rest }) => {
    const isAdmin = useSelector(getCurrentUserIsAdmin);

    return <Route {...rest} render={() => (isAdmin ? children : <FourOhFour />)} />;
};

export default AdminAuthRoute;
