import { ApiUserConnection, ApiUser, ApiSpace } from '../api/ApiTypes';

const firstNames = ['Brad', 'Angelina', 'Taylor', 'Billie', 'Andy', 'Jennifer'];
const lastNames = ['Pitt', 'Jolie', 'Swift', 'Eilish', 'Warhol', 'Aniston'];
const profileImages = [
    'https://firebasestorage.googleapis.com/v0/b/nowhere-bb566.appspot.com/o/avatar%2Fbroach%40aya.yale.edu.png?alt=media&token=df53fa88-cab7-432d-aa83-430919a84ae8',
    'https://lh3.googleusercontent.com/a-/AOh14Giia-dyUpdXah23_0CVJglcTuegWb5Zw84IiRmvqw'
];
const companies = ['NWR', 'Somewhere'];
const randInt = (max: number) => Math.floor(Math.random() * max);
const chooseOne = (list: any) => list[randInt(list.length)];
const randomDate = () => {
    const start = new Date(2020, 8, 14);
    const end = new Date();

    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toISOString();
};

const makeFakeSpace = (id: number): ApiSpace => ({
    capacity: 25,
    environmentId: id.toString(),
    creator: makeFakeUser(id),
    createdAt: randomDate(),
    title: `${chooseOne(firstNames)}'s space`,
    stationId: randInt(100).toString(),
    id: id.toString()
});

const makeFakeUser = (id: number): ApiUser => {
    const firstName = chooseOne(firstNames);
    const lastName = chooseOne(lastNames);

    return {
        firstName: firstName,
        lastName: lastName,
        displayName: `${firstName} ${lastName}`,
        email: `${firstName}-${id}@${lastNames}.com`,
        avatarUrl: chooseOne(profileImages),
        displayEmail: `${firstNames}-${id}@${lastNames}.com`,
        company: chooseOne(companies),
        title: 'Stand In',
        social: {
            linkedIn: `https://linkedin.com/in/${firstName[0]}${lastName}`,
            twitter: `${firstName[0]}${lastName}`
        },
        id: id.toString()
    };
};

const makeFakeConnection = (id: number): ApiUserConnection => ({
    toUser: makeFakeUser(id),
    space: makeFakeSpace(id),
    ts: randomDate(),
    id: id.toString()
});

export const makeFakeConnections = (numConnections: number): ApiUserConnection[] => {
    return [...Array(numConnections).keys()].map((_, i) => makeFakeConnection(i));
};
