import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import {
    getAuthState,
    getCurrentUserHasModifiedProfile,
    getCurrentUserLoaded,
    getCurrentUser,
    getAuthError
} from '../store/selectors';
import Cookies from 'js-cookie';
import CredentialsModal from '../components/modals/CredentialsModal';
import LoadingScreen from '../components/loading/LoadingScreen';
import { logInAnonymously } from '../utils/AnonymousAuthHelper';
import { useFirebase } from 'react-redux-firebase';
import { setCurrentUser } from '../store/actions';
import { ApiUser } from '../api/ApiTypes';
import { useEffect, useState } from 'react';
import { envAsBool } from '../utils/envLoader';
import { getUserStateByKey } from '../utils/UserStateHelper';

interface Props {
    children: React.ComponentElement<any, any>;
    path: string;
    exact?: boolean;
    disableCredsModal?: boolean;
}

// Route redirects you to log in if not logged in, verify email if needed,
// ask to view profile modal if profile hasn't been filled out, and only if
// all those things are okay, then send you on to the requested route

const AuthRoute: React.FC<Props> = ({ children, disableCredsModal, ...rest }) => {
    const auth = useSelector(getAuthState);
    const profileLoaded = useSelector(getCurrentUserLoaded);
    const profileModified = useSelector(getCurrentUserHasModifiedProfile);
    //const profileModalSeenRecently = !!Cookies.get('CredentialModalOptOut');
    const [profileModalSeenRecently, setProfileModaSeenRecently] = useState(false);
    const curUser = useSelector(getCurrentUser);
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const isAutoAnonLoginEnabled = envAsBool('ENABLE_AUTO_ANON_LOGIN');
    const authError = useSelector(getAuthError);
    const [isAutoAnonLoginOveride, setIsAutoAnonLoginOveride] = useState(false);
    const routesThatForceAutoAnonLoginOverride = ['/embedNowhere'];

    console.log(`[AuthRoute] Accessing ${location}`);

    if (!profileLoaded) return <LoadingScreen />;

    useEffect(() => {
        if (isLoaded(auth) && isEmpty(auth)) {
            const shortPath = '/' + location.pathname.split('/')[1] ?? location.pathname;
            if (routesThatForceAutoAnonLoginOverride.indexOf(shortPath) > -1) setIsAutoAnonLoginOveride(true);
            if (
                isAutoAnonLoginEnabled &&
                !(['/log-in', '/sign-up', '/invite', '/managerInvite', '/embedNowhere'].indexOf(shortPath) > -1)
            ) {
                logInAnonymously(firebase).then((anonLoginResult) => {
                    if (anonLoginResult.success) dispatch(setCurrentUser(anonLoginResult.user! as ApiUser));
                });
            }
        }
        getUserStateByKey('CredentialModalOptOut').then((res) => {
            if ((res as any).optOutTime) {
                setProfileModaSeenRecently(true);
            } else {
                setProfileModaSeenRecently(false);
            }
        });
    }, []);

    useEffect(() => {
        if (authError) console.log({ authError });
    }, authError);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLoaded(auth) && isEmpty(auth) ? (
                    isAutoAnonLoginEnabled && !isAutoAnonLoginOveride ? (
                        <LoadingScreen />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/log-in',
                                state: { from: location, error: 'You need to login first...' }
                            }}
                        />
                    )
                ) : (
                    //!disableCredsModal &&
                    // !profileModified &&
                    //  !profileModalSeenRecently /*  && !curUser.isFirstTimeUser */ ? (
                    //  <CredentialsModal />
                    // ) :
                    children
                )
            }
        />
    );
};

export default AuthRoute;
