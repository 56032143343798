import * as React from 'react';
import { useHistory } from 'react-router-dom';
import EnvironmentForm from '../components/environments/EnvironmentForm';
import { postApi } from '../api/api';
import { ApiEnvironmentEditableMetadata } from '../api/ApiTypes';
import styled from 'styled-components/macro';

const CreateEnvironment: React.FC = () => {
    const history = useHistory();
    const [error, setError] = React.useState('');

    const handleCreateEnvironment = (environmentData: ApiEnvironmentEditableMetadata) => {
        postApi(`/environment/create`, environmentData)
            .then(async (r) => {
                const json = await r.json();
                if (!r.ok) {
                    setError(json.message || 'Something went wrong');
                }

                if (json.id) {
                    history.push(`/environments`);
                }
            })
            .catch((err) => console.log('error: ', err));
    };

    return (
        <>
            <Wrapper>
                <Title>Create new Environment</Title>
                <EnvironmentForm
                    onSubmit={(environmentData: ApiEnvironmentEditableMetadata) =>
                        handleCreateEnvironment(environmentData)
                    }
                />
                {error && <h2>{error}</h2>}
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    height: 95vh;
    padding: 0 60px;
`;

const Title = styled.h1`
    margin-bottom: 30px;
`;

export default CreateEnvironment;
