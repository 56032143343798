import * as React from 'react';
import Routes from './routes/Routes';
import './css/components.css';
import './css/normalize.css';
import './css/nowhere-stag.css';
import './css/flexbox.css';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createStore } from 'redux';
import reducers from './store/reducers';
import { firebaseApp } from './firebase/Connection';
import AuthIsLoaded from './firebase/AuthIsLoaded';
import GlobalStyles from './css/GlobalStyles';
import { getRefCodeAndSetIfPresent } from './utils/ReferralCodeHelper';
import { ModalContextProvider } from './context/ModalContext';
import { FollowingContextProvider } from './components/following/FollowingContext';
import { AuthContextProvider } from './context/AuthContext';


const App = () => {
    const initialState = {};
    const store = createStore(reducers, initialState);
    const reactReduxFirebaseProps = {
        firebase: firebaseApp,
        dispatch: store.dispatch,
        config: {}
    };

    getRefCodeAndSetIfPresent();

    return (
        <>
            <GlobalStyles />
            <React.StrictMode>
                <Provider store={store}>
                    <ReactReduxFirebaseProvider {...reactReduxFirebaseProps}>
                        <ModalContextProvider>
                              <AuthIsLoaded>
                                    <AuthContextProvider>
                                        <FollowingContextProvider>
                                            <Routes />
                                        </FollowingContextProvider>
                                    </AuthContextProvider>
                              </AuthIsLoaded>
                        </ModalContextProvider>
                    </ReactReduxFirebaseProvider>
                </Provider>
            </React.StrictMode>
        </>
    );
};

export default App;
