import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ApiSpace } from '../../api/ApiTypes';
import { GreyishBodyText } from '../Typography';
import ValidatedFormInput from '../inputs/ValidatedFormInput';
import { TinyButton } from '../inputs/Button';
import styled from 'styled-components/macro';
import FormErrorMessage from '../inputs/FormErrorMessage';
import { inviteUserToStation } from '../../firebase/StationInvitationService';
import { Select } from '../inputs/Select';
import { UserType } from '../../api/ApiTypes';
import InputLabel from '../inputs/InputLabel';
import { getCurrentUser } from '../../store/selectors';
import { getApi } from '../../api/api';
import { Download } from '../../utils/DownloadHelper';
import FileUploader from '../inputs/UploadFile';
import { canAdmin } from '../../permissions/users';

interface Props {
    header: string;
    icon: string;
    placeholder: string;
    tooltip: string;
    space?: ApiSpace;
}
const InviteByEmailForm: React.FC<Props> = ({ header, tooltip, icon, placeholder, space }) => {
    const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit', shouldFocusError: true });
    const user = useSelector(getCurrentUser);

    const [userEmail, setUserEmail] = useState('');
    const [sending, setSending] = useState(false);
    const [apiError, setApiError] = useState('');
    const [success, setSuccess] = useState('');
    const [userTypes, setUserTypes] = useState([] as string[]);

    //const userTypes: UserType[] = [];
    const [userType, setUserType] = React.useState('Guest' as UserType);

    const { stationId } = useParams() as any;

    useEffect(() => {
      getApi('/user/types').then(async (res) => {
        return res.json();
      }).then((aut) => {
        const activeUersTypes = Object.keys(aut).reduce((acc, key) => {
          const ut = aut[key];
          return (ut !== 'Admin' && ut !== 'Anonymous' && !acc.find((i) => i === ut))? acc.concat(ut): acc
        }, [] as string[]);

        setUserTypes(activeUersTypes);
        setUserType(activeUersTypes[0] as UserType);
      });
    }, []);


    const stationToInvite = stationId || space?.stationId;
    const sendInvite = (email: string) => {
        setApiError('');
        setSuccess('');
        setSending(true);
        inviteUserToStation({
            stationId: stationToInvite,
            spaceId: space?.id,
            userEmail: email,
            userType
        }).then((res) => {
            setSending(false);
            res.ok ? setSuccess(email) : res.json().then((res) => setApiError(res.message));
        });
    };


    const getInvitesCSV = async () => {
        const csv = await getApi(`/station/${stationToInvite}/invites/csv`).then(async (res) => {
            return await res.text();
        });
        Download.body(csv).as(`Station-Invites-${stationToInvite}.csv`);
    }

    const onCSVUploadComplete = (result: { [key: string]: any }) => {
        setSuccess(result.userEmail);
    }

    const onCSVUploadError = (e: Error) => {
        setApiError(e.message);
    };

    return (
        <Container>
            <TopSection>
                <img src={icon} alt="link icon" style={{ marginRight: '15px' }} />
                <h3>{header}</h3>
            </TopSection>
            <GreyishBodyText style={{ marginBottom: '30px' }}>{tooltip}</GreyishBodyText>
            <FormSection onSubmit={handleSubmit(() => sendInvite(userEmail))}>
                {canAdmin(user) && (
                    <SelectWrapper>
                        <InputLabel htmlFor="userTypeSelect">Select Player Type (Admin Only)</InputLabel>
                        <Select
                            onChange={(value) => setUserType(value as UserType)}
                            value={userType}
                            options={userTypes.map((uType) => ({ label: uType, value: uType }))}
                        />
                    </SelectWrapper>
                )}
                <FormControl>
                    <label htmlFor="userEmail" style={{ display: 'none' }}>
                        Email
                    </label>
                    <ValidatedFormInput
                        type="textarea"
                        className="w-input"
                        maxLength={256000}
                        name="userEmail"
                        errors={errors}
                        placeholder={placeholder}
                        validation={register({
                            required: 'Email is required',
                            pattern: {
                                value: /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/i,
                                message: 'Invalid email address'
                            }
                        })}
                        value={userEmail}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserEmail(e.target.value)}
                        style={{ margin: '0 15px 0 0', width: '300px' }}
                    />
                    <TinyButton disabled={sending} type="submit">
                        {sending ? 'Sending...' : 'Add'}
                    </TinyButton>
                </FormControl>
                <GreyishBodyText style={{ marginBottom: '30px' }}>Multiple emails must be comma separated.</GreyishBodyText>
            </FormSection>
            <div>
                {apiError && <FormErrorMessage error={apiError} />}
                {success && <label style={{ margin: '0 0 0 15px' }}>Invite sent to {success}</label>}
                {(apiError || success) && <br/>}
            </div>
            <FileUploader
                onUpload={onCSVUploadComplete}
                onError={onCSVUploadError}
                destination={`/station/${stationToInvite}/invites/csv`}
                buttonText='Upload CSV'
                context={{spaceId: space?.id, userType}}
                fileType="text/csv"
            >
                <TinyButton onClick={getInvitesCSV}>Download invite list</TinyButton>
            </FileUploader>
        </Container>
    );
};


const Container = styled.div`
    padding-left: 45px;
    margin-bottom: 45px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const TopSection = styled.div`
    display: flex;
    margin-bottom: 15px;
    width: 100%;
`;

const FormSection = styled.form`
    width: 450px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: column;
`;

const FormControl = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: baseline;
`;

const SelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export default InviteByEmailForm;
