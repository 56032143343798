import * as React from 'react';
import styled from 'styled-components/macro';
import EmbedTitle from '../components/embeds/EmbedTitle';
import ConnectionList from '../components/orbit/ConnectionList';
import HelpergonImage from '../images/help-videogon@3x.png';

const OrbitEmbed = () => {
    return (
        <OrbitEmbedWrapper>
            <EmbedTitle title="My Orbit">View people you wish to connect with</EmbedTitle>
            <ConnectionList hideBackground={true}>
                <NoConnections />
            </ConnectionList>
        </OrbitEmbedWrapper>
    );
};

const OrbitEmbedWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direct: column;
    padding-top: 10px;
    padding-left: 150px;
    padding-right: 150px;
`;

const NoConnections = () => (
    <NoConnectionsWrapper>
        <Helpergon src={HelpergonImage} />
        <NoConnectionText>
            <NoConnectionTitle>
                Your orbit is empty. Connect with someone in the space to grow your orbit.
            </NoConnectionTitle>
            <NoConnectionList>
                <li>Click on someone’s pod</li>
                <li>Click the “connect” tab on the upper right of their pod</li>
            </NoConnectionList>
        </NoConnectionText>
    </NoConnectionsWrapper>
);

const NoConnectionsWrapper = styled.div`
    display: flex;
    max-width: 750px;
    align-items: center;
    justify-content: center;
`;

const Helpergon = styled.img`
    width: 200px;
    margin-right: 50px;
`;

const NoConnectionText = styled.div`
    display: flex;
    flex-direction: column;
    color: var(--off-white);
`;

const NoConnectionTitle = styled.h1`
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: normal;
`;

const NoConnectionList = styled.ol`
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    padding: 0 0 0 16px;
    margin-top: 20px;
`;

export default OrbitEmbed;
