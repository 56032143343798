import styled from 'styled-components/macro';
import default_image from '../../images/default-profile-moose.jpg';

type ProfileNonagonProps = {
    avatarUrl?: string;
    size?: number;
};

const ProfileNonagon = styled.div<ProfileNonagonProps>`
    background-image: url('${({ avatarUrl }) => avatarUrl || default_image}');
    ${({ avatarUrl }) => (avatarUrl ? `color: transparent` : 'color: purple')};
    background-size: cover;
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    border-radius: ${({ size }) => size && size / 2}px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    text-align: center;
    clip-path: var(--nonagon);
`;

ProfileNonagon.defaultProps = {
    size: 30
};

export default ProfileNonagon;
