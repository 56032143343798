import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import DateStamp from './DateStamp';
import moment from 'moment';
import { APIDiscover } from '../../api/ApiTypes';
import { useNwrEventsContext } from './EventsContext';
import { putApi, deleteApi } from '../../api/api';
import EventCardModal from './EventCardModal';
import { getCurrentUser } from '../../store/selectors';
import { useSelector } from 'react-redux';
import { AccountFeatureModal } from '../modals/AccountFeatureModal';
import { useModal } from '../../context/ModalContext';
import { isAnon } from '../../permissions/users';

const Container = styled.div`
    position: relative;
    width: 300px;
    cursor: pointer;
    box-shadow: 1px 2px 30px 0 rgba(0, 0, 0, 0.5);
`;

const CardBody = styled.div<{textColor: string, thumbnail: string}>`
    display: flex;
    flex-direction: column-reverse;
    height: 357px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 14px;
    color: ${(props) => props.textColor};
    background-color: grey;
    background-image:
        linear-gradient(to bottom, rgba(3, 2, 23, 0) 58%, var(--midnight) 94%),
        ${(props) => `url(${props.thumbnail})`};
    background-size: cover;
    background-repeat: no-repeat;
`;

const Title = styled.div`
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 4px;
    line-height: 30px;
`;
const HostName = styled.div`
`;
// const Description = styled.div``;
const Category = styled.div``;

const InfoButton = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: var(--off-white);
`;

const CTAButton = styled.div<{extraStyles: string}>`
    height: 43px;
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    ${(props) => props.extraStyles}
`;

const JoinButtonStyles = `
    background-color: var(--tennis-green);
    color: var(--midnight);

    &:hover {
        background-color: #8fac34;
    }
`;

const PurchaseButtonStyles = `
    background-color: #ed701c;
    color: var(--off-white);

    &:hover {
        background-color: #b2541e;
    }
`;

const ReminderButtonStyles = `
    background-color: var(--purple-blue);
    color: var(--off-white);

    &:hover {
        background-color: #2f2d86;
    }
`;

const SubscribedButtonStyles = `
    content: "I'm going";
    background-color: var(--battleship-grey);
    color: var(--off-white);

    &:hover {
        content: 'Cancel Reminder';
        background-color: var(--burnt-red);
    }
`;

type EventType = 'join' | 'purchase' | 'requestReminder' | 'reminderSet';

export const isLive = (timeInSeconds: number) => {
    const now = Date.now(); // FirebaseDate.seconds is UTC seconds, while Date.now() returns in ms
    const isAfter = moment(now).isAfter(timeInSeconds * 1000);
    return isAfter;
};

const EventCTAButton = ({event, styleOverride}: {event: APIDiscover, styleOverride?: string}) => {
    const {getFreshEvents, errorSetter} = useNwrEventsContext();
    const [subscribedContent, subscribedContentSetter] = useState(`I'm going`);
    const live = isLive(event.startDateTime._seconds);
    let type: EventType = 'requestReminder';
    const user = useSelector(getCurrentUser);
    const { openModal } = useModal();

    // TODO: Use api handlers in NwrEventsContext
    const handleSetReminderClick = async () => {
        if(isAnon(user)){
            openModal({content: <AccountFeatureModal />});
        } else {
            putApi(`/discover/${event.id}/subscribe`)
                .then(async (res) => {
                    getFreshEvents();
                })
            .catch((e) => errorSetter(e));
        }
    };

    // TODO: Use api handlers in NwrEventsContext
    const handleUnsubscribeClick = async () => {
        deleteApi(`/discover/${event.id}/subscribe`)
            .catch((e) => errorSetter(e))
            .finally(() => {
                getFreshEvents();
        });
    }

    const getValidExternalLink = (url: string) => {
        if (url.startsWith('http')) {
            return url
        } else {
            return 'https://' + url;
        }
    }

    const handlePurchaseLinkClick = () => {
        if (event.purchaseLink) {
            window.open(getValidExternalLink(event.purchaseLink), '_blank');
        } else {
            console.error('[EventCard]: Missing purchase link');
        }
    }

    if (live) {
        type = 'join';
    }

    if (event.isSubscribed && !live) {
        type = 'reminderSet';
    }

    if (event.purchaseLink) {
        type = 'purchase';
    }

    switch (type) {
        case 'join' :
            return (
                <CTAButton
                    onClick={() => {
                        if (event.inviteLink) {
                            window.open(event.inviteLink, '_top');
                        } else {
                            console.error('[EventCard]: Missing invite link', event)
                        }
                    }}
                    extraStyles={JoinButtonStyles + styleOverride ?? ''}
                >Join Event</CTAButton>
            );
        case 'purchase' :
            return (
                <CTAButton
                    onClick={handlePurchaseLinkClick}
                    extraStyles={PurchaseButtonStyles + styleOverride ?? ''}
                >
                    Purchase Ticket
                </CTAButton>
            );

        case 'requestReminder' :
            return (
                <>
                    <CTAButton
                        onClick={handleSetReminderClick}
                        extraStyles={ReminderButtonStyles + styleOverride ?? ''}
                    >
                        Remind Me
                    </CTAButton>
                </>
            );
        case 'reminderSet' :
            return (
                <CTAButton
                    extraStyles={SubscribedButtonStyles + styleOverride ?? ''}
                    onMouseEnter={() => subscribedContentSetter('Cancel Reminder')}
                    onMouseLeave={() => subscribedContentSetter(`I'm going`)}
                    onClick={handleUnsubscribeClick}
                >
                    {subscribedContent}
                </CTAButton>
            );
        default :
            return <></>;
    }
};

const EventCard = ({event}: {event: APIDiscover}) => {
    const { eventId } = useParams() as any;
    const [showInfoModal, showInfoModalSetter] = useState(false);

    useEffect(() => {
        if (event.id === eventId) {
            showInfoModalSetter(true);
        }
    }, []);

    const handleModalRequest = () => {
        showInfoModalSetter(!showInfoModal);
    };

    return (
        <Container>
            <CardBody
                onClick={handleModalRequest}
                textColor={event.textColor}
                thumbnail={event.thumbnail}
            >
                <DateStamp className='noTranslate' time={event.startDateTime._seconds}/>
                {/* <div>Approval: {event.isApproved ? 'true' : 'false'}</div> */}
                {/* <Category>{event.category}</Category> */}
                {/* <div>{event.id}</div> */}
                <HostName className='noTranslate'>{event.hostName}</HostName>
                <Title className='noTranslate'>{event.title}</Title>

            </CardBody>
            <EventCTAButton
                event={event}
            />
           <EventCardModal
                event={event}
                isOpen={showInfoModal}
                handleClose={handleModalRequest}
                ctaButton={(event: APIDiscover, styleOverride: string) => {
                        return (
                            <EventCTAButton event={event} styleOverride={styleOverride} />
                        )
                    }
                }
            />
        </Container>
    );
};

export default EventCard;
