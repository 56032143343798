import React from 'react';
import styled from 'styled-components/macro';

interface Props {
    timer: string
}

const StreamSession: React.FC<Props> = ({timer}) => {

    return (
        <>
            <Session>
                <TimeElapsed className="noTranslate">{timer}</TimeElapsed>
                <Label>SESSION</Label>
            </Session>
            {/* <SessionRemaining>
                    <TimeRemaining>00:00:00</TimeRemaining>
                    <Label>TIME REMAINING</Label>
                </SessionRemaining> */}
        </>
    );

}


const Session = styled.div`
    width: 100px;
    height: 38px;
`;

const TimeElapsed = styled.div`
    width: 80px;
    height: 24px;
    margin: 0 0 2px;
    font-family: all-round-gothic;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tennis-green);
`;

const Label = styled.div`
    height: 14px;
    margin: 6px 0 0;
    font-family: all-round-gothic;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #ffffff;
`;

const SessionRemaining = styled.div`
    width: 130px;
    height: 38px;
`;

const TimeRemaining = styled.div`
    width: 80px;
    height: 24px;
    margin: 0 51px 2px 0;
    font-family: all-round-gothic;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
`;

export default StreamSession;
