import * as React from 'react';
import { createContext, useContext, useState, useEffect } from 'react';
import { useFollowingContext } from './FollowingContext';

export const FollowingDebug = () => {
    const { userFollowingList } = useFollowingContext();

    const debugRenderer = () => {
        return (
            <></>
        )
    }

    return (
        <>
            {debugRenderer()}
        </>
        );
};
