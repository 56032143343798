import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import EventCard from './EventCard';
import LoadingScreen from '../loading/LoadingScreen';
import { APIDiscover } from '../../api/ApiTypes';
import { EventCategories, useNwrEventsContext } from './EventsContext';

const Container = styled.div`
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 300px);
    gap: 20px;
    width: 100%;
`;

const NoEventsMessage = styled.div`
    font-size: 24px;
    border-radius: 5px;
    margin-top: 20px;
    padding: 20px;
    text-align: center;
    background-color: var(--purple-blue);
`;

const EventList = () => {
    const {events, error, loaded, showAllEvents, eventFilters} = useNwrEventsContext();
    const [filteredEvents, filteredEventsSetter] = useState([] as any as APIDiscover[]);

    useEffect(() => {
        filteredEventsSetter(events);
    }, []);

    useEffect(() => {
        filterList();
    }, [eventFilters, showAllEvents, events]);

    const filterList = () => {
        if (showAllEvents) {
            filteredEventsSetter(events);
        } else {
            const newList = events.filter((item) => {
                 if (eventFilters.includes(item.category as EventCategories)) return item;
            });
            filteredEventsSetter(newList);
        }
    }

    const orderList = () => {
        console.log('this should order the list');
    }

    if (!loaded) return <LoadingScreen/>;
    if (error) {
        console.error(error);
        return <NoEventsMessage style={{'cursor': 'pointer'}} onClick={() => location.reload()}>Failed to get events</NoEventsMessage>
    };

    if (!events.length) {
        return <NoEventsMessage>You are here early! Events are coming, check back soon...</NoEventsMessage>
    }

    return (
        <Container>
            {filteredEvents?.map((event: APIDiscover) => (
                <EventCard key={event.id} event={event}/>
            ))}

            {/* Single EventCard test */}
            {/* <EventCard key={events[0].id} event={events[0]}/> */}
        </Container>
    );
};

export default EventList;
