import * as React from 'react';
import { useEffect } from 'react';
import { useModal } from '../../context/ModalContext';
// import { useToast } from '../../context/ToastContext';
import { Modal } from './Modal';
import styled from 'styled-components';

export const ModalsManager = () => {
    const { modal, openModal, closeModal } = useModal();
    // const { createToast } = useToast();

    // const localPlayer = client.getCurrentPlayerProfile();
    // const displayName = localPlayer ? `${localPlayer.displayName}` : 'A Mysterious Stranger';
    // const [contextChange, contextChangeSetter] = React.useState<number>(0);

    // useEffect(() =>

    // }, []);

    if (modal.content === null || modal.content === undefined) {
        return null;
    }

    return (
        <>
            <Modal visible={true} onHidden={closeModal}>
                {modal.content}
            </Modal>
        </>
    );
};
