import * as React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components/macro';

const ModalInner = styled.div`
    position: absolute;
    opacity: 1;
    margin: auto auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: var(--white);

    @media all and (min-width: 736px) {
        width: 60%;
        height: 60%;
        padding: 10%;
    }
`;

const TransparentModalInner = styled.div`
    position: absolute;
    opacity: 1;
    margin: auto auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: var(--white);
    z-index: 51;

    @media all and (min-width: 736px) {
        width: 60%;
        height: 60%;
    }
`;

type Props = {
    isOpen: boolean;
    handleClose?: () => void;
    hidden?: boolean;
    injected?: boolean;
};

export const Modal: React.FC<Props> = ({ children, handleClose, isOpen, hidden, injected }) => {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={handleClose}
            ariaHideApp={false}
            style={{
                overlay: {
                    backgroundColor: 'var(--navy-blue)',
                    zIndex: 50,
                    maxWidth: '100vw',
                    visibility: hidden ? 'hidden' : 'visible'
                },
                content: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    border: 'none',
                    background: 'transparent',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    overflow: 'hidden'
                }
            }}
            shouldCloseOnEsc={!!handleClose}
        >
            { injected ?
                <>{children}</> :
                (<>
                    <ModalInner>{children}</ModalInner>
                    {handleClose && <CloseButton onClick={() => handleClose()} />}
                </>)
            }
        </ReactModal>
    );
};

export const TransparentModal: React.FC<Props> = ({ children, handleClose, isOpen, hidden, injected }) => {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={handleClose}
            ariaHideApp={false}
            style={{
                overlay: {
                    backgroundColor: '#3f3f50bf',
                    zIndex: 50,
                    maxWidth: '100vw',
                    visibility: hidden ? 'hidden' : 'visible'
                },
                content: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    border: 'none',
                    background: 'transparent',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    overflow: 'hidden'
                }
            }}
            shouldCloseOnEsc={!!handleClose}
        >
            {injected ?
                <>{children}</>
               : (<TransparentModalInner>
                    {children}
                    {handleClose && <TransparentModalCloseButton onClick={() => handleClose()} />}
                </TransparentModalInner>)
            }
        </ReactModal>
    );
}

const BaseCloseStyles = `
    background-color: transparent;
    z-index: 65;
    padding: 0;
    border-radius: 30px;
    transition: background-color 750ms cubic-bezier(0.165, 0.84, 0.44, 1),
        color 750ms cubic-bezier(0.165, 0.84, 0.44, 1);

    &:before,
    &:after {
        content: '';
        position: absolute;
        background-color: var(--off-white);
        border-radius: 2px;
    }

    &:hover {
        background-color: var(--indigo);

        &:before,
        &:after {
            background-color: var(--white);
        }
    }
    &:active {
        background-color: var(--purple-blue);
    }

    &:before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &:after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
`;

const CloseButton = styled.button`
    ${BaseCloseStyles}
    position: absolute;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 120px;
    right: 60px;

    &:before,
    &:after {
        width: 42px;
        height: 2px;
        top: 16px;
    }

    &:before {
        left: 9px;
        top: 28px;
    }

    &:after {
        right: 9px;
        top: 28px;
    }
`;

export const SmallCloseButtonStyles = `
    ${BaseCloseStyles}
    width: 30px;
    height: 30px;

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 26px;
        height: 2px;
        background-color: var(--off-white);
        border-radius: 2px;
    }

    &:before {
        left: 2px;
        top: 14px;
    }

    &:after {
        right: 2px;
        top: 14px;
    }

`;

const TransparentModalCloseButton = styled.button`
    ${SmallCloseButtonStyles}
`;


export const ModalButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-align: center;
    width: 100%;
`;

export const ModalHeader = styled.h1`
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.5px;
    color: var(--white);
    margin-bottom: 30px;
`;

export const ModalText = styled.div`
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: var(--white);
    margin-bottom: 50px;
    width: 100%;
    text-align: left;
`;
