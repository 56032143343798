import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'react-redux-firebase';
import { getAuthState, getCurrentUser, getCurrentUserLoaded } from '../store/selectors';
import { postApi } from '../api/api';
import { Background, LogInModal } from './LogIn';
import LoadingComponent from '../components/loading/LoadingComponent';
import Message from '../components/layouts/Message';

const AcceptHostInvite = () => {
    const { inviteHostCode } = useParams() as any;
    const [waiting, setWaiting] = React.useState(true);
    const [error, setError] = React.useState('');

    const history = useHistory();
    const { pathname } = useLocation() as any;
    const profileLoaded = useSelector(getCurrentUserLoaded);
    const currentUser = useSelector(getCurrentUser);
    const auth = useSelector(getAuthState);

    React.useEffect(() => {
        postApi('/space/acceptHostInvite', { inviteHostCode })
            .then(async (response) => {
                setWaiting(true);
                if (response.ok) {
                    const { spaceId } = await response.json();
                    history.push(`/space/${spaceId}`);
                } else {
                    setError((await response.json()).message);
                    setWaiting(false);
                }
            })
            .catch((e) => {
                console.error(e);
                setError('Host Invite failed. Please contact a Nowhere admin for help');
                setWaiting(false);
            });
    }, [inviteHostCode, history, currentUser]);

    if (!profileLoaded) {
        return <LoadingComponent />;
    }

    if (isEmpty(auth)) {
        return (
            <LogInModal redirect={pathname}>
                <Background />
            </LogInModal>
        );
    }

    if (waiting) {
        return (
            <Message title="Opening a portal ...">
                <LoadingComponent />
            </Message>
        );
    }

    if (error) return <Message title="Opening a portal ..." body={`${error}`} />;

    return <></>;
};

export default AcceptHostInvite;
