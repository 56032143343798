import * as React from 'react';
import styled from 'styled-components/macro';
import { ApiEnvironment } from '../../api/ApiTypes';
import selectCaret from '../../images/i-dropdown-arrow.svg';
import checkIcon from '../../images/check.png';

const EnvironmentTile = ({ environment, onClick, isOpen }: { environment: ApiEnvironment | undefined; onClick: () => void; isOpen: boolean; }) => {
    return (
        <EnvironmentCell environment={environment} onClick={onClick} isOpen={isOpen}>
            <Label>{environment?.displayName}</Label>
        </EnvironmentCell>
    );
};

const background_with_gradient: (color: string, url: string) => string = (color, url) => {
    return `linear-gradient(to left, rgba(10, 8, 63, 0), var(--${color}) 99%), url('${url}');`
};

const closedStyles = `
    :first-child{
        :after {
            position: absolute;
            top: 32px;
            right: 35px;
            content: url(${ selectCaret });
            font-size: 20px;
            color: var(--tennis-green);
        }
    }
`;

const openStyles = `
    :first-child{
        :before {
            position: absolute;
            top: 32px;
            left: 35px;
            content: url(${ checkIcon });
        }
    }
`;

const closedGradient = 'steel-blue';
const closedHoverGradient = 'purple-blue'
const closedLabelColor = 'tennis-green';
const openGradient = 'light-navy';
const openHoverGradient = 'tennis-green';
const openLabelColor = 'white';

const EnvironmentCell = styled.div<{ environment: ApiEnvironment | undefined; isOpen: boolean }>`
    text-transform: none;
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 var(--dark-transparent-purple);
    background-image: ${({ environment, isOpen }) =>
        isOpen ? background_with_gradient(openGradient, environment?.dropdownImageUrl?? '') : background_with_gradient(closedGradient, environment?.dropdownImageUrl?? '') }
    :hover {
        > label {
            color: var(--${({isOpen}) => isOpen ? openLabelColor : closedLabelColor});
        }
        ${({isOpen}) => isOpen ? 'border: 2px solid var(--tennis-green);' : ''}
        background-image: ${({environment, isOpen}) =>
            isOpen ? background_with_gradient(openHoverGradient, environment?.dropdownImageUrl?? '') : background_with_gradient(closedHoverGradient, environment?.dropdownImageUrl?? '') }
    }
    ${({isOpen}) => isOpen ? openStyles : closedStyles};
`;

const Label = styled.label`
    padding-left: 36px;
    text-shadow: 1px 1px 6px var(--shadow-black);
    cursor: pointer;
    margin-bottom: 0px;
`;

export default EnvironmentTile;
