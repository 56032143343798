import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';

interface TwoItemToggleProps {
    leftLabel: string;
    rightLabel: string;
    value: boolean;
    changeHandler: (value: boolean) => void;
    externalControl?: boolean;
}

interface MultiItemToggleProps {
    labels: string[];
    value: number;
    changeHandler: (value: number) => void;
    externalControl?: boolean;
}

// Overloaded types for compatibility
function LabeledToggle(props: TwoItemToggleProps): JSX.Element;
function LabeledToggle(props: MultiItemToggleProps): JSX.Element;

function LabeledToggle(props: TwoItemToggleProps | MultiItemToggleProps) {
    const [uiValue, setUiValue] = useState(props.value);

    const isMultiItem = (props: TwoItemToggleProps | MultiItemToggleProps): props is MultiItemToggleProps => 'labels' in props;

    const selectedIndex = isMultiItem(props) ? props.value : props.value ? 0 : 1;

    const onToggleClick = (index: number) => {
        if (isMultiItem(props)) {
            if (!props.externalControl) setUiValue(index);
            props.changeHandler(index);
        } else {
            const newValue = index === 0;
            if (!props.externalControl) setUiValue(newValue);
            props.changeHandler(newValue);
        }
    };

    return (
        <ToggleContainer>
            {isMultiItem(props) ? (
                props.labels.map((label, index) => (
                    <ToggleItem
                        key={index}
                        selected={props.externalControl ? selectedIndex === index : uiValue === index}
                        onClick={() => onToggleClick(index)}
                    >
                        {label}
                    </ToggleItem>
                ))
            ) : (
                <>
                    <ToggleItem
                        selected={props.externalControl ? props.value : uiValue === true}
                        onClick={() => onToggleClick(0)}
                    >
                        {props.leftLabel}
                    </ToggleItem>
                    <ToggleItem
                        selected={props.externalControl ? !props.value : uiValue === false}
                        onClick={() => onToggleClick(1)}
                    >
                        {props.rightLabel}
                    </ToggleItem>
                </>
            )}
        </ToggleContainer>
    );
}

export default LabeledToggle;

const ToggleContainer = styled.div`
    display: flex;
    width: fit-content;
    height: 30px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: bold;
    color: var(--midnight);
    background-color: var(--acanthus-grey);
`;

const ToggleItem = styled.div<{ selected: boolean }>`
    width: fit-content;
    height: 30px;
    border-radius: 20px;
    padding: 5px 20px;
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    background-color: ${({ selected }) => (selected ? 'var(--tennis-green)' : 'transparent')};
    color: ${({ selected }) => (selected ? 'var(--midnight)' : 'var(--midnight)')};
`;

