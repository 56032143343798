import * as React from 'react';
import { createContext, useContext, useState } from 'react';

type ModalOptionsType = {
    content: null | string | JSX.Element;
    isFullScreen?: boolean;
    onClose?: () => void;
};

type Props = {
    children: React.ReactNode;
};

type ModalContextType = {
    modal: ModalOptionsType;
    openModal: (options: ModalOptionsType) => void;
    closeModal: () => void;
};

const ModalContext = createContext<ModalContextType | null>(null);

export const ModalContextProvider = ({ children }: Props) => {
    const [modal, modalSetter] = useState<ModalOptionsType>({ content: null });

    const openModal = (options: ModalOptionsType) => {
        modalSetter(options);
    };

    const closeModal = () => {
        if (modal.onClose) {
            modal.onClose();
        }
        modalSetter({ content: null });
    };

    return <ModalContext.Provider value={{ modal, openModal, closeModal }}>{children}</ModalContext.Provider>;
};

export const useModal = () => {
    const context = useContext(ModalContext);

    if (!context) throw new Error('ModalContext must be called from within the ModalContextProvider');

    return context;
};
