import * as React from 'react';
import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import useInterval from '../../hooks/useInterval';
import { getApi, putApi } from '../../api/api';
import Toggle from '../inputs/Toggle';
import { GreyishBodyText } from '../Typography';


interface Props {
    spaceId: string;
}

const PauseNewUserAccessForm: React.FC<Props> = ({ spaceId }) => {
    const [isPaused, setIsPaused] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [isNotPaused, setIsNotPaused] = useState(false);

    const apiRoute = `/space/${spaceId}/newUserAccessStatus`;

    const setPausedStatus = (isPausedStatus: boolean) => {
        setIsPaused(isPausedStatus);
        setIsNotPaused(!isPausedStatus);
    }

    const getNewUserAccessStatus = () => {
        getApi(`/space/${spaceId}`).then(async (nuas) => {
            const space = await nuas.json();
            const status = space?.space?.userAccessRestictions?.newUserAccess?.isPaused? true: false;
            if(!updating) setPausedStatus(status);
        }).catch((e: Error) => {
            console.log("Could not get newUserAccessStatus with error: ", e);
        });
    }

    const handleClick = (value: boolean) => {
        setUpdating(true)
        setPausedStatus(!value);
        putApi(apiRoute, { isPaused: !value }).then(async (res) => {
            const status = await res.json();
            setPausedStatus(status.isNewUserAccessPaused);
            setUpdating(false);
        }).catch((e: Error) => {
            console.log("Could not set newUserAccessStatus with error: ", e);
            setUpdating(false);
        });
    };

    useEffect(() => {
        getNewUserAccessStatus();
    }, [spaceId]);

    useInterval(() => {
        getNewUserAccessStatus();
    }, 5000);

    return (
        <Container>
            <NewUserAccessPauseToggleContainer>
                <TopSection>
                    <p>Pause Access For New Players</p>
                </TopSection>
                <GreyishBodyText style={{ marginBottom: '30px' }}>When paused, the invite link is temporarily disabled, no new players can enter the space, but people who have entered your station before can enter. Paused state only lasts until the end of the current session or the next session. Existing invite link will still work when reactivated.</GreyishBodyText>
                <ToggleContainer style={{ width: (isPaused? '460px': '205px') }}>
                    <Toggle value={isNotPaused} onChange={handleClick} />
                    <PauseText>{isPaused? 'No new access till the end of session': 'Invite Active'}</PauseText>
               </ToggleContainer>
            </NewUserAccessPauseToggleContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 45px;
    margin-bottom: 55px;
    margin-top: -25px;
`;

const NewUserAccessPauseToggleContainer = styled.div`
`;

const ToggleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 460px;
    max-width: 900px;
`;

const TopSection = styled.div`
    display: flex;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
`;

const PauseText = styled.div`
    font-size: 18px;
    letter-spacing: 0.33px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 5px;
    margin-left: 15px;
    float: left;
`;

export default PauseNewUserAccessForm;
