import * as React from 'react';
import ValidatedFormInput from '../inputs/ValidatedFormInput';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { AuthButton } from '../inputs/Button';
import styled from 'styled-components/macro';
import { ApiStation, ApiStationEditableMetadata } from '../../api/ApiTypes';
import UploadImage from '../inputs/UploadImage';
import { GreyishBodyText, WhiteLinkText } from '../Typography';
import { useHistory } from 'react-router-dom';
import { OpenCheckbox } from '../inputs/Checkbox';
import { getCurrentUserIsAdmin, getCurrentUser } from '../../store/selectors';
import { useSelector } from 'react-redux';
import { Select } from '../inputs/Select';
import InputLabel from '../inputs/InputLabel';
import Divider from '../Divider';
import ReactTooltip from 'react-tooltip';
import InfoIcon from '../../images/atom-i-info-alt.svg';
import { hasOwnerRole as isStationOwner, canAccessAdvancedSettings as caasStaion, canMemberEditSpaces } from '../../permissions/stations';
import { canAccessAdvancedSettings as caasUser } from '../../permissions/users';

interface StationFormProps {
    onSubmit: (stationData: ApiStationEditableMetadata) => Promise<any>;
    initialStationData?: ApiStation;
    edit?: boolean;
}
const StationForm: React.FC<StationFormProps> = ({ onSubmit, initialStationData, edit }) => {
    const isAdmin = useSelector(getCurrentUserIsAdmin);
    const curUser = useSelector(getCurrentUser);

    const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit', shouldFocusError: true });
    const [uploading, setUploading] = useState(false);
    const [station, setStation] = useState({
        description: initialStationData?.description,
        subtitle: initialStationData?.subtitle,
        title: initialStationData?.title,
        imageUrl: initialStationData?.imageUrl,
        isPublic: false,
        isGuestAbleToInvite: (edit)? initialStationData?.isGuestAbleToInvite: true,
        isSpectatorAbleToInvite: (edit)? initialStationData?.isSpectatorAbleToInvite: true,
        virtualComputerTypeOverride: initialStationData?.virtualComputerTypeOverride?? 'DEFAULT',
        isSpaceOwnerEditOnly: (initialStationData?.isSpaceOwnerEditOnly?? false),
        wallets: initialStationData?.wallets?? { eos: '' },
    } as ApiStationEditableMetadata);
    const history = useHistory();
    const [submitting, setSubmitting] = useState(false);

    const canAccessAdvancedSettings = () => {
      return (edit && initialStationData?.id)? caasStaion(initialStationData): caasUser(curUser);
    };

    const hasOwnerRole = () => {
       return (edit && initialStationData?.id)? isStationOwner(initialStationData, curUser): caasUser(curUser);
    };



    const submitFormData = () => {
        setSubmitting(true);
        onSubmit(station).then(() => setSubmitting(false));
    };

    const vcOverrideSelect: { label: string; value: "DEFAULT" | "FREE" | "PREMIUM" | "ADMIN";  }[] = [
      { label: 'No Override', value: "DEFAULT" },
      { label: 'Free', value: 'FREE' },
      { label: 'Premium', value: 'PREMIUM' },
      { label: 'Admin', value: 'ADMIN' }
    ];

    return (
        <>
            <form className="d--f jc--sa" onSubmit={handleSubmit(submitFormData)}>
                <FormColumns>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <UploadImage
                            onUpload={(u: string) =>
                                setStation({
                                    ...station,
                                    imageUrl: u
                                })
                            }
                            existingImage={station.imageUrl}
                            uploading={uploading}
                            setUploading={setUploading}
                        />
                        <SuggestedDimensionText>
                            Suggested dimensions 1200px x 800px
                            <br />
                            with a centered safe area of 700 x 330
                        </SuggestedDimensionText>

                        { hasOwnerRole() && <FeaturedCheckboxContainer>
                            <FeaturedCheckboxLabel htmlFor="isSpaceOwnerEditOnly">
                                Restrict edit access: Owner only.
                                <ReactTooltip effect="solid" />
                                <InactiveInfo data-tip="Disables access to advanced settings & environment changes for Managers and Hosts." src={InfoIcon} />
                            </FeaturedCheckboxLabel>
                            <OpenCheckbox
                                errors={errors}
                                name="isSpaceOwnerEditOnly"
                                checked={station.isSpaceOwnerEditOnly? true: false}
                                onChange={() => setStation({ ...station, isSpaceOwnerEditOnly: !station.isSpaceOwnerEditOnly })}
                            />
                        </FeaturedCheckboxContainer>
                       }
                    </div>
                    <div style={{ width: '480px' }}>
                        <label htmlFor="Title">Station Title* (24 characters)</label>
                        <ValidatedFormInput
                            errors={errors}
                            maxLength={24}
                            name="title"
                            onChange={(e: any) => setStation({ ...station, title: e.target.value })}
                            type="textarea"
                            style={{ height: '85px' }}
                            validation={register({ required: 'Station title is required' })}
                            value={station.title}
                        />
                        <label htmlFor="Station Subtitle">Hosted By (or Subtitle)*</label>
                        <ValidatedFormInput
                            errors={errors}
                            maxLength={85}
                            name="subtitle"
                            onChange={(e: any) => setStation({ ...station, subtitle: e.target.value })}
                            type="text"
                            validation={register({ required: 'Station subtitle is required' })}
                            value={station.subtitle}
                        />
                        <label htmlFor="Full Description">Description</label>
                        <ValidatedFormInput
                            errors={errors}
                            name="Full Description"
                            onChange={(e: any) => setStation({ ...station, description: e.target.value })}
                            type="textarea"
                            style={{ height: '275px' }}
                            validation={register({})}
                            value={station.description}
                        />

                        { canMemberEditSpaces(initialStationData, curUser) && <>
                          <Divider />
                          <h4>Guest Invite Access</h4>
                          <Divider />

                          <CheckboxWrapper>
                              <OpenCheckbox
                                  errors={errors}
                                  name="isGuestAbleToInvite"
                                  checked={station.isGuestAbleToInvite? true: false}
                                  onChange={() => setStation({ ...station, isGuestAbleToInvite: !station.isGuestAbleToInvite })}
                              />
                              <label htmlFor="isGuestAbleToInvite">
                                  Allow Guests invites
                                  <ReactTooltip effect="solid" />
                                  <InactiveInfo data-tip="Allows anyone to invite others to your Spaces" src={InfoIcon} />
                              </label>
                          </CheckboxWrapper>
                        </> }

                        { canAccessAdvancedSettings() && (
                          <>
                            <CheckboxWrapper>
                                <OpenCheckbox
                                    errors={errors}
                                    name="isSpectatorAbleToInvite"
                                    checked={station.isSpectatorAbleToInvite? true: false}
                                    onChange={() => setStation({ ...station, isSpectatorAbleToInvite: !station.isSpectatorAbleToInvite })}
                                />
                                <label htmlFor="isGuestAbleToInvite">
                                    Allow Spectator invites
                                    <ReactTooltip effect="solid" />
                                    <InactiveInfo data-tip="Allows anyone to invite Spectators, view only players, see Space edit page to configure" src={InfoIcon} />
                                </label>
                            </CheckboxWrapper>
                            {hasOwnerRole() && (
                              <>
                                <Divider />
                                <h4>Receive Payments In Crypto</h4>
                                <Divider />

                                <label htmlFor="EOSID">EOS ID</label>
                                <ValidatedFormInput
                                    errors={errors}
                                    maxLength={255}
                                    name="EOSID"
                                    onChange={(e: any) => setStation({ ...station, wallets: { ...(station?.wallets?? {}), ...{ 'eos': e.target.value } }})}
                                    type="text"
                                    validation={register({ })}
                                    value={station?.wallets?.eos?? ''}
                                />
                              </>
                            )}
                            </>
                        )}

                        { isAdmin? (
                          <>
                            <Divider />
                            <InputLabel htmlFor="virtualComputerTypeOverrideSelect">Virtual Computer Type Override</InputLabel>
                            <Select
                              onChange={(value) => setStation({ ...station,  virtualComputerTypeOverride: value as undefined | "DEFAULT" | "FREE" | "PREMIUM" | "ADMIN"})}
                              value={station?.virtualComputerTypeOverride?? 'DEFAULT'}
                              options={vcOverrideSelect}
                            />
                          </>
                        ): null }
                        <ButtonContainer>
                            <WhiteLinkText onClick={() => history.goBack()}>Cancel</WhiteLinkText>
                            <AuthButton id="signupButton" disabled={uploading || submitting}>
                                {uploading ? 'Uploading image....' : edit ? 'Update Station' : 'Create Station'}
                            </AuthButton>
                        </ButtonContainer>
                    </div>
                </FormColumns>
            </form>
        </>
    );
};

const FormColumns = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0 60px;
    > * {
        margin-bottom: 80px;
    }
    @media (min-width: 1024px) {
        flex-direction: row;
        > * {
            &:not(:last-child) {
                margin-right: 60px;
            }
        }
    }
`;

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;

    label {
        margin-bottom: 0;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin-top: 15px;
    > * {
        margin-right: 60px;
    }
`;

const SuggestedDimensionText = styled(GreyishBodyText)`
    display: flex;
    align-items: flex-end;
    text-align: right;
    margin-right: 20px;
    font-size: 16px;
`;

const InactiveInfo = styled.img`
    margin-left: 10px;
    margin-top: -4px;
    fill: #ffffff;
`;

const FeaturedCheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-top: 25px;
    margin-bottom: 20px;
`;

const FeaturedCheckboxLabel = styled.label`
    margin-top: 8px;
    margin-right: 10px;
`;

export default StationForm;
