import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { SmallCloseButtonStyles } from '../layouts/Modal';
import {
    ModalWrapper,
    ModalTitle,
    ModalDescription,
    ModalAction,
    ActionButtonPrimary,
    Cancel,
} from './Modal';
import { useModal } from '../../context/ModalContext';

export const AccountFeatureModal = () => {

    const { closeModal } = useModal();
    const history = useHistory();
    const [refreshPage, setRefreshPage] = useState<boolean>(false);

    const goToSignUp = () => {
        if (window.location.href.indexOf("embed") > -1){
            window.open('/sign-up?returnToGame=true', '_blank');
            setRefreshPage(true);
        } else {
            history.push('/sign-up');
            closeModal();
        }
    }

    const handleRefreshPage = () => {
        setRefreshPage(false);
        location.reload();
    }

    const handleCancel = () => {
        closeModal();
    };

    if(refreshPage){
        return (
            <ModalWrapper>
                <ModalTitle>Please refresh this pag</ModalTitle>
                <ModalDescription>
                    Once you log in or create an account click refresh this page to continue.
                </ModalDescription>
                <ModalAction>
                    <ActionButtonPrimary onClick={handleRefreshPage}>Refresh Page Now</ActionButtonPrimary>
                </ModalAction>
            </ModalWrapper>
        );
    }

    return (
        <ModalWrapper>
            <ModalTitle>Please create an account</ModalTitle>
            <ModalDescription>
                You&apos;ll need to log in or create an account in order to use this feature.
            </ModalDescription>
            <ModalAction>
                <ActionButtonPrimary onClick={goToSignUp}>Create an Account</ActionButtonPrimary>
                <Cancel onClick={handleCancel}>Maybe later</Cancel>
            </ModalAction>
        </ModalWrapper>
    );
}
