import * as React from 'react';
import { createContext, useContext, useState, useEffect } from 'react';
import { getApi } from '../../api/api';
import { APIUserFollowing } from '../../api/ApiTypes';
import { useSelector } from 'react-redux';
import { getAuthState } from '../../store/selectors';
import { isEmpty } from 'react-redux-firebase';
import { useAuthContext } from '../../context/AuthContext';


type Props = {
    children: React.ReactNode;
};

type FollowingContextType = {
    error: any,
    errorSetter: React.Dispatch<React.SetStateAction<any>>,
    userFollowingList: APIUserFollowing,
    getFollowingList: () => void,
};

const FollowingContext = createContext<FollowingContextType | null>(null);

export const FollowingContextProvider = ({ children }: Props) => {
    const [error, errorSetter] = useState<any | null>(null);
    const [userFollowingList, userFollowingListSetter] = useState<APIUserFollowing>({stations: [], users: []});
    const auth = useAuthContext();

    useEffect(() => {
        if (!isEmpty(auth)) {
            getFollowingList();
        }
    }, [auth]);

    const getFollowingList = async () => {
        getApi('/user/me/following').then(async (response) => {
            try{
                const res = (await response.json() as APIUserFollowing);
                console.log('userFollowingList: ', res);
                userFollowingListSetter(res);
            } catch(e) {
                console.warn('Could not retrieve following data.', e);
            }
        });
    }

    return (
            <FollowingContext.Provider
                value={{
                    error,
                    errorSetter,
                    userFollowingList,
                    getFollowingList,
                }}
            >
                {children}
            </FollowingContext.Provider>
        );
};

export const useFollowingContext = () => {
    const context = useContext(FollowingContext);

    if (!context) throw new Error('FollowingContext must be called from within the FollowingContextProvider');

    return context;
};
