import Cookies from 'js-cookie';

export const writeSpaceControlState = (spaceId: string, params: string) => {
    const baseDomain = `${window.location.host.split(":")[0]}`;
    const domain = ((baseDomain !== 'localhost')? ".": "") + baseDomain;
    const expireAfter = new Date(); expireAfter.setDate(expireAfter.getDate() + 1);

    if(params.startsWith('?')) params = params.replace("?", "");

    //We need to remove space control state cookies -
    //when we have to many old ones. -
    //This prevents hitting the cookie data limit per site.
    const scs = getAllSpaceCookies();
    if(scs.length > 3) {
        //We will find and remove the oldest cookie -
        //which is not the same space as this one.
        const cookieName = scs.find((c) =>  c !== spaceId)!;
        deleteSpaceCookie(cookieName, domain);
    }

    const cookieData =
        spaceId
            + "=" + params + "; domain="
            + domain + "; expires="
            + expireAfter + "; path=/";

    document.cookie = cookieData;
};

export const getAllSpaceCookies = () => {
    return document.cookie.split(';').reduce((acc, item)=> {
        const name = item.split("=")[0].trim();
        return (name.length === 20 && !( !/^[a-zA-Z0-9]+$/.test(name)))? acc.concat([name]): acc;
    },[] as string[]);
}

export const deleteSpaceCookie = (cookieName: string, domain: string) => {
    document.cookie = cookieName + "=" + "; path=/; domain="+ domain + "; expires=Thu, 01 Jan 1970 00:00:01 GMT";
}

export const write3DAccessableCookie = (name: string, value: string, expire: Date) => {
  const baseDomain = `${window.location.host.split(":")[0]}`;
  const domain = ((baseDomain !== 'localhost')? ".": "") + baseDomain;
  const expireAfter = expire;

  const cookieData = `${name}=${value}; domain=${domain}; expires=${expireAfter.toUTCString()}; path=/`;
  document.cookie = cookieData;
}

export const getCookie = (name: string): string => {
  const escape = (s: string) => { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); };
  const match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
  return match ? match[1] : '';
}

export const languagePrefrenceCookieName = 'UserLanguagePrefrence';

export const isCrossDomainCookieAccessEnabled = () => {
  try {
    return typeof window.localStorage === 'object'
  } catch(e) {
    return false;
  }
}
