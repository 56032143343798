import * as React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { getAuthError } from '../../store/selectors';
import UploadProfilePic, { doImageUpload } from '../../components/orbit/UploadProfilePic';
import ValidatedFormInput from '../../components/inputs/ValidatedFormInput';
import { StickyButton } from '../../components/inputs/Button';
import InputLabel from '../../components/inputs/InputLabel';
import { OutlinedInput } from '../../components/inputs/OutlinedInput';
import FormErrorMessage from '../../components/inputs/FormErrorMessage';
import { OpenCheckbox } from '../../components/inputs/Checkbox';
import { v4 as uuidv4 } from 'uuid';
import { getApiBaseUrl } from '../../api/api';

type SignUpProps = {
    onSubmit: (userProfile: Record<string, any>, password: string, setSubmitError: (error: string) => void) => void;
};

const SignUpByEmailFormDevBackdoor = ({ onSubmit }: SignUpProps) => {
    const authError = useSelector(getAuthError);
    const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit', shouldFocusError: true });

    const [email, setEmail] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [croppedAvatar, setCroppedAvatar] = React.useState(null as any);
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [privacyPolicy, setPrivacyPolicy] = React.useState(false as any);
    const [submitError, setSubmitError] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);

    const togglePrivacyPolicyAcceptance = () => setPrivacyPolicy(!privacyPolicy);

    const handleError = (error: string) => {
        console.log('handleError');
        setSubmitting(false);
        setSubmitError(error);
    };

    const submitEmailPassword = async () => {
        setSubmitting(true);
        setSubmitError('');
        const avatarUrl = await doImageUpload(croppedAvatar);

        const userProfile: any = { email, firstName, lastName };
        if (avatarUrl) {
            userProfile.avatarUrl = avatarUrl;
        }

        onSubmit(userProfile, password, handleError);
    };

    return (
        <Container>
            <UploadProfilePic onImageChanged={setCroppedAvatar} />
            <div className="d--f fd--c ai--c">
                <form
                    id="signup-Form"
                    name="wf-form-signup-Form"
                    data-name="signup Form"
                    className="form-2"
                    onSubmit={handleSubmit(submitEmailPassword)}
                    style={{ width: '100%', maxWidth: '510px' }}
                >
                    <NameContainer className="d--f">
                        <div>
                            <InputLabel htmlFor="firstName">First Name *</InputLabel>
                            <ValidatedFormInput
                                type="text"
                                maxLength={256}
                                name="firstName"
                                errors={errors}
                                validation={register({ required: 'First name is required' })}
                                value={firstName}
                                onChange={(e: any) => setFirstName(e.target.value)}
                                style={{ width: '200px', marginRight: '15px' }}
                            />
                        </div>
                        <div>
                            <InputLabel htmlFor="lastName">Last Name *</InputLabel>
                            <ValidatedFormInput
                                type="text"
                                maxLength={256}
                                name="lastName"
                                errors={errors}
                                validation={register({ required: 'Last name is required' })}
                                value={lastName}
                                onChange={(e: any) => setLastName(e.target.value)}
                                style={{ width: '295px' }}
                            />
                        </div>
                    </NameContainer>
                    <InputLabel htmlFor="signupEmail-2">Email *</InputLabel>
                    <ValidatedFormInput
                        type="email"
                        maxLength={256}
                        name="email"
                        errors={errors}
                        validation={register({
                            required: 'Email is required',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address'
                            }
                        })}
                        value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                        style={{ width: '510px' }}
                    />
                    <InputLabel htmlFor="loginPassword-2">Password *</InputLabel>
                    <OutlinedInput
                        type="password"
                        maxLength={256}
                        name="password"
                        data-name="loginPassword"
                        id="loginPassword"
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        style={{ width: '510px', marginBottom: '10px' }}
                    />
                    <ValidatedFormInput
                        type="password"
                        maxLength={256}
                        name="password"
                        validation={register({
                            required: 'Please confirm your password',
                            minLength: {
                                value: 8,
                                message: 'Password must be at least 8 characters'
                            },
                            validate: () => confirmPassword === password || 'Passwords must match'
                        })}
                        data-name="confirmLoginPassword"
                        id="confirmLoginPassword"
                        errors={errors}
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e: any) => setConfirmPassword(e.target.value)}
                        style={{ width: '510px' }}
                    />

                    <br/>

                    <div style={{ display: 'flex' }}>
                        <OpenCheckbox
                            validation={register({
                                validate: () => {
                                    console.log('privacyPolicy', privacyPolicy);
                                    return privacyPolicy || 'You must agree to the privacy policy';
                                }
                            })}
                            errors={errors}
                            name="privacy"
                            checked={privacyPolicy}
                            onChange={togglePrivacyPolicyAcceptance}
                        >
                            I accept NWR&apos;s{' '}
                            <a
                                href="https://urnowhere.com/privacy-policy"
                                style={{ textDecoration: 'underline' }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>{' '}
                            which treats everyone like guests in our home.
                        </OpenCheckbox>
                    </div>
                    {errors?.['privacy']?.message && <FormErrorMessage error={errors?.['privacy']?.message} />}

                    <div className="d--f jc--fe" onClick={handleSubmit(submitEmailPassword)}>
                        {<FormErrorMessage>{authError?.message || submitError}</FormErrorMessage>}
                        <StickyButton disabled={submitting}>Submit</StickyButton>
                    </div>
                </form>
            </div>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    @media (min-width: 768px) {
        flex-direction: row;
        margin: 0 auto;
    }
`;
const NameContainer = styled.div`
    max-width: 100px;
`;

export default SignUpByEmailFormDevBackdoor;
