import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getApi } from '../api/api';
import { ApiSpace } from '../api/ApiTypes';
import styled from 'styled-components/macro';
import { LargeButton } from '../components/inputs/Button';
import UserAgentHelper from '../utils/UserAgentHelper';
import { translationHelper } from '../utils/TanslationHelper';
import logo from '../images/logo.svg';

const SpaceEntrySplash = () => {
    const history = useHistory();
    const { meetInviteCode } = useParams() as any;
    const query = new URLSearchParams(document.location.search);
    const userLang = query.get('lang') || '';
    const [ spaceImg, setSpaceImage ] = useState('../images/secretCitadel_2.effectsResult_faces-p-1080.jpg');

    const [ spaceTitle, setSpaceTitle ] = useState('');
    const [ spaceSubtitle, setSpaceSubtitle ] = useState('');
    const [ publicSpaceInfo, setPublicSpaceInfo ] = useState({} as Partial<ApiSpace>);
    const [ isMobile, setIsMobile ] = useState(false);
    const [ isReady, setIsReady ] = useState(false);
    const hasSubtitle = spaceSubtitle !== '';

    const poweredByURL = 'https://nowhere.io';

  useEffect(() => {
      setIsMobile(UserAgentHelper.isMobile());
      if(userLang) translationHelper.translate(userLang);
    }, []);

    useEffect(() => {
        setIsReady(false);
        if(!meetInviteCode) return;
        getApi(`/public/info/space/meetInviteCode/${meetInviteCode}`).then(async (res) => {
            setPublicSpaceInfo((await res.json()) as  ApiSpace);
        }).catch((e: Error) => console.log('Could not get public space info with error: ', e));
    }, [meetInviteCode]);

    useEffect(() => {
      if(!publicSpaceInfo.imageUrl) return;
      setSpaceImage(publicSpaceInfo.imageUrl);
      setSpaceTitle(publicSpaceInfo.title ?? '');
      setSpaceSubtitle(publicSpaceInfo.subtitle ?? '');
      setIsReady(true);
    }, [publicSpaceInfo])

    const startExperience = () => {
       history.push(`/embedNowhere/${meetInviteCode}?${query.toString()}`);
    }

    if (!isReady) return null;
    return (
        <Container img={spaceImg}>
            <Content>
                <Modal isMobile={isMobile}>
                    <Text isMobile={isMobile} className="noTranslate">{spaceTitle}</Text>
                    <HLine hasSubtitle={hasSubtitle} className="noTranslate"></HLine>
                    {spaceSubtitle && <SubText isMobile={isMobile} className="noTranslate">{spaceSubtitle}</SubText>}
                    <StyledLargeButton onClick={() => startExperience()} green={true} reverseColor={true}>{"Start Experience"}</StyledLargeButton>
                </Modal>
            </Content>
            <PoweredBy href={poweredByURL}>
                <PoweredByCopy>Powered by</PoweredByCopy>
                <NWRLogo />
            </PoweredBy>
        </Container>
    );
};

const Container = styled.article<{img: string}>`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: all-round-gothic, sans-serif;

    background-image: url(${(props) => props.img});
    background-size: cover;
    background-position: center center;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 50px;

    background-color: black;
`;

const Modal = styled.div<{ isMobile: boolean }>`
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 50px;
    flex-direction: column;
    align-items: flex-start;
    max-width: 720px;

    border-radius: 15px;
    background-color: rgba(0,0,0,0.7);
    box-shadow: 1px 2px 30px 0 rgba(0, 0, 0, 0.5);
    z-index: 999;

    @media (max-width: 960px) {
        max-width: calc(100% - 10px);
        padding: 40px 20px;
    }

    // ${({ isMobile }) => (isMobile ? 'height: 50vh;  width: 80vw;' : '  height: 423px;  width: 768px;')};
`;

const Text = styled.h2<{ isMobile: boolean }>`
    margin-bottom: 15px;

    font-size: 80px;
    font-family: all-round-gothic,sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #fff;

    @media (max-width: 960px) {
        font-size: 50px;
    }
`;

const HLine = styled.hr<{hasSubtitle: boolean}>`
    width: 100%;
    height: 2px;
    margin: 8px 0px;

    color: var(--tennis-green);
    background-color: var(--tennis-green);
    border: none;

    ${(props) => props.hasSubtitle ? '' : 'margin-bottom: 40px;'}
`;

const SubText = styled.h2<{ isMobile: boolean }>`
    margin-top: 10px;
    margin-bottom: 25px;

    font-size: 14px;
    text-shadow: 3px 3px 18px rgba(0, 0, 0, 0.9);
    font-family: all-round-gothic, sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 0.5px;
    text-align: left;

    @media (max-width: 960px) {
    }
`;

const StyledLargeButton = styled(LargeButton)`
`;

const PoweredBy = styled.a`
    position: absolute;
    bottom: 20px;
    left: 10px;

    display: flex;
    flex-direction: column;

    cursor: pointer;
    text-decoration: none;

    &:hover {
        opacity: 0.75;
    }
`;

const PoweredByCopy = styled.div`
    margin-left: 10px;
    color: white;
`;

const NWRLogo = styled.div`
    width: 125px;
    height: 19px;
    background-image: url(${logo});
    background-size: cover;
    background-position: left;
`

export default SpaceEntrySplash;


