import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import FormErrorMessage from './FormErrorMessage';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { FieldError } from 'react-hook-form';

const StyledSelect = styled.select`
    width: 50%;
    max-width: 314px;
    height: 36px;
    margin-bottom: 15px;
    padding-left: 10px;
    border-radius: 5px;
    background-color: var(--steel-blue);
`;

const StyledOption = styled.option`
`;

type SelectProps = {
    register: any,
    options: OptionType[],
    name: string,
    onChange: (value: string) => void,
    placeholder?: string,
    placeholderValue?: string,
    validation?: any,
    errors?: DeepMap<Record<string, any>, FieldError>
};

export interface OptionType {
    value: string,
    label: string
}

const Select = ({ register, options, name, onChange, validation, errors, placeholder, placeholderValue, ...rest }: SelectProps) => {
    return (
        <>
            <StyledSelect name={name} {...register(name)} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} validation={validation} {...rest} ref={validation}>
                <StyledOption value={placeholderValue} hidden={true}>{placeholder}</StyledOption>
                {options.map((option: OptionType) => (
                    <StyledOption key={option.value} value={option.value} disabled={option.value === ''} hidden={option.value === ''}>
                        {option.label}
                    </StyledOption>
                ))}
            </StyledSelect>
            {errors?.[name]?.message && <FormErrorMessage error={errors?.[name]?.message} />}
        </>
    );
};

export default Select;
