import { SmallLabelText } from './Typography';
import * as React from 'react';
import styled from 'styled-components/macro';

interface Props {
    label: string;
    style?: any;
    innerStyle?: any;
    noWrap?: boolean;
}

const HelpText: React.FC<Props> = ({ label, style, innerStyle, noWrap }) => {
    const [hidden, setHidden] = React.useState(false);

    if (hidden) return null;

    return (
        <OuterContainer
            style={style}
            onClick={(event) => {
                event.stopPropagation();
                setHidden(true);
            }}
        >
            <InnerContainer
                style={innerStyle}
            >
                <TextContainer
                    noWrap={noWrap}
                >
                    <StyledSmallLabelText>{label}</StyledSmallLabelText>
                </TextContainer>
                <PointerArrow/>
            </InnerContainer>
        </OuterContainer>
    );
};

const OuterContainer = styled.div`
    display: none;
    position: absolute;
    cursor: pointer;

    @media (min-width: 980px) {
        display: initial;
    }
`;

const InnerContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-around;
    right: 10px;
`;

const TextContainer = styled.div.attrs((props: {noWrap: boolean | undefined}) => props)`
    position: relative;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--tennis-green);
    white-space: ${props => props.noWrap ? 'nowrap' : 'normal'};
`;

const StyledSmallLabelText = styled(SmallLabelText)`
    position: relative;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: var(--midnight);
`;

const PointerArrow = styled.div`
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    border-top: 10px solid transparent;
    border-left: 10px solid var(--tennis-green);
    border-bottom: 10px solid transparent;
`;

export default HelpText;
