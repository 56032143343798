import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { StyledSmallButton } from '../inputs/Button';
import { Select } from '../inputs/Select';
import { EventCategories, useNwrEventsContext } from './EventsContext';

const FilterButton = ({ filterName, active, onClick }: { filterName: EventCategories | 'All', active: boolean, onClick: () => void }) => {
    return (
        <StyledFilterButton active={active} onClick={onClick}>{filterName}</StyledFilterButton>
    )
};

const FilterManager = () => {
    const { showAllEvents, showAllEventsSetter, eventFilters, eventFiltersSetter } = useNwrEventsContext();
    const [showsActive, showsActiveSetter] = useState(false);
    const [talksActive, talksActiveSetter] = useState(false);
    const [hangsActive, hangsActiveSetter] = useState(false);
    const [otherActive, otherActiveSetter] = useState(false);

    const eventFiltersCopy = eventFilters;

    useEffect(() => {
        if (showAllEvents && eventFilters.length) {
            eventFiltersSetter([]);
        }
        showsActiveSetter(eventFilters.includes('Shows'));
        talksActiveSetter(eventFilters.includes('Talks'));
        hangsActiveSetter(eventFilters.includes('Hangs'));
        otherActiveSetter(eventFilters.includes('Other'));
    }, [eventFilters, showAllEvents]);

    const allClick = () => {
        eventFiltersSetter([]);
        showAllEventsSetter(true);
    };

    const addSelection = (selection: EventCategories) => {
        if (!eventFiltersCopy.includes(selection)) {
            eventFiltersCopy.push(selection);
            showAllEventsSetter(false);
            eventFiltersSetter(eventFiltersCopy => [...eventFiltersCopy]);
        }
    };

    const removeSelection = (selection: EventCategories) => {
        const newList = eventFilters.filter((item) => {
            if (item !== selection) return item;
        });
        eventFiltersSetter(newList);

        if (newList.length === 0) {
            showAllEventsSetter(true);
        }
    }

    return (
        <HeaderButtonContainer>
                <FilterButton
                    filterName='All'
                    active={showAllEvents}
                    onClick={allClick}
                />
                <FilterButton
                    filterName='Shows'
                    active={showsActive}
                    onClick={() => {
                        if (showsActive) {
                            removeSelection('Shows');
                        } else {
                            addSelection('Shows');
                        }

                    }}
                />
                <FilterButton
                    filterName='Talks'
                    active={talksActive}
                    onClick={() => {
                        if (talksActive) {
                            removeSelection('Talks');
                        } else {
                            addSelection('Talks');
                        }

                    }}
                />
                <FilterButton
                    filterName='Hangs'
                    active={hangsActive}
                    onClick={() => {
                        if (hangsActive) {
                            removeSelection('Hangs');
                        } else {
                            addSelection('Hangs');
                        }
                    }}
                />
                <FilterButton
                    filterName='Other'
                    active={otherActive}
                    onClick={() => {
                        if (otherActive) {
                            removeSelection('Other');
                        } else {
                            addSelection('Other');
                        }
                    }}
                />
        </HeaderButtonContainer>
    )
};

const EventsHeader = () => {
    const { toggleDisplayCreateModalSetter, updateCache, isAdmin } = useNwrEventsContext();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // TODO: Use real data
    // const selectOptions: OptionType[] = [
    //     {label: 'Sort By: Date', value: 'date'},
    //     {label: 'DonkeyKong', value: 'donkeyKong'},
    //     {label: 'MathewDamoan', value: 'matdamon'}
    // ];

    return (
        <>
            <HeaderContainer>
                <HeaderLeftContainer>
                    <HeaderTitle>Events</HeaderTitle>
                    <HeaderSubTitle>Find events, galleries, and experiences to explore. Create your own. Meet friends.</HeaderSubTitle>
                    <FilterManager />
                </HeaderLeftContainer>
                <HeaderRightContainer>
                    <CreateEventButton
                        onClick={() => toggleDisplayCreateModalSetter(true)}
                    >
                        Create Public Event
                    </CreateEventButton>
                    {isAdmin && (<AdminUpdateCache
                        onClick={() => updateCache()}
                    >
                        Update Cache
                    </AdminUpdateCache>)}
                    {/* TODO: Not Currently functional */}
                    {/* <StyledSelect value={selectOptions[0].value} options={selectOptions} onChange={(value) => console.log(value)}/> */}
                </HeaderRightContainer>
            </HeaderContainer>
            <TimeZoneText>{`All times shown in ${timezone} time`}</TimeZoneText>
        </>
    )
}

export default EventsHeader;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
`;

const HeaderLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const HeaderTitle = styled.h1`
    font-size: 60px;
    margin: 0px;
    margin-bottom: 14px;
`;

const HeaderSubTitle = styled.h4`
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 1.5;
    color: #fff;
`;

const HeaderButtonContainer = styled.div`
    // display: flex;
    // justify-content: space-between;
    margin-bottom: 7px;
`;

const StyledFilterButton = styled.button<{active: boolean}>`
    height: 36px;
    padding: 9px 14px 9px 14px;
    margin-right: 10px;
    border-radius: 4px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: var(--main-transition);
    box-shadow: 1px 2px 30px 0 rgba(0, 0, 0, 0.5);

    ${(props) => props.active ?
         `
            background-color: var(--off-white);
            color: var(--midnight);
            border: 1px solid var(--off-white);

            &:hover {
                background-color: var(--battleship-grey);
                border: 1px solid var(--battleship-grey);
            }
        ` :
         `
            background-color: var(--midnight);
            color: var(--battleship-grey);
            border: 1px solid var(--battleship-grey);

            &:hover {
                background-color: var(--tennis-green);
                color: var(--midnight);
                border: 1px solid var(--tennis-green);
            }
        `
    }
`;
const HeaderRightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const StyledSelect = styled(Select)`
    margin: 0px !important;
`;

const CreateEventButton = styled(StyledSmallButton)`
    font-weight: bold;
`;

const AdminUpdateCache = styled(StyledSmallButton)`
    width: fit-content;
    margin-top: 10px;
    font-weight: bold;
`;

const TimeZoneText = styled.div`
    margin-bottom: 7px;
`;

