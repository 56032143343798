import * as React from 'react';
import styled from 'styled-components/macro';

type Props = {
    title?: string;
    center?: boolean;
};

const OneColumnBackgroundPage: React.FC<Props> = ({ title, center, children }) => {
    return (
        <>
            <ForegroundContainer center={center}>
                <TitleContainer>
                    <h1 className="h1">{title}</h1>
                </TitleContainer>
                {children}
            </ForegroundContainer>
        </>
    );
};

const ForegroundContainer = styled.div<{ center?: boolean }>`
    background-color: var(--shadow-black);
    position: absolute;
    top: 0;
    height: 100vh;
    border-radius: 0 0 0 60px;
    right: 0;
    z-index: 3;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
    background-color: var(--navy-blue);
    align-items: center;
    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const TitleContainer = styled.div`
    padding: 75px 100px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 640px;
    > * {
        margin-bottom: 15px;
    }
`;

export default OneColumnBackgroundPage;
