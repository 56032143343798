import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getCurrentUser, getCurrentUserType, getCurrentUserLoaded, getCurrentUserIsAdmin } from '../../store/selectors';
import { setCurrentUser } from '../../store/actions';
import { Link } from 'react-router-dom';
import HostIcon from '../../images/atom-i-host.svg';
import { StyledSmallButton } from '../inputs/Button';
import { LanguageSelect } from '../inputs/LanguageSelect';
import ProfileNonagon from '../orbit/ProfileNonagon';
import close from '../../images/close.svg';
import downArrow from '../../images/down-arrow-big.svg';
import LoadingComponent from '../loading/LoadingComponent';
import { putApi } from '../../api/api';
import { useHistory, useLocation } from 'react-router-dom';
import { canGetFreeUpgrade, canViewAccountUpsell, isAnon } from '../../permissions/users';
import { envAsBool } from '../../utils/envLoader';

const NavDropdown = ({
    isOpen,
    setIsOpen,
    handleSignOut
}: {
    isOpen: boolean;
    setIsOpen: any;
    handleSignOut: () => void;
}) => {
    const profileLoaded = useSelector(getCurrentUserLoaded);
    const { id, avatarUrl, firstName } = useSelector(getCurrentUser);
    const user = useSelector(getCurrentUser);
    const userType = useSelector(getCurrentUserType);
    const isAdmin = useSelector(getCurrentUserIsAdmin);

    const [hovered, setHovered] = useState(false);
    const [showFreeSpace, setShowFreeSpace] = useState(false);
    const [showGoPro, setShowGoPro] = useState(false);
    const [disableOrganicSignUpBtn, setDisableOrganicSignUpBtn] = useState(false);
    const [enableTranslationMenu, setEnableTranslationMenu] = useState(envAsBool('ENABLE_TRANSLATE_MENU'));

    const dispatch = useDispatch();
    const history = useHistory();
    const toggleDropdownOpen = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (canGetFreeUpgrade(user) || isAnon(user)) {
            setShowFreeSpace(true);
        } else if (canViewAccountUpsell(user)) {
            setShowGoPro(true);
        } else {
            setShowFreeSpace(false);
            setShowGoPro(false);
        }

        if (isAdmin) setEnableTranslationMenu(true);
    }, [userType]);

    const goPro = () => {
        const successRoute = '/station/latest?fromPage=Nav&fromUserType=' + user.userType   + '&userId=' + user.id;
        history.push('/pricing?' + successRoute);
    };

    const getFreeSpace = () => {
        if (disableOrganicSignUpBtn) return;
        setDisableOrganicSignUpBtn(true);
        setShowFreeSpace(false);

        if (isAnon(user)) {
            history.push('/meet?login=true');
            return;
        }

        putApi(`/user/${id}/upgrade/free`)
            .then(async (resp) => {
                const response = await resp.json();
                if (resp.ok) {
                    dispatch(setCurrentUser(response));
                    history.push('/meet');
                    //  history.push('/station/latest')

                    //setShowFreeSpace(false)
                }
            })
            .catch((e) => {
                setDisableOrganicSignUpBtn(false);
                setShowFreeSpace(true);
            });
    };

    if (!profileLoaded) return <LoadingComponent />;
    return (
        <Container>
            <SideOptions>
                {showFreeSpace && (
                    <OrganicSignUpBtn disabled={disableOrganicSignUpBtn} onClick={getFreeSpace}>
                        <Icon style={{ margin: '0 6px 2px 0', width: '19px', height: '15px' }} src={HostIcon} />
                        <span>Get my free space</span>
                    </OrganicSignUpBtn>
                )}
                {showGoPro && (
                    <OrganicSignUpBtn onClick={goPro}>
                        <Icon style={{ margin: '0 6px 2px 0', width: '19px', height: '15px' }} src={HostIcon} />
                        <span>Go Pro</span>
                    </OrganicSignUpBtn>
                )}
                <DropdownButton
                    onClick={toggleDropdownOpen}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    <ProfileNonagon size={35} avatarUrl={avatarUrl}>
                        {firstName?.charAt(0).toUpperCase()}
                    </ProfileNonagon>
                    <IconBackground hovered={hovered} isOpen={isOpen}>
                        {isOpen ? <Icon src={close} alt="close" /> : <Icon src={downArrow} alt="open" />}
                    </IconBackground>
                </DropdownButton>
            </SideOptions>
            <Dropdown isOpen={isOpen}>
                <Link
                    to="/profile-edit"
                    style={{ textDecoration: 'none', color: 'white' }}
                    onClick={() => setIsOpen(false)}
                >
                    <DropdownItem>My Account</DropdownItem>
                </Link>
                <a
                    href="https://urnowhere.notion.site/urnowhere/Nowhere-Help-Center-1a1c7588b9f94211a2f4151b0063ef9d"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => setIsOpen(false)}
                    style={{ textDecoration: 'none', color: 'white' }}
                >
                    <DropdownItem>Help Center</DropdownItem>
                </a>
                <a
                    href="mailto:hello@urnowhere.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => setIsOpen(false)}
                    style={{ textDecoration: 'none', color: 'white' }}
                >
                    <DropdownItem>Contact Us</DropdownItem>
                </a>
                <DropdownItem onClick={handleSignOut}>{isAnon(user) ? "Log-in" : "Log-out"}</DropdownItem>
                {enableTranslationMenu && <LanguageSelect isInstantApply={true} />}
            </Dropdown>
        </Container>
    );
};

const SideOptions = styled.div`
    display: flex;
`;

const OrganicSignUpBtn = styled(StyledSmallButton)`
    height: 24px;
    padding: 4px 10px 3px 10px;
    border-radius: 20px;
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.35);
    border: solid 1px var(--tennis-green);
    background-color: transparent;

    margin: 20px 7px 0 0;
    font-family: all-round-gothic;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: var(--tennis-green);

    &:hover {
        background-color: transparent;
        color: #f6f6f9;
        border: solid 1px #f6f6f9;
    }
`;

const Container = styled.div`
    position: relative;
    margin-right: 15px;
    * {
        transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    }
`;

const DropdownButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 60px;
`;

const IconBackground = styled.div<{ hovered: boolean; isOpen: boolean }>`
    width: 39px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 2px;
    background-color: ${({ hovered, isOpen }) => {
        if (hovered) return 'var(--light-navy)';
        return isOpen ? 'var(--purple-blue)' : 'transparent';
    }};
`;

const Icon = styled.img`
    width: 12px;
    height: 16px;
`;

interface DropdownProps {
    isOpen: boolean;
}

const Dropdown = styled.div<DropdownProps>`
    position: absolute;
    top: 60px;
    right: 0;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    background: var(--midnight);
`;

const DropdownItem = styled.div`
    cursor: pointer;
    text-transform: uppercase;
    height: 60px;
    width: 180px;
    margin-top: 2px;
    padding-right: 26px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 3px;
    background: var(--steel-blue);
    &:hover {
        text-decoration: none;
        background: var(--purple-blue);
    }
`;

export default NavDropdown;
