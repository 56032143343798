import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ReactElement, ReactNode } from 'react';
const MeetDropdown = ({
    isOpen,
    setIsOpen,
    canBeManager,
    isLoggedIn,
    handleSignOut,
    handleGetNowherePro,
    activateLogInModal,
    canViewAccountUpsell
}: {
    isOpen: boolean;
    setIsOpen: any;
    isLoggedIn: boolean;
    canBeManager: boolean;
    handleSignOut: () => void;
    handleGetNowherePro: () => void;
    activateLogInModal: () => void;
    canViewAccountUpsell: boolean;
}) => {
    const close = () => setIsOpen(false);

    const handleLoginClick = () => {
        setIsOpen(false);
        activateLogInModal();
    };
    const baseUrl = document.location.origin;
    return (
        <>
            <IconContainer open={isOpen} onClick={() => setIsOpen(!isOpen)}>
                <StyledBurger open={isOpen}>
                    <div />
                    <div />
                    <div />
                </StyledBurger>
            </IconContainer>
            {isOpen && (
                <>
                    {isLoggedIn && (
                        <MobileNavContainer>
                            <MobileNavLinkBeginning to="/profile-edit" target="_top" onClick={close}>
                                My Account
                            </MobileNavLinkBeginning>
                            {/*            {canViewAccountUpsell && (
                                <MobileNavAnchor onClick={handleGetNowherePro}>Get Nowhere Pro</MobileNavAnchor>
                            )} */}
                            <MobileNavAnchor
                                target="_blank"
                                href="https://urnowhere.notion.site/urnowhere/Nowhere-Help-Center-1a1c7588b9f94211a2f4151b0063ef9d"
                                onClick={close}
                            >
                                Help Center
                            </MobileNavAnchor>
                            <MobileNavAnchor href={baseUrl + '/spaces'} onClick={close}>
                                Spaces
                            </MobileNavAnchor>
                            <MobileNavAnchor href={baseUrl + '/orbit'} onClick={close}>
                                Orbit
                            </MobileNavAnchor>
                            <MobileNavAnchor href="https://nowhere.io" target="_top" onClick={close}>
                                Nowhere.io
                            </MobileNavAnchor>
                            <MobileNavAnchorEnd onClick={handleSignOut}>Log-Out</MobileNavAnchorEnd>
                        </MobileNavContainer>
                    )}
                    {!isLoggedIn && (
                        <MobileNavContainer>
                            <MobileNavAnchorBeginning onClick={handleLoginClick}>
                                Log-In / Sign-Up
                            </MobileNavAnchorBeginning>
                            {/*          {canViewAccountUpsell && (
                                <MobileNavAnchor onClick={handleGetNowherePro}>Get Nowhere Pro</MobileNavAnchor>
                            )} */}
                            <MobileNavAnchor
                                target="_blank"
                                href="https://urnowhere.notion.site/urnowhere/Nowhere-Help-Center-1a1c7588b9f94211a2f4151b0063ef9d"
                                onClick={close}
                            >
                                Help Center
                            </MobileNavAnchor>
                            <MobileNavAnchor href={baseUrl + '/spaces'} onClick={close}>
                                {' '}
                                Spaces
                            </MobileNavAnchor>
                            <MobileNavAnchor href={baseUrl + '/orbit'} onClick={close}>
                                Orbit
                            </MobileNavAnchor>
                            <MobileNavAnchorEnd href="https://nowhere.io" target="_top">
                                Nowhere.io
                            </MobileNavAnchorEnd>
                        </MobileNavContainer>
                    )}
                </>
            )}
        </>
    );
};

export const StyledBurger = styled.button<{ open: boolean }>`
    //adapted from https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: transparent;
    ${({ open }) => (open ? 'width: 25px;' : 'width: 25px;')};

    height: 25px;
    border: none;
    opacity: 75% !important;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    pointer-events: auto;
    &:focus {
        outline: none;
    }

    div {
        width: 25px;
        height: 2px;
        background: var(--tennis-green);
        border-radius: 2px;
        position: relative;
        transform-origin: 1px;
        transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);

        //these are moving the divs that comprise the lines that form the x and hamburger icon, fancy!

        :first-child {
            transform: ${({ open }) => (open ? 'rotate(45deg) ' : 'rotate(0) ')};
            margin-left: ${({ open }) => (open ? '2.5px' : '0')};
        }

        :nth-child(2) {
            opacity: ${({ open }) => (open ? '0' : '1')};
            transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
        }

        :nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0) ')};
            margin-left: ${({ open }) => (open ? '2.5px' : '0')};
        }
    }
`;

const MobileNavContainer = styled.div`
    top: 75px;
    pointer-events: auto;
    right: 20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 75%;
    z-index: 99;
    > * {
        margin-bottom: 2px;
    }
`;

const NavLinkStylesMiddle = `
background-color: var(--midnight);

font-family: anisette-std;
width: 235px;
height: 60px;
font-stretch: normal;
font-style: normal;
line-height: normal;
font-size: 14px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 3px;
text-align: right;
color: #fff;
color: var(--white);
cursor: pointer;
padding: 20px;
border-top: 3px solid transparent;
border-bottom: 3px solid transparent;
transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
text-decoration: none;
&:hover {
    text-decoration: none;
    color: var(--tennis-green);
    border-bottom: 3px solid var(--tennis-green);
}
&:active {
    text-decoration: none;
    color: var(--tennis-green);
    border-bottom: 3px solid var(--tennis-green);
}
`;

const NavLinkStylesBeginning = `
background-color: var(--midnight);
border-radius: 20px 0 0 0;
font-family: anisette-std;
width: 235px;
height: 60px;
font-stretch: normal;
font-style: normal;
line-height: normal;
font-size: 14px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 3px;
text-align: right;
color: #fff;
color: var(--white);
cursor: pointer;
padding: 20px;
border-top: 3px solid transparent;
border-bottom: 3px solid transparent;
transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
text-decoration: none;
&:hover {
    text-decoration: none;
    color: var(--tennis-green);
    border-bottom: 3px solid var(--tennis-green);
}
&:active {
    text-decoration: none;
    color: var(--tennis-green);
    border-bottom: 3px solid var(--tennis-green);
}
`;

const NavLinkStylesEnd = `
    background-color: var(--midnight);
    border-radius: 0 0 20px 20px;
    font-family: anisette-std;
    width: 235px;
    height: 60px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 3px;
    text-align: right;
    color: #fff;
    color: var(--white);
    cursor: pointer;
    padding: 20px;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: var(--tennis-green);
        border-bottom: 3px solid var(--tennis-green);
    }
    &:active {
        text-decoration: none;
        color: var(--tennis-green);
        border-bottom: 3px solid var(--tennis-green);
    }
`;

const MobileNavLink = styled(Link)`
    ${NavLinkStylesMiddle}
`;

const MobileNavAnchor = styled.a`
    ${NavLinkStylesMiddle}
`;

const MobileNavLinkEnd = styled(Link)`
    ${NavLinkStylesEnd}
`;

const MobileNavAnchorEnd = styled.a`
    ${NavLinkStylesEnd}
`;

const MobileNavLinkBeginning = styled(Link)`
    ${NavLinkStylesBeginning}
`;

const MobileNavAnchorBeginning = styled.a`
    ${NavLinkStylesBeginning}
`;

const IconContainer = styled.div<{ open: boolean; onClick: any }>`
    background-color: rgba(3, 2, 23, 0.5);

    padding: 10px;
    border-radius: 10px;

    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    cursor: pointer;
    top: 20px;
    right: 20px;
    position: absolute;
`;

export default MeetDropdown;
