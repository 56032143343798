import * as React from 'react';
import styled from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';
import EnvironmentForm from '../components/environments/EnvironmentForm';
import { getApi, postApi } from '../api/api';
import { ApiEnvironmentEditableMetadata, ApiEnvironment } from '../api/ApiTypes';
import LoadingScreen from '../components/loading/LoadingScreen';

const UpdateEnvironment: React.FC = () => {
    const history = useHistory();
    const { environmentId } = useParams() as any;
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState('');
    const [environment, setEnvironment] = React.useState({} as ApiEnvironment);

    React.useEffect(() => {
        getApi(`/environment/${environmentId}`).then(async (r) => {
            const json = await r.json();
            if (!r.ok) {
                console.log(json.message || 'Could not get space data');
            } else {
                setEnvironment(json);
                setLoading(false);
            }
        });
    }, [environmentId]);

    const handleUpdateEnvironment = (environmentData: ApiEnvironmentEditableMetadata) => {
        postApi(`/environment/${environmentId}/update`, environmentData)
            .then(async (r) => {
                const json = await r.json();
                if (!r.ok) {
                    setError(json.message || 'Update Failed');
                }

                if (json.id) {
                    history.push(`/environments`);
                }
            })
            .catch((err) => console.log('error: ', err));
    };

    if (loading) return <LoadingScreen />;

    return (
        <Wrapper>
            <Title>Create new Environment</Title>
            <EnvironmentForm
                environment={environment}
                onSubmit={(environmentData: ApiEnvironmentEditableMetadata) => handleUpdateEnvironment(environmentData)}
            />
            {error && <h2>{error}</h2>}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    height: 95vh;
    padding: 0 60px;
`;

const Title = styled.h1`
    margin-bottom: 30px;
`;

export default UpdateEnvironment;
