import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { ApiEnvironment } from '../../api/ApiTypes';
import { getApi } from '../../api/api';
import EnvironmentTile from './EnvironmentTile';

interface Props {
    environmentId?: string;
    stationId: string;
    setEnvironmentId: (environmentId: string) => void;
    spaceId: string | undefined;
    disabled: boolean;
}


const EnvironmentSelect: React.FC<Props> = ({ environmentId, setEnvironmentId, stationId, spaceId, disabled }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [environments, setEnvironments] = useState([] as ApiEnvironment[]);
    if(!disabled) disabled = false;

    const toggleOpen = () => {
      if(disabled) return;
      setIsOpen(!isOpen);
    }

    const handleSetEnvironment = (environment?: ApiEnvironment) => {
        if(!environment) return;
        toggleOpen();
        setEnvironmentId(environment.id);
    };

    React.useEffect(() => {
        const url = (!spaceId)? `/station/${stationId}/environments`: `/station/${stationId}/environments/${spaceId}`;

        getApi(url).then(async (response) => {
            const json = (await response.json()) as ApiEnvironment[];
            console.log('[EnvironmentSelect] environments res: ', response, json);

            if (response.ok) {
                setEnvironments(json);
            }
        });
    }, [stationId]);

    React.useEffect(() => {
        if (!environmentId && environments[0]) {
            setEnvironmentId(environments[0].id);
        }
    }, [environmentId, setEnvironmentId, environments]);

    const selectedEnvironment: ApiEnvironment | undefined = environments.filter((env) => !!env).find((env) => env?.id === environmentId);
    const nonSelectedEnvironments: ApiEnvironment[] = environments.filter((env) => env?.id !== environmentId);

    return (
        <Container title={disabled? 'Environment selection restricted to owners.': 'Select an environment.'}>
            {isOpen ? (
                <DropDownList>
                    <EnvironmentTile
                        onClick={() => handleSetEnvironment(selectedEnvironment)}
                        environment={selectedEnvironment}
                        isOpen={isOpen}
                    />
                    {nonSelectedEnvironments.map((environment) => (
                        <EnvironmentTile
                            key={environment.id}
                            onClick={() => handleSetEnvironment(environment)}
                            environment={environment}
                            isOpen={isOpen}
                        />
                    ))}
                </DropDownList>
            ) : (
                <DropDownList>
                    {selectedEnvironment && <EnvironmentTile environment={selectedEnvironment} onClick={toggleOpen} isOpen={isOpen} />}
                </DropDownList>
            )}
        </Container>
    );
};

const Container = styled('div')`
    width: 510px;
    margin: 0 auto 30px;
    position: relative;
`;

const DropDownList = styled('div')``;

export default EnvironmentSelect;
