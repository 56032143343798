import * as React from 'react';

const FourOhFour = () => {
    return (
        <>
            <div className="utility-page-wrap">
                <div className="utility-page-content w-form">
                    <img src="../../images/polyMoose-glow.png" alt="" />
                    <h2>You really are NOWHERE</h2>
                    <div>The page you are looking for doesn&#x27;t exist or has been moved.</div>
                </div>
            </div>
        </>
    );
};

export default FourOhFour;
