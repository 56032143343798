import * as React from 'react';
import { Dispatch, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components/macro';
import Avatar from 'react-avatar-edit';
import sampleNonagonProfile from '../../images/polygon.png';
import { OutlineButtonNarrow } from '../inputs/Button';

import * as firebase from 'firebase/app';
import 'firebase/storage';

interface Props {
    onImageChanged: Dispatch<any>;
    currentImage?: string;
    horizontal?: boolean;
}
const UploadProfilePic: React.FC<Props> = ({ onImageChanged, horizontal, currentImage }) => {
    const [avatarImageUrl] = useState('');
    const [editing, setEditing] = useState(false);
    const [croppedImage, setCroppedImage] = useState('');
    const [editorClosed, setEditorClosed] = useState(false);
    const [editorRef, setEditorRef] = useState<Avatar | undefined | null>();

    const onBeforeFileLoad = (el: any) => {
        setEditing(true);
        if (el.target.files[0].size > 6666666) {
            alert('File is too big!');
            el.target.value = '';
        }
    };

    const onFileLoad = () => {
        console.log('onFileLoad');
        setEditorClosed(false);
        setEditing(true);
    };

    const onClose = () => {
        console.log('on close');
        if (!editorClosed) {
            setCroppedImage('');
            setEditorClosed(false);
        }
        setEditing(false);
    };

    const onDone = () => {
        onImageChanged(croppedImage);
        setEditorClosed(true);
    };

    if (editorClosed) {
        (editorRef! as any).onCloseClick();
    }

    return (
        <ImageUploadContainer editing={editing} horizontal={horizontal}>
            <Avatar
                ref={setEditorRef}
                width={horizontal ? 150 : 210}
                imageWidth={300}
                height={horizontal ? 150 : 210}
                cropRadius={80}
                lineWidth={2}
                onCrop={setCroppedImage}
                onClose={onClose}
                onBeforeFileLoad={onBeforeFileLoad}
                onFileLoad={onFileLoad}
                src={avatarImageUrl}
                // creates clickable area -- button is the Upload button
                label=""
                labelStyle={{ position: 'absolute', padding: horizontal ? '75px 175px' : '134px 103px' }}
            />

            {!editing && (
                <CroppedAvatar
                    src={croppedImage || currentImage || sampleNonagonProfile}
                    style={{
                        padding: '0px',
                        width: !editing && horizontal ? '150px' : '210px',
                        pointerEvents: 'none',
                        cursor: editing ? undefined : 'pointer',
                        marginBottom: editing ? '300px' : '0'
                    }}
                />
            )}
            <UploadButton onClick={editing ? onDone : () => null} editing={editing} horizontal={horizontal}>
                <p>
                    <span role="img" aria-label="bullet">
                        ⚪
                    </span>
                    ️ {editing ? 'Done' : 'Upload Profile Pic'}
                </p>
            </UploadButton>
        </ImageUploadContainer>
    );
};

const UploadButton = styled(OutlineButtonNarrow)<{ horizontal?: boolean; editing?: boolean }>`
    margin: ${({ horizontal, editing }) => (horizontal ? (editing ? '0 80px' : '0 20px') : '30px 0')};
    padding-top: 3px;
    transition: none;
`;

export async function doImageUpload(imageDataUrl: string): Promise<string | undefined> {
    if (!imageDataUrl) return undefined;
    const storageRef = firebase.storage().ref();

    const imageRef = storageRef.child(`avatar/${uuidv4()}.png`);
    return imageRef.putString(imageDataUrl, 'data_url').then(function (snapshot) {
        const avatarUrl = snapshot.metadata.fullPath;
        return firebase.storage().ref().child(avatarUrl).getDownloadURL();
    });
}

const ImageUploadContainer = styled.div<{ horizontal?: boolean; editing?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
    align-items: center;
    padding-right: 60px;
    ${({ editing, horizontal }) => editing && (horizontal ? 'margin: 0 75px' : 'margin: 0 30px;')};
    width: 265px;
    transition: none;
    &label,
    label {
        cursor: pointer;
    }
`;

const CroppedAvatar = styled.img`
    position: absolute;
    clip-path: var(--nonagon);
    padding: 30px;
    z-index: 0;
`;

export default UploadProfilePic;
