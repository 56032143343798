import * as React from 'react';

import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import { getApi } from '../api/api';
import { RaceConfigForm } from '../components/stationAdmin/RaceConfigForm';
import { PrizeManager } from '../components/stationAdmin/PrizeManager';
import { ApiSpace } from '../api/ApiTypes';
import { useParams, useHistory } from 'react-router-dom';
import { ReactComponent as SettingsIcon } from '../images/gear.svg';
import { ReactComponent as HistoryIcon } from '../images/i-favorite.svg';
import { useHash } from '../hooks/useHash';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../store/selectors';
import { canManageRacingSubsection } from '../permissions/users';

const defaultTab = '#configure';

const getTab = (hash: string) => {
    if (hash === '#configure') return '#configure';
    if (hash === '#prize-payouts') return '#prize-payouts';
    return defaultTab;
}

export const SpaceRaceConfig = () => {
  const {hash, updateHash} = useHash();
  const tab = getTab(hash);
  const { spaceId } = useParams() as { spaceId: string };
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [space, setSpace] = useState<ApiSpace | undefined>(undefined);

  const history = useHistory();

  const user = useSelector(getCurrentUser);

  const getSpace = async () => {
    getApi(`/space/${spaceId}`).then(async (resp) => {
      const res = await resp.json();
      setSpace(res.space);
    });
  }

  const onSubmitSuccess = () => {
    history.push(`/space/${spaceId}`);
  }

  useEffect(() => {
    getSpace().then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <TabSelectorContainer>
          <TabSelector>
              <Tab
                  selected={tab === '#configure'}
                  onClick={() => updateHash('#configure')}
              >
                  <StyledConfigIcon selected={tab === '#configure'}/>
                  <ConfigureTabText>Configure</ConfigureTabText>
              </Tab>
              {canManageRacingSubsection(user, space, 'prizePayouts') && <Tab
                  selected={tab === '#prize-payouts'}
                  onClick={() => updateHash('#prize-payouts')}
              >
                  <StyledPrizeIcon selected={tab === '#prize-payouts'}/>
                  <PrizeTabText>Prize Payouts</PrizeTabText>
              </Tab>}
          </TabSelector>
      </TabSelectorContainer>

      {tab === '#configure' && (
        <>
          {!isLoading && space && <RaceConfigForm space={space!} onSubmitSuccess={onSubmitSuccess} />}
        </>
      )}

      {tab === '#prize-payouts' && (
         <>
          {!isLoading && space && <PrizeManager space={space!} />}
        </>
      )}
    </>
  )

};

const TabSelectorContainer = styled.div`
    margin-top: 25px;
    width: 100%;
    height: 40px;
    border-bottom: 2px solid var(--tennis-green);
`;

const TabSelector = styled.div`
    display: flex;
    height: 100%;
    padding-left: 10px;

    @media (min-width: 375px) {
        padding-left: 30px;
    }

    @media (min-width: 768px) {
        padding-left: 90px;
    }
`;

const Tab = styled.div<{selected: boolean}>`
    display: flex;
    justify-content: space-between;
    width: fit-content;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: var(--navy-blue);
    letter-spacing: 1.17px;
    font-weight: bold;
    text-transform: uppercase;

    ${(props) => props.selected ?
        `
            border: 2px solid var(--tennis-green);
            border-bottom: 2px solid var(--midnight);
            color: var(--tennis-green);
        `
        :
        `
            cursor: pointer;
            border-bottom: 2px solid var(--tennis-green);
        `
    }

    &:hover div {
        color: var(--tennis-green);
    }

    &:hover svg path {
        fill: var(--tennis-green);
    }
`;

const TabTextStyles = `margin-top: 9px;`;

const ConfigureTabText = styled.div`
    ${TabTextStyles}
`;
const PrizeTabText = styled.div`
    ${TabTextStyles}
`;

const TabIconStyles = `
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    transition: var(--main-transition);
`;


const StyledConfigIcon = styled(SettingsIcon)<{selected: boolean}>`
    ${TabIconStyles}
    & path {
        fill: var(${(props) => props.selected ? '--tennis-green' : '--off-white'});
    }
`;

const StyledPrizeIcon = styled(HistoryIcon)<{selected: boolean}>`
    ${TabIconStyles}
    & path {
        fill: var(${(props) => props.selected ? '--tennis-green' : '--off-white'});
    }
`;
