import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const MeetGoPro = ({ isLoggedIn, handleGetNowherePro }: { isLoggedIn: boolean; handleGetNowherePro: () => void }) => {
    return (
        <>
            <IconContainer>
                <Text>Unlimited minutes, new worlds & more</Text>
                <Button>
                    <ButtonText onClick={handleGetNowherePro}>Go PRO</ButtonText>
                </Button>
            </IconContainer>
        </>
    );
};

const IconContainer = styled.div`
    background-color: rgba(3, 2, 23, 0.5);
    border-radius: 10px;
    cursor: pointer;
    top: 20px;
    right: 75px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    height: 45px;
    margin-left: 20px;
    z-index: 999;
`;

const Text = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    font-family: anisette-petite;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #bee639;

    @media (max-width: 768px) {
        font-size: 16px;
    }
    @media (max-width: 390px) {
        font-size: 12px;
    }
`;
const Button = styled.div`
    width: 116px;
    height: 30px;
    margin-right: 10px;
    justify-content: center;
    border-radius: 23px;
    background-color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
    pointer-events: auto;
    transition: all 0.3s ease-out; /* Add a transition effect */
    &:hover {
        background-color: #bee639;
    }
`;

const ButtonText = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    white-space: nowrap;
    font-family: anisette-std;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.14px;
    text-align: center;
    color: #0a0a0d;
`;

export default MeetGoPro;
