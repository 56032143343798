import * as React from 'react';
import { SpaceCardLabel } from './SpaceCardLabel';

import { Link, useLocation } from 'react-router-dom';
import { ApiSpace } from '../../api/ApiTypes';

type SpaceLinkProps = {
    space: ApiSpace;
};
export const SpaceLink: React.FunctionComponent<SpaceLinkProps> = (props) => (
    <Link to={`/space/${props.space.id}`}>{props.children}</Link>
);

type SpaceBoxProps = {
    space: ApiSpace;
    stationTitle?: string;
};

const SpaceBox: React.FunctionComponent<SpaceBoxProps> = (props) => {
    const { stationTitle, space } = props;
    const { pathname } = useLocation();
    const isEditing = pathname.includes(space.id);
    const totalAttendance = String((space?.attendance || 0));

    return (
        <SpaceCardLabel
            space={space}
            isEditing={isEditing}
            isActive={space.isActive}
            topRightLine={space.environment?.displayName || ''}
            middleRightLine={space.title}
            bottomRightLine={space.subtitle}
            topLeftLine={space.isActive ? 'Active' : 'Closed'}
            middleLeftLine={space.isActive ? totalAttendance : '- -'}
            bottomLeftLine={`of ${space.capacity}`}
        />
    );
};

export default SpaceBox;
