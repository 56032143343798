import * as React from 'react';
import { OutlineButton, StickyButton, StickyFormStatusContainer } from '../inputs/Button';
import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GreyishBodyText, RedLinkText, Title } from '../Typography';
import SocialCardFormContents from '../orbit/SocialCardFormContents';
import { useForm } from 'react-hook-form';
import { ApiUser } from '../../api/ApiTypes';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser, getCurrentUserHasModifiedProfile } from '../../store/selectors';
import { postApi } from '../../api/api';
import { setCurrentUser } from '../../store/actions';
import FormErrorMessage from '../inputs/FormErrorMessage';
import LoadingScreen from '../loading/LoadingScreen';
import _ from 'lodash';
import Cookies from 'js-cookie';
import CredentialsFormContent from '../orbit/CredentialsFormContent';

const CredentialsModal: React.FC = () => {
    const currentUser = useSelector(getCurrentUser);
    const [userProfile, setUserProfile] = useState<ApiUser>(({} as unknown) as ApiUser);
    const [loading, setLoading] = useState(true);
    const [canSubmit, setCanSubmit] = useState(false);
    const updateUserProfile = (profile: ApiUser) => {
        setUserProfile(profile);
        setCanSubmit(true);
    };
    const dispatch = useDispatch();

    useEffect(() => {
        setUserProfile(currentUser);
        setLoading(false);
    }, [currentUser]);

    const useFormObject = useForm();
    const { handleSubmit } = useFormObject;


    // if (profileModified || modalSeenRecently) window.location.reload();
    if (loading || _.isEmpty(currentUser)) return <LoadingScreen />;




    return (
        <>
            <Container>
                <CredsTitle>Introduce Yourself</CredsTitle>
                <br />


                <CredentialsFormContent
                    userProfile={userProfile}
                    updateUserProfile={updateUserProfile}
                />
                <br />
                <br />
            </Container>
        </>
    );
};

const StlyedOutlinedButton = styled(OutlineButton)`
    position: absolute;
`;

const CredsTitle = styled.h1`
    font-size: 60px;
    font-weight: bold;
    line-height: 65px;
    color: var(--off-white);
    text-transform: capitalize;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 50px;
        line-height: 50px;
    }

    @media (max-width: 550px) {
        font-size: 34px;
        line-height: 34px;
    }
    @media (max-width: 355px) {
        font-size: 28px;
        line-height: 28px;
    }
`;

const Container = styled.div`
    margin: 60px auto 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    @media (max-width: 768px) {
        align-items: flex-start;
    }
`;

export default CredentialsModal;
