import React, { useContext} from 'react';
import styled from 'styled-components/macro';
import { StreamContext } from './StreamContext';

const StreamViewerIntro = ({isHostView}: {isHostView: boolean}) => {

    const streamContext = useContext(StreamContext);

    if (isHostView) {
        return (
            <Container className={streamContext.stream.id ? 'invisible' : 'visible'}>
                <UnselectedSource>
                    <NoContent>
                        No stream source created.
                    </NoContent>
                    <CallToAction>
                        Choose a source &amp; start streaming!
                    </CallToAction>
                    <Instructions>
                        1. Choose a Stream Source below<br />
                        2. Choose Spaces to stream to<br />
                        {`3. Hit "Start Stream" above`}
                    </Instructions>
                </UnselectedSource>
            </Container>
        )
    } else {
        return (
            <Container className={streamContext.stream.id ? 'invisible' : 'visible'}>
                <GuestViewContainer>
                    <GuestCTA>{`No active stream`}</GuestCTA>
                </GuestViewContainer>
            </Container>
        )
    }
};



const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 394px;
    z-index: 20;
    background-color: var(--battleship-grey);
    color: var(--off-white);
    &.visible{
        visibility: visible;
    }
    &.invisible{
        visibility: hidden;
    }
}`;

const GuestViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

const GuestCTA =  styled.div`
    height: fit-content;
    width: fit-content;
    font-size: 30px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
`;

const UnselectedSource = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const NoContent = styled.div`
    margin-top: 100px;
    margin-bottom: 30px;
`;

const CallToAction = styled.div`
    height: fit-content;
    font-size: 30px;
    font-weight: bold;
`;

const Instructions = styled.div`
    margin-top: 30px;
`;

export default StreamViewerIntro;
