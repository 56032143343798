import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import lock from '../../images/lock.svg';
import { ApiSpace } from '../../api/ApiTypes';
import { SpaceUtil } from '../../utils/SpaceUtil';
import LinesEllipsis from 'react-lines-ellipsis';

type SpaceCardLabelProps = {
    topRightLine: string | React.ReactNode;
    middleRightLine: string | React.ReactNode;
    bottomRightLine: string | React.ReactNode;

    topLeftLine: string | React.ReactNode;
    middleLeftLine: string | React.ReactNode;
    bottomLeftLine: string | React.ReactNode;

    space?: ApiSpace;
    isEditing?: boolean;
    isActive?: boolean;
    useHover?: boolean;
    hide?: boolean;
};

export const SpaceCardLabel: React.FunctionComponent<SpaceCardLabelProps> = (props) => {
    const { isActive } = props;

    return (
        <Box isEditing={props.isEditing} hide={props?.hide} isActive={isActive} useHover={props.useHover}>
            <LeftBox isEditing={props.isEditing} isActive={isActive}>
                <TopLeftLine isEditing={props.isEditing}>{props.topLeftLine}</TopLeftLine>
                <MiddleLeftLine isEditing={props.isEditing}>{props.middleLeftLine}</MiddleLeftLine>
                <BottomLeftLine isEditing={props.isEditing}>{props.bottomLeftLine}</BottomLeftLine>
            </LeftBox>
            <RightBox space={props.space} isActive={isActive} isEditing={props.isEditing}>
                <TopRightLine isEditing={props.isEditing}>{props.topRightLine}</TopRightLine>
                <MiddleRightLine isEditing={props.isEditing}>{props.middleRightLine}</MiddleRightLine>
                <BottomRightLine isEditing={props.isEditing}>{props.bottomRightLine}</BottomRightLine>
                {!isActive && (
                    <img alt="locked" src={lock} style={{ position: 'absolute', bottom: '11px', right: '13px' }} />
                )}
            </RightBox>
        </Box>
    );
};

export const Box = styled.div<{
    hide?: boolean;
    isActive?: boolean;
    isEditing?: boolean;
    useHover?: boolean;
}>`
    opacity: ${({ hide }) => (hide ? 0 : 1)};
    height: ${({ isEditing }) => (isEditing ? '120px' : '80px')};
    border-radius: 15px ${({ isEditing }) => isEditing && '0 0 15px'};
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, ${({ isEditing }) => (isEditing ? '0' : '0.3')});
    border: solid ${({ isEditing }) => (isEditing ? '0 var(--midnight)' : '1px rgba(58, 8, 69, 0.5)')};
    background-image: ${({ isActive, isEditing }) =>
        isActive
            ? `linear-gradient(to left, var(--navy-blue), var(--${isEditing ? 'light-navy' : 'midnight'}))`
            : isEditing
            ? 'linear-gradient(to left, var(--navy-blue), var(--dark) 26%);'
            : 'linear-gradient(to left, var(--charcoal-grey), var(--dark) 26%)'};
    &:hover {
        ${({ isActive, isEditing, useHover }) =>
            !isEditing &&
            useHover &&
            (isActive
                ? 'background-image: linear-gradient(to left, var(--navy-blue), var(--light-navy))'
                : 'background-image: linear-gradient(to left, var(--navy-blue), var(--dark) 20%)')};
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: ${({ isEditing }) => (isEditing ? '315px' : '300px')};
    ${({ isEditing }) => isEditing && 'cursor: auto'};
`;

Box.defaultProps = {
    useHover: true
};

export const LeftBox = styled.div<{ isEditing?: boolean; isActive?: boolean }>`
    max-width: 60px;
    min-width: 60px;
    height: 100%;
    border-radius: 10px 0 0 10px;
    background-color: ${({ isActive, isEditing }) =>
        isActive ? (isEditing ? 'var(--light-navy)' : 'var(--steel-blue)') : 'var(--charcoal-grey)'};
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
`;

export const TopLeftLine = styled.div<{ isEditing?: boolean; isActive?: boolean }>`
    font-size: ${({ isEditing }) => (isEditing ? '14px' : '12px')};
    text-align: center;
    color: var(--off-white);
    text-transform: uppercase;
    margin-bottom: ${({ isEditing }) => (isEditing ? '10px' : '2px')};
`;

export const MiddleLeftLine = styled.div<{ isEditing?: boolean; isActive?: boolean }>`
    font-size: ${({ isEditing }) => (isEditing ? '30px' : '24px')};
    width: 100%;
    height: 29px;
    text-align: center;
    color: var(--off-white);
    margin-bottom: ${({ isEditing }) => (isEditing ? '2px' : '0')};
`;

export const BottomLeftLine = styled.div<{ isEditing?: boolean }>`
    font-size: ${({ isEditing }) => (isEditing ? '14px' : '12px')};
    color: ${({ isEditing }) => (isEditing ? 'var(--off-white)' : 'var(--battleship-grey)')};
`;

export const RightBox = styled.div<{ space?: ApiSpace, isActive?: boolean; isEditing?: boolean }>`
    // padding: ${({ isEditing }) => (isEditing ? '15px 10px' : '7px 10px')};
    padding: 7px 10px;
    position: relative;
    width: 81%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    background-image: ${({ space }) => ( space ?
        `
            linear-gradient(to right, rgba(10,8,63,1), rgba(10,8,63,0.65)),
            url(${SpaceUtil.getImage(space)})
        ` :
        'none'
    )};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // border: solid 1px var(--midnight);
    border-top-right-radius: ${({ isEditing }) => ( isEditing ? '0px' : '10px')};
    border-bottom-right-radius: ${({ isEditing }) => ( isEditing ? '0px' : '10px')};
`;

export const TopRightLine = styled.div<{ isEditing?: boolean }>`
    font-size: ${({ isEditing }) => (isEditing ? '17px' : '14px;')};
    color: ${({ isEditing }) => (isEditing ? 'var(--white)' : 'var(--off-white)')};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: ${({ isEditing }) => (isEditing ? '5px 0 11px' : '0')};
`;

export const MiddleRightLine = styled.div<{ isActive?: boolean; isEditing?: boolean }>`
    font-size: ${({ isEditing }) => (isEditing ? '29px' : '16px')};
    font-weight: bold;
    color: ${({ isEditing }) => (isEditing ? 'var(--white)' : 'var(--off-white)')};
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow-x: hidden;
    overflow-y: hidden;
    display: block;
    margin-bottom: ${({ isEditing }) => (isEditing ? '10px' : '0')};
    line-height: 1;
    text-align: left;
`;

export const BottomRightLine = styled.div<{ isEditing?: boolean }>`
    font-size: ${({ isEditing }) => (isEditing ? '17px' : '14px;')};
    color: ${({ isEditing }) => (isEditing ? 'var(--white)' : 'var(--off-white)')};
    display: block;
    text-overflow: ellipsis;
    text-align: left;
    overflow-x: hidden;
`;

export const PlusBox = styled.div`
    width: 75px;
    height: 40px;
    border-radius: 15px 0 0 15px;
    background-color: var(--light-navy);
    font-size: 24px;
    text-align: center;
    color: white;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: column;
`;

export const AddWords = styled.div`
    width: 100%;
    border-radius: 0 10px 10px 0;
    font-size: 16px;
    background-color: var(--indigo);
    color: var(--off-white);
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding-left: 15px;
`;

export const AddItemContainer = styled.div`
    flex-direction: row;
    display: flex;
    border: 1px solid rgba(58, 8, 69, 0.5);
    border-radius: 15px;
    margin: 15px 0 15px;
`;

type AddItemProps = {
    text: string;
    link: string;
};

export const AddItem: React.FunctionComponent<AddItemProps> = (props) => (
    <Link to={props.link}>
        <AddItemContainer>
            <PlusBox>+</PlusBox>
            <AddWords>{props.text}</AddWords>
        </AddItemContainer>
    </Link>
);
