import * as React from 'react';
import { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import StreamViewer from '../components/streaming/StreamViewer';
import StreamProvider from '../components/streaming/StreamContext';
import { ApiSpaceDetails } from '../api/ApiTypes';
import { getApi } from '../api/api';
import querystring from 'query-string';
import useInterval from '../hooks/useInterval';
import Spinner from '../images/i-generate-spinner-64px-green.svg';

const SpaceStreamViewer = () => {
  const { spaceId } = useParams() as { spaceId: string };
  const [ isLoaded, setIsLoaded ] = React.useState(false);
  const [ isAuthorizedToViewStream, setIsAuthorizedToViewStream ] = React.useState(undefined as boolean | undefined);
  const [ spaceDetails, setSpaceDetails ] = React.useState((undefined as unknown) as ApiSpaceDetails);
  const [ isActiveStream, setIsActiveStream ] = React.useState(false);
  const [ stationId, setStationId ] = React.useState('');
  const [ isFirstRun, setIsFirstRun ] = React.useState(true);

  const streamCheckInterval = 5000;
  const history = useHistory();
  const location = useLocation();

  const spaceDetailsHelper = () => {
    return getApi(`/space/${spaceId}`).then(async (sdr) => {
      if (sdr.ok) {
        const r2 = await sdr.json();
        setSpaceDetails(r2);
        setIsAuthorizedToViewStream(true);
      } else {
        setIsAuthorizedToViewStream(false);
      }
    });
  }

  const shouldShowLiveStream = (): boolean => {
    return isLoaded && !!isAuthorizedToViewStream && isActiveStream;
  }

  const liveStreamActiveHelper = () => {
    if(stationId) {
      getApi(`/station/${stationId}/liveStreams`).then(async (sdr) => {
        const streams = await sdr.json();
        const stream = streams.find((s: any) => s.spaces.includes(spaceId));
        const shouldBeActive = (stream.isActive && stream.details.status === 'active');
        (stream.isActive && stream.details.status === 'active')? setIsActiveStream(true): setIsActiveStream(false);
        if(!isActiveStream && shouldBeActive && !isFirstRun) window.location.href = window.location.href;
        setIsFirstRun(false);
      }).catch((e) => {
        console.log('Could not get liveStreams with ', e);
      });
    }
  }

  useEffect(() => {
     spaceDetailsHelper().catch(() => setIsAuthorizedToViewStream(false));
  }, [spaceId]);

  useEffect(() => {
    if(spaceDetails) {
      if(!querystring.parse(location.search)?.stationId) {
      } else {
        setIsLoaded(true);
      }
      setStationId(spaceDetails.space.stationId);
      liveStreamActiveHelper();
    }
  }, [spaceDetails]);

  useEffect(() => {
    const currentUrl = location.pathname
    if(stationId && !currentUrl.includes('stationId')) {
      history.push(`${currentUrl}?stationId=${stationId}`);
      setIsLoaded(true);
    }
  }, [stationId]);

  useEffect(() => {
    if(isAuthorizedToViewStream === false) history.push(`/space/${spaceId}`);
  }, [isAuthorizedToViewStream]);

  useInterval(() => {
    if(stationId) {
      liveStreamActiveHelper()
    }
  }, streamCheckInterval);

  return (
    <Container>
      {shouldShowLiveStream() && <StreamProvider>
        <StreamViewer isHostView={false} />
      </StreamProvider>}
      {!shouldShowLiveStream() && !isFirstRun && <GuestCTA>
        Wating for stream to start...
       </GuestCTA>}
       {!shouldShowLiveStream() && <SpinContainer><img
          src={Spinner}
          style={{
              width: '30px',
              height: '30px',
          }}
      /></SpinContainer>}
    </Container>
  )

};

const SpinContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const Container = styled.div`
    margin: 0px auto;
    justify-content: center;
    padding: 30px 45px;
    @media (min-width: 1280px) {
        width: 1260px;
        padding: 30px 0;
    }
`;

const GuestCTA =  styled.div`
    height: fit-content;
    width: fit-content;
    font-size: 30px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
`;

export default SpaceStreamViewer;
