/* eslint-disable react/jsx-key */
import * as React from 'react';
import { Column, useTable, useFlexLayout, usePagination, TableInstance, useSortBy } from 'react-table';
import styled from 'styled-components/macro';
import { CircleButton } from '../inputs/Button';
import { getPageIndicators } from '../../utils/pagination';
import downArrow from '../../images/down-arrow-green.svg';
import { GreyishBodyText } from '../Typography';

type DefaultSort = {
    id: string;
    desc: boolean;
};

const Table = ({
    columns,
    data,
    defaultSort,
    title,
    subtitle,
    skipRef
}: {
    columns: Array<Column>;
    data: Array<any>;
    defaultSort?: DefaultSort[];
    title?: string;
    subtitle?: string;
    skipRef?: any;
}) => {

    const table = useTable(
        {
            columns,
            data,
            initialState: { sortBy: defaultSort },
            autoResetPage: !skipRef?.current ?? true,
            autoResetExpanded: !skipRef?.current ?? true,
            autoResetGroupBy: !skipRef?.current ?? true,
            autoResetSelectedRows: !skipRef?.current ?? true,
            autoResetSortBy: !skipRef?.current ?? true,
            autoResetFilters: !skipRef?.current ?? true,
            autoResetRowState: !skipRef?.current ?? true,
            autoResetHiddenColumns: !skipRef?.current ?? true
        },
        useFlexLayout,
        useSortBy,
        usePagination
    );

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, pageCount } = table;

    return (
        <>
            {title ? (
                <TableTitle>
                    <h3>{title}</h3>
                    <GreyishBodyText>{subtitle}</GreyishBodyText>
                </TableTitle>
            ) : (
                <></>
            )}
            <TableElement {...getTableProps()}>
                {headerGroups.map((headerGroup) => (
                    <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <TableHeader {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                {column.isSorted && <SortingIndicator descending={column.isSortedDesc} />}
                            </TableHeader>
                        ))}
                    </TableHeaderRow>
                ))}
                <div {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);

                        return (
                            <TableRow {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </div>
            </TableElement>
            {pageCount > 1 && <Pagination table={table} />}
        </>
    );
};

const TableTitle = styled.div`
    background-color: var(--steel-blue);
    padding: 15px 30px;
    border-radius: 10px 10px 0 0;
    width: 335px;
`;

const TableElement = styled.div`
    border-collapse: separate;
    border-spacing: 0 20px;
    display: inline-block;
    text-align: left;
    background-color: var(--steel-blue);
    padding: 15px 30px;
    border-radius: 0 10px 10px 10px;
    margin-bottom: 30px;
`;

const TableHeaderRow = styled.div`
    background: none;
    font-weight: 700;
    color: var(--off-white);
`;

const TableHeader = styled.div`
    padding: 0.5rem 0;
    margin: 0;
`;

const TableRow = styled.div`
    background: none;
    height: 60px;
    border-radius: 15px;
    box-shadow: 1 1px 15px 0px var(--shadow-black);
    color: var(--midnight);
    font-weight: book;
    opacity: 0.9;
    border: solid 0px var(--off-white);
    background-color: var(--off-white);
    border-radius: 15px;
    margin-bottom: 10px;
`;

const TableCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
`;

type IndicatorProps = {
    descending?: boolean;
    className?: string;
};

const SortingImg = ({ descending, className }: IndicatorProps) => (
    <img src={downArrow} alt={descending ? 'Sorted Descending' : 'Sorted Ascending'} className={className} />
);

const SortingIndicator = styled(SortingImg)<IndicatorProps>`
    transform: scaleY(${(props) => (props.descending ? '1' : '-1')});
    padding-left: 5px;
    margin-bottom: 2px;
    height: 7px;
`;

const Pagination = ({ table }: { table: TableInstance }) => {
    const {
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex }
    } = table;

    return (
        <PaginationWrapper>
            <CircleButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
            </CircleButton>
            <CircleButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
            </CircleButton>
            <PaginationWrapper>
                {getPageIndicators(pageIndex + 1, pageCount).map((pageNumber) => {
                    if (typeof pageNumber === 'string')
                        return <PageIndicatorSpacer key={pageNumber}>{pageNumber}</PageIndicatorSpacer>;
                    if (pageIndex === pageNumber - 1)
                        return <ActivePageIndicator key={pageNumber}>{pageNumber}</ActivePageIndicator>;

                    return (
                        <PageIndicatorLink onClick={() => gotoPage(pageNumber - 1)} key={pageNumber}>
                            {pageNumber}
                        </PageIndicatorLink>
                    );
                })}
            </PaginationWrapper>
            <CircleButton onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
            </CircleButton>
            <CircleButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
            </CircleButton>
        </PaginationWrapper>
    );
};

const PaginationWrapper = styled.div`
    display: flex;
`;

const PageIndicatorStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    line-height: 1.14;
    text-align: center;
    letter-spacing: 1.08px;
    opacity: 0.9;
    color: #e3e2e9;
    font-weight: normal;
    padding: 0 5px;

    &:first-child {
        margin-left: 10px;
    }

    &:last-child {
        margin-right: 10px;
    }
`;

const PageIndicatorLink = styled(PageIndicatorStyles)`
    cursor: pointer;

    &:hover {
        color: #fff;
        text-shadow: 1px 0px 0px #fff;
    }
`;

const ActivePageIndicator = styled(PageIndicatorStyles)`
    color: var(--tennis-green);
    font-weight: 700;
`;

const PageIndicatorSpacer = styled(PageIndicatorStyles)``;

export default Table;
