import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { SmallGreenButton } from '../../components/inputs/Button';
import { postApi } from '../../api/api';
import { ApiSpaceEditableMetadata, SpaceMenuPermissions } from '../../api/ApiTypes';
import { FollowingDebug } from '../../components/following/FollowingDebug';

export const AddSpaces = () => {
    const environmentId = '4hoOZsi6XF8RHLc3aqXz';
    const stationId = 'Lz2WXRHVcSK9GEXwMDfR';

    const uniformSpaceData = {
        capacity: 150,
        environmentId: environmentId,
        subtitle: 'foobar',
        description: 'this is a description',
        isPrivate: false,
        featured: 1,
        isActive: true,
        menuPermissions: {
          Spaces:       'Everyone',
          Events:       'Everyone',
          Orbit:        'Everyone',
          Settings:     'Everyone',
          EditEntities: 'Hosts-Only',
       } as SpaceMenuPermissions,
    };

    const fakeSpacesDate: ApiSpaceEditableMetadata[] = [
        {...uniformSpaceData, title: '1'},
        {...uniformSpaceData, title: '2'},
        {...uniformSpaceData, title: '3'},
        {...uniformSpaceData, title: '4'},
        {...uniformSpaceData, title: '5'},
        {...uniformSpaceData, title: '6'},
        {...uniformSpaceData, title: '7'},
        {...uniformSpaceData, title: '8'},
        {...uniformSpaceData, title: '9'},
        {...uniformSpaceData, title: '10'},
        {...uniformSpaceData, title: '11'},
        {...uniformSpaceData, title: '12'},
        {...uniformSpaceData, title: '13'},
    ];

    const addSpace = (spaceData: ApiSpaceEditableMetadata) => {
        new Promise<void>((resolve) => {
            postApi(`/station/${stationId}/createSpace`, spaceData)
                .then(async (r: any) => {
                    const json = await r.json();
                    if (!r.ok) {
                        console.log(json.message || 'Something went wrong');
                    }

                    resolve();
                })
                .catch((err: any) => {
                    resolve();
                    console.log('error: ', err);
                });
        });
    };

    const handleAddSpaces = () => {
        console.log('adding spaces');

        fakeSpacesDate.map((spaceData) => addSpace(spaceData));
    };

    return (
        <SmallGreenButton onClick={handleAddSpaces}>NOT FOR PROD: Add Fake Spaces</SmallGreenButton>
    );
};
