import * as React from 'react';
import { useEffect, useState } from 'react';
import useInterval from '../hooks/useInterval';
import * as _ from 'lodash';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import LoadingComponent from '../components/loading/LoadingComponent';
import styled from 'styled-components/macro';
import SpaceCard from '../components/station/SpaceCard';
import StationPageHeader from '../components/station/StationPageHeader';
import { GreenDivider } from '../components/Divider';
import { Title } from '../components/Typography';
import { ApiStationDetails, ApiSpace, ApiStation } from '../api/ApiTypes';
import { getApi } from '../api/api';
import CountWidget from '../components/CountWidget';
import FeaturedSpaceCard from '../components/station/FeaturedSpaceCard';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../store/selectors';
import { LargeButton, StickyButton, StyledSmallButton } from '../components/inputs/Button';
import LineBreaksToBrTags from '../utils/LineBreaksToBrTags';
import { EventsCTA } from '../components/layouts/CTAFooter';
import EmptyListCard from '../components/layouts/EmptyListCard';
import cloudsDefault from '../images/clouds_default_mirrored.jpg';
import { isStationManager } from '../permissions/stations';
import gear from '../images/gear.svg';
import RemoveSelfFromStationModal from '../components/modals/RemoveSelfFromStationModal';
import useShowPageHelp from '../hooks/useShowPageHelp';
import HelpText from '../components/HelpText';
import { envAsBool } from '../utils/envLoader';

const POLLING_RATE = 5000;
const StationPage = () => {
    const [loading, setLoading] = useState(true);
    const [stationDetails, setStationDetails] = useState(({} as unknown) as ApiStationDetails);
    const { stationId } = useParams() as any;
    const { spaceId } = useParams() as any;
    const currentUser = useSelector(getCurrentUser);
    const history = useHistory();

    const getStationInfo = () => {
        console.log('[StationPage] getStationInfo');
        const loadStationDetails = async (sId: string): Promise<ApiStationDetails | null> => {
            const res = await getApi(`/station/${sId}`);
            if (res.ok) {
                const stationDetails = await res.json();
                setStationDetails(stationDetails);
                setLoading(false);
                return stationDetails;
            } else {
                console.warn('[StationPage] could not load station');
                setLoading(false);
                return null;
            }
        };

        switch(stationId) {
            case 'latest':
              getApi(`/user/me/stations`).then(async (response) => {
                  const res = (await response.json().then((ss) => {
                        return ss.map((s: ApiStation) => {
                        return { ...s, createdAt: { _seconds: ( s?.createdAt?._seconds?? 0 ) } };
                    }).sort((a: ApiStation, b: ApiStation) => a!.createdAt!._seconds - b!.createdAt!._seconds);
                  })) as ApiStation[];

                  const myLastestStation = res.filter((s) => s.creatorId === currentUser.id).pop();
                  const latestStation = myLastestStation? myLastestStation!: res.slice(-1).pop();

                  const latestStationId = latestStation!.id!;
                  const details = await loadStationDetails(latestStationId);

                  //Short circut for first time user
                  if(currentUser.isFirstTimeUser) {
                      const lsSpace = details!.spaces!.sort((a: ApiSpace, b: ApiSpace) => 
                          new Date(a?.createdAt?? 0).getTime() - new Date(b?.createdAt?? 0).getTime()
                      ).slice(-1).pop();

                      history.push(`/space/${lsSpace!.id!}`);
                      //This does not seem to work but keeping it just in case...
                      return <Redirect to={`/space/${lsSpace!.id!}`} />
                  }
              });
              break;
           case 'forSpace':
              getApi(`/space/${spaceId}`).then(async (s) => {
                const spDetails = await s.json();
                loadStationDetails(spDetails.space.stationId);
              });
              break;
           default:
            loadStationDetails(stationId);
        }
    }

    useEffect(() => {
        getStationInfo();
    }, [stationId]);

    useInterval(() => {
        getStationInfo();
    }, POLLING_RATE);

    return <StationPageComponent loading={loading} stationDetails={stationDetails} />;
};

export const StationPageComponent: React.FC<{
    loading: boolean;
    stationDetails: ApiStationDetails;
}> = ({ loading, stationDetails }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const spaceId = urlParams.get('spaceId');
    const [leaveModalOpen, setLeaveModalOpen] = useState(false);
    const [sortDescending, setSortDescending] = useState(true);

    const { station } = stationDetails;
    const spaces = _.sortBy(stationDetails.spaces, [({ isActive }) => (isActive ? -1 : 1)]);
    const description = LineBreaksToBrTags(station?.description);

    const initialSpaceIndex = _.findIndex(spaces, (r) => r.id === spaceId);

    const [featuredSpaceIdx, setFeaturedSpaceIdx] = useState<number | null>(
        initialSpaceIndex === -1 ? null : initialSpaceIndex
    );

    const showHelp = useShowPageHelp('stationPage');
    // const showHelp = true;
    const helpText = 'Edit your Station and its Spaces.';

    const history = useHistory();
    const user = useSelector(getCurrentUser);
    const isStationAdmin = isStationManager(stationDetails?.station, user);
    const hostedSpaceIds = (station?.hostedSpaceIds)? station!.hostedSpaceIds as string[]: [] as string[];
    const isSpaceHost = ((space: ApiSpace): boolean => {
        if(!stationDetails?.station) return false;
        return hostedSpaceIds.find((s) => s === space.id) !== undefined;
    });
    const activeSpaces = spaces.filter(({ isActive }) => isActive);

    if (loading) return <LoadingComponent />;
    if (!stationDetails) return <Redirect to="/404" />;

    if (!station || !spaces) {
        return <LoadingComponent />;
    }

    const handleEditStation = () => {
        history.push((isStationAdmin)? `/station/${station.id}/admin`: `/space/${hostedSpaceIds[0]}/admin`)
    }

    const sortDescendingWithFullAtBottom = (inputSpaces: ApiSpace[]) => {
        // There are lots of loops in here, this was quick and dirty and written to be easily understood, it is NOT performant lol
        // console.log('[StationPage] inputSpaces: ', inputSpaces);
        const activeSpacesWithAttendance = inputSpaces.map((space) => {
            space.attendance = space.attendance ?? 0
            return space;
        });
        // console.log('[StationPage] activeSpacesWithAttendance', activeSpacesWithAttendance);

        const fullSpaces = activeSpacesWithAttendance.filter((space) => {
            if (space.attendance! >= space.capacity) return space;
        });

        // console.log('[StationPage] fullSpaces', fullSpaces);

        const notFullSpaces = activeSpacesWithAttendance.filter((space) => {
            if (space.attendance! < space.capacity) return space;
        });

        // console.log('[StationPage] notFullSpaces', notFullSpaces);

        notFullSpaces.sort((a, b) => {
            return b.attendance! - a.attendance!;
        });

        // console.log('[StationPage] sorted notFullSpaces', notFullSpaces);

        const outputArray = notFullSpaces.concat(fullSpaces);
        // console.log('[StationPage] outputArray', outputArray);
        return outputArray
    }

    const spacesToMap = sortDescending ? sortDescendingWithFullAtBottom(activeSpaces) : activeSpaces;
    return (
        <>
            {(isStationAdmin || hostedSpaceIds.length > 0) && (
                <div>
                    <StickyButton onClick={handleEditStation} disabled={false}>
                        {showHelp && (
                            <HelpText label={helpText} style={{left: '25px', top: '31px'}} noWrap={true}/>
                        )}
                        <img src={gear} style={{ margin: '1px 10px 3px -4px', width: '20px' }} alt="admin" />
                        Admin
                    </StickyButton>
                </div>
            )}
            <StationPageHeader station={station} isStationAdmin={isStationAdmin}/>
            <SpaceGridContainer>
                <div
                    onClick={() => setSortDescending(!sortDescending)}
                >
                    {sortDescending}
                    <CountWidget
                        items={activeSpaces || []}
                        label={`of ${spaces.length} | open space${activeSpaces.length !== 1 ? 's' : ''}`}
                    />
                </div>
                {!activeSpaces || activeSpaces?.length === 0 ? (
                    <EmptyListCard background={cloudsDefault}>
                        <div style={{ maxWidth: '600px' }}>
                            “I was born walking, born in the nowhere between galaxies.”
                            <br />
                            <EmptySubtitle>― Hannah Lillith Assadi, Sonora</EmptySubtitle>
                        </div>
                        {isStationAdmin && (
                            <div>
                                <LargeButton onClick={() => history.push(`/station/${station.id}/admin`)}>
                                    Edit station and open a space
                                </LargeButton>
                            </div>
                        )}
                    </EmptyListCard>
                ) : activeSpaces?.length === 1 ? (
                    <FeaturedSpaceCard
                        space={activeSpaces[0]}
                        handleClose={() => console.log('refraining from closing space')}
                        isStationAdmin={isStationAdmin || isSpaceHost(activeSpaces[0])}
                        unclosable={true}
                        inviteCode={station.inviteCode}
                        isGuestAbleToInvite={station.isGuestAbleToInvite}
                    />
                ) : (
                    <>
                        {/*So sorry about the awful nested boolean mess below.*/}
                        {/*It shuffles elements of a two-wide grid around*/}
                        {/*in such a way that the next space card is displayed before */}
                        {/*the double-wide feature card in the name of not leaving an awkward*/}
                        {/*hole in the grid in a 2x2 grid. */}
                        <SpaceGrid>
                            {spacesToMap.map((space) => {
                                return (
                                    <SpaceCard
                                        isStationAdmin={isStationAdmin || isSpaceHost(space)}
                                        stationTitle={station.title}
                                        space={space}
                                        key={space.id}
                                        inviteCode={station.inviteCode}
                                        isGuestAbleToInvite={station.isGuestAbleToInvite}
                                        station={station}
                                    />
                                );
                            })}
                            {/* {spacesToMap.map((space, idx) => {
                                if (featuredSpaceIdx !== null && featuredSpaceIdx % 2 === 0) {
                                    // if (featuredSpaceIdx === idx)
                                    //     return (
                                    //         <FeaturedSpaceCard
                                    //             space={space}
                                    //             handleClose={() => setFeaturedSpaceIdx(null)}
                                    //             isStationAdmin={isStationAdmin || isSpaceHost(space)}
                                    //             inviteCode={station.inviteCode}
                                    //             isGuestAbleToInvite={station.isGuestAbleToInvite}
                                    //         />
                                    //     );
                                    // return (
                                    //     <SpaceCard
                                    //         space={space}
                                    //         stationTitle={station.title}
                                    //         isStationAdmin={isStationAdmin || isSpaceHost(space)}
                                    //         key={space.id}
                                    //         inviteCode={station.inviteCode}
                                    //         isGuestAbleToInvite={station.isGuestAbleToInvite}
                                    //     />
                                    // );
                                } else {
                                    // if (featuredSpaceIdx === idx + 1) {
                                    //     return (
                                    //         <>
                                    //             <FeaturedSpaceCard
                                    //                 space={spaces[idx + 1]}
                                    //                 handleClose={() => setFeaturedSpaceIdx(null)}
                                    //                 isStationAdmin={isStationAdmin || isSpaceHost(spaces[idx + 1])}
                                    //                 inviteCode={station.inviteCode}
                                    //                 isGuestAbleToInvite={station.isGuestAbleToInvite}
                                    //             />
                                    //             <SpaceCard
                                    //                 isStationAdmin={isStationAdmin || isSpaceHost(spaces[idx + 1])}
                                    //                 space={space}
                                    //                 stationTitle={station.title}
                                    //                 key={space.id}
                                    //                 inviteCode={station.inviteCode}
                                    //                 isGuestAbleToInvite={station.isGuestAbleToInvite}
                                    //             />
                                    //         </>
                                    //     );
                                    // } else if (featuredSpaceIdx === idx) {
                                    //     return <></>;
                                    // } else {
                                        return (
                                            <SpaceCard
                                                isStationAdmin={isStationAdmin || isSpaceHost(space)}
                                                stationTitle={station.title}
                                                space={space}
                                                key={space.id}
                                                inviteCode={station.inviteCode}
                                                isGuestAbleToInvite={station.isGuestAbleToInvite}
                                            />
                                        );
                                    }
                                }
                            })} */}
                        </SpaceGrid>
                    </>
                )}
            </SpaceGridContainer>
            <LeaveButton onClick={() => setLeaveModalOpen(true)}>Leave Station</LeaveButton>
            <RemoveSelfFromStationModal
              isOpen={leaveModalOpen}
              hideModal={() => setLeaveModalOpen(false)}
              stationId={station.id!}
            />
            <GreenDivider style={{ width: '289px', margin: '60px 0' }} />
            <EventsCTA />
        </>
    );
};

const SpaceGridContainer = styled.section`
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 50px 50px;
`;

const SpaceGrid = styled.div`
    margin: 0 0 30px 0;
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
    }
`;

const EmptySubtitle = styled.span`
    font-weight: lighter;
`;

const LeaveButton = styled(StyledSmallButton)`
    margin-left: 160px;
    margin-bottom: -30px;
    color: var(--white);
    background-color: var(--burnt-red);
    font-weight: bold;
    &:hover {
        color: var(--burnt-red);
        background-color: var(--white);
    }
`;

export default StationPage;
