import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import * as _ from 'lodash';
import { Title } from '../Typography';
import { Link, useHistory } from 'react-router-dom';
import { postApi } from '../../api/api';
import Toggle from '../inputs/Toggle';
import { ApiStation, ApiSpaceDetails } from '../../api/ApiTypes';
import TimeUtils from '../../utils/TimeUtils';
import { LargeButton, SmallButton } from '../inputs/Button';
import eye from '../../images/eye.svg';
import edit from '../../images/edit-icon.svg';
import { Modal } from '../layouts/Modal';
import Message from '../layouts/Message';
import LineBreaksToBrTags from '../../utils/LineBreaksToBrTags';
import { SpaceUtil } from '../../utils/SpaceUtil';
import useShowPageHelp from '../../hooks/useShowPageHelp';
import HelpText from '../HelpText';


type SpaceAdminHeaderProps = {
    spaceDetails: ApiSpaceDetails;
    setSpaceIsActive: (isActive: boolean) => void;
    setIsEditingSchedule: any;
    station: ApiStation;
};

const SpaceAdminHeader: React.FC<SpaceAdminHeaderProps> = ({ setSpaceIsActive, spaceDetails, station }) => {
    const history = useHistory();

    const spaceId = spaceDetails.space.id;

    const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);

    const updateSpaceActiveStatus = (newIsActive: boolean, kickUsers?: boolean) => {
        if (!newIsActive) {
            postApi(`/space/${spaceId}/close`, { kickUsers });
        } else {
            postApi(`/space/${spaceId}/update`, {
                isActive: newIsActive
            });
        }
        setSpaceIsActive(newIsActive);
    };
    const toggleSpaceActive = (newIsActive: boolean) => {
        if (newIsActive) {
            updateSpaceActiveStatus(true);
        } else {
            setShowConfirmCloseModal(true);
        }
    };

    const showHelp = useShowPageHelp('spaceAdmin');
    // const showHelp = true;
    const helpText = 'Open/Close your space to allow guests to enter or keep them out.';
    const helpTextInnerStyle = {
        width: '300px',
        top: '-13px',
        right: '205px'
    }

    const { title, subtitle, attendance, liteAttendance, environment, capacity, isActive, isOpen, id } = spaceDetails.space;
    const titleWithLineBreaks = LineBreaksToBrTags(title);

    const status = isOpen ? 'Live' : 'Closed';

    const totalTime = _.sum(spaceDetails.users.map((i) => i.stats?.totalTimeSpentSecs || 0));
    const entries = _.sum(spaceDetails.users.map((i) => i.stats?.timesEntered || 0));
    const averageSession = entries ? TimeUtils.formatDuration(totalTime / entries) : '--';

    const closeSpace = (kickUsers: boolean) => {
        updateSpaceActiveStatus(false, kickUsers);
        setShowConfirmCloseModal(false);
    };
    const cancelCloseSpace = () => {
        setShowConfirmCloseModal(false);
    };

    const topContainerStyle = {
        backgroundImage: `
            linear-gradient(to right, rgba(10,8,63,0.75), rgba(10,8,63,1)),
            url(${SpaceUtil.getImage(spaceDetails?.space)})
        `,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    };

    return (
        <>
            <Modal handleClose={() => setShowConfirmCloseModal(false)} isOpen={showConfirmCloseModal}>
                <Message
                    title={<span>{title? 'The space ': 'this space'}<span className='noTranslate'>{title?? ''}</span></span>}
                    body="Any attendees currently in the space will be booted."
                    onCancel={() => cancelCloseSpace()}
                    onConfirm={() => closeSpace(true)}
                    onSecondConfirm={() => closeSpace(false)}
                    confirmButtonText="Close Space and Kick Guests Out"
                    secondConfirmButtonText="Close Space but Guests Can Stay"
                />
            </Modal>
            <Container>
                <TopContainer style={topContainerStyle}>
                    <TitleContainer>
                        <div>
                            <Title className='noTranslate'>{titleWithLineBreaks}</Title>
                            <h5 className='noTranslate'>{subtitle}</h5>
                        </div>
                        <ActionsContainer>
                            <Link to={`/space/${spaceId}/lobby`} target="_blank" style={{ textDecoration: 'none' }}>
                                <LargeButton>Launch Space</LargeButton>
                            </Link>
                            <SmallButton
                                onClick={() => history.push(`/station/${station.id}`)}
                                icon={<img src={eye} alt="view" />}
                            >
                                Preview
                            </SmallButton>
                            <SmallButton
                                onClick={() => history.push(`/space/${id}/edit`)}
                                icon={<img src={edit} alt="edit" />}
                            >
                                Edit
                            </SmallButton>
                        </ActionsContainer>
                    </TitleContainer>
                    <div className="d--f jc--fs ai-fs" style={{ width: '70%', marginTop: '16px' }}>
                        <SpaceInfoItem>
                            <SpaceInfoField>Environment:</SpaceInfoField>
                            <SubTitle>{environment?.displayName}</SubTitle>
                        </SpaceInfoItem>
                        <SpaceInfoItem>
                            <SpaceInfoField>Capacity:</SpaceInfoField>
                            <SubTitle>{capacity.toFixed(0)}</SubTitle>
                        </SpaceInfoItem>
                    </div>
                </TopContainer>
                <StatsContainer>
                    <div className="d--f fd--c">
                        <div className="d--f jc--fs">
                            <div className="d--f ai--b" style={{ marginRight: '60px' }}>
                                <SpaceInfoField>Status:</SpaceInfoField>
                                <BodyText>{status}</BodyText>
                            </div>
                        </div>
                        <div className="d--f jc--fs">
                            <div className="d--f ai--b" style={{ marginRight: '16px' }}>
                                <SpaceInfoField>Active in-world:</SpaceInfoField>
                                <GreenBodyText>{attendance}</GreenBodyText>
                            </div>
                            <div className="d--f ai--b" style={{ marginRight: '16px' }}>
                                <SpaceInfoField>Active Streaming:</SpaceInfoField>
                                <GreenBodyText>{liteAttendance}</GreenBodyText>
                            </div>
                            <div className="d--f ai--b">
                                <SpaceInfoField>Avg Session:</SpaceInfoField>
                                <BodyText>{averageSession}</BodyText>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <div className="d--f jc--fe" style={{ marginBottom: '15px' }}>
                            <SpaceInfoField style={{ paddingRight: '16px' }}>
                                {isActive ? 'Space Active' : 'Space Dormant'}
                            </SpaceInfoField>
                            <Toggle onChange={toggleSpaceActive} value={isActive || false}>
                            </Toggle>
                            {showHelp && (<HelpText label={helpText} noWrap={false} innerStyle={helpTextInnerStyle}/>)}
                        </div>
                        {/*<SmallButton onClick={setIsEditingSchedule}>Add Schedule</SmallButton>*/}
                    </div>
                </StatsContainer>
            </Container>
        </>
    );
};

const SpaceInfoItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 30px;
`;

const SpaceInfoField = styled.h4`
    font-size: 14px;
    font-weight: bold;
    line-height: 2.5;
    letter-spacing: 0.23px;
    margin: 0 15px 0 0;
    text-transform: uppercase;
`;

const SubTitle = styled.h3`
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    margin: 0;
    letter-spacing: 0.5px;
    color: var(--white);
    text-transform: uppercase;
`;

const BodyText = styled.p`
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.23px;
    color: var(--white);
`;

const GreenBodyText = styled(BodyText)`
    font-weight: bold;
    color: var(--tennis-green);
`;

const Container = styled.section`
    width: 100%;
    background-color: var(--dark-indigo);
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 20px;
    margin-bottom: 15px;
`;

const ActionsContainer = styled.section`
    position: relative;
    top: 41px;
    width: 100%;
    min-width: 150px;
    display: flex;
    flex-direction: row;
    @media (min-width: 768px) {
        width: auto;
    }
    @media (min-width: 1024px) {
        flex-direction: column;
        align-items: flex-end;
    }
    > button {
        margin-bottom: 15px;
    }
    > a {
        margin-bottom: 15px;

        button {
            background-color: #bee639;
            border-color: #bee639;
        }
    }
`;

const TopContainer = styled.div`
    padding: 60px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const StatsContainer = styled.div`
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--steel-blue);
    padding: 30px 60px;
    border-bottom-left-radius: 20px;
`;

export default SpaceAdminHeader;
