import { postApi } from '../api/api';
import { UserType } from '../api/ApiTypes';

export const inviteUserToStation = async ({
    stationId,
    userEmail,
    spaceId,
    userType
}: {
    stationId: string;
    userEmail: string;
    spaceId?: string;
    userType?: UserType;
}) => {
    return postApi(`/station/${stationId}/invite`, { userEmail, spaceId, userType });
};
