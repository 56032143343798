import * as React from 'react';
import styled from 'styled-components/macro';
import { useState } from 'react';
import { SmallGreenButton, SvgIconButton } from '../inputs/Button';
import { ApiSpace, ApiStation } from '../../api/ApiTypes';
import { SpaceUtil } from '../../utils/SpaceUtil';
import goto from '../../images/goto.svg';
import livestream from '../../images/i-livestream-blue.svg'
import { SpaceCardLabel } from './SpaceCardLabel';
import { Link, useHistory } from 'react-router-dom';
import { GreyishBodyText } from '../Typography';
import { EditIcon } from '../../images/icons';
import LinesEllipsis from 'react-lines-ellipsis';
import { InviteToSpaceButton } from '../stationAdmin/InviteToSpace';
import { canShowStreamViewerButton } from '../../permissions/stations'
type SpaceCardProps = {
    space: ApiSpace;
    stationTitle?: string;
    isStationAdmin?: boolean;
    inviteCode?: string;
    isGuestAbleToInvite?: boolean;
    station: ApiStation;
};

const SpaceCard: React.FC<SpaceCardProps> = ({
    stationTitle,
    isStationAdmin,
    inviteCode,
    isGuestAbleToInvite,
    space,
    station
}) => {
    const [hovered, setHovered] = useState(false);
    return (
        <Container>
            <SpaceContainer
                background={SpaceUtil.getImage(space)}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                {/* Duplicated here and on overlay to preserve visibility as per NWR-607 */}
                {(isStationAdmin || isGuestAbleToInvite) && inviteCode && (
                    <InviteButtonContainer>
                        <InviteToSpaceButton
                            inviteCode={inviteCode!}
                            inviteHostCode={space.inviteHostCode!}
                            inviteSpectateCode=""
                            space={space}
                        />
                    </InviteButtonContainer>
                )}
                <WhiteFadeOverlay isWhite={!space.isActive} hovered={hovered} />
                <div
                    onMouseEnter={() => setHovered(true)}
                    style={{ position: 'absolute', bottom: '15px', right: '15px', cursor: 'pointer' }}
                >
                    <SpaceCardLabel
                        isActive={space.isActive}
                        hide={hovered}
                        topRightLine={stationTitle}
                        middleRightLine={space.title}
                        bottomRightLine={space.subtitle}
                        topLeftLine={space.isActive ? 'Active' : 'Closed'}
                        middleLeftLine={space.isActive ? space?.attendance || '0' : '- -'}
                        bottomLeftLine={`of ${space.capacity}`}
                    />
                </div>
                <SpaceDetailOverlay
                    stationTitle={stationTitle}
                    isStationAdmin={isStationAdmin}
                    space={space}
                    hovered={hovered}
                    inviteCode={inviteCode}
                    isGuestAbleToInvite={isGuestAbleToInvite}
                    station={station}
                />
            </SpaceContainer>
        </Container>
    );
};

const Container = styled.div<{ onClick?: () => void }>`
    position: relative;
    width: 100%;
    height: 300px;
`;

const WhiteFadeOverlay = styled.section<{ isWhite: boolean; hovered: boolean }>`
    position: absolute;
    width: 100%;
    height: 300px;
    top: 0;
    border-radius: 20px;
    ${({ hovered }) => hovered && 'cursor: pointer'};
    background-color: ${({ isWhite, hovered }) =>
        isWhite && !hovered ? 'rgba(108, 108, 137, 0.5)' : 'rgba(0, 0, 0, 0)'};
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
`;

interface SpaceDetailOverlayProps {
    space: ApiSpace;
    hovered: boolean;
    stationTitle?: string;
    isStationAdmin?: boolean;
    inviteCode?: string;
    isGuestAbleToInvite?: boolean;
    station: ApiStation;
}

const SpaceDetailOverlay: React.FC<SpaceDetailOverlayProps> = ({
    stationTitle,
    space,
    hovered,
    isStationAdmin,
    inviteCode,
    isGuestAbleToInvite,
    station
}) => {
    const history = useHistory();
    const handleEditSpace = () => history.push(`/space/${space.id}/`);
    const userCannotLaunchSpace = !isStationAdmin && !space.isActive;


    return (
        <div>
            <OverlayContainer hovered={hovered} isActive={space.isActive}>
                {(isStationAdmin || isGuestAbleToInvite) && inviteCode && (
                    <InviteButtonContainer>
                        <InviteToSpaceButton
                            inviteCode={inviteCode!}
                            inviteHostCode={space.inviteHostCode!}
                            inviteSpectateCode=""
                            space={space}
                        />
                    </InviteButtonContainer>
                )}
                <h5 className="noTranslate">{stationTitle}</h5>
                <h3 className="noTranslate">{space.title}</h3>
                <h4 className="noTranslate" style={{ marginBottom: '10px' }}>
                    {space?.subtitle}
                </h4>
                <GreyishBodyText className="noTranslate">
                    <LinesEllipsis text={space?.description} maxLine="4" />
                </GreyishBodyText>
                <br />

                <ButtonContainer>
                    <Link
                        to={`/space/${space.id}/lobby`}
                        target="_blank"
                        style={{
                            textDecoration: 'none',
                            pointerEvents: userCannotLaunchSpace ? 'none' : 'auto',
                            cursor: 'not-allowed'
                        }}
                    >
                        <SmallGreenButton disabled={userCannotLaunchSpace} style={{ marginBottom: '15px' }}>
                            <img style={{ marginRight: '10px' }} src={goto} alt="go to space" />
                            {space.isActive ? 'Launch' : isStationAdmin ? 'Open' : 'Closed'}
                        </SmallGreenButton>
                    </Link>

                  {canShowStreamViewerButton(station, space) && ( <StreamButton>
                        <Link
                            to={`/space/${space.id}/lite`}
                            target="_blank"
                            style={{
                                textDecoration: 'none',
                                pointerEvents: 'auto',
                                cursor: 'not-allowed',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <SmallGreenButton disabled={false} style={{ marginBottom: '15px' }}>
                                <img style={{ marginRight: '10px', backgroundSize: '17px' }} src={livestream} alt="go to stream viewer" />
                                {'Stream Viewer'}
                            </SmallGreenButton>
                        </Link>
                    </StreamButton>
)} 
                    {isStationAdmin && (
                        <EditButton>
                            <SvgIconButton
                                iconType="stroke"
                                baseColor="var(--off-white)"
                                activeColor="var(--navy-blue)"
                                onClick={handleEditSpace}
                            >
                                <EditIcon />
                                Edit
                            </SvgIconButton>
                        </EditButton>
                    )}
                </ButtonContainer>
            </OverlayContainer>
        </div>
    );
};

const OverlayContainer = styled.section<{ hovered: boolean; isActive?: boolean }>`
    width: 100%;
    height: 300px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    background-color: rgba(47, 45, 134, 0);
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    opacity: 0;
    padding: 30px 140px 70px 60px;
    ${({ hovered, isActive }) =>
        hovered &&
        `opacity: 1; background-color: ${isActive ? 'var(--dark-indigo);' : 'var(--charcoal-grey);'} cursor: pointer`};
`;

const InviteButtonContainer = styled.div`
    position: absolute;
    right: 15px;
    top: 30px;
`;

const ButtonContainer = styled.div`
    z-index: 100;
    position: absolute;
    padding-left: 60px;
    left: 0px;
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
`;

const EditButton = styled.div``;

const StreamButton = styled.div`
    margin-left: 10px;
`;

interface StyledContainerProps {
    background: string;
}

const SpaceContainer = styled.article<StyledContainerProps>`
    border-radius: 15px;
    height: 100%;
    width: 100%;
    background-image: url('${({ background }) => background}');
    background-size: cover;
    background-position: center center;
    position: relative;
`;

export default SpaceCard;
