import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { LargeButton, StickyButton } from '../components/inputs/Button';
import EmptyListCard from '../components/layouts/EmptyListCard';
import { EventsCTA } from '../components/layouts/CTAFooter';
import noConnectionsImage from '../images/nwCorp_v035.jpg';
import ConnectionList from '../components/orbit/ConnectionList';

const Orbit: React.FunctionComponent = () => {
    const history = useHistory();

    return (
        <>
            <Container>
                <TitleSection>
                    <div>
                        <h1>My Orbit</h1>
                        <h5>Your Nowhere rolladex</h5>
                    </div>
                </TitleSection>
                <ConnectionList>
                    <NoConnectionComponent />
                </ConnectionList>
            </Container>
            <StickyButton onClick={() => history.push('/profile-edit')} disabled={false}>
                Edit My Information
            </StickyButton>
            <EventsCTA />
        </>
    );
};

const NoConnectionComponent = () => {
    const history = useHistory();

    return (
        <EmptyListCard background={noConnectionsImage}>
            <div style={{ maxWidth: '500px' }}>
                Hmm… No connections yet.
                <br />
                Click on someone in a space to grow your Orbit.
            </div>
            <div>
                <LargeButton onClick={() => history.push('/spaces')}>
                    Go to Spaces to start meeting people
                </LargeButton>
            </div>
        </EmptyListCard>
    );
};

const Container = styled.div`
    margin: 0px auto;
    justify-content: center;
    padding: 30px 45px;
    @media (min-width: 1280px) {
        width: 1260px;
        padding: 30px 0;
    }
`;

const TitleSection = styled.section`
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
    }
    width: 100%;
    justify-content: space-between;
    margin: 0 0 45px 30px;
`;

export default Orbit;
