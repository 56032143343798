import FormErrorMessage from './FormErrorMessage';
import * as React from 'react';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { FieldError } from 'react-hook-form';
import { OutlinedInput, OutlinedTextarea } from './OutlinedInput';
import styled from 'styled-components/macro';
import { GreyishBodyText } from '../Typography';
import LineBreaksToBrTags from '../../utils/LineBreaksToBrTags';
import InfoIcon from '../../images/atom-i-info-alt.svg';
import ReactTooltip from 'react-tooltip'

// this requires that the 'errors' prop be populated with the errors
// attribute from the react-hook-form library's useForm hook
// the validation must be a call to the register function provided by the
// same hook.

const ValidatedFormInput: React.FC<
    DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
        HTMLInputElement | HTMLTextAreaElement
    > & {
        errors: DeepMap<Record<string, any>, FieldError>;
        validation: any;
        name: string;
        labelText?: string;
        labelElement?: JSX.Element;
        subtitleText?: string;
        value?: string | number;
        placeholder?: string;
        onChange: any;
        interactiveInfo?: { dataTip: string; infoIcon: string; }
    }
> = ({ errors, value, placeholder, validation, onChange, name, labelText, labelElement, subtitleText, type, interactiveInfo, ...rest }) => {
    return (
        <Container>
            <InputContainer>
                {labelText && <label htmlFor={name}>
                    {labelText}
                    {interactiveInfo ? (
                        <span>
                            <ReactTooltip effect="solid" />
                            <InteractiveInfo data-tip={interactiveInfo.dataTip} src={interactiveInfo.infoIcon} />
                        </span>
                    ): null }
                </label>}
                {labelElement && labelElement}
                {subtitleText && (
                    <>
                        <GreyishBodyText style={{ marginBottom: '10px' }}>
                            {LineBreaksToBrTags(subtitleText)}
                        </GreyishBodyText>
                    </>
                )}

                {type === 'textarea' ? (
                    <OutlinedTextarea value={value || ''} onChange={onChange} ref={validation} name={name} {...rest} />
                ) : (
                    <OutlinedInput
                        value={value || ''}
                        placeholder={placeholder || ''}
                        onChange={onChange}
                        ref={validation}
                        name={name}
                        type={type}
                        {...rest}
                    />
                )}
            </InputContainer>
            {errors?.[name]?.message && <FormErrorMessage error={errors?.[name]?.message} />}
        </Container>
    );
};

const Container = styled.div``;

const InputContainer = styled.div`
    width: 100%;
    margin: 0 0 15px 0;
    display: flex;
    flex-direction: column;
`;

export const TwoInputsOneLine = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (min-width: 768px) {
        > * {
            width: 100%;
            &:not(:first-child) {
                margin-left: 15px;
            }
        }
        flex-direction: row;
    }
`;


const InteractiveInfo = styled.img`
    margin-left: 10px;
    margin-top: -4px;
    fill: #ffffff;
`;


export default ValidatedFormInput;
