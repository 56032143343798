import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../store/actions';
import * as fb from 'firebase/app';
import 'firebase/auth';
import { putApi, getApi } from '../api/api';
import { ApiUser } from '../api/ApiTypes';
import { translationHelper } from '../utils/TanslationHelper';
import styled from 'styled-components/macro';
import LoadingComponentLobby from '../components/loading/LoadingComponentLobby';

const EmbedNowhere: React.FC = () => {
  const history = useHistory();

  const { meetInviteCode, provider } = useParams() as any;
  const [error, setError] = useState(false);
  const firebase = useFirebase();
  const dispatch = useDispatch();

  const query = new URLSearchParams(document.location.search);
  const ui = query.get('ui') || 'v2';
  const userToken = query.get('userToken') || '';
  const inviteManagerCode = query.get('inviteManagerCode') || '';
  const skyboxUrl = query.get('skyboxUrl') || '';
  const userLang = query.get('lang') || '';

  const getUser = async (): Promise<ApiUser> => {
    return await getApi('/user/me').then(async (response) => {
        let user = null;
        try{
            user = await response.json();
        } catch(event){
            console.log('User response is not valid json', response);
        }
        return user as ApiUser;
    })
  };

  useEffect(() => {
    if(userLang) translationHelper.translate(userLang);

    (async () => {
     if(provider && userToken) {
       const response = await putApi(`/thirdPartyAuth/${provider}`, { userToken, meetInviteCode, inviteManagerCode }).catch(() => setError(true));
       if (!response || !response.ok) {
        setError(true);
        return;
       }
       const { nwrAuthToken } = await response.json();

       if(!nwrAuthToken) {
         setError(true);
         return;
       }

       const result = await firebase.auth().signInWithCustomToken(nwrAuthToken).then(async (userCredential) => {
          // Signed in
          //var user = userCredential.user;
          const user = await getUser();
          dispatch(setCurrentUser(user));
        })
     }

      const response = await putApi(`/meet/invite/${meetInviteCode}`).catch(() => setError(true));
        if (!response || !response.ok) {
          setError(true);
          return;
        }
         const { success, meetInvite } = await response.json();

         if(!success) {
           setError(true);
           return;
         }

         const isSpectateInvite = ((meetInvite?.roleGrant?? 'MEMBER') === 'SPECTATOR');

         if(skyboxUrl) {
           await putApi(`/space/${meetInvite.spaceId}/skybox`, { skyboxUrl }).catch((e: Error) => console.log('Cold not set skyboxUrl with error: ', e));
         }

         history.push(`/space/${meetInvite.spaceId}/lobby?ui=${ui}${isSpectateInvite? '&isForcedSpectate=true': ''}${userLang? ('&lang=' + userLang): ''}`);
    })();
  }, [meetInviteCode]);

  return <EmbedDiv>
    <h3>{error? 'Access denied...': ''}</h3>
    <LoadingComponentLobby />
  </EmbedDiv>;
}

const EmbedDiv = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: red;
`;

export default EmbedNowhere;
