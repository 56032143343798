import * as React from 'react';
import Message from '../components/layouts/Message';
import FullScreenContainer from '../components/layouts/FullScreenContainer';
import ResetPasswordConfirm from '../components/emailHandlers/ResetPasswordConfirm';
import VerifyEmailConfirm from '../components/emailHandlers/VerifyEmailConfirm';
import SignInFromEmail from '../components/emailHandlers/SignInFromEmail';

const FirebaseEmailHandler = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('oobCode') as string;
    const mode = urlParams.get('mode');
    const continueUrl = (() => {
        try {
            const url = new URL(urlParams.get('continueUrl')!);
            return url.pathname + url.search;
        } catch (e) {
            console.log("Continue URL not found with error: ", e);
        }
        return '/';
    })();

    if (mode === 'resetPassword') {
        return <ResetPasswordConfirm code={code} />;
    }

    if (mode === 'verifyEmail') {
        return <VerifyEmailConfirm code={code} />;
    }

    if (mode === 'signIn') {
        return <SignInFromEmail continueUrl={continueUrl || '/'} />;
    }

    return (
        <FullScreenContainer>
            <Message
                title="Whoops! Something went wrong"
                body="If you're seeing this message by mistake, please contact a Nowhere admin and let them know"
            />
        </FullScreenContainer>
    );
};

export default FirebaseEmailHandler;
