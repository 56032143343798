import * as React from 'react';
import { useEffect, useState } from 'react';
import TwoColumnPage, { SpaceCount } from '../components/layouts/TwoColumnPage';
import AdminSpaceList from '../components/stationAdmin/AdminSpaceList';
import { useHistory, useParams } from 'react-router-dom';
import LoadingScreen from '../components/loading/LoadingScreen';
import styled from 'styled-components/macro';
import { RedButton, SmallButton } from '../components/inputs/Button';
import FourOhFour from './404';
import { AddItem } from '../components/station/SpaceCardLabel';
import Message from '../components/layouts/Message';
import InviteToStation from '../components/stationAdmin/InviteToStation';
import { getApi, postApi, deleteApi, putApi } from '../api/api';
import { ApiStationDetails } from '../api/ApiTypes';
import eye from '../images/eye.svg';
import edit from '../images/edit-icon.svg';
import { StationUserTable, StationBlockedUserTable, ManagerTable } from '../components/stationAdmin/UserTable';
import { StationDetailContext } from '../context/stationContext';
import { ThinTitle } from '../components/Typography';
import { StationUtil } from '../utils/StationUtil';
import AdminPanel from '../components/stationAdmin/AdminPanel';
import StreamParent from '../components/streaming/StreamParent';
import LineBreaksToBrTags from '../utils/LineBreaksToBrTags';
import AllSpaceToggle from '../components/stationAdmin/AllSpaceToggle';
import { useSelector } from 'react-redux';
import { getCurrentUser, getCurrentUserIsAdmin } from '../store/selectors';
import { isStationManager, canLiveStream } from '../permissions/stations';
import { Modal } from '../components/layouts/Modal';
import useInterval from '../hooks/useInterval';
import StreamProvider from '../components/streaming/StreamContext';
import { envAsBool } from '../utils/envLoader';
import { canAdmin } from '../permissions/users';
import { canViewAttendeeContactInfo, canUseDistributedInvite, canMemberEditSpaces } from '../permissions/stations';
import equal from 'fast-deep-equal';
const StationAdmin: React.FunctionComponent = (_props) => {
    const [loading, setLoading] = useState(true);
    const [stationDetails, setStationDetails] = useState(({} as unknown) as ApiStationDetails);
    const [stationDetailsWithStats, setStationDetailsWithStats] = useState((undefined as unknown) as ApiStationDetails);
    //const [shouldStripPlayerEmails, setShouldStripPlayerEmails] = useState(true);
    const { stationId } = useParams() as any;
    const skipTableResetRef = React.useRef<any>(null);
    const { station, spaces, users } = stationDetailsWithStats || stationDetails;
    const user = useSelector(getCurrentUser);
    const isManager = isStationManager(station, user);
    const history = useHistory();

    const stripPlayerEmails = () => {
      if(canAdmin(user)) return false;
      return !canViewAttendeeContactInfo(stationDetails.station);
    }

    const getStationInfo = () => {
        getApi(`/station/${stationId}`)
        .then(async (stationDetailsResponse) => {
            if (stationDetailsResponse.ok) {
                const r1 = await stationDetailsResponse.json();
                skipTableResetRef.current = true;
                if(!equal(r1, stationDetails)){
                    setStationDetails(r1);
                }
            }
            getApi(`/station/${stationId}/details`)
            .then(async (stationDetailsResponse) => {
                if (stationDetailsResponse.ok) {
                    const r2 = await stationDetailsResponse.json();
                    skipTableResetRef.current = true;
                    if(!equal(r2, stationDetailsWithStats)){
                        setStationDetailsWithStats(r2);
                        setLoading(false);
                    }
                }
            });
        })
    }

    useEffect(() => {
      if(!canAdmin(user) && stationDetails?.station?.isMeet) {
          history.push(`/meet/station/${stationDetails.station.id}`);
          return;
      }
    }, [stationDetails, user]);

    useEffect(() => {
        getStationInfo()
    }, [stationId]);

    useInterval(() => {
        getStationInfo();
    }, 5000);

    const regenerateInviteCode = (isManagerCode: boolean) => {
        const endpoint = isManagerCode ? 'regenerateInviteManagerCode' : 'regenerateStationInviteCode';
        const key = isManagerCode ? 'inviteManagerCode' : 'inviteCode';

        postApi(`/station/${stationId}/${endpoint}`)
            .then(async (regenerateCodeResponse) => {
                if (regenerateCodeResponse.ok) {
                    const data = await regenerateCodeResponse.json();
                    setStationDetailsWithStats({
                        ...stationDetailsWithStats,
                        station: {
                            ...stationDetailsWithStats.station,
                            [key]: data.station[key]
                        }
                    });
                }
            })
            .catch((error) => console.error(error));
    };

    const regenerateStationDistributionInviteCode = () => {
      putApi(`/meet/station/${stationId}/regenDistributionInvite`).then((res) => {
        setStationDetailsWithStats({ ...stationDetailsWithStats });
      }).catch((e: Error) => {
         console.error(e);
      });
    }

    const removeAllMembers = () => {
        postApi(`/station/${stationId}/users/removeAll`)
            .then(async (removeResponse) => {
                if (removeResponse.ok) {
                    const json = await removeResponse.json();
                    setStationDetailsWithStats({
                        ...stationDetailsWithStats,
                        users: stationDetailsWithStats.users.filter(
                            (user) => !json.removedUserIds.includes(user.user.id)
                        )
                    });
                }
            })
            .catch((error) => console.error(error));
    };

    if (loading) return <LoadingScreen />;
    if (!stationDetails) return <FourOhFour />;
    if (!isManager) return <FourOhFour />;

    return (
        <StationDetailContext.Provider
            value={{ stationDetails: stationDetailsWithStats, setStationDetails: setStationDetailsWithStats }}
        >
            <TwoColumnPage
                title={station?.subtitle || ''}
                leftColumn={
                    <>
                        <SpacePanelHeader>
                            <SpaceCount spaces={spaces} />
                            <AllSpaceToggle spaces={spaces} stationId={stationId} />
                        </SpacePanelHeader>
                        <LeftContainer>
                            <AddItem text="New Space" link={`/station/${stationId}/createSpace`} />
                            <AdminSpaceList spaces={spaces} />
                        </LeftContainer>
                    </>
                }
                rightColumn={
                    <Body
                        stationDetails={stationDetailsWithStats || stationDetails}
                        regenerateInviteCode={regenerateInviteCode}
                        regenerateStationDistributionInviteCode={regenerateStationDistributionInviteCode}
                    />
                }
            />

            <UserLists>
                <ManagerTable users={users} skipRef={skipTableResetRef} stripEmail={!canMemberEditSpaces(station, user) || stripPlayerEmails()}/>
                <StationUserTable users={users} stripEmail={!canMemberEditSpaces(station, user) || stripPlayerEmails()} removeAllMembers={removeAllMembers} skipRef={skipTableResetRef}/>
                <StationBlockedUserTable  stripEmail={!canMemberEditSpaces(station, user) || stripPlayerEmails()} users={users} skipRef={skipTableResetRef}/>
            </UserLists>
        </StationDetailContext.Provider>
    );
};

const SpacePanelHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 20px;
    align-items: center;
`;

const LeftContainer = styled.div`
    border-radius: 20px;
    box-shadow: inset 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--midnight);
`;

const UserLists = styled.div`
    padding: 60px 60px 120px 30px;
`;
interface BodyParams {
    stationDetails: ApiStationDetails;
    regenerateInviteCode: (manager: boolean) => void;
    regenerateStationDistributionInviteCode: () => void;
}

const Body: React.FC<BodyParams> = ({ stationDetails, regenerateInviteCode, regenerateStationDistributionInviteCode }) => {
    const { station } = stationDetails;
    const { stationId } = useParams() as any;
    const [followerCount, followerCountSetter] = useState<number | null>(null);

    const title = LineBreaksToBrTags(station?.title);
    const description = LineBreaksToBrTags(station?.description);
    const user = useSelector(getCurrentUser);
    const isManager = isStationManager(stationDetails?.station, user);
    const [showStationDeleteModal, setShowStationDeleteModal] = useState(false);
    const isAdmin = useSelector(getCurrentUserIsAdmin);
    const stationImg = StationUtil.getImage(station);
    const history = useHistory();

    useEffect(() => {
        getFollowerCount();
    }, []);

    const deleteStation = () => {
        deleteApi(`/station/${stationId}/`)
            .then(() => history.push(`/managed`))
            .catch((error) => console.error(error));
    };

    const getFollowerCount = () => {
        getApi(`/station/${stationId}/following/count`)
        .then(async (res) => {
            const r1 = await res.json();

            if (res.ok) {
                followerCountSetter(r1.count);
            } else {
                console.error('Failed to get /following/count', r1);
            }
        });
    };

    return (
        <>
            <Modal handleClose={() => setShowStationDeleteModal(false)} isOpen={showStationDeleteModal}>
                <Message
                    title={`Are you sure you want to delete this station?`}
                    body="This cannot be undone."
                    onCancel={() => setShowStationDeleteModal(false)}
                    onConfirm={deleteStation}
                    confirmButtonText={'Delete Station'}
                />
            </Modal>
            <ResponsiveWrapper>
                <TopContainer>
                    <ImageContainer>
                        <Image src={stationImg} />
                        <CountTag>Followers: <span className="noTranslate">{followerCount}</span></CountTag>
                    </ImageContainer>
                    <ActionsContainer>
                        <SmallButton
                            onClick={() => history.push(`/station/${stationId}`)}
                            icon={<img src={eye} alt="view" />}
                        >
                            Preview
                        </SmallButton>
                        <SmallButton
                            onClick={() => history.push(`/station/${stationId}/edit`)}
                            icon={<img src={edit} alt="edit" />}
                        >
                            Edit
                        </SmallButton>
                        {isAdmin && <RedButton onClick={() => setShowStationDeleteModal(true)}>Delete</RedButton>}
                    </ActionsContainer>
                </TopContainer>
                <div className="noTranslate">
                    <ThinTitle>{title}</ThinTitle>
                    <h4 style={{ marginBottom: '30px' }}>Host: {station?.description}</h4>
                    <h5 style={{ maxWidth: '626px', marginBottom: '30px' }}>{description}</h5>
                </div>
            </ResponsiveWrapper>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <InviteToStation inviteCode={station.inviteCode!} inviteManagerCode={station.inviteManagerCode!} station={stationDetails} />
            </div>

            <AdminPanel isManager={isManager} title="Regenerate Invites">
                <ul>
                    <li>
                        <SmallButton onClick={() => regenerateInviteCode(false)}>Regenerate Invite Code</SmallButton>
                        <br />
                    </li>
                    <li>
                        <SmallButton onClick={() => regenerateInviteCode(true)}>
                            Regenerate Manager Invite Code
                        </SmallButton>
                        {envAsBool('ENABLE_DISTRIBUTED_INVITE') && canUseDistributedInvite(station) && <br />}
                    </li>
                    {envAsBool('ENABLE_DISTRIBUTED_INVITE') && canUseDistributedInvite(station) && <li>
                        <SmallButton onClick={() => regenerateStationDistributionInviteCode()}>
                            Regenerate Distributed Invite
                        </SmallButton>
                    </li>}
                </ul>
            </AdminPanel>
            {envAsBool('ENABLE_STREAMING') && stationDetails && /* canLiveStream(station) && */
                <StreamProvider>
                    <StreamParent></StreamParent>
                </StreamProvider>
            }
            { console.log(`streaming feature: ${envAsBool('ENABLE_STREAMING')}`) }
            { console.log(`canManageStreams: ${canLiveStream(station)}`) }
        </>
    );
};

const ResponsiveWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 768px) {
        flex-direction: column;
    }
`;

const ActionsContainer = styled.section`
    width: 100%;
    min-width: 150px;
    display: flex;
    flex-direction: row;
    margin: 15px 30px 0 0;

    @media (min-width: 768px) {
        width: auto;
    }
    @media (min-width: 1024px) {
        flex-direction: column;
        align-items: flex-end;
        margin: 0 60px;
    }
    > button {
        margin: 0px 15px 15px 0;
    }
`;

const TopContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1024px) {
        flex-direction: row;
        align-items: flex-start;
    }
    justify-content: space-between;
    margin: 60px 0;
`;

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 240px;
    max-width: 540px;
`;

const CountTag = styled.div`
    position: absolute;
    top: 7px;
    right: 7px;
    background-color: var(--midnight);
    border-radius: 5px;
    padding: 0 7px 0 7px;
`;
// const Image = styled.div<{imgSource: string}>`
// background-image: url(${(props) => props.imgSource});
const Image = styled.img`
    border-radius: 15px;
    width: 100%;
    height: 100%;
    max-height: 240px;
    max-width: 540px;
    object-fit: cover;
`;

export default StationAdmin;
