import styled from 'styled-components/macro';
import { verticalCentering } from '../../css/positioning';

const FullScreenContainer = styled.div`
    ${verticalCentering};
    width: 100vw;
    height: 100vh;
    flex-direction: column;
`;

export default FullScreenContainer;
