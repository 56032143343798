import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import useInterval from '../../hooks/useInterval';
import { getApi } from '../../api/api';
import { ApiSpace } from '../../api/ApiTypes';
import SpaceCard from './SpaceCard';

import { ReactComponent as HistoryIcon } from '../../images/i-history.svg';

const liveUpdate = false;
const UPDATE_RATE = 15000;

const History = () => {
    const [spaces, spacesSetter] = useState<ApiSpace[] | null>(null);
    const [isLoaded, isLoadedSetter] = useState(false);

    useEffect(() => {
        getHistory().then(() => isLoadedSetter(true));
    }, []);

    if (liveUpdate) useInterval(() => getHistory(), UPDATE_RATE);

    const getHistory = async () => {
        getApi(`/spaces/user/history`).then(async (response) => {
            const res = (await response.json()) as ApiSpace[];
            console.log('get history of spaces', res);

            spacesSetter(res);
        });
    }

    const Grid = () => {
        if (!isLoaded) return <>Loading...</>
        if (isLoaded && (!spaces || spaces.length === 0)) {
            return <div></div>;
        }

        return (
            <HistoryGrid>
                {spaces!.map((space, i) => {
                    return <SpaceCard key={i} space={space} dynamic={true}/>
                })}
            </HistoryGrid>
        );
    };

    return (
        <HistoryContainer>
            <HistoryTitleContainer>
                <StyledHistoryIcon />
                <div>Previously Visited</div>
            </HistoryTitleContainer>
            <Grid />
        </HistoryContainer>

    );
};

const HistoryContainer = styled.div`

`;

const HistoryTitleContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 30px;
    margin-bottom: 24px;
    font-weight: bold;
`;

const StyledHistoryIcon = styled(HistoryIcon)`
    width: 24px;
    height: 24px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
    & path {
        fill: var(--tennis-green);
    }
`;

const HistoryGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(244px, 1fr));
    justify-content: space-between;
    row-gap: 10px;
`;


export default History;
