import { getApi, putApi } from '../api/api';


export const getAllUserState = () => {
  return getApi('/user/me/state').then((res) => {
    if(!res.ok) return {};
    return res.json();
  }).catch(() => { return {}; });
}

export const getUserStateByKey = (key: string) => {
  return getApi(`/user/me/state/${key}`).then((res) => {
    if(!res.ok) return {};
    return res.json();
  }).catch(() => { return {}; });
}

//expiration in seconds
export const setUserState = (key: string, value: { [key: string]: any }, expiration?: number) => {
  return putApi(`/user/me/state/${key}`, ( (expiration !== undefined)? { value, expiration }: { value } )).then((res) => {
    if(!res.ok) return {};
    return res.json();
  });
}

export const deleteUserStateByKey = (key: string) => {
  return setUserState(key, {}, 0);
}
