import * as React from 'react';
import { GreyishBodyText } from '../Typography';
import ValidatedFormInput, { TwoInputsOneLine } from '../inputs/ValidatedFormInput';
import Divider from '../Divider';
import { Link } from 'react-router-dom';
import ConnectionCard from './ConnectionCard';
import styled from 'styled-components/macro';
import UploadProfilePic, { doImageUpload } from './UploadProfilePic';
import { postApi } from '../../api/api';
import { setCurrentUser } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import UploadProfilePicCredentials from './UploadProfilePicCredentials';
import { AuthButton, BaseButton, EditButton, LargeButton, OutlineButton, SmallGreenButton } from '../inputs/Button';
import Cookies from 'js-cookie';
import { ActionButtonPrimary } from '../modals/Modal';
import { useForm } from 'react-hook-form';
import { write3DAccessableCookie } from '../../utils/CookieUtil';
import { deleteUserStateByKey, setUserState } from '../../utils/UserStateHelper';
interface SocialCardFormContentsProps {
    updateUserProfile: (userProfile: any) => void;
    userProfile: any;
}

const CredentialsFormContent: React.FC<SocialCardFormContentsProps> = ({ updateUserProfile, userProfile }) => {
    const useFormObject = useForm();
    const { register, errors, handleSubmit } = useFormObject;

    const dispatch = useDispatch();

    const now = new Date();
    const handleSkip = () => {
        setUserState('CredentialModalOptOut', { optOutTime: now.getTime() }, 86400);
       // Cookies.set('CredentialModalOptOut', 'temp', { expires: 1 });
        window.location.reload();
    };

    const doNotPester = () => {
        deleteUserStateByKey('CredentialModalOptOut');
        setUserState('CredentialModalOptOut', { optOutTime: now.getTime() });
/*         Cookies.remove('CredentialModalOptOut', 'temp', { expires: 1 });
        Cookies.set('CredentialModalOptOut', 'perm'); */
        window.location.reload();
    };

    const [updating, setUpdating] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [apiError, setApiError] = useState('');

    const updateProfile = async () => {
        setUpdating(true);
        setUpdated(false);
        setApiError('');

        postApi('/user/me/update', {
            userType: userProfile.userType,
            avatarUrl: userProfile.avatarUrl,
            badgeSubtitle: userProfile.badgeSubtitle,
            company: userProfile.company,
            displayName: userProfile.displayName,
            social: userProfile.social,
            title: userProfile.title,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            url: userProfile.url,
            phone: userProfile.phone,
            displayEmail: userProfile.displayEmail
        })
            .then(async (r) => {
                const response = await r.json();
                if (!r.ok) {
                    if (response.apiError?.isJoi) {
                        console.log(response.apiError.details.map((d: any) => d.message).join(', '));
                        setApiError(response.apiError.details.map((d: any) => d.message).join(', '));
                    } else {
                        setApiError('Something went wrong');
                    }
                } else {
                    setUpdated(true);
                    setUserState('CredentialModalOptOut', { optOutTime: now.getTime() });
                  //  Cookies.set('CredentialModalOptOut', 'perm');
                    dispatch(setCurrentUser(response));
                    window.location.reload();

                    setTimeout(() => {
                        setUpdated(false);
                    }, 30000);
                }
                setUpdating(false);
            })
            .catch((err) => {
                setApiError(err.message);
            });
    };

    const onImageChanged = async (url: string) => {
        await doImageUpload(url)
            .then((newAvatarUrl) => {
                return postApi('/user/me/update', {
                    avatarUrl: newAvatarUrl || userProfile.avatarUrl,
                    userType: userProfile.userType,
                    badgeSubtitle: userProfile.badgeSubtitle,
                    company: userProfile.company,
                    displayName: userProfile.displayName,
                    social: userProfile.social,
                    title: userProfile.title,
                    firstName: userProfile.firstName,
                    lastName: userProfile.lastName,
                    url: userProfile.url,
                    phone: userProfile.phone,
                    displayEmail: userProfile.displayEmail,
                    tippingEmail: userProfile.tippingEmail,
                    optInMarketing: userProfile.optInMarketing
                });
            })
            .then(async (r) => {
                const response = await r.json();
                if (!r.ok) {
                    if (response.apiError?.isJoi) {
                        console.log(response.apiError.details.map((d: any) => d.message).join(', '));
                        // setApiError(response.apiError.details.map((d: any) => d.message).join(', '));
                    } else {
                        //   setApiError('💣 Something went wrong with uploading your image 🙁');
                    }
                } else {
                    setUpdated(true);
                    dispatch(setCurrentUser(response));
                    setTimeout(() => {
                        setUpdated(false);
                    }, 30000);
                }
            });
    };

    return (
        <>
            <Container>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    <UploadProfilePicCredentials currentImage={userProfile.avatarUrl} onImageChanged={onImageChanged} />
                    {updated && <h3>Update Successful!</h3>}
                </div>
                <div style={{ marginBottom: '30px', width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        <br />
                        <TwoInputsOneLine>
                            <ValidatedFormInput
                                labelText="Public Name*"
                                type="text"
                                errors={errors}
                                validation={register({
                                    required: 'Display name is required'
                                })}
                                maxLength={45}
                                name="DisplayName"
                                value={
                                    userProfile.displayName ||
                                    `${userProfile.firstName ?? ''} ${userProfile.lastName ?? ''}`
                                }
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        displayName: e.target.value
                                    })
                                }
                            />

                            <ValidatedFormInput
                                labelText="Subtitle"
                                type="text"
                                errors={errors}
                                validation={register({})}
                                maxLength={26}
                                name="BadgeSubtitle"
                                value={userProfile.badgeSubtitle}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        badgeSubtitle: e.target.value
                                    })
                                }
                            />
                        </TwoInputsOneLine>
                    </div>
                    <Divider />
                    <OrbitContainer>
                        <h3>My Contact Card</h3>
                        <GreyishBodyText>
                            Connect with others to exchange info. Find your contacts on the Orbit Page.&nbsp;
                            <Link to="/orbit">
                                <GreyLink>Orbit Page</GreyLink>.
                            </Link>
                        </GreyishBodyText>
                        <br />

                        <TwoInputsOneLine>
                            <ValidatedFormInput
                                labelText="Title"
                                type="text"
                                errors={errors}
                                validation={register({})}
                                maxLength={200}
                                name="JobTitle"
                                value={userProfile.title}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        title: e.target.value
                                    })
                                }
                            />

                            <ValidatedFormInput
                                labelText="Company"
                                type="text"
                                errors={errors}
                                validation={register({})}
                                maxLength={200}
                                name="company"
                                value={userProfile.company}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        company: e.target.value
                                    })
                                }
                            />
                        </TwoInputsOneLine>

                        <TwoInputsOneLine>
                            <ValidatedFormInput
                                labelText="Display Email"
                                type="text"
                                errors={errors}
                                validation={register({
                                    pattern: {
                                        value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i,
                                        message: 'Please import a valid email'
                                    }
                                })}
                                maxLength={200}
                                name="displayEmail"
                                value={userProfile.displayEmail}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        displayEmail: e.target.value
                                    })
                                }
                            />

                            <ValidatedFormInput
                                labelText="Phone"
                                type="text"
                                errors={errors}
                                validation={register({})}
                                maxLength={200}
                                name="phone"
                                value={userProfile.phone}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        phone: e.target.value
                                    })
                                }
                            />
                        </TwoInputsOneLine>

                        <TwoInputsOneLine>
                            <ValidatedFormInput
                                labelText="Website"
                                type="text"
                                errors={errors}
                                validation={register({
                                    pattern: {
                                        value: /^(?:https?:\/\/)?(?!www | www\.)[A-Za-z0-9_-]+\.+[A-Za-z0-9./%&=?_:;-]+$/,
                                        message: 'Please input a valid url'
                                    }
                                })}
                                maxLength={200}
                                name="url"
                                value={userProfile.url}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        url: e.target.value
                                    })
                                }
                            />

                            <ValidatedFormInput
                                labelText="Linked-in URL"
                                type="text"
                                errors={errors}
                                validation={register({
                                    validate: (value: string) =>
                                        !value || value.match(/^(?:https?:\/\/)?(www\.)?linkedin.com\/in\/.+/)
                                            ? true
                                            : 'Please enter a valid Linked-in URL'
                                })}
                                maxLength={200}
                                name="linkedin"
                                value={userProfile.social?.linkedIn}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        social: {
                                            ...userProfile.social,
                                            linkedIn: e.target.value
                                        }
                                    })
                                }
                            />
                        </TwoInputsOneLine>

                        <TwoInputsOneLine>
                            <ValidatedFormInput
                                labelText="Twitter Handle"
                                type="text"
                                errors={errors}
                                validation={register({
                                    pattern: {
                                        value: /^[a-zA-Z0-9_]{1,15}$/,
                                        message: 'Please input just your twitter username (no @)'
                                    }
                                })}
                                maxLength={200}
                                name="twitter"
                                value={userProfile.social?.twitter}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        social: {
                                            ...userProfile.social,
                                            twitter: e.target.value
                                        }
                                    })
                                }
                            />

                            <ValidatedFormInput
                                labelText="Instagram Handle"
                                type="text"
                                errors={errors}
                                validation={register({
                                    pattern: {
                                        value: /^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/,
                                        message: 'Please input just your instagram handle (no @)'
                                    }
                                })}
                                maxLength={200}
                                name="instagram"
                                value={userProfile.social?.instagram}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        social: {
                                            ...userProfile.social,
                                            instagram: e.target.value
                                        }
                                    })
                                }
                            />
                        </TwoInputsOneLine>
                    </OrbitContainer>
                    <FlexRow>
                        <StyledActionButtonPrimary onClick={handleSubmit(updateProfile)}>
                            Save & Continue
                        </StyledActionButtonPrimary>
                        <StlyedOutlinedButton onClick={handleSkip} style={{ marginRight: '30px' }}>
                            Skip
                        </StlyedOutlinedButton>
                        <DontAskAgain onClick={doNotPester}>Don&apos;t ask again</DontAskAgain>
                    </FlexRow>
                </div>
            </Container>
        </>
    );
};

const DontAskAgain = styled.div`
    font-family: all-round-gothic;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.14px;
    color: white;
    text-decoration: underline;
    margin-left: 15px;
    cursor: pointer;
    @media (max-width: 768px) {
        margin-left: 0px;
        margin-top: 20px;
    }
`;

const StyledActionButtonPrimary = styled(ActionButtonPrimary)`
    margin-right: 15px;
    @media (max-width: 768px) {
        margin-right: 0px;
        margin-bottom: 20px;
    }
`;

const FlexRow = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    //below 500px
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const StlyedOutlinedButton = styled(OutlineButton)`
    position: relative;
    font-size: 16px;
    margin-bottom: 0 !important;
`;
const ValidatedFormInputMarginRight = styled(ValidatedFormInput)`
    margin-right: 20px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    //below 500px
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const OrbitContainer = styled.div`
    a {
        color: var(--battleship-grey);
        transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
        &:hover {
            color: var(--off-white);
            text-decoration: none;
        }
    }
`;

const GreyLink = styled.span`
    font-weight: bold;
    text-decoration: underline;
`;

export default CredentialsFormContent;
