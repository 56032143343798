import FileUploader from 'react-firebase-file-uploader';
import * as React from 'react';
import { useState } from 'react';
import { firebaseApp } from '../../firebase/Connection';
import styled from 'styled-components/macro';
import { disabledButtonStyles, OutlineButtonStyles } from './Button';
import { UploadIcon } from '../../images/icons';

interface UploadImageProps {
    onUpload: (s: string) => void;
    existingImage?: string;
    setUploading: (uploading: boolean) => void;
    uploading: boolean;
    imageType?: string;
}

const UploadImage: React.FC<UploadImageProps> = ({
    setUploading,
    uploading,
    onUpload,
    existingImage,
    imageType = 'Custom'
}) => {
    const [progress, setProgress] = useState(0);
    const [image, setImage] = useState(existingImage);

    const handleUploadStart = () => {
        setProgress(0);
        setUploading(true);
    };

    const handleProgress = (progress: number) => setProgress(progress);

    const handleUploadError = (err: string) => {
        setUploading(false);
        console.log('error: ', err);
    };

    const handleUploadSuccess = (filename: string) => {
        setProgress(100);
        firebaseApp
            .storage()
            .ref('images')
            .child(filename)
            .getDownloadURL()
            .then((url) => {
                setImage(url);
                onUpload(url);
            })
            .then(() => setUploading(false));
    };

    return (
        <Container>
            <PlaceholderContainer>
                {image && !uploading ? (
                    <Image alt="your uploaded file" src={image} />
                ) : (
                    <UploadPlaceholder uploading={uploading} progress={progress} imageType={imageType} />
                )}
            </PlaceholderContainer>
            <ClickableArea>
                <FakeButton disabled={uploading}>
                    <UploadIcon />
                    {uploading ? 'Uploading...' : image ? `Update ${imageType} Image` : `Upload ${imageType} Image`}
                </FakeButton>
                <FileUploader
                    hidden
                    accept="image/*"
                    name="spaceImage"
                    randomizeFilename
                    storageRef={firebaseApp.storage().ref('images')}
                    onUploadStart={handleUploadStart}
                    onUploadError={handleUploadError}
                    onUploadSuccess={handleUploadSuccess}
                    onProgress={handleProgress}
                />
            </ClickableArea>
        </Container>
    );
};

const UploadPlaceholder = ({
    uploading,
    progress,
    imageType
}: {
    uploading: boolean;
    progress: number;
    imageType: string;
}) => {
    return (
        <>
            <UploadIcon />
            <label htmlFor="">{uploading ? `Progress: ${progress}` : `Upload ${imageType} Image`}</label>
        </>
    );
};

const PlaceholderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 390px;
    height: 190px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='13' ry='13' stroke='%23F6F6F9FF' stroke-width='4' stroke-dasharray='7 15' stroke-dashoffset='5' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 13px;
`;

const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
    border: 4px solid transparent;
`;

const ClickableArea = styled.label`
    width: 240px;
    position: absolute;
    padding: 120px 195px;
`;

const FakeButton = styled.div<{ disabled?: boolean }>`
    ${OutlineButtonStyles};
    position: absolute;
    bottom: 0;
    right: 0;
    > span {
        margin-right: 10px;
    }
    ${({ disabled }) => disabled && disabledButtonStyles};
`;

const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 80px;
    margin-right: 20px;
`;

export default UploadImage;
