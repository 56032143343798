import * as React from 'react';
import { useState } from 'react';
import { UploadIcon } from '../../images/icons';
import styled from 'styled-components/macro';
import { postApi } from '../../api/api';
import { TinyButton } from '../inputs/Button';

interface UploadFileProps {
    onUpload: (result: { [key: string]: any }) => void;
    destination: string;
    buttonText?: string,
    context?: { [key: string]: any };
    fileType?: string;
    onError?: (e: Error) => void;
    children?: React.ReactElement<any, any> | string;
}


const FileUploader: React.FC<UploadFileProps> = ({
    onUpload,
    destination,
    buttonText = "Upload",
    context = { dtm: (new Date()).getTime() },
    fileType = '*',
    onError = (e: Error) => console.log('Could not upload file with error: ', e),
    children = ""
}) => {
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const handleProgress = (progress: number) => setProgress(progress);
    const hiddenFileInputId = 'file-uploader-' + (Math.random() % 10000000).toString();

    const handleUploadError = (err: Error) => {
        onError(err);
    };

    const toBase64 = (file: any): Promise<string> =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = () => resolve(reader?.result?.toString() ?? '');
                reader.onerror = (error) => reject(error);
            });

    const handleClick = () => {
        if(!uploading) document?.getElementById(hiddenFileInputId)?.click();;
    }

    const handleFileChange = async (event: any) => {
        setUploading(true);
        setProgress(0);
        try {
            const fileInput = event.target;
            const file = fileInput.files[0];
            const name = encodeURIComponent(file.name);
            const upFileType = file.type;
            if(fileType !== '*' && fileType !== upFileType) throw new Error("Wrong file type");
            const data = { ...context, file: (await toBase64(file))  };

            const uploadResult = await postApi(destination, data);

            if(!uploadResult.ok) {
                throw new Error((await uploadResult.json()).message);
            }

            setProgress(100);
            setUploading(false);
            onUpload(await uploadResult.json());

        } catch (e) {
            setProgress(100);
            setUploading(false);
            handleUploadError(e);
        }

    }

    return (
        <FileUploaderContainer>
            <input type="file" id={hiddenFileInputId} style={{display:'none'}} onChange={handleFileChange} />
            <UploadButton onClick={handleClick} disabled={uploading}>{buttonText}</UploadButton>
            {children}
        </FileUploaderContainer>
    );
};

const FileUploaderContainer = styled.div`
    display: inline-flex;
`;

const UploadButton = styled(TinyButton)`
    margin-right: 15px;
`


export default FileUploader;





