// Checks to see if the client has a pagesVisited field in localStorage otherwise writes it. Allows consumers to update PageInterests

export type Interests = 'showTooltip';
export interface PageInterests {
    showTooltip: boolean;
}

export type Pages = 'stationPage' | 'editSpace' | 'spaceAdmin';
export interface PagesVisited {
    'stationPage': PageInterests;
    'editSpace': PageInterests;
    'spaceAdmin': PageInterests;
}

const setStorageWithDefaults = () => {
    // TODO: check if we are a first time user before writing to localStorage.
    console.log(`[pageVisitUtil] cookie doesn't exist, hydrating with defaults`);
    const defaults = {
        'stationPage': {
            showTooltip: true
        },
        'editSpace': {
            showTooltip: true
        },
        'spaceAdmin': {
            showTooltip: true
        }
    } as PagesVisited;

    try {
        window.localStorage.setItem('pagesVisited', JSON.stringify(defaults));
        return defaults;
    } catch {
        console.error(`Failed to set pageVisit defaults to localStorage`);
        return null;
    }
}

const getPagesVisited = () => {
    console.log('getPagesVisited');
    const hasPagesVisitedField = window.localStorage?.pagesVisited;
    console.log(hasPagesVisitedField);
    if (hasPagesVisitedField) {
        // TODO: Check that the return from localStorage matches our PagesVisited shape instead of just casting.
        const pagesVisited = JSON.parse(window.localStorage.pagesVisited) as PagesVisited;
        console.log(pagesVisited);
        return pagesVisited;
    } else {
        return setStorageWithDefaults();
    }
}

const setPageInterestValue = (page: Pages, interest: Interests, value: any) => {
    console.log('setPageInterestValue');
    const pagesVisited = getPagesVisited();
    if (!pagesVisited) {
        console.error(`localStorage.pagesVisited doesn't exist`);
    } else {
        pagesVisited[page][interest] = value;
        window.localStorage.setItem('pagesVisited', JSON.stringify(pagesVisited));
    }
}

export const PageVisitUtils = {
    getPagesVisited,
    setPageInterestValue
}
