import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ModalWrapper, ModalTitle, ModalAction, ActionButtonPrimary } from './Modal';
import { useModal } from '../../context/ModalContext';
import { getApi, putApi, deleteApi } from '../../api/api';
import Checkbox from '../inputs/Checkbox';
import { ApiUser } from '../../api/ApiTypes';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/selectors';
import { setCurrentUser } from '../../store/actions';


export const FollowSettingsModal = () => {

    const { closeModal } = useModal();
    const phoneRef = useRef<HTMLInputElement>(null);
    const currentUser = useSelector(getCurrentUser);

    // const [notifyByEmail, setNotifyByEmail] = useState<boolean>(true);
    const [notifyBySMS, setNotifyBySMS] = useState<boolean>(true);
    const [notifyPhoneNumber, setNotifyPhoneNumber] = useState<string>('');
    const [errors, setErrors] = useState<string[]>([]);

    const handlePhoneChange = () => {
        setNotifyPhoneNumber(phoneRef.current?.value ?? '');
    };

    const handleConfirm = () => {
        const phone = notifyPhoneNumber.trim();
        if ((notifyBySMS && phone === '') || (phone !== '' && !notifyBySMS)) {
            setErrors(['Phone number required for SMS alerts.']);
        } else {
            setErrors([]);
            const noticePreferenceData = {
                email: {
                    isAllowed: true,
                    notifyOn: [
                        {
                            eventType: 'EVENT_REMINDERS',
                            isAllowed: true,
                        },
                    ],
                },
                sms: {
                    isAllowed: notifyBySMS,
                    number: notifyPhoneNumber,
                    notifyOn: [
                        {
                            eventType: 'EVENT_REMINDERS',
                            isAllowed: notifyBySMS,
                        },
                    ],
                },
            };
            // console.log('saving prefs...', noticePreferenceData);
            putApi(`/user/me/preferences/notifications`, noticePreferenceData)
                .then((savePrefsResponse) => {
                    // console.log('savePrefsResponse', savePrefsResponse);
                    if(!currentUser.preferences){
                        currentUser.preferences = {};
                    }
                    if(!currentUser.preferences.notifications){
                        currentUser.preferences.notifications = {}
                    }
                    currentUser.preferences.notifications = noticePreferenceData;
                    setCurrentUser(currentUser);
                    closeModal();
                })
                .catch((e) => {
                    console.warn('Could not update following settings', e);
                });
        }
    };

    useEffect(() => {
        getApi(`/user/me`)
            .then((prefsResponse: any) => {
                if(prefsResponse.ok){
                    prefsResponse.json().then((prefsData: ApiUser) => {
                        // console.log('prefsData', prefsData);
                        // default to true
                        // setNotifyBySMS(prefsData.preferences?.notifications?.sms?.isAllowed ?? false);
                        setNotifyPhoneNumber(prefsData.preferences?.notifications?.sms?.number ?? '');
                    }).catch((e: any) => {
                        console.warn('Could not parse following settings.', e);
                    });
                    // setNotifyByEmail(prefsResponse.preferences.notifications.email.isAllowed);
                } else {
                    console.warn('Could not retrieve following settings', prefsResponse);
                }
            })
            .catch((e) => {
                console.warn('Could not retrieve following settings', e);
            });
    }, []);

    return (
        <ModalWrapper>
            <ModalTitle>Confirm following settings</ModalTitle>
            <NotificationOptions>
                <Header1>Notifications</Header1>
                <RowName>
                    Event reminders
                    <br />
                    <FinePrint>
                        Receive two email updates per event you follow, for SMS only receive one at event start time
                    </FinePrint>
                </RowName>

                <Header2>Email</Header2>
                <Checkbox2>
                    {/* Always true and uneditable as per NWR-1590 requirements */}
                    <Checkbox
                        checked={true}
                        labelText=""
                        disabled={true}
                        onChange={() => {
                            /* empty */
                        }}
                    />
                </Checkbox2>

                <Header3>
                    SMS
                    <br />
                    <FinePrint>Recommended</FinePrint>
                </Header3>
                <Checkbox3>
                    <Checkbox checked={notifyBySMS} labelText="" onChange={() => setNotifyBySMS(!notifyBySMS)} />
                </Checkbox3>
            </NotificationOptions>
            <PhoneOptions>
                <Label>Phone number for SMS alerts *</Label>
                <PhoneInput value={notifyPhoneNumber} ref={phoneRef} onChange={() => handlePhoneChange()} />
                <FinePrint>
                    * SMS available for U.S. numbers only. More to come.
                    <br />
                    Standard messaging rates apply.
                </FinePrint>
            </PhoneOptions>
            <ModalAction>
                {errors && <Errors>{errors}</Errors>}
                <ActionButtonPrimary onClick={handleConfirm}>Confirm</ActionButtonPrimary>
            </ModalAction>
        </ModalWrapper>
    );
};

const NotificationOptions = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: 325px 100px 100px;
    grid-template-rows: 40px 60px;
    gap: 10px 10px;
    justify-items: stretch;
    align-items: stretch;
`;

const Header1 = styled.div`
    grid-area: 1 / 1 / 1 / 1;
    font-weight: bold;
    font-size: 24px;
`;
const Header2 = styled.div`
    grid-area: 1 / 2 / 1 / 2;
    justify-self: center;
    font-weight: bold;
`;
const Header3 = styled.div`
    grid-area: 1 / 3 / 1 / 3;
    justify-self: center;
    font-weight: bold;
    text-align: center;
`;
const RowName = styled.div`
    grid-area: 2 / 1 / 2 / 1;
    font-weight: bold;
`;
const Checkbox2 = styled.div`
    grid-area: 2 / 2 / 2 / 2;
    justify-self: center;
`;
const Checkbox3 = styled.div`
    grid-area: 2 / 3 / 2 / 3;
    justify-self: center;
`;
const FinePrint = styled.div`
    font-size: 12px;
    color: var(--battleship-grey);
    font-weight: normal;
`;
const PhoneOptions = styled.div`
    margin-top: 20px;
`;
const Label = styled.div`
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
`;
const PhoneInput = styled.input`
    border: 1px solid var(--off-white);
    color: var(--off-white);
    background-color: var(--navy-blue);
    width: 439px;
    height: 40px;
    margin: 6px 0 10px 0;
    padding: 10px 15px;
    border-radius: 10px;
    letter-spacing: 1px;
`;

const Errors = styled.div`
    color: var(--burnt-red);
    font-weight: bold;
    margin-bottom: 10px;
`;
