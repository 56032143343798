import * as React from 'react';
import styled from 'styled-components/macro';
import { uniqueId } from 'lodash';
import checkbox from '../../images/checkbox.svg';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { FieldError } from 'react-hook-form';

const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
`;

const Box = styled.span<{ disabled?: boolean }>`
    ${(props) => (props.disabled ? 'opacity:0.5;' : 'cursor:pointer;')}
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 4px;
    ${(props) =>
        props.disabled ? '' : 'background: var(--purple-blue); border: 3px solid var(--indigo);'}

    margin-right: 15px;
    ${(props) => (props.disabled ? 'pointer-events:none; opacity:0.5;' : '')}

    &:after {
      position: absolute;
      top: 4px;
      left: 4px;
      content: '';
      width: 9px;
      height: 5px;
      border: 3px solid #fcfff4;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }

    &:hover::after {
      opacity: 0.3;
    }
  }
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    color: var(--battleship-grey);
`;

const Input = styled.input`
    visibility: hidden;

    &:checked + label {
        span:after {
            opacity: 1;
        }
    }
`;

interface CheckboxProps {
    errors?: DeepMap<Record<string, any>, FieldError>;
    name?: string;
    validation?: any;
    checked: boolean;
    onChange: (e: React.ChangeEvent) => void;
    labelText?: string;
    disabled?: boolean;
    children?: React.ReactNode;
}

const Checkbox = ({ checked, onChange, labelText, validation, name, disabled }: CheckboxProps) => {
    const id = uniqueId('checkbox_');

    return (
        <>
            <Wrapper>
                <Input id={id} name={name} type="checkbox" disabled={disabled} checked={checked} onChange={onChange} ref={validation} />
                <Label htmlFor={id}>
                    <Box disabled={disabled}/>
                    {labelText}
                </Label>
            </Wrapper>
        </>
    );
};

export const OpenCheckbox: React.FC<CheckboxProps> = ({ checked, onChange, children, validation, name, disabled }) => {
    const id = uniqueId('checkbox_');

    return (
        <>
            <Wrapper>
                <Input id={id} name={name} type="checkbox" checked={checked} disabled={disabled} onChange={onChange} ref={validation} />
                <TransparentBox src={checkbox} alt="" checked={checked} onClick={onChange} />
                <label htmlFor={name}>{children}</label>
            </Wrapper>
        </>
    );
};

const TransparentBox = styled.img<{ checked: boolean; onClick: any }>`
    margin: 0 15px 0 -15px;
    background-color: ${({ checked }) => (checked ? 'var(--tennis-green)' : 'transparent')};
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    cursor: pointer;
    border-radius: 5px;
`;

export default Checkbox;
