import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import EventsHeader from '../components/nwrEvents/EventsHeader';
import EventList from '../components/nwrEvents/EventList';
import CreateEvent from '../components/nwrEvents/CreateEvent';
import { EventsCTA, SpacesCTA } from '../components/layouts/CTAFooter';
import { NwrEventsContextProvider, useNwrEventsContext } from '../components/nwrEvents/EventsContext';

const MainPage = styled.div`
    padding: 50px 90px 50px 90px;
    margin-bottom: 140px;
`;

type NwrEventsProps = {
    isEmbed?: boolean,
    create?: boolean,
    update?: boolean,
    eventId?: string
}

const EventsPage = ({isEmbed}: NwrEventsProps) => {
    const {toggleDisplayCreateModal} = useNwrEventsContext();

    if (toggleDisplayCreateModal) return <CreateEvent isEmbed={isEmbed ?? false}/>;

    return (
        <>
            <MainPage>
                <EventsHeader />
                <EventList />
            </MainPage>
            <EventsCTA />
        </>
    );
};

const NwrEvents = ({isEmbed, create, update, eventId}: NwrEventsProps) => {
    return (
        <NwrEventsContextProvider>
            <EventsPage isEmbed={isEmbed}/>
        </NwrEventsContextProvider>
    );
};

export default NwrEvents;
