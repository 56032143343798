import FileUploader from 'react-firebase-file-uploader';
import * as React from 'react';
import { useState } from 'react';
import { firebaseApp } from '../../firebase/Connection';
import styled from 'styled-components/macro';
import { disabledButtonStyles, OutlineButtonStyles } from '../inputs/Button';
import { UploadIcon } from '../../images/icons';

interface UploadEventImageProps {
    onUpload: (s: string) => void;
    existingImage?: string;
    setUploading: (uploading: boolean) => void;
    uploading: boolean;
}

const UploadEventImage: React.FC<UploadEventImageProps> = ({
    setUploading,
    uploading,
    onUpload,
    existingImage,
}) => {
    const [progress, setProgress] = useState(0);
    const [image, setImage] = useState(existingImage);

    const handleUploadStart = () => {
        setProgress(0);
        setUploading(true);
    };

    const handleProgress = (progress: number) => setProgress(progress);

    const handleUploadError = (err: string) => {
        setUploading(false);
        console.log('error: ', err);
    };

    const handleUploadSuccess = (filename: string) => {
        setProgress(100);
        firebaseApp
            .storage()
            .ref('images')
            .child(filename)
            .getDownloadURL()
            .then((url) => {
                setImage(url);
                onUpload(url);
            })
            .then(() => setUploading(false));
    };

    return (
        <Container>
            <Title>Event Image</Title>
            <PlaceholderContainer>
                {image && !uploading ? (
                    <Image alt="your uploaded file" src={image} />
                ) : (
                    <UploadPlaceholder uploading={uploading} progress={progress} />
                )}
            </PlaceholderContainer>
            <ClickableArea>
                <FakeButton disabled={uploading}>
                    <UploadIcon />
                    {uploading ? 'Uploading...' : image !== existingImage ? `Update` : `Upload`}
                </FakeButton>
                <FileUploader
                    hidden
                    accept="image/*"
                    name='eventImage'
                    randomizeFilename
                    storageRef={firebaseApp.storage().ref('images')}
                    onUploadStart={handleUploadStart}
                    onUploadError={handleUploadError}
                    onUploadSuccess={handleUploadSuccess}
                    onProgress={handleProgress}
                />
            </ClickableArea>
        </Container>
    );
};

const UploadPlaceholder = ({
    uploading,
    progress,
}: {
    uploading: boolean;
    progress: number;
}) => {
    return (
        <>
            <UploadIcon />
            <label htmlFor="">{uploading ? `Progress: ${progress}` : ``}</label>
        </>
    );
};

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 120px;
    height: 180px;
    > label {
        margin-bottom: 0px;
    }
`;

const Title = styled.h4`
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    font-size: 14px;
    text-transform: uppercase;
`;
const PlaceholderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 93px;
    height: 112px;
    border: dashed var(--off-white);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
`;

const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    border: 4px solid transparent;
`;

const ClickableArea = styled.label`
    width: 100%;
`;

const FakeButton = styled.div<{ disabled?: boolean }>`
    ${OutlineButtonStyles};
    bottom: 0;
    right: 0;
    ${({ disabled }) => disabled && disabledButtonStyles};
    > span {
        margin-right: 10px;
    }
`;


export default UploadEventImage;
