import * as React from 'react';
import SpaceForm from '../components/stationAdmin/SpaceForm';
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ApiSpaceEditableMetadata, ApiSpace } from '../api/ApiTypes';
import CenteredSingleColumnLayout from '../components/layouts/CenteredSingleColumnLayout';
import LoadingScreen from '../components/loading/LoadingScreen';
import { getApi, postApi, putApi } from '../api/api';
import { canAdmin } from '../permissions/users';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../store/selectors';


const EditSpace: React.FC = () => {
    const history = useHistory();
    const curUser = useSelector(getCurrentUser);

    const { spaceId } = useParams() as any;
    const [loading, setLoading] = useState(true);
    const [error, setError] = React.useState('');
    const [space, setSpace] = useState({} as ApiSpace);

    useEffect(() => {
        getApi(`/space/${spaceId}`).then(async (r) => {
            const json = await r.json();
            if (!r.ok) {
                console.log(json.message || 'Could not get space data');
            }
            if (json.space) {
                setSpace(json.space);
                setLoading(false);
            } else {
                console.log('Space data missingfrom response');
            }
        });
    }, [spaceId]);

    React.useEffect(() => {
      if(!canAdmin(curUser) && space?.isMeet) {
          history.push(`/meet/space/${space.id}`);
          return;
      }
    }, [space, curUser]);

    const handleUpdateSpace = (spaceData: ApiSpaceEditableMetadata) =>
        new Promise<void>((resolve) => {
            putApi(`/space/${spaceId}`, spaceData)
                .then(async (r) => {
                    const json = await r.json();
                    const parsedErrors = json?.error?.details?.map((err: any) => err?.message).join(' ');
                    if (!r.ok) {
                        setError(parsedErrors || 'Update failed');
                    }
                    if (json.id) {
                        history.push(`/space/${json.id}`);
                    }
                    resolve();
                })
                .catch((err) => {
                    console.log('error: ', err);
                    resolve();
                });
        });

    if (loading) return <LoadingScreen />;
    return (
        <CenteredSingleColumnLayout title="Update Your Space" subtitle="">
            <SpaceForm
                title="Edit your space"
                space={space}
                onSubmit={(spaceData: ApiSpaceEditableMetadata) => handleUpdateSpace(spaceData)}
                stationId={space!.stationId}
            />
            {error && <h2>{error}</h2>}
        </CenteredSingleColumnLayout>
    );
};

export default EditSpace;
