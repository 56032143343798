import * as React from 'react';
import CallToActionFullWidth from './CallToActionFullWidth';
import CTAImagePlay from '../../images/spaces-footer-cta.jpg';
import CTAImageGuestAnon from '../../images/CTAImageGuestAnon.jpg';
import CTAImagePlus from '../../images/CTAImagePlus.jpg';
import CTAImagePro from '../../images/CTAImagePro.jpg';

import { canUseUnlimitedTime, canViewAccountUpsell, isAnon, isGuest } from '../../permissions/users';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/selectors';
import { SubscriptionModal } from '../modals/PaymentModals';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { putApi } from '../../api/api';
import { setCurrentUser } from '../../store/actions';
import { useDispatch } from 'react-redux';

export const EventsCTA = () => {
    //isAnon: === 0
    //isGuest: === 10
    //canUseUnlimitedTime:  >= 30
    //canViewAccountUpsell: < 40

    //shoudlShowGuestAnonCTA = 0 || 10
    //shouldShowPlayCTA = (!0 && !10) && !>=30  ---simplified = 20
    //shouldShowPlusCTA = >=30 && <40 ---simplified = 30
    //shouldShowProCTA = >=40

    const currentUser = useSelector(getCurrentUser);
    const shouldUpsell = canViewAccountUpsell(currentUser);
    const { id, avatarUrl, firstName } = useSelector(getCurrentUser);
    const history = useHistory();

    const isAnonymous = isAnon(currentUser);
    const isGuestAccount = isGuest(currentUser);

    const shouldShowGuestAnonCTA = isAnonymous || isGuestAccount;
    const shouldShowPlayCTA = !shouldShowGuestAnonCTA && !canUseUnlimitedTime(currentUser);
    const shouldShowPlusCTA = canUseUnlimitedTime(currentUser) && shouldUpsell;
    const shouldShowProCTA = !shouldUpsell;

    const [showSubscriptionsModal, setShowSubscriptionsModel] = useState<boolean>(false);
    const dispatch = useDispatch();
    
    const getMyFreeMeet = () => {
        if (isAnon(currentUser)) {
            history.push('/meet');
            return;
        }

        putApi(`/user/${id}/upgrade/free`)
        .then(async (resp) => {
            const response = await resp.json();
            if (resp.ok) {
                dispatch(setCurrentUser(response));
                history.push('/meet');
                //  history.push('/station/latest')

                //setShowFreeSpace(false)
            }
        })
        .catch((e) => {
            console.warn('Error getting free meet', e);
        });
    };


    const showSubscriptionModal = () => {
        const successRoute = shouldShowPlayCTA
            ? '/spaces#my-stations?fromPage=CTA&fromUserType=' + currentUser.userType  + '&userId=' + id
            : shouldShowPlusCTA
            ?'/station/latest?fromPage=CTA&fromUserType=' + currentUser.userType  + '&userId=' + id
            : '';
        history.push('/pricing?' + successRoute);
        //setShowSubscriptionsModel(true);
    };

    const hideSubscriptionModal = () => {
        setShowSubscriptionsModel(false);
    };

    return (
        <>
            {showSubscriptionsModal && (
                <SubscriptionModal
                    handelClose={hideSubscriptionModal}
                    successRoute={
                        shouldShowPlayCTA
                            ? '/spaces#my-stations?fromPage=CTA&fromUserType=' + currentUser.userType  + '&userId=' + id
                            : shouldShowPlusCTA
                            ? '/station/latest?fromPage=CTA&fromUserType=' + currentUser.userType  + '&userId=' + id
                            : ''
                    }
                />
            )}
            <CallToActionFullWidth
                image={
                    shouldShowGuestAnonCTA
                        ? CTAImageGuestAnon
                        : shouldShowPlayCTA
                        ? CTAImagePlay
                        : shouldShowPlusCTA
                        ? CTAImagePlus
                        : CTAImagePro
                }
                link={
                    shouldShowGuestAnonCTA
                        ? ''
                        : shouldShowPlayCTA
                        ? ''
                        : shouldShowPlusCTA
                        ? ''
                        : 'https:///www.nowhere.io/contact'
                }
                onClick={
                    shouldShowGuestAnonCTA
                        ? getMyFreeMeet
                        : shouldShowPlayCTA
                        ? showSubscriptionModal
                        : shouldShowPlusCTA
                        ? showSubscriptionModal
                        : undefined
                }
                text={
                    shouldShowGuestAnonCTA
                        ? 'Your own instant spatial experience. MEET.NOWHERE.io.'
                        : shouldShowPlayCTA
                        ? 'Unlimited minutes, new worlds & much more'
                        : shouldShowPlusCTA
                        ? 'Take your journey to the next level'
                        : `Event support, world build, or custom integration?`
                }
                buttonText={
                    shouldShowGuestAnonCTA
                        ? 'Get My Free Meet'
                        : shouldShowPlayCTA
                        ? 'Go Pro'
                        : shouldShowPlusCTA
                        ? 'See Plans & Upgrade'
                        : 'Contact Us'
                }
                maxHeight={600}
            />
        </>
    );
};

//these are not used anymore
export const SpacesCTA = () => {
    const currentUser = useSelector(getCurrentUser);
    const shouldUpsell = canViewAccountUpsell(currentUser);
    const [showSubscriptionsModal, setShowSubscriptionsModel] = useState<boolean>(false);

    const showSubscriptionModal = () => {
        setShowSubscriptionsModel(true);
    };

    const hideSubscriptionModal = () => {
        setShowSubscriptionsModel(false);
    };
    return (
        <>
            {showSubscriptionsModal && (
                <SubscriptionModal handelClose={hideSubscriptionModal} successRoute={'/spaces'} />
            )}
            <CallToActionFullWidth
                image={CTAImageGuestAnon}
                link={shouldUpsell ? '' : 'mailto:hello@urnowhere.com'}
                onClick={shouldUpsell ? showSubscriptionModal : undefined}
                text={
                    shouldUpsell
                        ? 'More spaces, more features, more fun.'
                        : `Need event support, world build, or custom integration?`
                }
                buttonText={shouldUpsell ? 'See plans & Upgrade' : 'Contact Us'}
                maxHeight={600}
            />
        </>
    );
};

export const StationsCTA = () => {
    const currentUser = useSelector(getCurrentUser);
    const shouldUpsell = canViewAccountUpsell(currentUser);
    const [showSubscriptionsModal, setShowSubscriptionsModel] = useState<boolean>(false);

    const showSubscriptionModal = () => {
        setShowSubscriptionsModel(true);
    };

    const hideSubscriptionModal = () => {
        setShowSubscriptionsModel(false);
    };
    return (
        <>
            {showSubscriptionsModal && (
                <SubscriptionModal handelClose={hideSubscriptionModal} successRoute={'/stations'} />
            )}
            <CallToActionFullWidth
                image={CTAImageGuestAnon}
                link={shouldUpsell ? '' : 'mailto:hello@urnowhere.com'}
                onClick={shouldUpsell ? showSubscriptionModal : undefined}
                text={
                    shouldUpsell
                        ? 'More spaces, more features, more fun.'
                        : `Need event support, world build, or custom integration?`
                }
                buttonText={shouldUpsell ? 'See plans & Upgrade' : 'Contact Us'}
                maxHeight={600}
            />
        </>
    );
};

