import * as React from 'react';
import styled from 'styled-components/macro';

const Message = styled.div`
    background-color: transparent;
    font-family: all-round-gothic, sans-serif;
    height: 16px;
    font-size: 16px;
    font-weight: bold;
    color: var(--burnt-red);
    margin: 0;
`;

interface FormErrorMessageProps {
    error?: string;
}
const FormErrorMessage: React.FC<FormErrorMessageProps> = ({ children, error }) => {
    return (
        <ErrorBox>
            <Message>
                {error} {children}
            </Message>
        </ErrorBox>
    );
};

const ErrorBox = styled.div`
    margin: 10px 15px 20px;
`;

export default FormErrorMessage;
