import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { getCurrentUser } from '../store/selectors';
import { useSelector } from 'react-redux';
import { canHaveStations } from '../permissions/users';
import LoadingComponent from '../components/loading/LoadingComponent';
import { env } from '../utils/envLoader';
import '../css/nowhere-stag.css';
import '../css/components.css';

const Landing = () => {
    const user = useSelector(getCurrentUser);
    const isFirstTimeUser = user?.isFirstTimeUser? true: false;
    const landingPage = ((lp: string) => lp? lp: 'Explorer')(env('LANDING_PAGE'));
    const canHaveOwnStations = canHaveStations(user);
  
    if (landingPage === 'spaces') return <Redirect to={'/spaces'} />;

    if (landingPage === 'events') {
        return <Redirect to={'/events'} />
    } else {
        if (canHaveOwnStations) {
            if(isFirstTimeUser) {
                return <Redirect to={{ pathname: '/station/latest' }} />;
            } else {
                return <Redirect to={{ pathname: '/' }} />;
            }
        }

        if (!canHaveOwnStations) {
            return <Redirect to={{ pathname: '/' }} />;
        }

        if (canHaveOwnStations) {
            return <Redirect to={{ pathname: '/managed' }} />;
        }
    }

    return <LoadingComponent />;
};

export default Landing;
