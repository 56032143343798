import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNwrEventsContext } from './EventsContext';
import { getCurrentUser } from '../../store/selectors';
import { StickyButton, SmallButton } from '../inputs/Button';
import { Title } from '../Typography';
import ValidatedFormInput from '../inputs/ValidatedFormInput';
import Select, { OptionType } from '../inputs/TEMP_Select';
import { Modal } from '../layouts/Modal';
import { postApi } from '../../api/api';
import { OutlinedInput } from '../inputs/OutlinedInput';
import UploadEventImage from './UploadEventImage';
import Calendar from '../inputs/Calendar';
import { EventCategories } from './EventsContext';
import EventDatePicker from './EventDatePicker';
import { FirebaseDate } from '../../api/ApiTypes';
import defaultMoose from '../../images/default-profile-moose.jpg';

const EventForm = ({handleConfirm}: {handleConfirm: () => void}) => {
    const user = useSelector(getCurrentUser);
    const [updated, updatedSetter] = useState(false);
    const [updating, updatingSetter] = useState(false);
    const [imageUploading, imageUploadingSetter] = useState(false);
    const [showTimePicker, showTimePickerSetter] = useState(false);
    const [calError, calErrorSetter] = useState<string | null>(null);

    // Post Data
    const [category, categorySetter] = useState<eventCategoriesType>('');
    const [title, titleSetter] = useState<string | null>(null);
    const [image, imageSetter] = useState<string>(defaultMoose);
    const [description, descriptionSetter] = useState<string | null>(null);
    const [startDateTime, startDateTimeSetter] = useState<FirebaseDate | null>(null);
    const [endDateTime, endDateTimeSetter] = useState<FirebaseDate | null>(null);
    const [inviteLink, inviteLinkSetter] = useState('');
    const [purchaseLink, purchaseLinkSetter] = useState('');

    // Form
    const useFormObject = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: true
    });
    const { register, handleSubmit, errors } = useFormObject;

    useEffect(() => {
        if (startDateTime !== null && endDateTime !== null) {
            timesAreValid();
        }
    }, [startDateTime, endDateTime]);

    const timesAreValid = () => {
        if (startDateTime && endDateTime) {
            const isBefore = moment((startDateTime as any).seconds).isBefore(moment((endDateTime as any).seconds));

            if (isBefore) {
                calErrorSetter(null);
                return true;
            } else {
                calErrorSetter('Event start time should be before event end time.');
                return false;
            }
        } else {
            calErrorSetter('Please select start and end times.');
            return false;
        }
    }

    const postEvent = async () => {
        if (timesAreValid()) {
            let res;
            try {
                // TODO: Extract this post
                updatingSetter(true);
                res = await postApi('/discover', {
                    title: title,
                    hostName: user.displayName ?? 'An unknown host',
                    thumbnail: image,
                    description: description,
                    category: category,
                    eventTier: 1,
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    // textColor: '#bee639',
                    // topSortFlag: true,
                    // inviteTo: ???,
                    inviteLink: inviteLink,
                    isPaid: purchaseLink !== '' ? true : false,
                    purchaseLink: purchaseLink
                });

                const newEvent = await res.json();
                console.log('Event created', newEvent);
                if(newEvent.error) {
                    alert(JSON.stringify(newEvent.error));
                    throw new Error(JSON.stringify(newEvent.error));
                }

                handleConfirm();
            } catch {
                console.error(res);
            } finally {
                updatingSetter(false);
                updatedSetter(true);
            }
        }
    }

    const handleCategoryChange = (value: string) => {
        categorySetter(value as eventCategoriesType);
    };

    const handleDescriptionChange = (value: string) => {
        descriptionSetter(value);
    }

    return (
        <FormContainer>
            <form onSubmit={handleSubmit(postEvent)}>
                <CategoryTitleImageContainer>
                    <label>Event Category*</label>
                    <Select
                        name='category'
                        register={register}
                        options={selectOptions}
                        onChange={handleCategoryChange}
                        placeholder='Choose a category'
                        placeholderValue=''
                        validation={register({ required: 'Please select a category' })}
                        errors={errors}
                    />

                    <ImageUploadContainer>
                        <UploadEventImage
                            onUpload={(u) => imageSetter(u)}
                            uploading={imageUploading}
                            setUploading={imageUploadingSetter}
                            existingImage={image}
                        />
                    </ImageUploadContainer>

                    <TitleInput
                        labelText='Event Title*'
                        type='text'
                        errors={errors}
                        validation={register({
                            required: 'An event title is required.'
                        })}
                        maxLength={50}
                        name='eventTitle'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            titleSetter(e.target.value);
                        }}
                        value={title ?? undefined}
                    />
                </CategoryTitleImageContainer>

                <ValidatedFormInput
                    labelText='Description*'
                    type='textarea'
                    errors={errors}
                    validation={register({
                        required: 'Please provide a description.'
                    })}
                    name='eventDescription'
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        descriptionSetter(e.target.value);
                    }}
                    value={description ?? undefined}
                />

                <EventDatePicker
                    name='dateTimePicker'
                    register={register}
                    errors={errors}
                    calError={calError}
                    validation={register({
                        required: 'Please select a starting and ending date and time.'
                    })}
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    startDateTimeSetter={startDateTimeSetter}
                    endDateTimeSetter={endDateTimeSetter}
                />

                <ValidatedFormInput
                    labelElement={<label>Invite Link* <Subtitle> (Go to Spaces &gt; Click Explore &gt; Click invite to copy)</Subtitle></label>}
                    type='text'
                    errors={errors}
                    validation={register({
                        required: 'An invite link is required'
                    })}
                    name='inviteLink'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        inviteLinkSetter(e.target.value);
                    }}
                    value={inviteLink}
                />

                <label>Ticket Link<Subtitle> (Leave blank if not ticketed)</Subtitle></label>
                <OutlinedInput
                    type='url'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        purchaseLinkSetter(e.target.value);
                    }}
                    value={purchaseLink}
                ></OutlinedInput>
            </form>
            <StickyButton onClick={handleSubmit(postEvent)} disabled={updating}>
                {updating ? 'Submitting...' : updated ? 'Submitted!' : 'Submit'}
            </StickyButton>
            <Modal
                isOpen={showTimePicker}
                handleClose={() => showTimePickerSetter(false)}
            >
                <Calendar
                    title='Start/End Date &amp; Time'
                    onConfirm={(time: {start: Date, end: Date}) => {

                        const utcStart = moment(time.start).valueOf();
                        const utcEnd = moment(time.end).valueOf();

                        startDateTimeSetter({seconds: utcStart / 1000} as unknown as FirebaseDate);
                        endDateTimeSetter({seconds: utcEnd / 1000} as unknown as FirebaseDate)
                        showTimePickerSetter(false);
                    }}
                />
            </Modal>
        </FormContainer>
    );
};

const CreateEvent = ({isEmbed}: {isEmbed: boolean}) => {
    const { toggleDisplayCreateModalSetter } = useNwrEventsContext();
    const [confirmModal, confirmModalSetter] = useState(false);
    const [redirect, redirectSetter] = useState(false);

    if (redirect) {
        return <Redirect to='/' />
    }

    return (
        <Container>
            <StyledTitle>Create Public Event</StyledTitle>
            <StickyWrapper isEmbed={isEmbed}>
                <BackButton
                    onClick={() => toggleDisplayCreateModalSetter(false)}
                >
                    Back to Events
                </BackButton>
            </StickyWrapper>
            <EventForm handleConfirm={() => confirmModalSetter(true)}/>
            <Modal
                isOpen={confirmModal}
                handleClose={() => redirectSetter(true)}
            >
                <ConfirmModal>
                    <h1>Event Submitted!</h1>
                    <div>The NOWHERE team will review this event and publish to the events page shortly. We will reach out if we have any questions.</div>
                    <StyledConfirmButton onClick={() => redirectSetter(true)}>Okay</StyledConfirmButton>
                </ConfirmModal>
            </Modal>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 648px;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
`;

const StyledTitle = styled(Title)`
    margin-bottom: 30px;
`;

const StickyWrapper = styled.div<{isEmbed: boolean}>`
    position: fixed;
    top: 0px;
    right: 0px;
    padding: ${(props) => props.isEmbed ? '60px' : '100px'} 60px 60px 60px;
`;

const BackButton = styled(SmallButton)`
`;

const Subtitle = styled.span`
    font-weight: normal;
`;

const FormContainer = styled.div`
    position: relative;
    width: 100%;
`;

const CategoryTitleImageContainer = styled.div`
    position: relative;
`;

const selectOptions: OptionType[] = [
    {
        value: 'Shows' as EventCategories,
        label: 'Shows'
    },
    {
        value: 'Talks' as EventCategories,
        label: 'Talks'
    },
    {
        value: 'Hangs' as EventCategories,
        label: 'Hangs'
    },
];

const ImageUploadContainer = styled.div`
    position: absolute;
    top: 0px;
    right: -7px;
`;

const TitleInput = styled(ValidatedFormInput)`
    max-width: 75%;
`;

type eventCategoriesType = 'Shows' | 'Talks' | 'Hangs' | '';

const ConfirmModal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
`;

const StyledConfirmButton = styled(SmallButton)`
    width: fit-content;
`;

export default CreateEvent;
