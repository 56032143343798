import * as React from 'react';
import styled from 'styled-components/macro';
import { WhiteLinkText } from '../Typography';
import { OutlineButton, SmallButton } from '../inputs/Button';

interface MessageProps {
    title: React.ReactNode;
    body?: React.ReactNode;
    children?: React.ReactNode;
    onCancel?: () => void;
    onConfirm?: () => void;
    onSecondConfirm?: () => void;
    confirmButtonText?: string;
    secondConfirmButtonText?: string;
    confirmButtonDisabled?: boolean;
    secondConfirmButtonDisabled?: boolean;
}

const Message: React.FC<MessageProps> = ({
    onCancel,
    onConfirm,
    onSecondConfirm,
    confirmButtonText,
    secondConfirmButtonText,
    confirmButtonDisabled,
    secondConfirmButtonDisabled,
    title,
    body,
    children
}) => {
    return (
        <Container>
            <Content>
                <MessageTitle className="message-title">{title}</MessageTitle>
                <MessageBody>{body}</MessageBody>
                <div>{children}</div>
                {onConfirm && onSecondConfirm ? (
                    <StackedButtonContainer>
                        <SmallButton onClick={onConfirm} disabled={confirmButtonDisabled}>
                            {confirmButtonText}
                        </SmallButton>
                        <OutlineButton onClick={onSecondConfirm} disabled={secondConfirmButtonDisabled}>
                            {secondConfirmButtonText}
                        </OutlineButton>
                        {onCancel && <WhiteLinkText onClick={onCancel}>Cancel</WhiteLinkText>}
                    </StackedButtonContainer>
                ) : (
                    <MessageButtonContainer>
                        {onConfirm && confirmButtonText && (
                            <SmallButton onClick={onConfirm} disabled={confirmButtonDisabled}>
                                {confirmButtonText}
                            </SmallButton>
                        )}
                        {onCancel && <WhiteLinkText onClick={onCancel}>Cancel</WhiteLinkText>}
                    </MessageButtonContainer>
                )}
            </Content>
        </Container>
    );
};

export const MessageTitle = styled.div`
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    color: var(--white);
`;

export const MessageBody = styled.div`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: var(--white);
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const MessageButtonContainer = styled.div`
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    > * {
        margin-right: 30px;
    }
`;

const StackedButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    button {
        margin-bottom: 20px;
        display: inline-flex;
    }
`;

const Content = styled.section`
    display: inline-block;
    margin: 200px 60px;
    max-width: 90vw;
    > * {
        margin-bottom: 30px;
    }
`;
export default Message;
