import * as React from 'react';
import styled from 'styled-components/macro';
import EmbedTitle from '../components/embeds/EmbedTitle';
import NwrEvents from './NwrEvents';

const EventsEmbed = () => {
    return (
        <EventsEmbedWrapper>
            <NwrEvents isEmbed={true}/>
        </EventsEmbedWrapper>
    );
};

const EventsEmbedWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`;

export default EventsEmbed;
