import * as firebase from 'firebase/app';
import querystring from 'query-string';
import { env } from '../utils/envLoader';
import firebaseConfig from '../firebase/firebase-config';

type ApiParams = {
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    path: string;
    params?: Record<string, string | boolean | number | undefined>;
    data?: Record<string, unknown>;
};

type FetchParams = {
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    headers: Record<string, string>;
    body?: string;
    mode: 'cors';
};

const getUrlIdToken = (): string => {
    // ATM using location.searchParams
    const url = new URL(location.href);
    return url.searchParams.get('idToken') ? String(url.searchParams.get('idToken')) : '';
};

export function getApiBaseUrl(): string {
    let baseUrl = 'https://beta.urnowhere.com';
    if (env('REACT_APP_BACKEND') === 'localhost') {
        baseUrl = `http://localhost:5001/${firebaseConfig.projectId}/us-central1`;
    } else if (env('REACT_APP_BACKEND')) {
        baseUrl = env('REACT_APP_BACKEND');
    }

    return `${baseUrl}/api`;
}

async function makeApiCall({ method, path, params, data }: ApiParams) {
    const idTokenCookie = await firebase.auth().currentUser?.getIdToken();
    const urlToken = getUrlIdToken();
    const idToken = (urlToken)? urlToken: idTokenCookie;

    const baseUrl = getApiBaseUrl();

    const url = `${baseUrl}${path}${params ? '?' + querystring.stringify(params || {}) : ''}`;

    const sessionCookiePrefix = "_type:sc:";

    const Authorization = (() => {
        if(idToken) {
            if(idToken!.startsWith(sessionCookiePrefix)) {
                return `Session ${idToken!.substr(sessionCookiePrefix.length)}`;
            } else {
                return `Bearer ${idToken!}`;
            }
        } else {
            return "";
        }
    })();

    //console.log("AUTH: ", Authorization);

    const headers: Record<string, string> = { 'Content-Type': 'application/json' };
    if (idToken) {
        headers.Authorization = `${Authorization}`;
    }

    //console.log("idToken: ", idToken, " Auth: ", headers.Authorization);

    const fetchParams: FetchParams = {
        method,
        headers,
        mode: 'cors'
    };

    if (data) {
        fetchParams.body = JSON.stringify(data || {});
    }

    return fetch(url, fetchParams);
}

export async function getApi(path: string, params?: Record<string, string>) {
    return makeApiCall({ method: 'GET', path, params });
}

export async function postApi(path: string, data?: Record<string, unknown>) {
    return makeApiCall({ method: 'POST', path, data });
}

export async function putApi(path: string, data?: Record<string, unknown>) {
    return makeApiCall({ method: 'PUT', path, data });
}

export async function deleteApi(path: string, params?: Record<string, string | boolean | number | undefined>) {
    return makeApiCall({ method: 'DELETE', path, params });
}
