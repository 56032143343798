import { combineReducers } from 'redux';
import { FirebaseReducer, firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { ApiUser } from '../api/ApiTypes';
import {
    AGREE_TO_TERMS,
    SystemActionTypes,
    SystemState,
    SET_CURRENT_USER,
    CurrentUserActionTypes,
    CLEAR_CURRENT_USER,
    SET_PROFILE_LOADED
} from './types';

export interface RootState {
    firebase: FirebaseReducer.Reducer<ApiUser, ApiUser>;
    firestore: any;
    system: SystemState;
    currentUser: ApiUser;
}

const initialState: SystemState = {
    agreedToTerms: false,
    profileLoaded: false
};

const systemReducer = (state = initialState, action: SystemActionTypes): SystemState => {
    switch (action.type) {
        case AGREE_TO_TERMS: {
            return {
                ...state,
                agreedToTerms: true
            };
        }
        case SET_PROFILE_LOADED: {
            return {
                ...state,
                profileLoaded: true
            };
        }
        default:
            return state;
    }
};

const currentUserInitial = {} as ApiUser;
const currentUserReducer = (state = currentUserInitial, action: CurrentUserActionTypes): ApiUser => {
    switch (action.type) {
        case SET_CURRENT_USER: {
            return {
                ...state,
                ...action.currentUser
            };
        }
        case CLEAR_CURRENT_USER: {
            return currentUserInitial;
        }
        default:
            return state;
    }
};

const reducers = combineReducers<RootState>({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    system: systemReducer,
    currentUser: currentUserReducer
});

export default reducers;
