import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUser } from '../store/actions';
import { useForm } from 'react-hook-form';
import FormErrorMessage from '../components/inputs/FormErrorMessage';
import { getAuthError, getAuthState, getCurrentUserType, getCurrentUser } from '../store/selectors';
import ValidatedFormInput from '../components/inputs/ValidatedFormInput';
import OneColumnBackgroundPage from '../components/layouts/OneColumnBackgroundPage';
import InputLabel from '../components/inputs/InputLabel';
import { isEmpty } from 'react-redux-firebase';
import GoogleAuthButton from '../components/inputs/GoogleAuthButton';
import AnonymousAuthButton from '../components/inputs/AnonymousAuthButton';
import DividingLine from '../components/DividingLine';
import { LargeButton, SmallButtonStyles, BaseButtonStyles, AuthButton } from '../components/inputs/Button';
import { RedLinkText } from '../components/Typography';
import styled from 'styled-components/macro';
import DefaultBackground from '../images/login-bg-2.jpg';
import { getApi } from '../api/api';
import { ApiUser } from '../api/ApiTypes';
import { pickBy } from 'lodash';
import { Modal } from '../components/layouts/Modal';
import Message from '../components/layouts/Message';
import { SpaceUtil } from '../utils/SpaceUtil';
import useInterval from '../hooks/useInterval';
import * as fb from 'firebase/app';
import 'firebase/auth';
import { relative } from 'path';
import { canHaveStations, isAnon } from '../permissions/users';

const LogIn = () => {
    return (
        <>
            <LogInModal>
                <Background />
            </LogInModal>
        </>
    );
};

export const Background = styled.div`
    height: 100vh;
    min-height: 700px;
    background-color: var(--navy-blue);
    background-image: url(${DefaultBackground});
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: all-round-gothic, sans-serif;
    color: #fff;
    font-size: 16px;
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 33vh;
    width: 50%;
    @media (max-width: 1024px) {
        display: none;
    }
`;

export const LogInModal: React.FC<{ redirect?: string }> = ({ children, redirect }) => {
    const location = useLocation() as any;

    const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

    useEffect(() => {
        if (location.state && location.state.showDeleteConfirm) {
            setShowDeleteConfirm(location.state.showDeleteConfirm);
        }
    }, [location.state]);

    const params = new URLSearchParams(window.location.search);
    const allowSignups =
        !!params.get('allowSignups') ||
        location.pathname.startsWith('/') ||
        location.pathname.startsWith('/invite') ||
        location.pathname.startsWith('/hostInvite') ||
        location.pathname.startsWith('/managerInvite');

    return (
        <>
            <BackgroundScrimContainer>
                {children}
                <BackgroundScrim />
            </BackgroundScrimContainer>
            <OneColumnBackgroundPage title="Log-in" center={allowSignups}>
                <LogInComponent redirect={redirect} allowSignups={allowSignups} />
                {!allowSignups && <NoSignupBanner />}
            </OneColumnBackgroundPage>
            <DeleteConfirmModal isOpen={showDeleteConfirm} hideModal={() => setShowDeleteConfirm(false)} />
        </>
    );
};

const BackgroundScrimContainer = styled.div`
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    @media (max-width: 1024px) {
        display: none;
    }
`;

const BackgroundScrim = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
`;

export const LogInComponent: React.FC<{ redirect?: string; allowSignups: boolean }> = ({ redirect, allowSignups }) => {
    const history = useHistory();
    const firebase = useFirebase();

    const dispatch = useDispatch();
    const auth = useSelector(getAuthState);
    const authError = useSelector(getAuthError);
    const currentUser = useSelector(getCurrentUser);
    const userType = useSelector(getCurrentUserType);
    const canHost = canHaveStations(currentUser);
    const location = useLocation() as any;

    const urlParams = new URLSearchParams(window.location.search);
    const returnToGame = urlParams.get('returnToGame');
    const goToProfileEdit = urlParams.get('goToProfileEdit');

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit', shouldFocusError: true });

    // referrer is only populated from the AuthRoutes redirect and, hopefully,
    // from invite link redirect in the LoggedOutAcceptInvite component.
    // so if the user came from a deep link behind auth gate, they are redirected there
    // while users who log in normally are redirected to either host or station

    const referrer = location?.state?.from?.pathname;
    const redirectLink = redirect || referrer || (canHost ? '/managed' : '/');

    const spaceId = urlParams.get('spaceId');
    // console.log('!!!', useParams(), spaceId);

    const { inviteCode, inviteHostCode, inviteManagerCode } = useParams() as any;
    const qs = new URLSearchParams(
        pickBy({ inviteCode, inviteHostCode, inviteManagerCode, returnToGame, spaceId })
    ).toString();

    console.log('invite found', qs);

    const getUser = async (): Promise<ApiUser> => {
        return await getApi('/user/me').then(async (response) => {
            let user = null;
            try {
                user = await response.json();
            } catch (event) {
                console.log('User response is not valid json', response);
            }
            return user as ApiUser;
        });
    };

    const isValidUser = (user: any) => {
        if (typeof user !== 'undefined' && user !== null) {
            return Object.keys(user).length > 1 && user?.id?.length > 0;
        }
        return false;
    };

    // Callback is invoked after successful auth and user setup within AnonAuthButton
    const handleAuthSuccess = async () => {
        console.log('handle auth success callback');
        try {
            const user = await getUser();
            if (isValidUser(user)) {
                handleRedirect(user as ApiUser, user.id!, !!qs, redirectLink);
            } else {
                console.error('Invalid user found in handleAuthSuccess');
            }
        } catch (err) {
            console.error(err);
        }
    };

    const logInWithEmailAndPassword = async () => {
        try {
            const firebaseUser = await firebase.login({ email, password });
            const user = await getUser();
            dispatch(setCurrentUser(user));
            handleRedirect(user as ApiUser, auth.uid, !!qs, redirectLink);
        } catch (err) {
            console.error(err);
        }
    };

    const relay = (url: string) => {
        const isFullyQualified = ['http://', 'https://'].find((p) => url.startsWith(p));
        const uri = isFullyQualified ? (new URL(url).pathname + new URL(url).search).toString() : url;
        history.push(uri);
    };

    const handleRedirect = async (
        user: ApiUser,
        usrId: string,
        hasInvite: boolean,
        primaryRedirectLink: string,
        spaceId?: string
    ): Promise<void> => {
        console.log(`usrId: ${usrId}, isFirstTimeUser: ${user?.isFirstTimeUser}, hasInvite: ${hasInvite}`);

        console.log('returnToGame', returnToGame);
        console.log('goToMyAccount', goToProfileEdit);
        if (!returnToGame && !goToProfileEdit) {
            // When we have a first time user with no invite
            // We want to send them right into the world if they own one
   /*          if (user?.isFirstTimeUser && !hasInvite) {
                const lastOwnedSpaceId = await SpaceUtil.getLatestOwnedSpaceId(usrId);

                lastOwnedSpaceId
                    .map((id: string) => {
                        history.push(`/space/${id}/lobby`);
                    })
                    .getOrElse(() => {
                        relay(primaryRedirectLink);
                    });
            } else { */
                if (spaceId) {
                    relay(primaryRedirectLink + `&spaceId=${spaceId}`);
                } else {
                    relay(primaryRedirectLink);
                }
          //  }
        } else if (goToProfileEdit) {
            history.push('/profile-edit');
        } else {
            history.push('/return-to-game');
        }
    };

    useEffect(() => {
        getUser().then((user) => {
            if (!isEmpty(auth) && isValidUser(user)) {
                dispatch(setCurrentUser(user));
            }
        });
    }, []);

    useEffect(() => {
        console.log('log-in useEffect of currentUser found userType: ', userType);
        if (!isEmpty(auth) && isValidUser(currentUser) && !isAnon(currentUser)) {
            handleRedirect(currentUser as ApiUser, auth.uid, !!qs, redirectLink);
        }
    }, [currentUser]);

    const continueAnon = () => {
        document.location.href = '/spaces';
    };

    return (
        <>
            <Container>
                <div style={{ width: '100%', maxWidth: '440' }}>
                    <GoogleAuthButton>Log in with Google</GoogleAuthButton>
                </div>
                <DividingLine />
                {!isAnon(currentUser) && (
                    <div style={{ width: '100%', maxWidth: '440' }}>
                        <AnonymousAuthButton handleAuthSuccess={handleAuthSuccess}>
                            Continue as Guest
                        </AnonymousAuthButton>
                    </div>
                )}
                {isAnon(currentUser) && (
                    <div style={{ width: '100%', maxWidth: '440' }}>
                        <LargeButton onClick={continueAnon}>Continue as Guest</LargeButton>
                    </div>
                )}
                <DividingLine />

                <form
                    id="signup-Form"
                    name="wf-form-signup-Form"
                    data-name="signup Form"
                    onSubmit={handleSubmit(logInWithEmailAndPassword)}
                    className="form-2"
                    style={{ width: '100%', maxWidth: '100%' }}
                >
                    <InputLabel htmlFor="loginEmail-2">Email</InputLabel>
                    <ValidatedFormInput
                        type="email"
                        errors={errors}
                        validation={register({
                            required: 'Email is required',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address'
                            }
                        })}
                        maxLength={256}
                        name="email"
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        placeholder="Enter Email"
                    />
                    <InputLabel htmlFor="loginPassword-2">Password</InputLabel>
                    <ValidatedFormInput
                        type="password"
                        errors={errors}
                        validation={register({ required: 'Password is required' })}
                        maxLength={256}
                        name="password"
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        placeholder="Enter Password"
                        autoComplete="current-password"
                    />
                    <SubmitContainer>
                        <LargeButton type="submit">Log-in</LargeButton>
                        <Link to="/reset-password" className="link">
                            <RedLinkText>Reset Password</RedLinkText>
                        </Link>
                    </SubmitContainer>
                    {authError ? <FormErrorMessage>{authError.message}</FormErrorMessage> : <></>}
                </form>
                {allowSignups && (
                    <SignUpLink
                        to={{
                            pathname: `/sign-up`,
                            state: { from: { pathname: redirectLink } },
                            search: `${qs ? '?' : ''}${qs}`
                        }}
                    >
                        <h3>
                            New to Nowhere? <u>Sign-up</u>
                        </h3>
                    </SignUpLink>
                )}
            </Container>
        </>
    );
};

const Container = styled.div`
    position: relative;
    padding: 0 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 640px;
`;

const SubmitContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 60px 0 60px;
`;

const LinkStyles = `
    margin: 0;
    display: inline-block;
    position: absolute;
    bottom: -40px;
    left: 100px;
    cursor: pointer;
    color: var(--off-white);
    &:hover {
        text-decoration: underline;
        color: white;
    }
`;

const SignUpLink = styled(Link)`
    ${LinkStyles}
`;

const NoSignupBanner = () => (
    <NoSignupWrapper>
        <ButtonRow>
            <BannerButtonSection
                title="Apply to host"
                buttonText="Host Nowhere"
                color="var(--off-white)"
                link="https://www.urnowhere.com/host-nowhere"
            />
            <BannerButtonSection
                title="Exclusive Access"
                buttonText="Join NWR Club"
                color="var(--tennis-green)"
                link="https://www.urnowhere.com/join-nwr-club"
            />
        </ButtonRow>
        <NoSignupText>
            Issue creating an account? Try the Invite Link again or get in touch with the host of an event you&apos;r
            invited to. <br />
            Need more help? <a href="mailto:support@urnowhere.com">Email Support</a>
        </NoSignupText>
    </NoSignupWrapper>
);

const NoSignupWrapper = styled.div`
    background: var(--purple-blue);
    width: 100%;
    padding: 40px 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const ButtonRow = styled.div`
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
    max-width: 440px;
    flex-direction: row;
    width: 100%;
`;

type BannerButtonProps = {
    title: string;
    buttonText: string;
    color: string;
    link: string;
};

const BannerButtonSection = ({ title, buttonText, color, link }: BannerButtonProps) => (
    <div>
        <BannerTitle color={color}>{title}</BannerTitle>
        <BannerButton href={link} color={color}>
            {buttonText}
        </BannerButton>
    </div>
);

const BannerTitle = styled.h3<{ color: string }>`
    color: ${({ color }) => color};
    margin-bottom: 20px;
`;

const BannerButton = styled.a<{ color: string }>`
    ${BaseButtonStyles}
    ${SmallButtonStyles}
    outline: 0;
    color: var(--navy-blue);
    border: 2px ${({ color }) => color} solid;
    background: ${({ color }) => color};
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    display: inline-flex;

    &:visited,
    &:-webkit-any-link {
        color: var(--navy-blue);
    }

    &:hover {
        text-decoration: none;
        color: ${({ color }) => color};
        background: transparent;
        border: 2px ${({ color }) => color} solid;
    }
`;

const NoSignupText = styled.div`
    max-width: 440px;
    color: var(--off-white);

    a {
        color: var(--off-white);
        text-decoration: underline;

        &:hover {
            color: var(--tennis-green);
        }
    }
`;

const DeleteConfirmModal = ({ isOpen, hideModal }: { isOpen: boolean; hideModal: () => void }) => (
    <Modal handleClose={hideModal} isOpen={isOpen}>
        <Message
            title="Your account and personal data have been deleted"
            body="We hope you consider joining us again sometime."
            onConfirm={hideModal}
            confirmButtonText="So Long For Now"
        />
    </Modal>
);

export default LogIn;
