import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { get } from 'lodash';
import { getApi, deleteApi } from '../../api/api';
import { ApiConnections, ApiUserConnection, ApiStation } from '../../api/ApiTypes';
import { Select } from '../inputs/Select';
import ConnectionCard from './ConnectionCard';
import { isStationManager } from '../../permissions/stations';
import { makeFakeConnections } from '../../utils/fakeConnections';
import LoadingComponent from '../loading/LoadingComponent';
import clearIcon from '../../images/atom-i-close-dark.svg';


const sortOptions = [
    { name: 'Sort By: Date Connected', sortBy: 'ts' },
    { name: 'Sort By: Name', sortBy: 'toUser.displayName' },
    { name: 'Sort By: Company', sortBy: 'toUser.company' },
    { name: 'Sort By: Station Connected', sortBy: 'space.stationId' }
];

// Use lodash's get to access nested property's stored as a string,
const orbitSort = (sortByProperty: string) => (a: ApiUserConnection, b: ApiUserConnection) => {
    const getSortBy = (sbp: string) => {
      if(sbp !== 'toUser.displayName') return sbp;
      return get(a, sbp)? sbp: 'toUser.firstName';
    };

    const valueA = get(a, getSortBy(sortByProperty));
    const valueB = get(b, getSortBy(sortByProperty));

    console.log({ valueA, valueB });

    if (valueA < valueB) {
        return -1;
    }
    if (valueA > valueB) {
        return 1;
    }

    return 0;
};

type ConnectionListProps = {
    children: React.ReactNode;
    hideBackground?: boolean;
};

const ConnectionList = ({ children, hideBackground }: ConnectionListProps) => {
    const [connections, setConnections] = useState<ApiConnections>({ connections: [] });
    const [displayConnections, setDisplayConnections] = useState<ApiConnections>({ connections: [] });
    const [managedStations, setManagedStations] = useState([] as ApiStation[]);

    const options = sortOptions.map((option) => ({
        value: option.sortBy,
        label: option.name
    }));
    const [sortValue, setSortValue] = useState(options[0].value);

    const urlParams = new URLSearchParams(window.location.search);
    const test = urlParams.get('test');

    const [searchQuery, setSearchQuery] = useState(urlParams.get('query'));
    const searchInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (test) {
            const fakeConnections = makeFakeConnections(10);
            setConnections({ connections: fakeConnections });
        } else {
            getApi('/user/me/connections').then(async (response) => {
                const dbConnections = (await response.json()) as { [key: string]: any };
                const connections = dbConnections.connections.map((c: { [key: string]: any }) => {
                    if(c?.displayName) return c;
                    return { ...c, displayName: c.firstName };
                });
                setConnections({ connections });
            }).catch((err) => {
                setConnections({ connections: [] });
            });
        }

        getApi('/user/me/stations').then(async (response) => {
            const json = (await response.json()) as ApiStation[];
            setManagedStations(json.filter((station) => isStationManager(station)));
        });
    }, [test]);

    useEffect(() => {
        if((searchQuery !== null && searchQuery.length > 0) || sortValue){
            const sq = searchQuery?? '';
            setDisplayConnections({
                ...connections,
                connections: searchAndSort(connections, sq, sortValue)
            });
        } else {
            setDisplayConnections({...connections});
        }
    }, [searchQuery, connections, sortValue]);

    if (!connections) {
        return <LoadingComponent />;
    }

    const handleSearchTextChange = () => {
        setSearchQuery(searchInput.current?.value as string);
    };

    const handleSortChange = (value: string) => {
        setSortValue(value);
    };

    const handleClearText = () => {
        setSearchQuery('');
    };

    const removeConnection = (currentConnection: ApiUserConnection) => {
        deleteApi(`/user/${currentConnection.toUser.id}/disconnect`)
            .then(async (r) => {
                const json = await r.json();
                if (!r.ok) {
                    console.log(json.message || 'Error: remove connection failed');
                }
                setConnections({
                    ...connections,
                    connections: connections.connections.filter(
                        (connection) => connection.toUser.id !== currentConnection.toUser.id
                    )
                });
            })
            .catch((e) => console.log(e));
    };

    const searchAndSort = (orbitConnections: ApiConnections, query: string, sort: string) => {
        let results = [...orbitConnections.connections]
        const query_tokens = query.toLowerCase().split(' ');
        for(const token of query_tokens) {
            results = results.filter((item) => {
                const itemIncludesSearchTerm = item.toUser.firstName?.toLowerCase().includes(token) || item.toUser.lastName?.toLowerCase().includes(token);
                return itemIncludesSearchTerm;
            })
        }
        results.sort(orbitSort(sort));
        return results;
    }

    return (
        <>
            {connections.connections.length === 0 ? (
                <>{children}</>
            ) : (
                <div>
                    <SelectWrapper>
                        <Select width="300px" onChange={handleSortChange} options={options} value={sortValue} />
                    </SelectWrapper>
                    <SearchWrapper>
                        <SearchQuery ref={searchInput}
                            id="orbit_search"
                            type="text"
                            placeholder="Search by name"
                            value={searchQuery || ''}
                            onChange={handleSearchTextChange}
                        />
                        <ClearIcon onClick={handleClearText} />
                    </SearchWrapper>
                    <ConnectionCardsSection hideBackground={hideBackground}>
                        {typeof displayConnections.connections == 'undefined' || displayConnections.connections.length == 0
                        ?
                            <>
                                No connection matches the current search query.
                            </>
                        :
                        (displayConnections.connections || []).map((connection) =>
                            connection.toUser ? (
                                <ConnectionCard
                                    key={connection.toUser.id}
                                    connection={connection}
                                    removeConnection={removeConnection}
                                    managedStations={managedStations}
                                />
                            ) : undefined
                        )}
                    </ConnectionCardsSection>
                </div>
            )}
        </>
    );
};

const SelectWrapper = styled.div`
    display: inline-block;
    margin-left: 30px;
`;

const SearchWrapper = styled.div`
    float: right;
    margin-top: 5px;
    margin-right: 30px;
    position: relative;
`;

const SearchQuery = styled.input`
    position: relative;
    margin: 5px 15px 5px 0px;
    border-radius: 22.5px;
    padding: 10px 10px;
    width: 240px;
    height: 40px;
    background-color: var(--off-white);
    font-family: var(--all-round-gothic);
    color: var(--midnight);
`;

const ClearIcon = styled.div`
    position: absolute;
    display: block;
    top: 17px;
    right: 30px;
    width: 15px;
    height: 15px;
    z-index: 1;
    cursor: pointer;
    user-select: none;
    background-image: url(${clearIcon});
`;

type SectionProps = {
    hideBackground?: boolean;
};

const ConnectionCardsSection = styled.section<SectionProps>`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    padding: 30px;
    background-color: ${({ hideBackground }) => (hideBackground ? 'transparent' : 'var(--steel-blue)')};
    border-radius: 10px;
    width: 600px;
    margin-left: 30px;
    @media (min-width: 1250px) {
        margin: 0px auto;
        width: 1190px;
        grid-template-columns: 1fr 1fr;
    }
`;

export default ConnectionList;
