import * as React from 'react';
import { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAuthState } from '../store/selectors';
import { FirebaseReducer, isEmpty } from 'react-redux-firebase';


type Props = {
    children: React.ReactNode;
};

type AuthContextType = {
    error: any,
    auth: FirebaseReducer.AuthState | null,
};

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthContextProvider = ({ children }: Props) => {
    const [error, errorSetter] = useState<any | null>(null);
    const [auth, authSetter] = useState<FirebaseReducer.AuthState | null>(null);
    const authState = useSelector(getAuthState);

    useEffect(() => {
        if (isEmpty(authState)) {
            authSetter(null);
        } else {
            authSetter(authState);
        }
    }, [authState]);

    return (
            <AuthContext.Provider
                value={{
                    error,
                    auth
                }}
            >
                {children}
            </AuthContext.Provider>
        );
};

export const useAuthContext = () => {
    const context = useContext(AuthContext);

    if (!context) throw new Error('AuthContext must be called from within the AuthContextProvider');

    return context;
};
