const envar = require('../env.json');
const _UNDEFINED_ = '_UNDEFINED_';

export const env = (name: string): string => {
    if (envar[name] && envar[name] !== _UNDEFINED_) {
        return envar[name];
    } else if (process.env[name]) {
        return process.env[name] as string;
    }

    return '';
};

export const envAsBool = (name: string): boolean => {
    const renv = env(name);

    return (renv.toLowerCase() === 'true');
};

export const envAsNumber = (name: string): number => {
    const renv = env(name);

    return parseInt(renv);
};
