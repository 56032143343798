import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingScreen from '../components/loading/LoadingComponent';
import { getAuthState } from '../store/selectors';
import * as firebase from 'firebase/app';

const DebugToken: React.FC = () => {
    const [token, setToken] = useState('');

    const auth = useSelector(getAuthState);

    if (!auth) {
        return <LoadingScreen />;
    }

    firebase
        .auth()
        .currentUser?.getIdToken(/* forceRefresh */ true)
        .then(function (idToken: string) {
            setToken(idToken);
        })
        .catch(function (error: any) {
            setToken(error);
        });

    return (
        <div>
            uid: {firebase.auth().currentUser?.uid} <p />
            email: {firebase.auth().currentUser?.email} <p />
            idToken={token}
        </div>
    );
};

export default DebugToken;
