import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import TwoColumnPage, { SpaceCount } from '../components/layouts/TwoColumnPage';
import SpaceAdminBody from '../components/stationAdmin/SpaceAdminBody';
import { getApi, postApi } from '../api/api';
import { ApiStationDetails, ApiSpaceDetails, ApiSpace, ApiPermission } from '../api/ApiTypes';
import { AddItem } from '../components/station/SpaceCardLabel';
import AdminSpaceList from '../components/stationAdmin/AdminSpaceList';
import { MediumButton, StickyButton } from '../components/inputs/Button';
import styled from 'styled-components/macro';
import _ from 'lodash';
import LoadingScreen from '../components/loading/LoadingScreen';
import { SpaceMemberTable, SpaceHostTable, SpaceBlockedUserTable } from '../components/stationAdmin/UserTable';
import LoadingComponent from '../components/loading/LoadingComponent';
import AllSpaceToggle from '../components/stationAdmin/AllSpaceToggle';
import { SpaceDetailContext } from '../context/spaceContext';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../store/selectors';
import { isStationManager } from '../permissions/stations';
import Message from '../components/layouts/Message';
import FullScreenContainer from '../components/layouts/FullScreenContainer';
import useInterval from '../hooks/useInterval';
import { canAdmin } from '../permissions/users';
import { canViewAttendeeContactInfo } from '../permissions/stations';

const SpaceAdmin: React.FunctionComponent = () => {
    const { spaceId } = useParams() as { spaceId: string };
    const [stationDetails, setStationDetails] = useState(({} as unknown) as ApiStationDetails);
    const [spaceDetails, setSpaceDetails] = useState((undefined as unknown) as ApiSpaceDetails);
    const [spaceDetailsWithStats, setSpaceDetailsWithStats] = useState((undefined as unknown) as ApiSpaceDetails);
    const [stationDetailsLoading, setStationDetailsLoading] = useState(true);
    const [spaceDetailsLoading, setSpaceDetailsLoading] = useState(true);
    const [meetInviteCode, setMeetInviteCode] = useState('');
    const [error, setError] = useState('');

    const history = useHistory();

    const user = useSelector(getCurrentUser);
    const isManager = isStationManager(stationDetails?.station, user);
    const skipTableResetRef = React.useRef<any>(null);

    const stripPlayerEmails = () => {
      if(canAdmin(user)) return false;
      return !canViewAttendeeContactInfo(stationDetails.station);
    }

    useEffect(() => {
      if(!canAdmin(user) && spaceDetails?.space?.isMeet) {
          history.push(`/meet/space/${spaceDetails.space.id}`);
          return;
      }
    }, [spaceDetails, user]);

    const getSpaceInfo = () => {
        getApi(`/space/${spaceId}/station`).then(async (stationDetailsResponse) => {
            if (stationDetailsResponse.ok) {
                const r1 = await stationDetailsResponse.json();
                skipTableResetRef.current = true;
                setStationDetails(r1);
            }
            setStationDetailsLoading(false);
        });

        getApi(`/space/${spaceId}`).then(async (spaceDetailsResponse) => {
            if (spaceDetailsResponse.ok) {
                const r2 = await spaceDetailsResponse.json();
                skipTableResetRef.current = true;
                setSpaceDetails(r2);
            }
            setSpaceDetailsLoading(false);
        });

        getApi(`/space/${spaceId}/details`).then(async (spaceDetailsResponse) => {
            if (spaceDetailsResponse.ok) {
                skipTableResetRef.current = true;
                setSpaceDetailsWithStats(await spaceDetailsResponse.json());
            } else {
                if (spaceDetailsResponse.status === 403) {
                    skipTableResetRef.current = true;
                    const json = (await spaceDetailsResponse.json()) as ApiPermission;
                    setError(json.message);
                }
            }
            setSpaceDetailsLoading(false);
        });

        getApi(`/meet/space/${spaceId}`).then(async (meetInfoResponse) => {
          if (meetInfoResponse.ok) {
            const mi = await meetInfoResponse.json();
            setMeetInviteCode(mi.meetInviteCode);
          }
        });

        skipTableResetRef.current = false;
    }

    useEffect(() => {
        getSpaceInfo();
    }, [spaceId]);

    useInterval(() => {
        getSpaceInfo();
    }, 5000);

    const station = stationDetails?.station;
    const spaces = stationDetails?.spaces;
    const adminSpaces: ApiSpace[] = isManager
        ? spaces
        : spaces
        ? spaces.filter((space) => station.hostedSpaceIds?.includes(space.id))
        : [];

    const users = spaceDetailsWithStats?.users || spaceDetails?.users;

    const handleBackToStation = () => history.push(`/station/${station.id}/admin`);

    // Updating the station details with a new isActive status so that the
    // AdminSpaceList in the left column will properly reflect space open/close changes
    const updateListSpaceStatus = (isActive: boolean) => {
        setStationDetails({
            ...stationDetails,
            spaces: stationDetails.spaces.map((space) =>
                space.id !== spaceId
                    ? space
                    : {
                          ...spaceDetailsWithStats.space,
                          isActive
                      }
            )
        });
    };

    const updateSpaceActiveStatus = (isActive: boolean) => {
        setSpaceDetailsWithStats({
            ...spaceDetailsWithStats,
            space: {
                ...spaceDetailsWithStats.space,
                isActive
            }
        });
        updateListSpaceStatus(isActive);
    };

    const regenerateInviteCode = (isHostCode: boolean, onSuccess: () => void) => {
        const domainObject = isHostCode ? 'space' : 'station';
        const domainObjectId = isHostCode ? spaceId : station.id;
        const endpoint = isHostCode ? 'regenerateInviteHostCode' : 'regenerateStationInviteCode';
        const key = isHostCode ? 'inviteHostCode' : 'inviteCode';

        postApi(`/${domainObject}/${domainObjectId}/${endpoint}`)
            .then(async (regenerateCodeResponse) => {
                if (regenerateCodeResponse.ok) {
                    const data = await regenerateCodeResponse.json();

                    if (isHostCode) {
                        setSpaceDetailsWithStats({
                            ...spaceDetailsWithStats,
                            [domainObject]: {
                                ...spaceDetailsWithStats.space,
                                [key]: data.space[key]
                            }
                        });
                    } else {
                        setStationDetails({
                            ...stationDetails,
                            station: {
                                ...stationDetails!.station,
                                [key]: data.station[key]
                            }
                        });
                    }
                    onSuccess();
                }
            })
            .catch((error) => console.log(error));
    };

    if (error) {
        return (
            <FullScreenContainer>
                <Message
                    title={error}
                    body="Please visit a different space or contact a Station manager if you think this is an error"
                />
            </FullScreenContainer>
        );
    }

    if (spaceDetailsLoading || stationDetailsLoading || _.isEmpty(station)) return <LoadingScreen />;

    return (
        <>
            <Container>
                <TwoColumnPage
                    title={spaceDetails?.space?.title}
                    leftColumn={
                        stationDetails && (
                            <div style={{ paddingTop: '15px' }}>
                                {isManager && (
                                    <MediumButton style={{ marginBottom: '30px' }} onClick={handleBackToStation}>
                                        Back to <span className="noTranslate">{`${station?.title}`}</span>
                                    </MediumButton>
                                )}
                                <SpacePanelHeader>
                                    <SpaceCount spaces={spaces} />
                                    <AllSpaceToggle spaces={spaces} stationId={station.id} />
                                </SpacePanelHeader>
                                <LeftContainer>
                                    <AddItem
                                        text="New Space"
                                        link={`/station/${stationDetails?.station?.id}/createSpace`}
                                    />
                                    <AdminSpaceList stationTitle={stationDetails?.station.title} spaces={adminSpaces} />
                                </LeftContainer>
                            </div>
                        )
                    }
                    rightColumn={
                        <SpaceAdminBody
                            setSpaceIsActive={updateSpaceActiveStatus}
                            spaceDetails={spaceDetailsWithStats || spaceDetails}
                            station={station}
                            regenerateInviteCode={regenerateInviteCode}
                            canDeleteSpace={isManager}
                            meetInviteCode={meetInviteCode}
                        />
                    }
                />
                <div style={{ padding: '30px 60px' }}>
                    {spaceDetailsWithStats ? (
                        <SpaceDetailContext.Provider
                            value={{ spaceDetails: spaceDetailsWithStats, setSpaceDetails: setSpaceDetailsWithStats }}
                        >
                            <SpaceHostTable users={users} skipRef={skipTableResetRef} stripEmail={stripPlayerEmails()}/>
                            <SpaceMemberTable users={users} skipRef={skipTableResetRef} stripEmail={stripPlayerEmails()}/>
                            <SpaceBlockedUserTable users={users} skipRef={skipTableResetRef} stripEmail={stripPlayerEmails()}/>
                        </SpaceDetailContext.Provider>
                    ) : (
                        <LoadingComponent />
                    )}
                </div>
            </Container>
            <StickyButton onClick={() => history.push(`/space/${spaceId}/edit`)} disabled={false}>
                Edit Space
            </StickyButton>
        </>
    );
};

const LeftContainer = styled.div`
    border-radius: 20px;
    box-shadow: inset 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--midnight);
    margin-top: 30px;
    padding: 15px;
`;

const SpacePanelHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 20px;
    align-items: center;
`;

const Container = styled.div`
    position: relative;
`;

export default SpaceAdmin;
