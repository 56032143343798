import * as React from 'react';

const LineBreaksToBrTags: (string?: string) => string | JSX.Element[] = (string) => {
    if (!string) return '';
    return string.split('\n').map((text: string, index: number) => (
        <React.Fragment key={`${text}-${index}`}>
            {text}
            <br />
        </React.Fragment>
    ));
};

export default LineBreaksToBrTags;
