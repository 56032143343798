import * as React from 'react';
import { useEffect } from 'react';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { AcceptApiInvite, ApiStation } from '../api/ApiTypes';
import LoadingComponent from '../components/loading/LoadingComponent';
import Message from '../components/layouts/Message';
import { postApi } from '../api/api';
import { LargeButton } from '../components/inputs/Button';
import { useSelector } from 'react-redux';
import { getAuthState, getCurrentUserLoaded } from '../store/selectors';
import { Background, LogInModal } from './LogIn';
import { isEmpty } from 'react-redux-firebase';

const AcceptManagerInvite = () => {
    const history = useHistory();
    const { inviteManagerCode } = useParams() as any;
    const [error, setError] = React.useState('');
    const [waiting, setWaiting] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    const [station, setStation] = React.useState({} as ApiStation);

    const profileLoaded = useSelector(getCurrentUserLoaded);
    const { pathname } = useLocation() as any;
    const auth = useSelector(getAuthState);

    const stationAdminLink = (station: ApiStation) => (station ? `/station/${station.id}/admin` : '/stations');

    useEffect(() => {
        postApi('/station/acceptManagerInvite', {
            inviteManagerCode
        })
            .then(async (response) => {
                console.log('response', response);
                if (response.ok) {
                    const { station } = (await response.json()) as AcceptApiInvite;
                    setStation(station!);
                } else {
                    setError((await response.json()).message);
                }
                setSuccess(response.ok);
                setWaiting(false);
            })
            .catch((e) => {
                console.error(e);
                setWaiting(false);
                setSuccess(false);
            });
    }, [inviteManagerCode, auth]);

    if (!profileLoaded) {
        return <LoadingComponent />;
    }

    if (isEmpty(auth)) {
        return (
            <LogInModal redirect={pathname}>
                <Background />
            </LogInModal>
        );
    }

    if (waiting) {
        return (
            <Message title="Opening a portal ...">
                <LoadingComponent />
            </Message>
        );
    }

    if (success) {
        return (
            <Message
                title="Opening a portal ..."
                body={
                    <>
                        You&apos;re in! You are now a manager of{' '}
                        <Link to={stationAdminLink(station)}>{station.title}</Link>
                    </>
                }
            >
                <LargeButton onClick={() => history.push(stationAdminLink(station))}>
                    Go to station admin page
                </LargeButton>
            </Message>
        );
    }

    if (error) return <Message title="Opening a portal ..." body={`${error}`} />;

    return <></>;
};

export default AcceptManagerInvite;
