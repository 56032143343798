import * as React from 'react';
import Message from '../layouts/Message';
import { Modal } from '../layouts/Modal';
import { ApiStation, ApiUser } from '../../api/ApiTypes';
import { Select } from '../inputs/Select';
import FormErrorMessage from '../inputs/FormErrorMessage';
import { postApi } from '../../api/api';

type InviteModalProps = {
    userToInvite: ApiUser;
    managedStations: ApiStation[];
    isOpen: boolean;
    handleClose: () => void;
};

const InviteConnectionModal = ({ userToInvite, managedStations, isOpen, handleClose }: InviteModalProps) => {
    const [selectedStation, setSelectedStation] = React.useState({} as ApiStation);
    const [sending, setSending] = React.useState(false);
    const [apiError, setApiError] = React.useState('');
    const [success, setSuccess] = React.useState('');

    const name = `${userToInvite.firstName} ${userToInvite.lastName}`;

    const sendInvite = () => {
        setApiError('');
        setSuccess('');
        setSending(true);

        if (!selectedStation) {
            setApiError('You must select a station to invite this connection to');
        } else {
            postApi(`/station/${selectedStation.id}/invite`, { userEmail: userToInvite.email }).then((res) => {
                setSending(false);
                res.ok
                    ? setSuccess(`${name} has been invited to ${selectedStation.title}!`)
                    : res.json().then((res) => setApiError(res.message));
            });
        }
    };

    const handleSelect = (value: string) => {
        const station = JSON.parse(value);
        setSelectedStation(station);
    };

    return (
        <Modal isOpen={isOpen} handleClose={handleClose}>
            <Message
                title={`Invite ${name} to the party!`}
                onConfirm={sendInvite}
                confirmButtonText="Send Invite"
                confirmButtonDisabled={sending}
                onCancel={handleClose}
            >
                <Select
                    onChange={handleSelect}
                    value={JSON.stringify(selectedStation)}
                    options={[
                        { label: 'Select Station', value: '' },
                        ...managedStations.map((station) => ({
                            value: JSON.stringify(station),
                            label: station.title
                        }))
                    ]}
                />
                {apiError && <FormErrorMessage error={apiError} />}
                {success && <h4>{success}</h4>}
            </Message>
        </Modal>
    );
};

export default InviteConnectionModal;
