import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { ApiStationDetails } from '../api/ApiTypes';
import Message from '../components/layouts/Message';
import { getApi } from '../api/api';
import LoadingScreen from '../components/loading/LoadingScreen';
import { StationPageComponent } from './StationPage';
import { LogInModal } from './LogIn';

const LoggedOutAcceptInvite = () => {
    const { inviteCode } = useParams() as any;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [stationDetails, setStationDetails] = useState(({} as unknown) as ApiStationDetails);

    useEffect(() => {
        getApi(`/station/byInviteCode?inviteCode=${inviteCode}`)
            .then(async (response) => {
                if (response.ok) {
                    const stationDetails = await response.json();
                    console.log({ stationDetails });
                    setStationDetails(stationDetails);
                } else {
                    const res = await response.json();
                    setErrorMsg(res.message);
                    setError(true);
                }
                setLoading(false);
            })
            .catch(() => setError(true));
    }, [inviteCode]);

    const { pathname } = useLocation() as any;
    const urlParams = new URLSearchParams(window.location.search);
    const spaceId = urlParams.get('spaceId');
    const isLite = document.location.pathname.endsWith('/lite');

    if (loading) return <LoadingScreen />;
    if (error) return <Message title="Sorry">{errorMsg || 'Something went wrong with your invite.'}</Message>;

    return (
        <LogInModal redirect={spaceId ? `/space/${spaceId}/${isLite? 'lite': 'lobby'}` : pathname}>
            <StationPageComponent loading={loading} stationDetails={stationDetails} />
        </LogInModal>
    );
};

export default LoggedOutAcceptInvite;
