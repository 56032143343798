import * as React from 'react';
import { useFirebase } from 'react-redux-firebase';
import styled from 'styled-components/macro';
import { AuthButton } from './Button';
import { getApi, postApi } from '../../api/api';
import { setCurrentUser } from '../../store/actions';
import { getAuthState } from '../../store/selectors';
import { useSelector, useDispatch } from 'react-redux';
import QuestionIcon from '../../images/question_icon.png';
import { ApiUser } from '../../api/ApiTypes';
import { logInAnonymously } from '../../utils/AnonymousAuthHelper';

interface Props {
    children: React.ReactElement<any, any> | string;
    handleAuthSuccess: () => void;
}

const AnonymousAuthButton: React.FC<Props> = ({ children, handleAuthSuccess }) => {
    const firebase = useFirebase();
    const dispatch = useDispatch();

    const logInAnonymouslyHandler = async () => {
        const anonLoginResult = await logInAnonymously(firebase).catch((err: Error) => {
          console.log('Anonymous auth failed with error: ', err);
          return { success: false } as { success: boolean; user?: Partial<ApiUser>;};
        });

        if(anonLoginResult.success) {
          const anonUser = anonLoginResult.user! as ApiUser;
          dispatch(setCurrentUser(anonUser));
          handleAuthSuccess();
        }
    };

    return (
        <Button onClick={logInAnonymouslyHandler}>
            <Text>{children}</Text>
        </Button>
    );

}

const Button = styled(AuthButton)`
    display: flex;
    padding: 0 15px 0;
    font-size: 20px;
    align-items: center;
    &:hover {
        background: transparent;
        color: var(--off-white);
    }
`;

const Text = styled.span`
    padding: 5px 6px 5px 4px;
    margin: 0;
`;

export default AnonymousAuthButton;
