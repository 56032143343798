import * as React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getCurrentUserIsAdmin } from '../../store/selectors';

type AdminPanelProps = {
    children: React.ReactNode;
    isManager: boolean;
    title: string;
};

const AdminPanel: React.FC<AdminPanelProps> = ({ children, isManager, title }) => {
    const isAdmin = useSelector(getCurrentUserIsAdmin);

    if (!isAdmin && !isManager) {
        return <></>;
    }

    return (
        <Container>
            <h2>{title}</h2>
            <div style={{ fontSize: 'x-small' }}>(only shown for admins & managers)</div>
            {children}
        </Container>
    );
};

const Container = styled.div`
    background-color: var(--steel-blue);
    padding: 30px;
    border: 2px dashed white;
    max-width: 700px;
    margin-right: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > * {
        margin-bottom: 15px;
    }
`;

export default AdminPanel;
