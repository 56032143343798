import * as React from 'react';
import useCopyClipboard from '../../hooks/useCopyClipboard';
import { TinyButton } from '../inputs/Button';
import Divider from '../Divider';
import styled from 'styled-components/macro';
import { GreyishBodyText } from '../Typography';

interface Props {
    header: string;
    icon: string;
    link: string;
    tooltip: string;
    children?: any;
}
const InviteByLinkForm: React.FC<Props> = ({ header, icon, link, tooltip, children }) => {
    const [LinkCopied, setLinkCopied] = useCopyClipboard(link, { successDuration: 2000 });

    return (
        <Container>
            <div>
                <TopSection>
                    <img src={icon} alt="link icon" style={{ marginRight: '15px' }} />
                    <h3>{header}</h3>
                </TopSection>
                <GreyishBodyText style={{ marginBottom: '30px' }}>{tooltip}</GreyishBodyText>
            </div>
            <div>{children}</div>
            <LinkContainer>
                <LinkText className='noTranslate'>{link}</LinkText>
                <TinyButton onClick={setLinkCopied}>{LinkCopied ? 'Copied!' : 'copy'}</TinyButton>
            </LinkContainer>
            <Divider style={{ margin: '5px 0' }} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 45px;
    margin-bottom: 55px;
`;

const LinkContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 900px;
`;

const TopSection = styled.div`
    display: flex;
    margin-bottom: 15px;
`;

const LinkText = styled.div`
    font-size: 16px;
    letter-spacing: 0.33px;
`;

export default InviteByLinkForm;
