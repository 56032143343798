import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import useInterval from '../../hooks/useInterval';
import { getApi } from '../../api/api';
import { ApiSpace } from '../../api/ApiTypes';
import SpaceCard from './SpaceCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import {  getCurrentUserIsAdmin } from '../../store/selectors';
import { OpenCheckbox } from '../inputs/Checkbox';
import { useLocation, useHistory } from "react-router-dom";
import InputLabel from '../inputs/InputLabel';

import { ReactComponent as GlobalIcon } from '../../images/i-global.svg';
import  LoadingComponent  from '../loading/LoadingComponent';
interface PublicSpacesAPI {
    spaces: ApiSpace[],
    limit: number,
    offset: number,
    totalCount: number
};

//const liveUpdate = false;
//const UPDATE_RATE = 15000;

const AllSpaces = ({openModal}: {openModal?: (link: string, text: string) => void}) => {
    const search = useLocation().search;

    const [isLoaded, isLoadedSetter] = useState(false);
    const [pageState, setPageState] = useState({ items: [] as ApiSpace[], hasMore: true, limit: 24, offset: 0, totalCount: -1 });
    const [includePrivateAndClosedSpaces, setIncludePrivateAndClosedSpaces] = useState(() => {
      return (String(new URLSearchParams(search).get('includePrivateAndClosedSpaces')?? false) === 'true');
    });
    const history = useHistory();
    const isAdmin = useSelector(getCurrentUserIsAdmin);

    useEffect(() => {
        getPublicSpaces().then(() => isLoadedSetter(true));
    }, []);

    useEffect(() => {
        if(pageState.totalCount === -1) return;
        history.push(`?includePrivateAndClosedSpaces=${includePrivateAndClosedSpaces}`);
        history.go(0);
    }, [includePrivateAndClosedSpaces]);

    useEffect(() => {
      console.log({ useEffect: true, pageState });
    }, [pageState])


    const getPublicSpaces = async () => {
        if( (pageState.offset >= pageState.totalCount) && (pageState.totalCount != -1) ) {
            setPageState({ ...pageState, hasMore: false });
            return;
        }

        getApi(`/spaces/public?limit=${pageState.limit}&offset=${pageState.offset}&includePrivateAndClosedSpaces=${includePrivateAndClosedSpaces}`).then(async (response) => {
            const res = (await response.json()) as PublicSpacesAPI;
            const nextOffset = ((pageState.offset + pageState.limit) > res.totalCount)? res.totalCount: (pageState.offset + pageState.limit);
            setPageState({ ...pageState, items: pageState.items.concat(res.spaces), hasMore: true, offset: nextOffset, totalCount: res.totalCount });
            isLoadedSetter(true);
        });
    }

    const Grid = () => {
        if (!isLoaded) return (
            <LoadingComponent />
        );

        if (isLoaded && (pageState.items.length === 0)) {
            return <div></div>;
        }

        return (
          <InfiniteScroll
            style={InfiniteScrollCss}
            dataLength={pageState.items.length}
            next={getPublicSpaces}
            hasMore={pageState.hasMore}
            loader={<AllSpacesLoading><LoadingComponent /></AllSpacesLoading>}
            endMessage={<div></div>}
          >
            {pageState.items.map((space, i) => (
              <SpaceCard key={i} space={space} dynamic={true} openModal={openModal} />
            ))}
          </InfiniteScroll>
        );
    };

    return (
        <AllSpacesContainer>
            <AllSpacesTitle>
                <StyledAllSpacesIcon />
                <div>All Spaces</div>
            </AllSpacesTitle>
                { isAdmin && (
                  <CheckboxWrapper>
                      <OpenCheckbox
                          errors={() => console.log('Error checking all spaces...')}
                          name='isAll'
                          checked={includePrivateAndClosedSpaces}
                          onChange={() => setIncludePrivateAndClosedSpaces(!includePrivateAndClosedSpaces)}
                      />
                      <InputLabel htmlFor="isAll">Include Private &amp; Inactive</InputLabel>
                  </CheckboxWrapper>
                )}
            <Grid />
        </AllSpacesContainer>

    );
};


const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    clear: left;
    margin-left: 10px;
    margin-bottom: 10px;

    label {
        margin-bottom: 0;
    }
`;

const AllSpacesLoading = styled.div`
    text-align: center;
    clear: left;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 40px;
    padding-bottom: 40px;
`;

const AllSpacesContainer = styled.div`
    overflow: hidden;
`;

const AllSpacesTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 30px;
    margin-bottom: 24px;
    font-weight: bold;
`;

const StyledAllSpacesIcon = styled(GlobalIcon)`
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
    & path {
        fill: var(--off-white);
    }
`;

const AllSpacesGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(244px, 1fr));
    justify-content: space-between;
    row-gap: 10px;
`;


const InfiniteScrollCss = {
  width: '100%',
  display: 'grid',
  'gridTemplateColumns': 'repeat(auto-fill, minmax(244px, 1fr))',
  'justifyContent': 'space-between',
  'rowGap': '10px',
  overflow: 'hidden'
};

    // grid-gap: 1rem;

// const AllSpacesGrid = styled.div`
//     width: 100%;
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
// `;

export default AllSpaces;
