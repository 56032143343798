import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

// Keyframes for animations
const rotate = keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(420deg); }
`;

const rotateSub1 = keyframes`
  0%, 10% { transform: rotate(0); }
  100%, 30% { transform: rotate(120deg); }
`;

const rotateSub2 = keyframes`
  0%, 10% { transform: rotate(0); }
  30%, 70% { transform: rotate(60deg); }
  100% { transform: rotate(120deg); }
`;

const rotateSub3 = keyframes`
  0%, 70% { transform: rotate(0); }
  100% { transform: rotate(120deg); }
`;

// Styled-component for the loading screen container
const LoadingScreenContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: var(--midnight); /* Matching the .bg class */
  font-family: 'all-round-gothic', sans-serif; /* Ensure this font is loaded */
  background-position: center;
`;

// Styled-component for the loader
const Loader = styled.div`
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'all-round-gothic', sans-serif;
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: var(--midnight);
`;

// Styled-component for the SVG spinner
const Spinner = styled.svg`
  height: 48px;
  width: 48px;

  .nc-loop-bars-anim-3-icon-f,
  .nc-loop-bars-anim-3-icon-f > * {
    --animation-duration: 1.5s;
    transform-origin: 50% 50%;
  }

  .nc-loop-bars-anim-3-icon-f {
    animation: ${rotate} var(--animation-duration) infinite cubic-bezier(.65, .05, .36, 1);
  }

  .nc-loop-bars-anim-3-icon-f > :nth-child(1) {
    animation: ${rotateSub1} var(--animation-duration) infinite linear;
  }

  .nc-loop-bars-anim-3-icon-f > :nth-child(2) {
    animation: ${rotateSub2} var(--animation-duration) infinite linear;
  }

  .nc-loop-bars-anim-3-icon-f > :nth-child(3) {
    animation: ${rotateSub3} var(--animation-duration) infinite linear;
  }

  @keyframes nc-loop-bars-anim-3 {
    0% { transform: rotate(0); }
    100% { transform: rotate(420deg); }
  }

  @keyframes nc-loop-bars-anim-3-sub-1 {
    0%, 10% { transform: rotate(0); }
    100%, 30% { transform: rotate(120deg); }
  }

  @keyframes nc-loop-bars-anim-3-sub-2 {
    0%, 10% { transform: rotate(0); }
    30%, 70% { transform: rotate(60deg); }
    100% { transform: rotate(120deg); }
  }

  @keyframes nc-loop-bars-anim-3-sub-3 {
    0%, 70% { transform: rotate(0); }
    100% { transform: rotate(120deg); }
  }
`;

export default function LoadingComponent() {
  return (
    <LoadingScreenContainer>
      <Loader>
        {/* Inline SVG */}
        <Spinner xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-hidden="true">
          <title>Loading Spinner</title>
          <g fill="#bee639" className="nc-icon-wrapper">
            <g className="nc-loop-bars-anim-3-icon-f">
              <path d="M59,35H5a3,3,0,0,1,0-6H59a3,3,0,0,1,0,6Z" fill="#bee639"></path>
              <path d="M59,35H5a3,3,0,0,1,0-6H59a3,3,0,0,1,0,6Z" opacity="0.7" data-color="color-2"></path>
              <path d="M59,35H5a3,3,0,0,1,0-6H59a3,3,0,0,1,0,6Z" fill="#bee639" opacity="0.4"></path>
            </g>
          </g>
        </Spinner>
        {/* Uncomment the following line if you want to display the Loading text */}
        {/* <span style={{ marginLeft: '20px' }}>Loading...</span> */}
      </Loader>
    </LoadingScreenContainer>
  );
}
