import * as React from 'react';
import styled from 'styled-components/macro';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ApiSpace, ApiStationDetails } from '../../api/ApiTypes';
import { SpaceUtil } from '../../utils/SpaceUtil';
import useCopyClipboard from '../../hooks/useCopyClipboard';
import FollowTag from '../following/FollowTag';

import hostIcon from '../../images/i-host@3x.webp';
import settingsIcon from '../../images/i-settings@3x.webp';
import previewIcon from '../../images/i-preview.webp';
import inviteIcon from '../../images/i-link@3x.webp';

const SpaceCard = ({space, large, dynamic, openModal}: {space: ApiSpace, large?: boolean, dynamic?: boolean, openModal?: (link: string, text: string) => void}) => {
    const [hovered, setHovered] = useState(false);
    const [followTagHovered, setFollowTagHovered] = useState(false);
    const { pathname } = useLocation();

    const isAdmin = false;
    const isEmbed = pathname.startsWith('/embeds');
    const isLive = (space?.isOpen && space?.attendance && space?.attendance > 0) ? true : false;

    const launchLink = `/space/${space.id}/lobby`;
    const lobbyPath = window.location.origin + launchLink;
    const invitedPath = space.inviteCode ? window.location.origin + `/invite/${space.inviteCode}?spaceId=${space.id}` : null;
    const inviteUserLink = invitedPath ? invitedPath : lobbyPath;
    const settingsButtonTarget = '_self';
    const settingsButtonLocation = isAdmin ? `/space/${space.id}/edit` : `/station/${space.stationId}`;

    const playerCountText = space?.attendance === 1 ? 'player' : 'players';
    const viewerCountText = space?.liteAttendance === 1 ? 'viewer' : 'viewers';

    const completeAttedanceTag = () => {
      const playerPart = `${space?.attendance ?? 0} ${playerCountText}`;
      const isViewPartNotNeeded = (space?.liteAttendance === 0 || !space?.liteAttendance);
      const viewerPart = isViewPartNotNeeded? '': `${space?.liteAttendance ?? 0} ${viewerCountText}`;

      return playerPart + (isViewPartNotNeeded? '': ` / ${viewerPart}`);
    };
 
    const handleLaunchSpace = () => {
        if (isEmbed && openModal) {
            openModal(launchLink, space.title)
        } else {
            open(launchLink, '_blank');
        }
    };

    return (
        <Container
            large={large ?? false}
            dynamic={dynamic ?? false}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Card
                url={SpaceUtil.getImage(space)}
                large={large ?? false}
                hovered={hovered}
                isAdmin={isAdmin}
            >
                <ClickBox onClick={handleLaunchSpace} large={large ?? false}/>
                <TagContainer>
                    <LeftTags>
                        <LiveContainer>
                            {isLive ?
                                <>
                                    <LiveTag>Live</LiveTag>
                                    <PlayerCountTag>{`${completeAttedanceTag()}`}</PlayerCountTag>
                                </> :
                                <div></div>
                            }
                        </LiveContainer>
                    </LeftTags>
                    <FollowTagContainer
                        onMouseEnter={() => setFollowTagHovered(true)}
                        onMouseLeave={() => setFollowTagHovered(false)}
                        hovered={hovered}
                        isAdmin={isAdmin}
                        large={large ?? false}
                    >
                        <StyledFollowTag stationId={space.stationId} stationRole={space.stationRole ?? 'MEMBER'} isHovered={hovered && followTagHovered} stationFollowerCount={space?.stationFollowerCount?? undefined}/>
                    </FollowTagContainer>
                </TagContainer>
                <AdminContainer large={large ?? false} hovered={hovered} isAdmin={isAdmin}>
                    <InviteByLinkButton link={inviteUserLink} large={large ?? false} hovered={hovered}/>
                    <StyledSettingsAnchor href={settingsButtonLocation} target={settingsButtonTarget} rel="noreferrer" hovered={hovered}>
                        <HiddenIcon
                            large={large ?? false}
                            hovered={hovered}
                            src={isAdmin ? settingsIcon : previewIcon}
                        />
                    </StyledSettingsAnchor>
                    <HostIcon large={large ?? false} src={hostIcon} isAdmin={isAdmin}/>
                </AdminContainer>
            </Card>
            <Title
                large={large ?? false}
                hovered={hovered}
                className="noTranslate"
            >{space?.title}</Title>
        </Container>
    );
};

export default SpaceCard;

const InviteByLinkButton = ({link, large, hovered}: {link: string, large: boolean, hovered: boolean}) => {
    const [linkCopied, linkCopiedSetter] = useCopyClipboard(link, { successDuration: 2000 });

    return(
        <InviteLinkContainer hovered={hovered}>
            <HiddenIcon
                large={large ?? false}
                hovered={hovered}
                src={inviteIcon}
                onClick={linkCopiedSetter}
            />
            {linkCopied && (<LinkCopiedTooltip>Copied!</LinkCopiedTooltip>)}
        </InviteLinkContainer>
    );
};

const Container = styled.div<{ onClick?: () => void, large: boolean, dynamic: boolean}>`
    position: relative;
    width: 244px;
    width: 100%;
    width: ${(props) => props.large ? '410px' : props.dynamic ? '100%' : '244px'};
    height: fit-content;
    cursor: pointer;
`;

const Card = styled.div<{url: string, large: boolean, hovered: boolean, isAdmin: boolean}>`
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    height: 135px;
    border-radius: 10px;
    box-shadow: 1px 1px 30px 0 rgba(0,0,0,0.35);
    background-size: cover;
    transition: var(--main-transition);

    ${(props) => `background-image: url(${props.url});`}

    ${(props) => props.large ?
        `height: 227px;
         padding: 15px;
        ` : ``
    }

    ${(props) => !props.hovered ?
        `border: solid 5px var(--midnight);` :
        props.isAdmin ?
        `border: solid 5px var(--purple-blue);` :
        `border: solid 5px var(--tennis-green);`
    }
`;

const ClickBox = styled.div<{large: boolean}>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    ${(props) => props.large ?
        `width: 342px;` :
        `width: 204px;`
    }
`;

const TagContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
`;

const LeftTags = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: fit-content;
`;

const LiveContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const tagStyles = `
    width: fit-content;
    height: fit-content;
    padding: 1px 6px 1px 6px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: var(--burnt-red);
    box-shadow: 1px 1px 6px 0 #010027;
    border-radius: 5px;
`;

const LiveTag = styled.div`
    ${tagStyles}
    background-color: var(--burnt-red);
`;

const FollowTagContainer = styled.div<{hovered: boolean, isAdmin: boolean, large: boolean}>`
    position: absolute;
    top: 10px;
    height: fit-content;
    transition: var(--main-transition);
    z-index: +2;

    right: ${(props) => {
        if (!props.hovered) return '10px;'
        if (!props.large) return '45px;'
        return '75px;'
    }}
`;

const StyledFollowTag = styled(FollowTag)`

`;

const PlayerCountTag = styled.div`
    ${tagStyles}
    background-color: #1f2030;
`;

const Title = styled.h2<{large: boolean, hovered: boolean}>`
    margin-top: 5px;
    margin-left: 5px;
    transition: var(--main-transition);

    ${(props) => props.large ?
        `font-size: 30px;` :
        `font-size: 20px;`
    }

    ${(props) => props.hovered ?
        `color: var(--tennis-green);` :
        ``
    }
`;

const HostName = styled.h3`
    margin-left: 5px;
    font-size: 14px;
    font-weight: normal;
`;

const AdminContainer = styled.div<{large: boolean, hovered: boolean, isAdmin: boolean}>`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    bottom: 0;
    right: 0;
    padding-left: 0px;
    padding-bottom: 3px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: var(--main-transition);
    cursor: pointer;

    ${(props) => props.isAdmin ?
        `
            background-color: var(--purple-blue);
        ` :
        `
            background-color: var(--tennis-green);
        `
    }


    ${(props) => props.large ?
        `
            width: 68px;
            height: 51px;
        ` :
        `
            width: 40px;
            height: 30px;
        `
    }
    ${(props) => props.hovered ?
        `
            height: 100%;
            opacity: 1;
        ` :
        `
            border-top-left-radius: 10px;
            border-top-right-radius: 0px;
        `
    }

    ${(props) => {
        if (!props.isAdmin && props.hovered) {
            return 'opacity: 1;'
        } else if (!props.isAdmin && !props.hovered) {
            return 'opacity: 0;'
        }
    }}
`;

const HostIcon = styled.img<{large: boolean, isAdmin: boolean}>`
    ${(props) => props.large ?
        `
            width: 24px;
            height: 24px;
        ` :
        `
            width: 14px;
            height: 14px;
        `
    }
    ${(props) => props.isAdmin ?
    `
        opacity: 1;
    ` :
    `
        opacity: 0;
    `

    }
`;

const HiddenIcon = styled.img<{large: boolean, hovered: boolean}>`
    opacity: 0;
    ${(props) => props.large ?
        `
            width: 44px;
            height: 44px;
        ` :
        `
            width: 26px;
            height: 26px;
        `
    }
    ${(props) => props.hovered ?
        `
            opacity: 1;
        `:
        `
            height: 0px;
        `
    }

    border-radius: 5px;
    &:hover {
        border: 2px solid var(--tennis-green);
    }
    transition: var(--main-transition);
`;

const InviteLinkContainer = styled.div<{hovered: boolean}>`
    ${(props) => props.hovered ?
        `` : `height: 0px;`
    }
    position: relative;
`;

const StyledSettingsAnchor = styled.a<{hovered: boolean}>`
    ${(props) => props.hovered ? `` : `height: 0px;`}
`;

const LinkCopiedTooltip = styled.div`
    position: absolute;
    top: 20%;
    right: 100%;
    width: fit-content;
    height: 20px;
    background-color: var(--tennis-green);
    border-radius: 5px;
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.35);
    padding: 0 4px 2px 4px;
    color: var(--midnight);
    z-index: +2;
`;
