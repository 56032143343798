import React from 'react';
import styled from 'styled-components/macro';
import CTALiveStream from '../../images/cta-livestream.png';
import { useHistory } from 'react-router-dom';

const StreamUpsell = () => {
    const history = useHistory();
    return (
        <Container>
 
                <FlexColumnMarginRight>
                    <Image src={CTALiveStream}></Image>
                </FlexColumnMarginRight>
            <FlexColumn>
                <PanelTitle>Upgrade to access Live Stream</PanelTitle>
                <PanelContent>Stream high quality video to one or all of your spaces simultaneously.</PanelContent>
                <PanelContent>Use the Stream Viewer page to stream to thousands.</PanelContent>
                <UpgradeButton onClick={() => history.push('https://now.urnowhere.com/pricing')}>
                    GO PRO TO UNLOCK
                </UpgradeButton>
                </FlexColumn>
        
        </Container>
    );
};
const Image = styled.img`
    width: 100%;
    height: 260px;
    object-fit: fill;
    border-radius: 20px;


`;  
const FlexColumn = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 320px;

`;  

const FlexColumnMarginRight = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 320px;
    margin-right: 20px;
    @media (max-width: 700px) {
        margin-right: 0px;
        margin-bottom: 20px;
    }
`;  


const Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 700px;
    height: 300px;
    color: var(--off-white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-image: linear-gradient(346deg, #1f2030 , #343550 60%);
    border-radius: 20px;
    padding: 20px;

    @media (max-width: 700px) {
        flex-direction: column;
        max-width: 360px;
        height: auto;
    }
`;



const PanelTitle = styled.h2`
    font-size: 24px;
    margin-bottom: 20px;
`;

const PanelContent = styled.p`
    font-size: 16px;
    margin-bottom: 10px;
`;

const UpgradeButton = styled.button`
    font-family: all-round-gothic;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.43px;
    text-align: center;
    color: #010027;
    margin: 10px 0 0;
    padding: 10px 50px 10px;
    border-radius: 30px;
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.5);
    background-color: #bee639;
`;

export default StreamUpsell;
