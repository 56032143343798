import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { FieldError } from 'react-hook-form';
import moment from 'moment';
import FormErrorMessage from '../../components/inputs/FormErrorMessage';
import { FirebaseDate } from '../../api/ApiTypes';
import { Modal } from '../layouts/Modal';
import Calendar from '../inputs/Calendar';
import { OutlineButton } from '../inputs/Button';

type EventDatePickerProps = {
    register: any,
    name: string,
    validation?: any,
    errors: DeepMap<Record<string, any>, FieldError>,
    calError: string | null,
    startDateTime: FirebaseDate | null,
    endDateTime: FirebaseDate | null,
    startDateTimeSetter: React.Dispatch<React.SetStateAction<FirebaseDate | null>>,
    endDateTimeSetter: React.Dispatch<React.SetStateAction<FirebaseDate | null>>
};

const EventDatePicker = ({ register, name, validation, errors, calError, startDateTime, endDateTime, startDateTimeSetter, endDateTimeSetter }: EventDatePickerProps) => {
    const [showTimePicker, showTimePickerSetter] = useState(false);

    return (
        <>
            <DateContainer
                name={name}
                {...register(name)}
                validation={validation}
            >
                <DateButton
                    onClick={() => {
                        showTimePickerSetter(true)
                    }}
                >
                    Set Date &amp; Time *
                </DateButton>
                {startDateTime && (
                    <StartDateOutput>{`START: ${moment((startDateTime as any).seconds * 1000).format('MMM  DD, h:mm a')}`}</StartDateOutput>
                )}
                {endDateTime && (
                    <EndDateOutput>{`END: ${moment((endDateTime as any).seconds * 1000).format('MMM  DD, h:mm a')}`}</EndDateOutput>
                )}

                <Modal
                    isOpen={showTimePicker}
                    handleClose={() => showTimePickerSetter(false)}
                >
                    <Calendar
                        title='Start/End Date &amp; Time'
                        onConfirm={(time: {start: Date, end: Date}) => {
                            const utcStart = moment(time.start).valueOf();
                            const utcEnd = moment(time.end).valueOf();

                            startDateTimeSetter({seconds: utcStart / 1000} as unknown as FirebaseDate);
                            endDateTimeSetter({seconds: utcEnd / 1000} as unknown as FirebaseDate)
                            showTimePickerSetter(false);
                        }}
                    />
                </Modal>
            </DateContainer>
            {errors?.[name]?.message && <FormErrorMessage error={errors?.[name]?.message} />}
            {/* Hacky validation message */}
            {calError && (<FormErrorMessage error={calError} />)}
        </>
    );
};

const DateContainer = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    padding: 10px;
    background-color: var(--steel-blue);
    border-radius: 10px;
`;

const DateButton = styled.div`
    margin-right: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    border: 1px solid var(--off-white);
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: var(--main-transition);

    &:hover {
        background-color: var(--off-white);
        color: var(--midnight);
    }
`;

const StartDateOutput = styled.div`
    padding-top: 5px;
    margin-right: 10px;
`;

const EndDateOutput = styled.div`
    padding-top: 5px;
`;

export default EventDatePicker;
