import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getCurrentUserType } from '../store/selectors';
import { useHistory } from 'react-router-dom';

const ReturnToGame: React.FC = () => {
    const currentUserType = useSelector(getCurrentUserType);
    const history = useHistory();

    if (currentUserType === 'Anonymous') {
        history.push('log-in?returnToGame=true');
        return null;
    }

    return(
        <Container>
            <h1>
                Done and done!
                <br />
                Close this tab to continue.
            </h1>
            <Summary>
                Your account has been created. Please close this browser tab to get back to your active world.
            </Summary>
        </Container>
    );

}

const Container = styled.div`
    margin-top: 25vh;
    margin-left: auto;
    margin-right: auto;
    width: 50vh;
`;

const Summary = styled.div`
    margin-top: 50px;
    font-size: 20px;
    line-height: 30px;
`;

export default ReturnToGame;
