import { BigGreenNumbers, SmallLabelText } from './Typography';
import * as React from 'react';
import styled from 'styled-components/macro';

interface Props {
    items: any[];
    label: string;
    isDescending?: boolean;
    toggleDescending?: () => void;
}

const CountWidget: React.FC<Props> = ({ items, label }) => {
    if (!items) return <></>;
    return (
        <OuterContainer>
            <Container>
                <StyledBigGreenNumbers className="noTranslate">{items.length}</StyledBigGreenNumbers>
                <StyledSmallLabelText>{label}</StyledSmallLabelText>
            </Container>
        </OuterContainer>
    );
};

const OuterContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

const Container = styled.div`
    position: absolute;
    display: flex;
    width: fit-content;
    height: 214px;
    top: -53px;
    right: -30px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    background-color: var(--steel-blue);
    z-index: -1;
`;

const StyledBigGreenNumbers = styled(BigGreenNumbers)`
    position: relative;
    top: -50px;
    margin-top: -5px;
    margin-right: 30px;
    font-size: 100px;
`;

const StyledSmallLabelText = styled(SmallLabelText)`
    position: relative;
    top: -20px;
    font-weight: normal;
`;
export default CountWidget;
