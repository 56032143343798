import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import StationForm from '../components/stationAdmin/StationForm';
import CenteredSingleColumnLayout from '../components/layouts/CenteredSingleColumnLayout';
import { ApiStation, ApiStationEditableMetadata } from '../api/ApiTypes';
import { getApi, putApi } from '../api/api';
import  LoadingComponent from '../components/loading/LoadingComponent';
import { useState } from 'react';
import LoadingScreen from '../components/loading/LoadingScreen';
import { canAdmin } from '../permissions/users';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../store/selectors';

const EditStation: React.FC = () => {
    const history = useHistory();
    const curUser = useSelector(getCurrentUser);

    const [loading, setLoading] = useState(true);
    const [error, setError] = React.useState('');
    const { stationId } = useParams() as any;
    const [station, setStation] = React.useState((undefined as unknown) as ApiStation);

    React.useEffect(() => {
        getApi(`/station/${stationId}`).then(async (response) => {
            const _station = (await response.json()).station;
            setStation(_station);
            setLoading(false);
        });
    }, [stationId]);

    React.useEffect(() => {
      if(!canAdmin(curUser) && station?.isMeet) {
          history.push(`/meet/station/${station.id}`);
          return;
      }
    }, [station, curUser]);


    const updateStation = (stationUpdate: ApiStationEditableMetadata) =>
        new Promise<void>((resolve) => {
            setError('');
            if(station.imageUrl === stationUpdate.imageUrl) delete stationUpdate.imageUrl;
            putApi(`/station/${stationId}`, stationUpdate)
                .then(async (r) => {
                    const json = await r.json();
                    if (!r.ok) {
                        setError(json.msessage || 'Something went wrong');
                    }
                    history.push(`/station/${stationId}/admin`);
                })
                .catch(() => {
                    setError('Something went very wrong');
                    resolve();
                });
        });

    if (loading) return <LoadingScreen />;
    return (
        <>
            <CenteredSingleColumnLayout title="Update your Station" subtitle="Let people know what you're all about">
                {!station && <LoadingComponent />}
                {station && <StationForm onSubmit={updateStation} initialStationData={station} edit={true} />}
                {error && <h2>Error</h2>}
            </CenteredSingleColumnLayout>
        </>
    );
};

export default EditStation;
