import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { isLive } from './EventCard';
import { FirebaseDate } from '../../api/ApiTypes';

type DateStampProps = {
    time: number;
    className?: string;
}

const DateStampContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 75px;
    color: var(--off-white);
    text-transform: uppercase;
    box-shadow: 1px 2px 30px 0 rgba(0, 0, 0, 0.5);
`;
const DateStampBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 76px;
    background-color: var(--steel-blue);
    font-weight: bold;
    font-size: 12px;
    text-align: center;
`;

const DayLine = styled.span<{day: string}>`
`;

const DateLine = styled.span<{date: string}>`
    font-size: 24px;
    font-weight: 200;
`;

const MonthLine = styled.span<{month: string}>`
`;

const DateStampBottom = styled.div<{isLive: boolean}>`
    height: 30px;
    padding-top: 6px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border-bottom-right-radius: 10px;

    ${(props) => props.isLive ? `
        color: var(--midnight);
        background-color: var(--tennis-green);
    `:`
        background-color: var(--purple-blue);
    `}
`;

const DateStamp = ({time, className}: DateStampProps) => {
    const timeInMS = time * 1000;

    const getLiveOrTime = () => {
        if (isLive(time)) return 'Live';

        const hour = moment(timeInMS).format('h:mm a');
        return hour;
    }

    const day = moment(timeInMS).format('ddd');
    const date = moment(timeInMS).format('DD');
    const month = moment(timeInMS).format('MMM');
    return (
        <DateStampContainer className={className?? ''}>
            <DateStampBody>
                <DayLine day={day}>{day}</DayLine>
                <DateLine date={date}>{date}</DateLine>
                <MonthLine month={month}>{month}</MonthLine>
            </DateStampBody>
            <DateStampBottom
                isLive={isLive(time)}
            >
                {getLiveOrTime()}
            </DateStampBottom>
        </DateStampContainer>
    );
};

export default DateStamp;
