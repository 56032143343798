import { createGlobalStyle } from 'styled-components';

type GlobalStyleProps = {
    transparent?: boolean;
};

const GlobalStyles = createGlobalStyle<GlobalStyleProps>`
  :root {
    //colors
    --aqua-marine: #1de9b6;
    --battleship-grey: #6c6c89;
    --acanthus-grey: #9999a8;
    --burnt-red: #9a0a0a;
    --charcoal-grey: #3f3f50;
    --dark: #292833;
    --dark-indigo: #0a083f;
    --dark-purple: #3a0845;
    --dark-transparent-purple: var(--dark-transparent-purple);
    --darker-purple: #27052e;
    --darkest-purple: #1c0321;
    --fuchsia: #e90fe9;
    --light-navy: #2f2d86;
    --light-pink: #f9ecf0;
    --indigo: #110f71;
    --midnight: #030217;
    --midnight-translucent: rgba(3, 2, 23, 0.75);
    --navy-blue: #010027;
    --purple-blue: #3e3adf;
    --robin-egg-blue: #95fff9;
    --shadow-black: rgba(0, 0, 0, 0.35);
    --soft-green: #72c472;
    --steel-blue: #1b1a4d;
    --tennis-green: #bee639;
    --transparent-purple: rgba(39, 5, 46, 0.6);
    --off-white: #f6f6f9;
    --white: #ffffff;
    --white-two: #fbfbfb;
// shapes
    --nonagon: polygon(50% 5%, 80% 16%, 95% 43%, 90% 73%, 67% 94%, 33% 94%, 10% 73%, 5% 43%, 20% 16%);
// Transition
    --main-transition: all 500ms cubic-bezier(0.673, 0.003, 0.509, 0.997);
// Breakpoints
    --mobile: '950px';

  body {
    background: ${({ transparent }) => (transparent ? 'rgba(0, 0, 0, 0)' : 'var(--navy-blue)')};
  }
}`;

export default GlobalStyles;
