 // @ts-nocheck
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from '../layouts/Modal';
import { env } from '../../utils/envLoader';
import { write3DAccessableCookie } from '../../utils/CookieUtil';
import { getCurrentUserId, getCurrentUserEmail } from '../../store/selectors';
import { useSelector } from 'react-redux';
import { getApi } from '../../api/api';

//test CC: 4242 4242 4242 4242

// @ts-ignore
declare module 'react' {
  interface HTMLAttributes<T> extends React.HTMLAttributes<T> {
    'stripe-pricing-table'?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
  }
}

/*
declare module 'react' {
  interface IntrinsicElements {
    'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
  }
}
*/

export const SubscriptionModal = ({ handelClose, successRoute }: { handelClose: () => void; successRoute?: string; }) => {
  const nwrUserId = useSelector(getCurrentUserId);
  const nwrUserEmail = useSelector(getCurrentUserEmail);
  const closeModal = () => console.log('Subscription Modal closed');

  //const { loading, setLoading } = useState(true);
  //const { customerSessionClientSecret,  setCustomerSessionClientSecret } = useState(undefined as string | undefined);

  let loading = true;
  let customerSessionClientSecret: string | undefined = undefined;

  useEffect(() => {
    getApi('/payment/session').then(async (result) => {
      const json = await result.json();
      console.log(json);
      if(json.success) customerSessionClientSecret = json.body.client_secret;
      loading = false;
    });
    if(successRoute) {
      write3DAccessableCookie('paymentSuccessRoute', successRoute, new Date(new Date().setDate(new Date().getDate() + 1)));
    }
  }, []);

  return (
    <>
    {loading && <Modal isOpen={true} handleClose={handelClose}>
        <stripe-pricing-table
          style={ {"width": "100vw"} }
          pricing-table-id={env('STRIPE_PRICING_TABLE_ID')}
          publishable-key={env('STRIPE_PUBLISHABLE_KEY')}
          client-reference-id={nwrUserId}
          customer-email={nwrUserEmail}
          customer-session-client-secret={customerSessionClientSecret}
        >
        </stripe-pricing-table>
      </Modal>
    }
    </>
  );
}
