// Modal Context when game is active.

import * as React from 'react';
import { createContext, useContext, useState, useEffect } from 'react';
import { APIDiscover } from '../../api/ApiTypes';
import { getApi, deleteApi, postApi, putApi } from '../../api/api';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/selectors';
import { canAdmin, canManageDiscover } from '../../permissions/users';

type Props = {
    children: React.ReactNode;
};

export type EventCategories = 'Other' | 'Shows' | 'Talks' | 'Hangs';

type NwrEventsContextType = {
    events: APIDiscover[];
    // eventsSetter?: React.Dispatch<React.SetStateAction<APIDiscover[]>>;
    getFreshEvents: () => void;
    error: any;
    errorSetter: React.Dispatch<React.SetStateAction<any>>;
    loaded: boolean;
    showAllEvents: boolean;
    showAllEventsSetter: React.Dispatch<React.SetStateAction<boolean>>;
    eventFilters: EventCategories[];
    eventFiltersSetter: React.Dispatch<React.SetStateAction<EventCategories[]>>;
    toggleDisplayCreateModal: boolean;
    toggleDisplayCreateModalSetter: React.Dispatch<React.SetStateAction<boolean>>;
    isAdmin: boolean;
    // Admin only
    updateCache: () => void;
};

const NwrEventsContext = createContext<NwrEventsContextType | null>(null);

export const NwrEventsContextProvider = ({ children }: Props) => {
    const [events, eventsSetter] = useState<APIDiscover[]>([]);
    const [loaded, loadedSetter] = useState(false);
    const [error, errorSetter] = useState<any | null>(null);
    const [showAllEvents, showAllEventsSetter] = useState(true);
    const [eventFilters, eventFiltersSetter] = useState<EventCategories[]>([]);
    const [toggleDisplayCreateModal, toggleDisplayCreateModalSetter] = useState(false);
    const currentUser = useSelector(getCurrentUser);

    const isAdmin = canAdmin(currentUser);
    const canManage = canManageDiscover(currentUser);

    useEffect(() => {
        // TODO: useFetch
        getFreshEvents();
    }, []);

    const getFreshEvents = async () => {
        loadedSetter(false);

        try {
            const requestedEvents = await getApi('/discover');
            // const requestedEvents = await getApi('/discover/all');
            const requestedEventsJSON = await requestedEvents.json() as APIDiscover[];

            eventsSetter(requestedEventsJSON);
        } catch {
            (e: any) => {
                console.error(e);
                errorSetter(e)
            }
        } finally {
            loadedSetter(true);
        }
    };

    const createEvent = async () => {
        console.log('createEvent stub');
    }

    const deleteEvent = async (event: APIDiscover) => {
        // TODO: Check if user has permission to delete this event or if they are a site admin.
        // if (event.permissions || user.isAdmin)
        let res;
        try {
            res = await deleteApi(`/disover/${event.id}`);
            console.log('Event:', event.title, ' deleted.')
            getFreshEvents();
        } catch {
            errorSetter(res);
        }
    };

    // TODO: Use in EventCard CTAs post Discovery launch
    const toggleSubscribeToEvent = async (event: APIDiscover) => {
        let res;
        try {
            if (event.isSubscribed) {
                res = await deleteApi(`/discover/${event.id}/subscribe`);
            } else {
                res = await putApi(`/discover/${event.id}/subscribe`);
            }
            getFreshEvents();
        } catch {
            errorSetter(res);
        }
    };

    const toggleEventApproval = async (event: APIDiscover) => {
        if (canManage) {
            let res;
            try {
                res = await putApi(`/discover/${event.id}/approved/${!event.isApproved ? 1 : 0}`);
                getFreshEvents();
            } catch {
                errorSetter(res);
            }
        } else {
            console.error('User is not admin');
        }
    };

    const updateCache = async () => {
        if (canManage) {
            const res = await putApi('/discover/cache');
            const jsonRes = await res.json();
            console.log(jsonRes);
            if (jsonRes.success) {
                getFreshEvents();
            } else {
                console.error('API failed to update cache');
            }
        } else {
            console.error('User is not admin');
        }
    };

    return (
            <NwrEventsContext.Provider
                value={{
                    events,
                    getFreshEvents,
                    loaded,
                    error,
                    errorSetter,
                    showAllEvents,
                    showAllEventsSetter,
                    eventFilters,
                    eventFiltersSetter,
                    toggleDisplayCreateModal,
                    toggleDisplayCreateModalSetter,
                    updateCache,
                    isAdmin
                }}
            >
                {children}
            </NwrEventsContext.Provider>
        );
};

export const useNwrEventsContext = () => {
    const context = useContext(NwrEventsContext);

    if (!context) throw new Error('NwrEventsContext must be called from within the NwrEventsContextProvider');

    return context;
};
