import * as React from 'react';
import { useFirebase } from 'react-redux-firebase';
import GoogleIcon from '../../images/Google__G__Logo.svg';
import styled from 'styled-components/macro';
import { AuthButton } from './Button';
import { getApi, postApi } from '../../api/api';
import { setCurrentUser, clearCurrentUser } from '../../store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { getAuthState } from '../../store/selectors';
import { getRefCodeAndSetIfPresent, clearRefCodeAndLoc } from '../../utils/ReferralCodeHelper';

import * as fb from 'firebase/app';
import 'firebase/auth';
import equal from 'fast-deep-equal';

interface Props {
    children: React.ReactElement<any, any> | string;
    type?: 'signup' | 'login'
    destination?: string;
}

const GoogleAuthButton: React.FC<Props> = ({ children, destination, type }) => {
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const auth = useSelector(getAuthState);

    const anonFlow = async () => {
        console.log('anon flow');
        const provider = new fb.auth.GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
        let googleProfile: any = {};
        try {
            console.log('Linking with google account...')
            const firebaseUser = await firebase!.auth()!.currentUser!.linkWithPopup(provider);
            googleProfile = firebaseUser!.additionalUserInfo!.profile;
         } catch(err) {
            console.error('Linking google credentials to anonymous user failed.', err);
            throw err;
        }
        console.log('Authentication successfull', googleProfile);
        return {
            displayName: googleProfile.name,
            photoURL: googleProfile.picture,
            email: googleProfile.email
        };

    };

    const signUpFlow = async () => {
        console.log('sign up flow');
        const firebaseUser = await firebase.login({ type: 'popup', provider: 'google' });
        const googleProfile: any = firebaseUser!.additionalUserInfo!.profile;
        return {
            displayName: googleProfile.name,
            photoURL: googleProfile.picture,
            email: googleProfile.email,
        }
    };

    const reconcileProfile = (initialUser: any, googleUser: { [key: string]: any }, isAnon: boolean) => {
        const user = {...initialUser};
        if (isAnon || !user?.firstName) {
            user.firstName = googleUser?.displayName?.split(' ')[0];
        }
        if (isAnon || !user?.lastName) {
            user.lastName = googleUser?.displayName?.split(' ')[1];
        }
        if (isAnon || !user?.avatarUrl) {
            user.avatarUrl = googleUser?.photoURL;
        }
        if (isAnon || !user?.email) {
            user.email = googleUser?.email;
        }
        if (isAnon || !user?.userType) {
            user.userType = 'Guest';
        }
        if (isAnon || user.deletedAt) {
            user.deletedAt = null;
        }
        if (isAnon) {
            user.displayName = googleUser.displayName;
            user.badgeSubtitle = "";
            user.isInvited = true; // remove this if we open up anon
        }

        user.referralCode = getRefCodeAndSetIfPresent();

        delete user.email;
        delete user.id;
        delete user.captchaToken;
        return user;
    }

    const logInOrSignUpWithGoogle = async () => {
        const isAnon = (auth.isAnonymous)? true: false;

        try {

            let googleProfile: { [key: string]: any } = {};

            console.log('retrieve google user info');
            if (isAnon && type === 'signup') {
              googleProfile = await anonFlow().catch((e: Error) => {
                return { error: e };
              });
            } else {
                googleProfile = await signUpFlow();
            }
            console.log(googleProfile);

            if(googleProfile?.error) {
              alert(googleProfile!.error! + ' To login as this user navigate to the login page.');
              return;
            }

            console.log('retrieve user profile');
            const userProfile = await getApi('/user/me');
            let user = await userProfile.json();
            console.log(user);
            const reconciledProfile = reconcileProfile(user, googleProfile, isAnon);
            console.log(reconciledProfile);
            clearRefCodeAndLoc();

            // check if the user is valid
            if(Object.keys(user).length === 1) {
                console.log('...missing user record');

                // check if the user is valid
                console.log('create user record');
                const createResponse = await postApi('/user/create', reconciledProfile);
                if (createResponse.ok) {
                    console.log('creation successful');
                    user = await createResponse.json();
                    dispatch(setCurrentUser(user));
                } else {
                    console.log('Failed to create a user profile.', createResponse);
                    firebase.logout();
                }

            } else {

                // UPDATE PROFILE IF NEEDED
                console.log('check user profile for updates');
                const changed = !equal(user, reconciledProfile);
                if (changed) {
                    console.log('user changed, updating...');
                    const updateResponse = await postApi('/user/me/update', reconciledProfile);
                    if (updateResponse.ok){
                        console.log('update successful');
                        user = await updateResponse.json();
                        //dispatch(clearCurrentUser());
                        console.log({ user });
                        dispatch(setCurrentUser(user));
                        if(isAnon) {
                          if(destination) {
                              window.location.href = destination;
                           } else {
                              window.location.reload();
                           }
                        }
                    } else {
                        console.log('Failed to update user profile.', updateResponse);
                        // no need to logout
                    }
                }

            }

        } catch (err: any){
            console.error('logInOrSignUpWithGoogle error', err);
        }

    };

    return (
        <TwoElementButton onClick={logInOrSignUpWithGoogle}>
            <Icon src={GoogleIcon} />
            <Text>{children}</Text>
        </TwoElementButton>
    );
};

const TwoElementButton = styled(AuthButton)`
    display: flex;
    padding: 0 15px 0;
    font-size: 20px;
    align-items: center;
    width: 340px;
    pointer-events: auto;
    &:hover {
        background: transparent;
        color: var(--off-white);
    }
    @media (max-width: 960px) {
        max-width: 340px;
       width: 100vw;
       justify-content: space-around;
    }
    @media (max-width: 460px) {
       max-width: 340px;
       width: 70vw;

    }
`;

const Icon = styled.img`
    height: 100%;
    padding: 5px 5px 5px 2px;
    margin: 5px 0;
    @media (max-width: 460px) {
        height: 60%;
 
     }
`;

const Text = styled.span`
    padding: 5px 6px 5px 4px;
    margin: 0;
`;
export default GoogleAuthButton;
