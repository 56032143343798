import * as React from 'react';
import styled from 'styled-components/macro';
import { useHistory, Link } from 'react-router-dom';
import { getApi } from '../api/api';
import { ApiEnvironment } from '../api/ApiTypes';
import EnvironmentTile from '../components/environments/EnvironmentTile';
import { DeleteIcon } from '../images/icons';
import { Modal } from '../components/layouts/Modal';
import Message from '../components/layouts/Message';
import { postApi } from '../api/api';

const Environments = () => {
    const [environments, setEnvironments] = React.useState([] as ApiEnvironment[]);

    React.useEffect(() => {
        getApi('/environments').then(async (response) => {
            const json = (await response.json()) as ApiEnvironment[];

            if (response.ok) {
                setEnvironments(json);
            }
        });
    }, []);

    const removeEnvironment = (environment: ApiEnvironment) => {
        setEnvironments(environments.filter((env) => env.id !== environment.id));
    };

    return (
        <Container>
            <Tiles>
                {environments.map((environment) => (
                    <Tile environment={environment} removeEnvironment={removeEnvironment} key={environment.id} />
                ))}
                <CreateLink to="/environment/create">+ Add Environment</CreateLink>
            </Tiles>
        </Container>
    );
};

const Tile = ({
    environment,
    removeEnvironment
}: {
    environment: ApiEnvironment;
    removeEnvironment: (environment: ApiEnvironment) => void;
}) => {
    const history = useHistory();
    const [showModal, setShowModal] = React.useState(false);
    const [apiError, setApiError] = React.useState('');

    const handleDelete = () => {
        postApi(`/environment/${environment.id}/delete`).then(async (r) => {
            const json = await r.json();

            if (!r.ok) {
                const message = json.message || 'Could not delete environment';
                setApiError(message);
            } else {
                removeEnvironment(environment);
            }
        });
    };

    return (
        <TileRow>
            <EnvironmentTile onClick={() => history.push(`environment/${environment.id}`)} environment={environment} isOpen={false} />
            <DeleteButton onClick={() => setShowModal(true)}>
                <DeleteIcon />
            </DeleteButton>
            <Modal isOpen={showModal} handleClose={() => setShowModal(false)}>
                <Message
                    title={`Are you sure you want to delete environment "${environment.displayName}"?`}
                    onCancel={() => setShowModal(false)}
                    onConfirm={handleDelete}
                    confirmButtonText="Delete"
                >
                    {apiError && <Error>{apiError}</Error>}
                </Message>
            </Modal>
        </TileRow>
    );
};

const Error = styled.span`
    font-family: all-round-gothic, sans-serif;
    height: 16px;
    font-size: 16px;
    font-weight: bold;
    color: var(--burnt-red);
`;
const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Tiles = styled.div`
    width: 510px;
`;

const DeleteButton = styled.button`
    border: none;
    outline: none;
    background: transparent;
    position: absolute;
    right: -50px;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);

    svg {
        stroke: var(--off-white);
    }

    &:hover {
        svg {
            stroke: var(--burnt-red);
        }
    }
`;

const TileRow = styled.div`
    position: relative;
`;

const CreateLink = styled(Link)`
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    padding-left: 66px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 var(--dark-transparent-purple);
    text-decoration: none;
    text-shadow: 1px 1px 6px var(--shadow-black);
    cursor: pointer;
    background-color: var(--steel-blue);

    font-family: all-round-gothic, sans-serif;
    color: white;
    text-transform: uppercase;
    font-weight: bold;

    &:hover {
        color: var(--tennis-green);
        text-decoration: none;
    }
`;

export default Environments;
