import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { getApi, deleteApi, putApi } from '../api/api';
import { ApiUser, UserType } from '../api/ApiTypes';
import { getColumnWidth, getNameFromUser, accessorType } from '../utils/tableUtils';
import { buildAvatarColumn } from '../components/stationAdmin/UserTable';
import { SvgIconButton } from '../components/inputs/Button';
import { DeleteIcon, EditIcon } from '../images/icons';
import { Modal } from '../components/layouts/Modal';
import { Link } from 'react-router-dom';
import Message from '../components/layouts/Message';
import VerticalDotMenu from '../components/VerticalDotMenu';
import firebaseConfig from '../firebase/firebase-config';
import { OutlinedInput } from '../components/inputs/OutlinedInput';
import { Select } from '../components/inputs/Select';
import Table from '../components/stationAdmin/Table';
import { omit } from 'lodash';
import { CircleButton } from '../components/inputs/Button';
import { getPageIndicators } from '../utils/pagination';

const makeColumn = (header: string, accessor: accessorType, data: ApiUser[]) => {
  return { accessor, Header: header, width: getColumnWidth(data, accessor, header) };
};

const UserAdmin: React.FunctionComponent = () => {
  const [users, setUsers] = useState([] as ApiUser[]);
  const [filteredUsers, setFilteredUsers] = useState([] as ApiUser[]);
  const [filterValue, setFilterValue] = useState('');
  const [userToDelete, setUserToDelete] = useState(undefined as ApiUser|undefined);
  const [stationsToReview, setStationsToReview] = useState([] as Record<string, string>[]);
  const [userToEdit, setUserToEdit] = useState(undefined as ApiUser|undefined);
  const [loading, setLoading] = useState(false);
  const [userTypes, setUserTypes] = useState({} as any);
  const [activeUserTypeList, setActiveUserTypeList] = useState([] as UserType[]);
  const [playerQueryResults, setPlayerQueryResults] = useState({ totalCount: 0, limit: 10, offset: 0, search: '', results: [] });
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  const removeUser = (user: ApiUser) => {
    deleteApi(`/user/${user.id}`).then(async (r) => {
      const json = await r.json();

      if (!r.ok) {
        console.log(json.message || 'Could not delete player');
      } else {
        setUsers(users.filter((u) => u.id !== user.id));
        setUserToDelete(undefined);
        if (json.length > 0) {
          setStationsToReview(json);
        }
      }
    });
  }

  const columns = useMemo(() => [
    buildAvatarColumn('avatarUrl'),
    makeColumn('NAME', getNameFromUser as (val: Record<string, any>) => string, users),
    makeColumn('EMAIL', 'email', filteredUsers),
    makeColumn('PLAYER TYPE', 'userType', filteredUsers),
    {
      id: 'id',
      Header: 'ID',
      Cell: function buildDots({ row: { original: user } }: { row: { original: ApiUser } }) {
        return <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://console.firebase.google.com/project/${firebaseConfig.projectId}/firestore/data/~2Fusers~2F${user.id}`}
        >
          {user.id}
        </a>;
      },
      width: 250,
      disableSortBy: true
    },
    {
        id: 'dots',
        Cell: function buildDots({ row: { original: user } }: { row: { original: ApiUser } }) {
          return <UserMenuButtons
            user={user}
            onDeleteClick={() => setUserToDelete(user)}
            onEditClick={() => setUserToEdit(user)}
          />;
        },
        width: 30,
        disableSortBy: true
    }
  ], [filteredUsers]);


  const userTypeHelper = (ut?: string, uts?: UserType[]) => {
    const allUserTypeMap = (uts)? uts: userTypes;
    if(!ut) return Object.keys(allUserTypeMap)[0] as UserType;
    //console.log({ userType: ut, outputUserType: allUserTypeMap[ut], uts });
    return allUserTypeMap[ut] as UserType;
  }

  const queryUsers = async (_offset=0, _search='', _isForceUpdate = false) => {
    setLoading(true);
    const uts = userTypes;
    const pqr = playerQueryResults;
    const search = _isForceUpdate? encodeURIComponent(_search): encodeURIComponent((_search? _search: pqr.search));
    const limit = pqr.limit;
    const offset = _offset?? pqr.offset;

    await getApi(`/users/paginated?limit=${limit}&offset=${offset}&search=${search}`).then(async (userResponse) => {
      if (userResponse) {
        const pqRes = await userResponse.json();
        const users = pqRes.results.map(
          (u: ApiUser) => { return { ...u, userType: userTypeHelper(u.userType, uts) } }
        );

        setPlayerQueryResults({ ...playerQueryResults, ...{ ...pqRes, results: users } });

        const undeletedUsers = users.filter((user: ApiUser) => !user.deletedAt)
        setUsers(undeletedUsers);
        setFilteredUsers(undeletedUsers);
      }
    }).catch((e: Error) => {
      console.log('Could not get user search result with error: ', e);
    });
    setLoading(false);
  };

  const getUserTypes = async () => {
    getApi('/user/types').then(async (res) => {
      const uts = await res.json();
      setUserTypes(uts);
      const activeUTs = Object.keys(uts).reduce((acc, key: string) => {
        const ut = uts[key];
        return (!acc.find((u) => u === ut))? acc.concat(ut as UserType): acc;
      }, [] as UserType[]);

      setActiveUserTypeList(activeUTs);
      return { userTypes: uts, activeUserTypes: activeUTs };
    });
  };

  const gotoPage = (pageNumber: number) => {
    const itemsPerPage = playerQueryResults.limit;
    //const totalCount   = playerQueryResults.totalCount;
    const offset = (pageNumber - 1) * itemsPerPage;
    console.log('DOS OFFSET: ', offset);
    queryUsers(offset);
  };

  const previousPage = () => {
    gotoPage(pageIndex);
  }

  const nextPage = () => {
    gotoPage(pageIndex + 2);
  }

  React.useEffect(() => {
    getUserTypes();
  }, []);

  React.useEffect(() => {
    if(Object.keys(userTypes).length > 0) queryUsers();
  }, [userTypes]);

  React.useEffect(() => {
    console.log({ playerQueryResults });
    const pqr = playerQueryResults;
    setCanPreviousPage(pqr.offset > 0);
    setCanNextPage(((pqr.offset + 10) < pqr.totalCount));
    setPageCount(Math.ceil(pqr.totalCount / pqr.limit));
    console.log({ offset: pqr.offset, limit: pqr.limit});
    setPageIndex((pqr.offset) / pqr.limit);
  }, [playerQueryResults]);

  React.useEffect(() => {
    console.log({ pageIndex, canPreviousPage, canNextPage, pageCount });
  }, [canPreviousPage, pageCount, canNextPage, pageIndex]);

  const filterByName = (user: ApiUser, value: string): boolean => {
    return getNameFromUser(user).toLowerCase().includes(value.toLowerCase());
  }

  /*const filterUsers = () => {
    if (filterValue) {
      setFilteredUsers(users.filter(user => filterByName(user, filterValue)));
    } else {
      setFilteredUsers(users);
    }
  }

  React.useEffect(() => {
    filterUsers();
  }, [filterValue, users])
  */

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setFilterValue(search);
    //if(search !== playerQueryResults.search) queryUsers(0, search, true);
  }

  const doSearch = () => {
    const search = filterValue;
    if(search !== playerQueryResults.search) queryUsers(0, search, true);
  }

  const updateUser = (userData: ApiUser) => {
    setLoading(true);
    // We need to remove non-editable keys from the update data, hence the omit
    putApi(
      `/user/${userData.id}/userType/${userData.userType}`
    ).then(async (r) => {
      setLoading(false);
      const json = await r.json();

      if (!r.ok) {
        console.log(json.message || 'Could not update player');
      } else {
        setFilteredUsers(filteredUsers.map((user) => user.id === userData.id ? json : user));
        setUserToEdit(undefined);
      }
    });
  }

  return (
    <>
      <FilterWrapper>
        <label htmlFor="userFilter">Search Players</label>
        <OutlinedInput
          type="text"
          id="userFilter"
          name="userFilter"
          onChange={updateSearch}
          value={filterValue}
        />
        { !loading && (<Button onClick={doSearch}>Search</Button> )}
      </FilterWrapper>
      <Table
        columns={columns}
        data={filteredUsers}
        title="All Players"
        defaultSort={[{ id: 'firstName', desc: false }]}
      />
      <DeleteUserModal
        user={userToDelete}
        isOpen={!!userToDelete}
        hideModal={() => setUserToDelete(undefined)}
        onDelete={removeUser}
      />
      <ReviewTransferredStationsModal
        stations={stationsToReview}
        isOpen={stationsToReview.length > 0}
        hideModal={() => setStationsToReview([])}
      />
      <EditUserModal
        user={userToEdit}
        isOpen={!!userToEdit}
        hideModal={() => setUserToEdit(undefined)}
        onEdit={updateUser}
        loading={loading}
        userTypes={userTypes}
        activeUserTypeList={activeUserTypeList}
        userTypeHelper={userTypeHelper}
      />

       <PaginationSuperWrapper>
         <PaginationWrapper>
              <CircleButton onClick={() => gotoPage(1)} disabled={!canPreviousPage}>
                  {'<<'}
              </CircleButton>
              <CircleButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
              </CircleButton>
              <PaginationWrapper>
                  {getPageIndicators(pageIndex + 1, pageCount).map((pageNumber) => {
                      if (typeof pageNumber === 'string')
                          return <PageIndicatorSpacer key={pageNumber}>{pageNumber}</PageIndicatorSpacer>;
                      if (pageIndex === pageNumber - 1)
                          return <ActivePageIndicator key={pageNumber}>{pageNumber}</ActivePageIndicator>;

                      return (
                          <PageIndicatorLink onClick={() => gotoPage(pageNumber)} key={pageNumber}>
                              {pageNumber}
                          </PageIndicatorLink>
                      );
                  })}
              </PaginationWrapper>
              <CircleButton onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
              </CircleButton>
              <CircleButton onClick={() => gotoPage(pageCount)} disabled={!canNextPage}>
                  {'>>'}
              </CircleButton>
          </PaginationWrapper>
        </PaginationSuperWrapper>



    </>
  );
}

const FilterWrapper = styled.div`
  width: 300px;
  padding: 10px 30px;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const MenuWrapper = styled.div`
    position: relative;
    width: 30px;
    height: 60px;
`;

const UserMenuButtons = ({ user, onDeleteClick, onEditClick }: {
  user: ApiUser, onDeleteClick: () => void, onEditClick: () => void
}) => {
  return (
    <MenuWrapper>
      <VerticalDotMenu>
        <SvgIconButton
            onClick={onDeleteClick}
            activeColor="var(--burnt-red)"
            baseColor="var(--off-white)"
            iconType="stroke"
        >
            <DeleteIcon /> Delete
        </SvgIconButton>
        <SvgIconButton
            onClick={onEditClick}
            activeColor="var(--soft-green)"
            baseColor="var(--off-white)"
            iconType="stroke"
        >
            <EditIcon /> Edit
        </SvgIconButton>
      </VerticalDotMenu>
    </MenuWrapper>
  );
};

const DeleteUserModal = ({ user, isOpen, hideModal, onDelete }: {
  user?: ApiUser, isOpen: boolean, hideModal: () => void, onDelete: (user: ApiUser) => void,
}) => {
  return (
    <Modal handleClose={hideModal} isOpen={isOpen}>
      {user && (
        <Message
          title={`Are you sure you want to delete player ${user.firstName} ${user.lastName}?`}
          body="This is not a soft delete. All information about this player will be removed."
        >
          <ModalButtons>
            <CancelButton onClick={hideModal}>Cancel</CancelButton>
            <SvgIconButton
                onClick={() => onDelete(user)}
                activeColor="var(--burnt-red)"
                iconType="stroke"
                baseColor="var(--off-white)"
            >
                <DeleteIcon /> Yes, Delete Player
            </SvgIconButton>
          </ModalButtons>
        </Message>
      )}
    </Modal>
  );
};

const EditUserModal = ({ user, isOpen, hideModal, onEdit, loading, userTypes, activeUserTypeList, userTypeHelper }: {
  user?: ApiUser,
  isOpen: boolean,
  hideModal: () => void,
  onEdit: (user: ApiUser) => void,
  loading: boolean,
  userTypes: any,
  activeUserTypeList: UserType[],
  userTypeHelper: (ut: string, uts?: UserType[]) => UserType;
}) => {
  const [userType, setUserType] = useState('' as UserType);

  

  useEffect(() => {
    if (user) {
      setUserType(user.userType!);
    }
  }, [user]);

  const handleSubmit = () => {
    if (user) {
      onEdit({ ...user, userType })
    }
  }
  return (
    <Modal handleClose={hideModal} isOpen={isOpen}>
      {user && (
        <Message title={`Edit player ${user.firstName} ${user.lastName}`}>
          <label htmlFor="userType">Player Type</label>
          <Select
            id="userType"
            value={userTypeHelper(userType)}
            onChange={(value: string) => setUserType(value as UserType)}
            options={activeUserTypeList.map((option) => ({ label: userTypeHelper(option), value: userTypeHelper(option) }))}
            width="400px"
          />
          <ModalButtons>
            <CancelButton onClick={hideModal}>Cancel</CancelButton>
            <SvgIconButton
                onClick={handleSubmit}
                activeColor="var(--soft-green)"
                iconType="stroke"
                baseColor="var(--off-white)"
                disabled={loading}
            >
                <EditIcon /> {loading ? 'Updating...' : 'Update Player'}
            </SvgIconButton>
          </ModalButtons>
        </Message>
      )}
    </Modal>
  );
}

const ReviewTransferredStationsModal = ({ stations, isOpen, hideModal }: {
  stations: Record<string, string>[], isOpen: boolean, hideModal: () => void,
}) => {
  return (
    <Modal handleClose={hideModal} isOpen={isOpen}>
      <Message
        title="You are now the owner of the following stations"
        body="These stations were owned by the deleted user. Click on the links below to see station info"
      >
        <ul>
          {stations.map((station) => (
            <li key={station.id}><Link to={`/station/${station.id}`}>{station.name}</Link></li>
          ))}
        </ul>
      </Message>
    </Modal>
  );
};

const CancelButton = styled.button`
    appearance: none;
    color: var(--burnt-red);
    font-size: 16px;
    font-weight: bold;
    background: none;
    border: none;
    margin-right: 20px;
`;

const ModalButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-align: center;
    width: 100%;
`;

const Button = styled.button`
    color: var(--midnight);
    border-radius: 15px;
    font-weight: bold;
    padding: 5px 15px;
    font-size: 17px;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
        background-color: var(--tennis-green);
    }
    position: relative;
    margin-top: 20px;
`;

const PaginationSuperWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 1024px;
`;

const PaginationWrapper = styled.div`
    display: flex;
`;

const PageIndicatorStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    line-height: 1.14;
    text-align: center;
    letter-spacing: 1.08px;
    opacity: 0.9;
    color: #e3e2e9;
    font-weight: normal;
    padding: 0 5px;

    &:first-child {
        margin-left: 10px;
    }

    &:last-child {
        margin-right: 10px;
    }
`;

const PageIndicatorLink = styled(PageIndicatorStyles)`
    cursor: pointer;

    &:hover {
        color: #fff;
        text-shadow: 1px 0px 0px #fff;
    }
`;

const ActivePageIndicator = styled(PageIndicatorStyles)`
    color: var(--tennis-green);
    font-weight: 700;
`;

const PageIndicatorSpacer = styled(PageIndicatorStyles)``;

export default UserAdmin
