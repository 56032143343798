// @ts-nocheck
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { env } from '../utils/envLoader';
import { write3DAccessableCookie } from '../utils/CookieUtil';
import { getCurrentUserId, getCurrentUserEmail, getCurrentUserType, getCurrentUser } from '../store/selectors';
import { useSelector } from 'react-redux';
import { getApi } from '../api/api';
import styled from 'styled-components';
import { Redirect, useHistory, useParams, useLocation } from 'react-router-dom';
import MiddleRenderMusuem from '../images/middleRenderMusuem.webp';
import OpenTabIcon from '../images/i-open-tab-ko.svg';
import { canUseUnlimitedTime, isAnon, isGuest } from '../permissions/users';

//test CC: 4242 4242 4242 4242

// @ts-ignore
declare module 'react' {
    interface HTMLAttributes<T> extends React.HTMLAttributes<T> {
        'stripe-pricing-table'?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
}

/*
declare module 'react' {
  interface IntrinsicElements {
    'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
  }
}
*/

export const PricingPage = () => {
    const nwrUserId = useSelector(getCurrentUserId);
    const user = useSelector(getCurrentUser);
    const id = user.id;
    const nwrUserEmail = useSelector(getCurrentUserEmail);
    const history = useHistory();

    const userType = useSelector(getCurrentUserType);

    const location = useLocation();
    console.log('Location:', location); // {pathname: "/pricing", search: "", hash: "", state: undefined, key: "3j3z3d"}
    const successRoute =
        location.search.substring(1) ||
        '/profile-edit?fromPage=pricing&fromUserType=' + user?.userType + '&userId=' + user.id;
    const successRouteWithoutParams = (location.search.split('?')[1])?? encodeURIComponent('/pricing');
    console.log('successRoute', successRoute);

    const closeModal = () => console.log('Subscription Modal closed');

    //const { loading, setLoading } = useState(true);
    //const { customerSessionClientSecret,  setCustomerSessionClientSecret } = useState(undefined as string | undefined);

    let loading = true;
    let customerSessionClientSecret: string | undefined = undefined;

    const handleGoBack = () => {
        if (isGuest(user)) {
            putApi(`/user/${id}/upgrade/free`)
                .then(async (resp) => {
                    const response = await resp.json();
                    if (resp.ok) {
                        dispatch(setCurrentUser(response));
                        history.push(successRoute);
                    }
                })
                .catch((e) => {
                    console.error('Error upgrading account to play');
                    history.push(successRoute);
                });
        } else {
            try {
                //check if there is history to go back to 
                if(location.key !== 'default'){
                    history.goBack();
                } else {
                    history.push('/spaces#my-stations');
                }
            } catch (e) {
                console.error('Error going back, going to success route');
                history.push('/spaces#my-stations');
            }
        }
    };

    useEffect(() => {
        if(!userType) return;
        console.log('user:', user, userType, nwrUserId);
        if (isAnon(user)) {
            history.push('/sign-up?returnToPricing=true&successRoute=' + successRouteWithoutParams);
            return;
        }

        getApi('/payment/session').then(async (result) => {
            const json = await result.json();
            console.log(json);
            if (json.success) customerSessionClientSecret = json.body.client_secret;
            loading = false;
        });
        if (successRoute) {
            write3DAccessableCookie(
                'paymentSuccessRoute',
                successRoute,
                new Date(new Date().setDate(new Date().getDate() + 1))
            );
        }
        console.log('canUseUnlimitedTime: ', canUseUnlimitedTime(user));
    }, [user]);

    return (
        <>
            {loading && (
                <DaddyContatiner>
                    <Title>Choose a plan</Title>
                    <SubtitleTextCurrentPlan>
                        Current Plan: {userType === 'Play' ? 'Play (Free)' : userType}
                    </SubtitleTextCurrentPlan>
                    <StripeContainer>
                        <stripe-pricing-table
                            style={{ width: '100vw' }}
                            pricing-table-id={env('STRIPE_PRICING_TABLE_ID')}
                            publishable-key={env('STRIPE_PUBLISHABLE_KEY')}
                            client-reference-id={nwrUserId}
                            customer-email={nwrUserEmail}
                            customer-session-client-secret={customerSessionClientSecret}
                        ></stripe-pricing-table>
                    </StripeContainer>
                    <HorizontalRule />
                    <FlexRowSpaceBetween>
                        <FlexRow>
                            <BlueButton>
                                <BlueButtonText onClick={handleGoBack}>
                                    {!canUseUnlimitedTime(user) ? 'CONTINUE AS PLAY' : 'BACK'}
                                </BlueButtonText>
                            </BlueButton>

                            <SubtitleText>
                                {!canUseUnlimitedTime(user)
                                    ? 'Continue with limited free Play account'
                                    : 'Continue with current account'}
                            </SubtitleText>
                        </FlexRow>
                        <FlexRowLinkOutButton onClick={() => window.open('https://www.nowhere.io/pricing', '_blank')}>
                            <OpenTabIconImg src={OpenTabIcon}></OpenTabIconImg>
                            <LinkText>Full Feature List</LinkText>
                        </FlexRowLinkOutButton>
                    </FlexRowSpaceBetween>
                    <SubCard>
                        <SubCardImage src={MiddleRenderMusuem}></SubCardImage>
                        <FlexColumn>
                            <SubCardTitle>Custom experience? Big event? Enterprise?</SubCardTitle>
                            <SubtitleText>— Custom world build & experience development</SubtitleText>
                            <SubtitleText>— API integration & white label embeds</SubtitleText>
                            <SubtitleText>— Unlimited capacities (150 per space)</SubtitleText>
                            <SubtitleText>— Hybrid events & Disguise XR Stage integration</SubtitleText>
                            <GreenButton onClick={() => window.open('https://www.nowhere.io/contact', '_blank')}>
                                <GreenButtonText>GET IN TOUCH</GreenButtonText>
                            </GreenButton>
                        </FlexColumn>
                    </SubCard>
                </DaddyContatiner>
            )}
        </>
    );
};

const OpenTabIconImg = styled.img`
    width: 18px;
    height: 18px;
    margin-right: 10px;
`;

const LinkText = styled.div`
    font-family: all-round-gothic;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #fff;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
`;

const DaddyContatiner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #010027;
    margin-top: 20px;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    align-items: flex-start;
    min-width: 325px;
`;

const Title = styled.div`
    font-family: all-round-gothic;
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: center;
    color: white;
`;

const StripeContainer = styled.div`
    width: 80vw;
`;
const HorizontalRule = styled.hr`
    width: 100%;
    color: white;
    margin: 20px 0;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    align-items: center;
`;

const FlexRowLinkOutButton = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 825px) {
        margin-top: 20px;
    }
`;

const FlexRowSpaceBetween = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    max-width: 960px;
    @media (max-width: 825px) {
        flex-direction: column;
        align-items: center;
    }
`;

const BlueButton = styled.button`
    height: 30px;
    margin: 0 20px 0 0;
    padding: 7px 15px 6px;
    border-radius: 20px;
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.35), 1px 1px 15px 0 rgba(0, 0, 0, 0.35);
    border: solid 1px white;
    background-color: var(--navy-blue);
    display: flex;
    align-items: center;
`;

const BlueButtonText = styled.div`
    font-family: all-round-gothic;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: white;

    @media (max-width: 585px) {
        font-size: 12px;
    }
`;

const GreenButton = styled.button`
    height: 45px;
    width: 100%;
    padding: 7px 15px 6px;
    border-radius: 20px;
    background-color: #bee639;
    margin-right: 30px;
    margin-top: 40px;
`;

const GreenButtonText = styled.div`
    font-family: all-round-gothic;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #010027;
`;

const SubCardTitle = styled.div`
    margin: 0 0 10px;
    text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
    font-family: all-round-gothic;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 0.5px;
    color: #fff;
`;
const SubtitleTextCurrentPlan = styled.div`
    font-family: all-round-gothic;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: white;
    margin-bottom: 20px;
`;
const SubtitleText = styled.div`
    font-family: all-round-gothic;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: white;
`;

const SubCard = styled.div`
    width: 80vw;
    max-width: 960px;
    height: 340px;
    margin-top: 40px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(-6deg, #1f2030 1%, #343550 100%);
    display: flex;
    margin-bottom: 40px;
    @media (max-width: 768px) {
        flex-direction: column;
        height: 600px;
    }
`;

const SubCardImage = styled.img`
    width: 50%;
    object-fit: cover;
    height: 300px;
    margin-right: 20px;
    border-radius: 10px;
    @media (max-width: 950px) {
        width: 40%;
        height: 300px;
        margin-bottom: 20px;
    }

    @media (max-width: 768px) {
        width: 100%;
        height: 300px;
        margin-bottom: 20px;
    }
`;
