import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Message from '../components/layouts/Message';
import { Modal } from '../components/layouts/Modal';
import { getApi, putApi } from '../api/api';
import { SubscriptionModal } from '../components/modals/PaymentModals';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, getCurrentUserEmail, getCurrentUserId, getCurrentUserType } from '../store/selectors';
import { write3DAccessableCookie } from '../utils/CookieUtil';
import { env } from '../utils/envLoader';
import { hasOwnerRole, isStationOwner } from '../permissions/stations';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import TimeCat from '../images/time-cat.gif';
import { canUseUnlimitedTime, isAnon, isGuest } from '../permissions/users';
import { setCurrentUser } from '../store/actions';


const SpaceKicked: React.FC = () => {
    const currentUser = useSelector(getCurrentUser);
    const userType = useSelector(getCurrentUserType);
    const nwrUserId = useSelector(getCurrentUserId);
    const nwrUserEmail = useSelector(getCurrentUserEmail);


    const dispatch = useDispatch();
    const { spaceId } = useParams() as { spaceId: string };
    const params = new URLSearchParams(window.location.search);
    const reason = params.get('reason');
    const bootType = params.get('type');

    const meetingTimerExpired = bootType === 'meetingTimerExpired';
    const [userOwnsStation, setUserOwnsStation] = useState(false);


    console.log('BOOTED:: ', bootType);
    const isRejoinAllowed = bootType === 'joinedTwice' ? true : false;
    const [stationId, setStationId] = React.useState('');

    const [rejoinTimer, setRejoinTimer] = useState(60);
    const [isRejoinButtonDisabled, setIsRejoinButtonDisabled] = useState(true);
    const [rejoinButtonText, setRejoinButtonText] = useState(`REJOIN SPACE IN ${rejoinTimer}`);
    const [pricingPageVisible, setPricingPageVisible] = useState(false);
    React.useEffect(() => {
        getApi(`/space/${spaceId}/stationId`).then(async (r) => {
            const json = await r.json();
            console.log('json', json);

            if (r.ok) {
                setStationId(json.stationId);
                await getApi(`/station/${json.stationId}`).then(async (ss) => {
                    const json = await ss.json();
                    console.log('station json', json);
                    setUserOwnsStation(json.station.role === 'OWNER');

                });
            }
        });
        alert(isRejoinAllowed);
    }, [spaceId]);

    useEffect(() => {
        const timer = setInterval(() => {
            setRejoinTimer((prevTimer) => {
                const newTimer = prevTimer - 1;
                setRejoinButtonText(newTimer > 0 ? `REJOIN SPACE IN ${newTimer}` : 'REJOIN SPACE');
                setIsRejoinButtonDisabled(newTimer > 0);
                return newTimer;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const getMyFreeMeet = () => {
        if (isAnon(currentUser)) {
           // history.push('/meet?login=true');
            window.open('/meet?login=true', '_blank');
            return;
        }

        putApi(`/user/${currentUser.id}/upgrade/free`)
            .then(async (resp) => {
                const response = await resp.json();
                if (resp.ok) {
                    dispatch(setCurrentUser(response));

                    // history.push('/meet');
                    window.open('/meet', '_blank');
                    //  history.push('/station/latest')

                    //setShowFreeSpace(false)
                }
            })
            .catch((e) => {
                console.warn('Error getting free meet', e);
            });
    };

    const history = useHistory();
    const returnToStation = () => history.push(`/station/${stationId}`);

    const returnToSpace = () => {
        const isRejoinAllowed = (!meetingTimerExpired || rejoinTimer <= 0);

        if (isRejoinAllowed) {
            history.push(`/space/${spaceId}/lobby`);
        }
    };

    const forceReturnToSpace = () => history.push(`/space/${spaceId}/lobby`);

    if (meetingTimerExpired && userOwnsStation) {
        return (

            <MainContainer>
                {pricingPageVisible && (
                         <SubscriptionModal
                        handelClose={() => setPricingPageVisible(false)}
                        successRoute={'/space/'+spaceId+'/lobby?fromPage=Meet&fromUserType='+ userType + '&userId=' + currentUser.id}
                    />
                    )}

                <SpaceInfoContainer>
                    <CatGif src={TimeCat}></CatGif>
                    <Title>Your free session has ended</Title>
                    <SubTitle>Thank you for using NOWHERE. Upgrade for unlimited minutes, new worlds & more</SubTitle>
                    <FlexBox>
                    <RejoinSpaceButton onClick={returnToSpace} disabled={isRejoinButtonDisabled}>
                        <RejoinSpaceButtonText>{rejoinButtonText}</RejoinSpaceButtonText>
                    </RejoinSpaceButton>
                    </FlexBox>

                    <CTAContainer>
                        <CTATitle>Become a NOWHERE PRO.</CTATitle>
                        <SubTitle>
                            Unlimited meeting time, 15+ purpose built worlds, Space capacity up to 150, live stream,
                            large event options & advanced admin features.
                        </SubTitle>

                        <GreenButton onClick={()=>{
                                  const successRoute = '/space/'+spaceId+'/lobby?fromPage=SpaceKicked&fromUserType='+ userType + '&userId=' + currentUser.id;
                                  history.push('/pricing?' + successRoute)
                        }}>
                           <GreenButtonText >SEE PLANS & UPGRADE</GreenButtonText> 
                        </GreenButton>
                    </CTAContainer>
                </SpaceInfoContainer>
            </MainContainer>
        );
    }else if (meetingTimerExpired && ( isGuest(currentUser) || isAnon(currentUser))) {
        return (
            <MainContainer>
                <SpaceInfoContainer>
                    <CatGif src={TimeCat}></CatGif>
                    <Title>Your free session has ended</Title>
                    <SubTitle>Upgrade for unlimited minutes, new worlds & more</SubTitle>
                    <RejoinSpaceButton onClick={returnToSpace} disabled={isRejoinButtonDisabled}>
                        <RejoinSpaceButtonText>{rejoinButtonText}</RejoinSpaceButtonText>
                    </RejoinSpaceButton>

                    <CTAContainer>
                        <CTATitle>Host your next meeting NOWHERE.</CTATitle>
                        <SubTitle>Make gathering online more social, personal and fun.</SubTitle>
                        <GreenButton onClick={getMyFreeMeet}>
                            <GreenButtonText>GET MY FREE MEET</GreenButtonText>
                        </GreenButton>
                    </CTAContainer>
                </SpaceInfoContainer>
            </MainContainer>
        );
    }else if (meetingTimerExpired && !canUseUnlimitedTime(currentUser)) {
        return (
            <MainContainer>
                <SpaceInfoContainer>
                    <CatGif src={TimeCat}></CatGif>
                    <Title>Your free session has ended</Title>
                    <SubTitle>Upgrade for unlimited minutes, new worlds & more</SubTitle>
                    <RejoinSpaceButton onClick={returnToSpace} disabled={isRejoinButtonDisabled}>
                        <RejoinSpaceButtonText>{rejoinButtonText}</RejoinSpaceButtonText>
                    </RejoinSpaceButton>

                    <CTAContainer>
                        <CTATitle>Host your next meeting NOWHERE.</CTATitle>
                        <SubTitle>Make gathering online more social, personal and fun.</SubTitle>
                        <GreenButton onClick={() => window.open('/meet', '_blank')}>
                            <GreenButtonText>GO TO MY MEET</GreenButtonText>
                        </GreenButton>
                    </CTAContainer>
                </SpaceInfoContainer>
            </MainContainer>
        );
    } else if (meetingTimerExpired) {
        return (
            <MainContainer>
                <SpaceInfoContainer>
                    <CatGif src={TimeCat}></CatGif>
                    <Title>Your free session has ended</Title>
                    <SubTitle>Upgrade for unlimited minutes, new worlds & more</SubTitle>
                    <RejoinSpaceButton onClick={returnToSpace} disabled={isRejoinButtonDisabled}>
                        <RejoinSpaceButtonText>{rejoinButtonText}</RejoinSpaceButtonText>
                    </RejoinSpaceButton>

                    <CTAContainer>
                        <CTATitle>Host your next meeting NOWHERE.</CTATitle>
                        <SubTitle>Make gathering online more social, personal and fun.</SubTitle>
                        <GreenButton onClick={() => window.open('/station/latest', '_blank')}>
                            <GreenButtonText>GO TO MY STATION</GreenButtonText>
                        </GreenButton>
                    </CTAContainer>
                </SpaceInfoContainer>
            </MainContainer>
        );
    } else {
        return (
            <Modal isOpen={true} handleClose={returnToStation}>
                <Message
                    title="You've been kicked out"
                    onConfirm={returnToStation}
                    confirmButtonText="Return to Station"
                    secondConfirmButtonDisabled={!isRejoinAllowed}
                    onSecondConfirm={isRejoinAllowed ? returnToSpace : undefined}
                    secondConfirmButtonText={'rejoin here'}
                >
                    {reason && (
                        <>
                            Message: &ldquo;{reason}&ldquo;
                            <br />
                        </>
                    )}
                    {params.get('banned') ? (
                        <>You may need to ask a Host if you can return to the space.</>
                    ) : (
                        !reason && <>Please take some time off and then you&#39;re welcome to come back</>
                    )}
                </Message>
            </Modal>
        );
    }
};

const FlexBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const CTAContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    width: 100%;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(-6deg, #1F2030 1%, #343550 100%);
    border-radius: 20px;
    margin-top: 40px;
`;

const CatGif = styled.img`
    height: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
    object-fit: cover;
    @media (max-width: 768px) {
        height: 150px;
    }
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    align-items: center;
    max-width: 768px;
`;

const SpaceInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    align-items: flex-start;
`;


const RejoinSpaceButtonText = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    &:hover {
        color: #0a083f;
    }
`;

const RejoinSpaceButton = styled.div<{ disabled: boolean }>`
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
    max-width: 300px;
    border-radius: 20px;
    background-color: ${(props) => (props.disabled ? 'gray' : '#010027')};
    border: solid 1px white;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.25s ;
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    
    &:hover {
        background-color: ${(props) => (props.disabled ? 'gray' : 'white')};
    }
    &:hover ${RejoinSpaceButtonText} {
        color: #0a083f;
      }
`;

const GreenButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
    max-width: 300px;
    border-radius: 20px;
    background-color: #bee639;
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
    margin-bottom: 10px;
    transition: all 0.25s ;
    &:hover {
        background-color: white;
    }
`;

const GreenButtonText = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #0a083f;
    margin-left: 10px;
    margin-right: 10px;
`;

const Title = styled.div`
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
    margin-bottom: 20px;
`;
const CTATitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
`;
const SubTitle = styled.div`
    font-size: 14px;
    margin-bottom: 20px;
`;

export default SpaceKicked;

